import React, { useState, useContext, useEffect } from "react";
import { MenuContext } from "../context/MenuContext";
import { AuthContext } from "../context/AuthContext";
import OffCanvas from "../components/global/OffCanvas";
import { PartidasContext } from "../context/PartidasContext";
import { DestajosContext } from "../context/DestajosContext";
import { EditModeContext } from "../context/EditModeContext";
import { ProyectosContext } from "../context/ProyectosContext";
import { CapacitorContext } from "../context/CapacitorContext";
import ListaCotejoTabla from "../components/obra/ListaCotejoTabla";
import { SubdestajosContext } from "../context/SubdestajosContext";
import PartidaDetail from "../components/cronograma/PartidaDetail";
import DestajoDetail from "../components/cronograma/DestajoDetail";
import CronogramaMobile from "../components/cronograma/CronogramaMobile";
import SubdestajoDetail from "../components/cronograma/SubdestajoDetail";
import PlantillasButtons from "../components/plantillas/PlantillasButtons";

const Progreso = ({ idProyecto }) => {
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const { editMode, editModeOn, editModeOff, viewLevel } =
    useContext(EditModeContext);
  const { subdestajo, subdestajos, getSubDestajos } =
    useContext(SubdestajosContext);
  const { partida, partidas, getPartidasProyecto } =
    useContext(PartidasContext);
  const { proyecto, getSingleProyecto } = useContext(ProyectosContext);
  const { destajo, destajos, getDestajos } = useContext(DestajosContext);
  const { platform } = useContext(CapacitorContext);
  const { setSelected } = useContext(MenuContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    getObra();
    setSelected({ title: "Cronograma" });
    return handleClear;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClear = () => {
    //clearAlerts();
    editModeOff();
  };

  const getObra = () => {
    editModeOff();
    getPartidasProyecto(idProyecto);
    getSingleProyecto(idProyecto);
    getSubDestajos(idProyecto);
    getDestajos(idProyecto);
  };

  const handleDetail = () => {
    setShowOffCanvas(true);
  };

  const renderLista = () => {
    if (proyecto && proyecto !== null) {
      if (proyecto.permiso && proyecto.permiso !== "cliente") {
        if (platform === "web") {
          return (
            <ListaCotejoTabla
              obra={proyecto}
              proyecto={proyecto}
              partidas={partidas}
              destajos={destajos}
              editMode={editMode}
              getProyecto={getObra}
              viewLevel={viewLevel}
              editModeOn={editModeOn}
              editModeOff={editModeOff}
              subdestajos={subdestajos}
              showDetails={handleDetail}
            />
          );
        }
        return (
          <div className="row ">
            <CronogramaMobile
              partidas={partidas}
              destajos={destajos}
              getAllItems={getObra}
              idProyecto={idProyecto}
              subdestajos={subdestajos}
            />
          </div>
        );
      }
    }
  };

  const renderOffCanvasContent = () => {
    if (showOffCanvas) {
      if (partida !== null) {
        return (
          <PartidaDetail
            idProyecto={idProyecto}
            callback={getObra}
            partida={partida}
          />
        );
      }
      if (destajo !== null) {
        return (
          <DestajoDetail
            idProyecto={idProyecto}
            callback={getObra}
            destajo={destajo}
          />
        );
      }
      if (subdestajo !== null) {
        return (
          <SubdestajoDetail
            idProyecto={idProyecto}
            subdestajo={subdestajo}
            callback={getObra}
          />
        );
      }
    }
  };

  const renderContent = () => {
    if (platform === "web") {
      return (
        <div className="container-fluid">
          <PlantillasButtons
            idProyecto={idProyecto}
            tipo="cronograma"
            permiso={proyecto !== null ? proyecto.permiso : user.permiso}
            partidas={partidas}
          />
          {renderLista()}
          <OffCanvas
            showOffCanvas={showOffCanvas}
            setShowOffCanvas={setShowOffCanvas}
          >
            {renderOffCanvasContent()}
          </OffCanvas>
        </div>
      );
    } else {
      return (
        <div
          className="container-fluid px-0 h-100"
          style={{ overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="px-0">{renderLista()}</div>
          <OffCanvas
            showOffCanvas={showOffCanvas}
            setShowOffCanvas={setShowOffCanvas}
          >
            {renderOffCanvasContent()}
          </OffCanvas>
        </div>
      );
    }
  };

  return renderContent();
};

export default Progreso;
