import React, { useContext, useEffect, useState } from "react";
import { ProyectosContext } from "../../context/ProyectosContext";
import SearchableSelect from "../global/SearchableSelect";

const ObraClienteForm = ({ idCliente, handleCancel }) => {
  const [idProyecto, setIdProyecto] = useState("");
  const { proyectos, postProyecto, getAllProyectos } =
    useContext(ProyectosContext);

  useEffect(() => {
    getAllProyectos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(proyectos)) {
      if (proyectos.length > 0) {
        setIdProyecto(proyectos[0].idProyecto);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proyectos]);

  function handleSubmit(e) {
    e.preventDefault();
    const currentProyecto = proyectos.find(
      (proyecto) => parseInt(proyecto.idProyecto) === parseInt(idProyecto)
    );
    if (currentProyecto && currentProyecto !== null) {
      postProyecto({ ...currentProyecto, idCliente });
    }
  }

  function getOpciones() {
    if (Array.isArray(proyectos)) {
      const proyectosRender = proyectos.filter(
        ({ idCliente }) => idCliente === null
      );
      if (proyectosRender.length === 0) {
        return <option>No hay proyectos disponibles.</option>;
      }
      return <SearchableSelect
        items={proyectosRender}
        itemText={"nombre"}
        itemValue={"idProyecto"}
        modifier={(proyecto) => setIdProyecto(proyecto.value)}
        value={idProyecto}
      />
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <label>Selecciona un Proyecto</label>
      {getOpciones()}
      <div className="row mt-4">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn text-muted w-100"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn btn-primary w-100">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default ObraClienteForm;
