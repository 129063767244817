import React, { createContext, useContext, useReducer } from "react";
import DestajosReducer from "../reducers/DestajosReducer";
import DestajosService from "../services/DestajosService";
import {
  SET_PROPERTY_SINGLE_DESTAJO,
  SET_PROPERTY_DESTAJO,
  CREATE_NUEVO_DESTAJO,
  DESTAJOS_RECEIVED,
  SET_DESTAJO,
  DELETE_DESTAJO,
  REORDENAR_DESTAJOS,
} from "../types/destajos";
import { validarPorcentajeTotal } from "../utils";
import { ModalContext } from "./ModalContext";
import AvancesService from "../services/AvancesService";

const initialState = {
  destajo: null,
};

export const DestajosContext = createContext(initialState);

export const DestajosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(DestajosReducer, initialState);

  const { alert, success } = useContext(ModalContext);

  const getDestajos = (idProyecto) => {
    DestajosService.getDestajos(idProyecto).then((res) => {
      const { destajos } = res.data;
      dispatch({ type: DESTAJOS_RECEIVED, payload: destajos });
    });
  };

  const getSingleDestajo = (idProyecto, idDestajo) => {
    DestajosService.getSingleDestajo(idProyecto, idDestajo).then((res) => {
      const { destajo } = res.data;
      dispatch({ type: SET_DESTAJO, payload: destajo });
    });
  };

  const setDestajo = (destajo) => {
    dispatch({ type: SET_DESTAJO, payload: destajo });
  };

  const clearSingleDestajo = () => {
    dispatch({ type: SET_DESTAJO, payload: null });
  };

  const setPropertySingleDestajo = (key, value) => {
    dispatch({ type: SET_PROPERTY_SINGLE_DESTAJO, payload: { key, value } });
  };

  const setPropertyDestajo = (idDestajo, key, value) => {
    dispatch({
      type: SET_PROPERTY_DESTAJO,
      payload: { idDestajo, key, value },
    });
  };

  const postSingleDestajo = (idProyecto, destajo, callback) => {
    let service = DestajosService.putSingleDestajo;
    let { idDestajo } = destajo;
    if (isNaN(idDestajo)) {
      service = DestajosService.postSingleDestajo;
    }
    let data = { ...destajo };
    if (typeof data.responsable === "object" && data.responsable !== null) {
      data.responsable = data.responsable.idUsuario;
    }
    service(idProyecto, data)
      .then(() => {
        success("Subdestajo guardado.");
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch(alert);
  };

  const postDestajos = (idProyecto, destajos, callback) => {
    if (!validarPorcentajeTotal(destajos, "idDestajo"))
      return alert(
        "La suma de ponderaciones debe ser 100. Verifica las ponderaciones de los destajos."
      );
    DestajosService.postDestajos(idProyecto, destajos)
      .then(() => {
        success("Cambios a Destajos guardados exitosamente.");
        if (callback) callback();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const createNuevoDestajo = (idPartida, numDestajos) => {
    dispatch({
      type: CREATE_NUEVO_DESTAJO,
      payload: {
        idDestajo: "nuevo-" + numDestajos,
        idPartida: idPartida,
        nombre: "Nuevo Destajo",
        ponderacion: 0,
        orden: numDestajos + 1,
        progreso: 0,
      },
    });
  };

  const deleteDestajo = (idDestajo) => {
    dispatch({ type: DELETE_DESTAJO, payload: idDestajo });
  };

  const reordenarDestajos = (idPartida, { oldIndex, newIndex }) => {
    dispatch({
      type: REORDENAR_DESTAJOS,
      payload: { idPartida, oldIndex, newIndex },
    });
  };

  const postAvanceDestajo = (idDestajo, positivo, idProyecto, callback) => {
    const cont = positivo ? 10 : -10;
    AvancesService.postAvance(idProyecto, { idDestajo, cont })
      .then(() => {
        if (callback) callback();
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <DestajosContext.Provider
      value={{
        ...state,
        setDestajo,
        getDestajos,
        postDestajos,
        deleteDestajo,
        getSingleDestajo,
        reordenarDestajos,
        postSingleDestajo,
        postAvanceDestajo,
        clearSingleDestajo,
        setPropertyDestajo,
        createNuevoDestajo,
        setPropertySingleDestajo,
      }}
    >
      {children}
    </DestajosContext.Provider>
  );
};
