import api from "./api";
import { getArgs } from "../utils";

const route = "/entradas";

export default {
  getEntradas: (idProyecto, limit, offset, params) =>
    api.get(
      `${route}/${idProyecto}?limit=${limit}&offset=${offset}${getArgs(params)}`
    ),
  getByRevisionItem: (idRevisionItem) => api.get(`${route}/revision-item/${idRevisionItem}`),
  getChatLength: (idProyecto) => api.get(`${route}/${idProyecto}/length`),
  postEntrada: (idProyecto, entrada) =>
    api.post(`${route}/${idProyecto}`, { ...entrada }),
};
