import React from "react";
import CurvaCritica from "../charts/CurvaCritica";
import ColorBox from "../common/ColorBox";

const colors = [
  { title: "Retraso", color: "#db8e62", className: "retraso-gradiente" },
  { title: "A Tiempo", color: "#89d405", className: "ontime-gradiente" },
];

const CurvaCriticaObra = ({ partidas, aTiempo, real }) => {
  return (
    <div className="row pt-3 pb-2">
      {real && (
        <CurvaCritica
          sqSize={910}
          partidas={partidas.filter((partida) => partida.punto_critico)}
          aTiempo={aTiempo}
          real={real}
        />
      )}
      <div className="container-fluid">
        <ColorBox color={colors[0]} />
        <ColorBox color={colors[1]} />
      </div>
    </div>
  );
};

export default CurvaCriticaObra;
