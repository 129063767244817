import api from "./api";

const route = "/estimacionversiones";

export default {
  getEstimacionVersiones: (idProyecto, idContrato, idEstimacion) =>
    api.get(`${route}/${idProyecto}/${idContrato}/versiones/${idEstimacion}`),
  getSingleEstimacionVersion: (idProyecto, idContrato, idEstimacionVersion) =>
    api.get(`${route}/${idProyecto}/${idContrato}/${idEstimacionVersion}`),
  postEstimacionVersion: (idProyecto, idContrato, idEstimacion, file) => {
    const formData = new FormData();
    formData.append("adjunto", file);
    return api.post(
      `${route}/${idProyecto}/${idContrato}/generadores/${idEstimacion}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  downloadEstimacionVersion: (idProyecto, idEstimacionVersion) =>
    api.get(`${route}/${idProyecto}/version/${idEstimacionVersion}/descargar`, {
      responseType: "blob",
    }),
  putEstimacionVersion: (idProyecto, data) =>
    api.put(`${route}/${idProyecto}/version/${data.idEstimacionVersion}`, { ...data }),
  deleteEstimacionVersion: (EstimacionVersion) =>
    api.delete(`${route}/${EstimacionVersion}`),
};
