import React from "react";

const colors = {
  autorizada: "success",
  enviada: "dark",
  cancelada: "danger",
  creada: "warning",
  revisada: "primary",
  liberada: "success",
};

const StatusBadge = ({ fluid, color, text }) => {
  return (
    <div
      className={`badge badge-pill bg-${
        color ? color : colors[text] ? colors[text] : "primary"
      } p-2 large text-uppercase ${fluid ? "w-100": ""}`}
    >
      {text}
    </div>
  );
};

export default StatusBadge;
