import React, { useContext, useEffect } from "react";
import { CambioEstimacionVersionesContext } from "../context/CambioEstimacionVersionsContext";
import StatusBadge from "../components/common/StatusBadge";
import UserItem from "../components/users/UserItem";
import useProyecto from "../hooks/useProyecto";
import moment from "moment";

const HistorialEstimacionVersion = ({ idEstimacionVersion }) => {
  const { idProyecto } = useProyecto();
  const { cambioestimacionversiones, getCambioEstimacionVersiones } =
    useContext(CambioEstimacionVersionesContext);

  useEffect(() => {
    getCambioEstimacionVersiones(idProyecto, idEstimacionVersion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idEstimacionVersion]);

  const renderCambios = () => {
    if (Array.isArray(cambioestimacionversiones)) {
      if (cambioestimacionversiones.length === 0) {
        return <p>No hay cambios registrados en esta versión de estimación</p>;
      }
      return cambioestimacionversiones.map((cambio) => (
        <div key={cambio.idCambioEstimacionVersion} className="card p-2 shadow-sm border mb-3">
          <div className="row mb-3 align-items-center">
            <div className="col-6 col-md-8">
              <UserItem size="sm" user={cambio.Usuario} />
            </div>
            <div className="col-6 col-md-4 text-end">
              <span className="small d-block">
                {moment(cambio.createdAt).format("DD MMM YYYY")}
              </span>
              <span className="small d-block">
                {moment(cambio.createdAt).format("HH:mm")}
              </span>
            </div>
          </div>
          <p className="px-2 mb-1">{cambio.comentario}</p>
          <div className="row align-items-center">
            <div className="hide-mobile col-4 small">
              <StatusBadge text={cambio.estado_anterior} />
            </div>
            <div className="hide-mobile col-4 text-center">
              <i className="fa fa-arrow-right" />
            </div>
            <div className="col-12 col-md-4 small text-end">
              <StatusBadge text={cambio.estado_nuevo} />
            </div>
          </div>
        </div>
      ));
    }
  };
  return (
    <div>
      <h1 className="h4">Historial de Cambios</h1>
      {renderCambios()}
    </div>
  );
};

export default HistorialEstimacionVersion;
