import React, { useContext, useEffect, useState } from "react";
import { ProyectosContext } from "../../context/ProyectosContext";
import { PartidasContext } from "../../context/PartidasContext";
import { EntradasContext } from "../../context/EntradasContext";
import { AuthContext } from "../../context/AuthContext";
import BitacoraInput from "../bitacora/BitacoraInput";
import Entradas from "../bitacora/Entradas";
import ItemDetail from "./ItemDetail";
import IconItem from "../global/IconItem";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import ItemAlertas from "./ItemAlertas";

const PartidaDetail = ({ idProyecto, partida, callback }) => {
  const [tab, setTab] = useState("details");

  const { getSinglePartida, postSinglePartida, setPropertySinglePartida } =
    useContext(PartidasContext);

  const { postEntrada } = useContext(EntradasContext);

  const { proyecto } = useContext(ProyectosContext);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetchPartida();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPartida = () => {
    getSinglePartida(idProyecto, partida.idPartida);
  };

  const postComentario = (message, files) => {
    postEntrada(
      idProyecto,
      { message, files, idPartida: partida.idPartida },
      fetchPartida
    );
  };

  const renderComments = () => {
    if (Array.isArray(partida.Entradas) && tab === "comments") {
      return (
        <div className="my-3">
          <h6 className="bold">Comentarios</h6>
          <div style={{maxHeight: '70vh'}} className="">
            <Entradas
              user={user}
              height="auto"
              idProyecto={idProyecto}
              entradas={partida.Entradas}
            />

            <BitacoraInput modifier={postComentario} />
          </div>
        </div>
      );
    }
  };

  const renderPreconditions = () => {
    const dependences = [
      {
        idProyecto: 10,
        idPartida: 71,
        punto_critico: null,
        nombre: "PRELIMINARES",
        orden: 1,
        ponderacion: "2",
        fecha_inicio: "2023-10-02T00:00:00.000Z",
        fecha_fin: "2023-10-07T00:00:00.000Z",
        notificar: null,
        programado: "1.00",
        real: "0.00",
        responsable: null,
        nombre_responsable: null,
        correo_responsable: null,
        ejecutado: "0.0000",
        autorizado: "1075366.3230",
        progreso: "0.00",
      },
      {
        idProyecto: 10,
        idPartida: 72,
        punto_critico: null,
        nombre: "TERRACERIAS",
        orden: 2,
        ponderacion: "42",
        fecha_inicio: "2023-10-09T00:00:00.000Z",
        fecha_fin: "2023-10-14T00:00:00.000Z",
        notificar: null,
        programado: "1.00",
        real: "0.00",
        responsable: null,
        nombre_responsable: null,
        correo_responsable: null,
        ejecutado: "0.0000",
        autorizado: "18847111.2623",
        progreso: "0.00",
      },
    ];
    if (
      Array.isArray(dependences) &&
      partida.idPartida === 74 
      // tab === "alerts"
    ) {
      return dependences.map((current) => (
        <div className="col-6">
          <IconItem
            name="Predecesora"
            icon={
              parseInt(current.progreso) === 1
                ? "check text-success"
                : "lock text-secondary"
            }
          >
            <p className="mb-0">{current.nombre}</p>
          </IconItem>
        </div>
      ));
    }
  };

  const renderDetails = () => {
    if (tab === "details") {
      return (
        <ItemDetail
          id="idPartida"
          item={partida}
          itemLabel="Partida"
          proyecto={proyecto}
          callback={callback}
          saveItem={postSinglePartida}
          modifier={setPropertySinglePartida}
        />
      );
    }
  };

  const renderAlerts = () => {
    if(tab === 'alerts') {
      return(
        <ItemAlertas 
          idKey={"idPartida"} 
          idItem={partida["idPartida"]} 
        />
      );
    }
  }
  return (
    <div className="container-fluid pb-4" style={{height: '100%'}}>
      <IonSegment value={tab} className="mb-3">
        <IonSegmentButton onClick={() => setTab("details")} value="details">
          <IonLabel>Detalles</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton onClick={() => setTab("alerts")} value="alerts">
          <IonLabel>Alertas</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton onClick={() => setTab("comments")} value="comments">
          <IonLabel>Comentarios</IonLabel>
        </IonSegmentButton>
      </IonSegment>

      {renderDetails()}
      {renderAlerts()}
      {renderPreconditions()}
      {renderComments()}
    </div>
  );
};

export default PartidaDetail;
