import React, { useContext } from "react";
import { PartidasContratoContext } from "../../context/PartidasContratoContext";
import { EstimacionesContext } from "../../context/EstimacionesContext";
import { ConceptosContext } from "../../context/ConceptosContext";
import { ContratosContext } from "../../context/ContratosContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import { PreciarioContext } from "../../context/PreciarioContext";
import { useNavigate } from "react-router-dom";

const PreciarioButtons = () => {
  const navigate = useNavigate();
  const { proyecto } = useContext(ProyectosContext);
  const { contrato } = useContext(ContratosContext);
  const { estimacion } = useContext(EstimacionesContext);
  const { spinner, partidas, postPartidasPreciario, createPartidaPreciario } =
    useContext(PartidasContratoContext);
  const { editMode, cancelEdit, editPartidasPreciario } =
    useContext(PreciarioContext);
  const { conceptos, postConceptos } = useContext(ConceptosContext);

  const idContrato = contrato !== null ? contrato.idContrato : null;
  const idProyecto = proyecto !== null ? proyecto.idProyecto : null;

  const handleUploadPreciario = () => {
    navigate(`/obra/${idProyecto}/finanzas/${idContrato}/preciario`);
  };

  const handleSubmitPartidas = () => {
    postPartidasPreciario(idProyecto, idContrato, partidas, cancelEdit);
  };

  const handleSubmitConceptos = () => {
    postConceptos(conceptos);
  };

  const renderButtons = () => {
    if (
      editMode === null &&
      contrato !== null &&
      Array.isArray(partidas) &&
      estimacion === null
    ) {
      return (
        <div>
          {partidas.length === 0 && (
            <button
              className="btn btn-outline-primary me-2 mb-2"
              onClick={handleUploadPreciario}
            >
              <i className="fa fa-file me-1"></i> Cargar Catálogo
            </button>
          )}
          <button
            className="btn btn-outline-primary mb-2"
            onClick={editPartidasPreciario}
            disabled={spinner}
          >
            <i className="fa fa-edit me-1"></i> Editar Partidas
          </button>
        </div>
      );
    }
    if (editMode === "partidas" && estimacion === null) {
      return (
        <div>
          <button
            className="btn btn-primary me-3 mb-2"
            onClick={handleSubmitPartidas}
            disabled={spinner}
          >
            <i className="fa fa-save me-2"></i> Guardar Partidas
          </button>
          <button
            className="btn btn-outline-primary me-2 mb-2"
            onClick={createPartidaPreciario}
            disabled={spinner}
          >
            <i className="fa fa-plus me-2"></i> Agregar Partida
          </button>
          <button
            className="btn btn-outline-danger mb-2"
            onClick={cancelEdit}
            disabled={spinner}
          >
            <i className="fa fa-times me-2"></i> Cancelar
          </button>
        </div>
      );
    }
    if (editMode === "conceptos" && estimacion === null) {
      return (
        <div>
          <button
            className="btn btn-primary mb-2"
            onClick={handleSubmitConceptos}
            disabled={spinner}
          >
            Guardar Conceptos
          </button>
          <button
            variant="outline-danger mb-2"
            className="btn btn-outline-danger ml-3"
            onClick={cancelEdit}
            disabled={spinner}
          >
            Cancelar
          </button>
        </div>
      );
    }
  };

  return <div className="d-inline-block me-2">{renderButtons()}</div>;
};

export default PreciarioButtons;
