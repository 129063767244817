import React from "react";
import { IonItem, IonLabel, IonIcon } from "@ionic/react";
import usePreciario from "../../hooks/usePreciario";
import { informationCircle } from "ionicons/icons";

const ConceptoMobile = ({ concepto }) => {
  const { showConceptoDetail } = usePreciario();
  return (
    <IonItem className="custom-ionitem">
      <IonLabel>
        <span className="small">{concepto.nombre}</span>
      </IonLabel>
      <IonIcon
        onClick={() => showConceptoDetail(concepto)}
        icon={informationCircle}
        color="primary"
        slot="end"
      />
    </IonItem>
  );
};

export default ConceptoMobile;
