import React, { useContext } from "react";
import { SubdestajosContext } from "../../context/SubdestajosContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import AvancePickerMobile from "../cronograma/AvancePickerMobile";
import Rubro from "../common/Rubro";

const SubdestajoTabla = ({ subdestajo, getProyecto }) => {
  const { proyecto } = useContext(ProyectosContext);

  const { idProyecto, permiso } = proyecto;

  const { postAvanceSubDestajo } = useContext(SubdestajosContext);

  const enabled = () => {
    return ["admin", "supervisor"].includes(permiso);
  };

  const postAvance = (progreso) => {
    postAvanceSubDestajo(
      subdestajo.idSubDestajo,
      progreso > subdestajo.progreso,
      idProyecto,
      getProyecto
    );
  };

  return (
    <tr className="border-bottom">
      <td className="ponderacion">
        <span className="bold">{parseInt(subdestajo.ponderacion)}%</span>
      </td>
      <td className="rubro">
        <div className="row mx-0">
          <div className="col-2 px-0"></div>
          <div className="col-10 px-0">
            <Rubro
              completado={subdestajo.completado}
              nombre={subdestajo.nombre}
              className="p bold"
            />
          </div>
        </div>
      </td>
      <td className="text-center" style={{ width: 180 }}>
        {["admin", "supervisor"].includes(permiso) ? (
          <AvancePickerMobile
            progreso={parseInt(subdestajo.progreso)}
            setCantidad={postAvance}
            disabled={!enabled()}
            intervalo={10}
            limite={100}
          />
        ) : (
          <p className="bold mb-0">{parseInt(subdestajo.progreso)}%</p>
        )}
      </td>
      {/*<td>
        <button className="btn btn-sm btn-light border me-2">
          <i className="fa fa-bell"></i>
        </button>
        <button className="btn btn-sm btn-light border me-2">
          <i className="fa fa-user"></i>
        </button>
        <button className="btn btn-sm btn-light border">
          <i className="fa fa-comment"></i>
        </button>
        </td>*/}
    </tr>
  );
};

export default SubdestajoTabla;
