import React from "react";

const Tab = ({ title, handler, selected }) => {
  return (
    <div className="col-4">
      <button
        className={`btn w-100 btn-${
          selected ? "primary" : "outline-primary"
        }`}
        onClick={handler}
      >
        {title}
      </button>
    </div>
   
  );
};

export default Tab;
