import arrayMove from "array-move";
import { DELETE_PARTIDA, SET_PROPERTY_PARTIDA } from "../actions/types";
import {
  CREATE_NUEVA_PARTIDA,
  PARTIDAS_RECEIVED,
  REORDENAR_PARTIDAS,
  SET_PARTIDA,
  SET_PROPERTY_SINGLE_PARTIDA,
} from "../types/partidas";

const PartidasReducer = (state, { type, payload }) => {
  switch (type) {
    case PARTIDAS_RECEIVED:
      return { ...state, partidas: payload };
    case SET_PARTIDA: {
      let { partida } = state;
      if (partida !== null && typeof partida === "object" && payload !== null) {
        partida = { ...partida, ...payload };
      } else {
        partida = payload;
      }
      return { ...state, partida };
    }
    case SET_PROPERTY_PARTIDA: {
      let { idPartida, key, value } = payload;
      let partidas = [...state.partidas];
      let partida = partidas.find(
        (partida) => String(partida.idPartida) === String(idPartida)
      );
      if (partida) partida[key] = value;
      return { ...state, partidas };
    }
    case SET_PROPERTY_SINGLE_PARTIDA: {
      const partida = { ...state.partida };
      const { key, value } = payload;
      partida[key] = value;
      return { ...state, partida };
    }
    case REORDENAR_PARTIDAS: {
      const partidas = [...state.partidas];
      const { oldIndex, newIndex } = payload;
      let partidasMoved = [...arrayMove(partidas, oldIndex, newIndex)];
      partidasMoved = partidasMoved.map((partida, index) => ({
        ...partida,
        orden: index,
      }));
      return { ...state, partidas: partidasMoved };
    }
    case CREATE_NUEVA_PARTIDA:
      return {
        ...state,
        partidas: [...state.partidas, payload],
      };

    case DELETE_PARTIDA: {
      let partidas = [...state.partidas];
      for (let i = 0; i < partidas.length; i++)
        if (partidas[i].idPartida === payload) {
          if (String(payload).includes("nueva")) partidas.splice(i, 1);
          else partidas[i].idPartida = "delete" + payload;
        }
      return {
        ...state,
        partidas,
      };
    }
    default:
      return { ...state };
  }
};
export default PartidasReducer;
