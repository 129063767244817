import React, { createContext, useReducer, useContext } from "react";
import EstimacionVersionesService from "../services/EstimacionVersionesService";
import EstimacionVersionesReducer from "../reducers/EstimacionVersionesReducer";
import {
  ESTIMACIONVERSIONES_RECEIVED,
  SET_ESTIMACIONVERSION,
  CREATE_ESTIMACIONVERSION,
  SET_PROPERTY_ESTIMACIONVERSION,
} from "../types/estimacionversiones";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import moment from "moment";

const initialState = {
  estimacionversiones: null,
  estimacionversion: null,
};

export const EstimacionVersionesContext = createContext(initialState);

export const EstimacionVersionesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    EstimacionVersionesReducer,
    initialState
  );

  const { alert, success, clearModal } = useContext(ModalContext);

  const getEstimacionVersiones = (idProyecto, idContrato, idEstimacion) => {
    EstimacionVersionesService.getEstimacionVersiones(
      idProyecto,
      idContrato,
      idEstimacion
    )
      .then((response) => {
        const { estimacionversiones } = response.data;
        dispatch({
          type: ESTIMACIONVERSIONES_RECEIVED,
          payload: estimacionversiones,
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleEstimacionVersion = (
    idProyecto,
    idContrato,
    idEstimacionVersion
  ) => {
    EstimacionVersionesService.getSingleEstimacionVersion(
      idProyecto,
      idContrato,
      idEstimacionVersion
    )
      .then((response) => {
        const { estimacionversion } = response.data;
        dispatch({ type: SET_ESTIMACIONVERSION, payload: estimacionversion });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setEstimacionVersion = (EstimacionVersion) => {
    dispatch({ type: SET_ESTIMACIONVERSION, payload: EstimacionVersion });
  };

  const createEstimacionVersion = () => {
    dispatch({ type: CREATE_ESTIMACIONVERSION });
  };

  const setPropertyEstimacionVersion = (key, value) => {
    dispatch({ type: SET_PROPERTY_ESTIMACIONVERSION, payload: { key, value } });
  };

  const downloadEstimacionVersion = (idProyecto, idEstimacionVersion) => {
    EstimacionVersionesService.downloadEstimacionVersion(
      idProyecto,
      idEstimacionVersion
    )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `EstimacionVersion_${idEstimacionVersion}_Proyecto_${idProyecto}_${moment().format("YYYY_MM_DD_HH_mm_ss")}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const saveEstimacionVersion = (
    idProyecto,
    idContrato,
    idEstimacion,
    file,
    callback
  ) => {
    dispatch({ type: SHOW_SPINNER });
    EstimacionVersionesService.postEstimacionVersion(
      idProyecto,
      idContrato,
      idEstimacion,
      file
    )
      .then((res) => {
        dispatch({ type: HIDE_SPINNER });
        success("Version guardada.");
        if (typeof callback === "function") {
          callback(res.data.idEstimacionVersion);
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const updateEstimacionVersion = (idProyecto, data, callback) => {
    dispatch({ type: SHOW_SPINNER });
    EstimacionVersionesService.putEstimacionVersion(idProyecto, data)
      .then((res) => {
        dispatch({ type: HIDE_SPINNER });
        if (typeof callback === "function") {
          callback(res.data.cambioestimacionversion);
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteEstimacionVersion = (idEstimacionVersion, callback) => {
    dispatch({ type: SHOW_SPINNER });
    EstimacionVersionesService.deleteEstimacionVersion(idEstimacionVersion)
      .then(() => {
        success("EstimacionVersion deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <EstimacionVersionesContext.Provider
      value={{
        ...state,
        setEstimacionVersion,
        saveEstimacionVersion,
        getEstimacionVersiones,
        deleteEstimacionVersion,
        createEstimacionVersion,
        updateEstimacionVersion,
        downloadEstimacionVersion,
        getSingleEstimacionVersion,
        setPropertyEstimacionVersion,
      }}
    >
      {children}
    </EstimacionVersionesContext.Provider>
  );
};
