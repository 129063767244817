import React, { useContext, createContext, useReducer } from "react";
import {
  SET_ITEM,
  CREATE_ITEM,
  ITEMS_RECIBIDOS,
  SET_PROPERTY_ITEM,
  SET_ITEMS_BY_REVISION,
} from "../types/items";
import { ModalContext } from "./ModalContext";
import ItemsReducer from "../reducers/ItemsReducer";
import ItemsService from "../services/ItemsService";
import AdjuntosService from "../services/AdjuntosService";

const initialState = {
  items: null,
  item: null,
  itemsByRevision: null
};

export const ItemsContext = createContext(initialState);

export const ItemsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ItemsReducer, initialState);

  const { success, alert, clearModal } = useContext(ModalContext);

  const createItem = () => {
    dispatch({ type: CREATE_ITEM });
  };

  const setItem = (item) => {
    dispatch({ type: SET_ITEM, payload: item });
  };

  const getItemsByRevision = (idRevision, idProyecto) => {
    return new Promise((resolve, reject) => {
      ItemsService.getItemsOnRevision(idRevision, idProyecto)
      .then((res) => {
        const { items } = res.data;
        dispatch({ type: SET_ITEMS_BY_REVISION, payload: items });
        resolve(res);
      })
      .catch(err => {
        alert(err)
        reject(err)
      })
    })
  }

  const getItemsChecklist = (idProyecto, idChecklist) => {
    ItemsService.getItemsChecklist(idProyecto, idChecklist).then((res) => {
      const { items } = res.data;
      // console.log(items);
      dispatch({ type: ITEMS_RECIBIDOS, payload: items });
    });
  };

  const postItem = (idProyecto, item) => {
    let service = ItemsService.putItem;
    if (isNaN(parseInt(item.idItem))) {
      service = ItemsService.postItemChecklist;
    }
    service(idProyecto, item)
      .then(() => {
        clearModal();
        success("Item guardado.");
        getItemsChecklist(idProyecto, item.idChecklist);
      })
      .catch(alert);
  };

  const setPropertyItem = (key, value) => {
    dispatch({ type: SET_PROPERTY_ITEM, payload: { key, value } });
  };

  const putChecklistItems = (idProyecto, items, callback) => {
    ItemsService.putChecklistItems(idProyecto, items).then(() => {
      clearModal();
      success("Items guardados.");
      if (typeof callback === "function") {
        callback();
      }
      if (items.length > 0) {
        getItemsChecklist(idProyecto, items[0].idChecklist);
      }
      dispatch({ type: ITEMS_RECIBIDOS, payload: items });
    });
  };

  const deleteItem = (idProyecto, idItem, callback) => {
    ItemsService.deleteItem(idProyecto, idItem, callback).then((res) => {
      success("Item eliminado.");
      if (typeof callback === "function") {
        callback();
      }
    });
  };

  const completeItem = (idProyecto, idItem, idChecklist, callback) => {
    ItemsService.completeItem(idProyecto, idItem)
      .then(() => {
        success("Elemento actualizado.");
        if (typeof callback === "function") {
          callback();
        }
        getItemsChecklist(idProyecto, idChecklist);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            return alert("Debes agregar evidencia para completar el item.");
          }
        }
        alert(error);
      });
  };

  const uploadEvidencia = (idProyecto, item, file) => {
    let formData = AdjuntosService.getFormData(file);
    AdjuntosService.postAdjunto(formData).then((res) => {
      const { idAdjunto } = res.data;
      item.idAdjunto = idAdjunto;
      ItemsService.putItem(idProyecto, item).then(() => {
        getItemsChecklist(idProyecto, item.idChecklist);
        success("Evidencia guardada.");
        clearModal();
      });
    });
  };

  return (
    <ItemsContext.Provider
      value={{
        ...state,
        setItem,
        postItem,
        createItem,
        deleteItem,
        completeItem,
        setPropertyItem,
        uploadEvidencia,
        putChecklistItems,
        getItemsChecklist,
        getItemsByRevision
      }}
    >
      {children}
    </ItemsContext.Provider>
  );
};
