import React, { createContext, useReducer, useContext } from "react";
import { ModalContext } from "./ModalContext";
import RevisionesReducer from "../reducers/RevisionesReducer";
import RevisionesService from "../services/RevisionesService";
import { GET_REVISIONES, SET_CURRENT_REVISION } from "../types/revisiones";
import { ChecklistContext } from "./ChecklistContext";
import { ItemsContext } from "./ItemsContext";

const initialState = { revisiones: null, revision: null };

export const RevisionesContext = createContext(initialState);

export const RevisionesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(RevisionesReducer, initialState);

  const { success, clearModal, alert } = useContext(ModalContext);
  const { checklist } = useContext(ChecklistContext);
  const { getItemsByRevision, itemsByRevision, item } =
    useContext(ItemsContext);

  const isItemOnRevision = () => {
    let itemIsOnRevision = false;
    let revisionItem = {};

    if (itemsByRevision) {
      itemsByRevision.forEach((obj) => {
        if (obj.idItem === item.idItem) {
          itemIsOnRevision = true;
          revisionItem = obj;
        }
      });
    }

    return [itemIsOnRevision, revisionItem];
  };

  const getRevisiones = (idChecklist, idProyecto) => {
    const service = RevisionesService.getRevisiones;
    service(idChecklist, idProyecto)
      .then((res) => {
        const { revisiones } = res.data;
        dispatch({ type: GET_REVISIONES, payload: revisiones });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const postRevision = (revision) => {
    const service = RevisionesService.postRevision;
    service(revision)
      .then(() => {
        getRevisiones(checklist.idChecklist, revision.idProyecto);
        clearModal();
        success("Revision generada.");
      })
      .then(() => {})
      .catch((error) => {
        alert(error);
      });
  };

  const addItemToRevision = (idProyecto, item, revision) => {
    const service = RevisionesService.addItemOnRevision;

    return new Promise((resolve, reject) => {
      service(idProyecto, item, revision)
        .then((res) => {
          getItemsByRevision(revision.idRevision, idProyecto);
          resolve(res);
        })
        .catch((err) => {
          alert(err);
          reject(err);
        });
    });
  };

  const updateItemByRevision = (idProyecto, idRevisionItem, data) => {
    const service = RevisionesService.updateItemOnRevision;

    return new Promise((resolve, reject) => {
      service(idProyecto, idRevisionItem, data)
        .then((res) => {
          getItemsByRevision(state.revision.idRevision);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  const setCurrentRevision = (revision) => {
    dispatch({ type: SET_CURRENT_REVISION, payload: revision });
  };

  return (
    <RevisionesContext.Provider
      value={{
        ...state,
        postRevision,
        getRevisiones,
        setCurrentRevision,
        addItemToRevision,
        updateItemByRevision,
        isItemOnRevision,
      }}
    >
      {children}
    </RevisionesContext.Provider>
  );
};
