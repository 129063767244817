import React from "react";

const PermisoFila = ({ usuario, editPermiso, deletePermiso }) => {
  return (
    <tr className="hover-light border-bottom">
      <td className="py-4 ps-5">{usuario.nombre_usuario}</td>
      <td className="py-4">
        <i className="fa fa-envelope text-primary" />
        <span className="ms-2">{usuario.correo}</span>
      </td>
      <td className="py-4 text-capitalize">{usuario.nombre_proyecto}</td>
      <td className="py-4 text-capitalize">{usuario.permiso}</td>
      <td className="py-4">
        <button
          className="btn btn-sm btn-outline-primary me-3"
          onClick={() => editPermiso(usuario)}
        >
          <i className="fa fa-edit" />
        </button>
        <button
          className="btn btn-sm btn-outline-danger"
          onClick={() => deletePermiso(usuario)}
        >
          <i className="fa fa-trash" />
        </button>
      </td>
    </tr>
  );
};

export default PermisoFila;
