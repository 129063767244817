import React, { useContext, useEffect } from "react";
import { ContratosContext } from "../../context/ContratosContext";
import { ModalContext } from "../../context/ModalContext";
import ContratoCard from "../contratos/ContratoCard";
import { ProyectosContext } from "../../context/ProyectosContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import { CapacitorContext } from "../../context/CapacitorContext";
import { getValue } from "../../utils";
import { useNavigate } from "react-router-dom";
import ContratoForm from "./ContratoForm";
import useProyecto from "../../hooks/useProyecto";

const Contratos = () => {
  const navigate = useNavigate();
  const { idProyecto } = useProyecto();
  const appconfig = useContext(AppConfigContext);
  const { proyecto } = useContext(ProyectosContext);
  const { platform } = useContext(CapacitorContext);
  const { modalComponent } = useContext(ModalContext);
  const { contratos, createContrato, getContratosProyecto } =
    useContext(ContratosContext);
  const permiso = proyecto.permiso;

  useEffect(() => {
    getContratosProyecto(idProyecto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto]);

  useEffect(() => {
    if (Array.isArray(contratos)) {
      handleSingleContrato();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contratos]);

  const handleSingleContrato = () => {
    if (contratos.length > 0) {
      let current = contratos[0];
      if (current && current !== null) {
        if (getValue(appconfig, "single_contract", "boolean")) {
          navigate(`/obra/${idProyecto}/finanzas/${current.idContrato}`);
        }
      }
    }
  };

  const handleCreate = () => {
    console.log('crete contrac');
    modalComponent(
      "Agregar Contrato",
      <ContratoForm idProyecto={idProyecto} />
    );
  };

  function renderBotones() {
    if (["admin", "coordinador"].includes(permiso)) {
      return (
        <div className="col text-end px-0">
          <button className="btn btn-primary" onClick={() => {
            const num = contratos && contratos !== null ? contratos.length + 1 : 1;
            createContrato(num);
            handleCreate();
          }}>
            +
          </button>
        </div>
      );
    }
  }

  function renderContratos() {
    if (Array.isArray(contratos)) {
      if (contratos.length > 0) {
        return contratos.map((contrato) => (
          <div key={contrato.idContrato} className="col-12 col-md-6 col-lg-4 mb-2">
            <ContratoCard contrato={contrato} />
          </div>
        ));
      }
      return <p>No hay contratos registrados en este proyecto.</p>;
    }
  }

  const renderHeader = () => {
    if(platform === 'web') {
      return(
        <div className="row align-items-center mb-3">
          <div className="col">
            <h2 className="h4 bold mb-0">Contratos</h2>
          </div>
          {renderBotones()}
        </div>
      )
    }
  }

  return (
    <div className="container-fluid px-0">
      {renderHeader()}
      <div className="row py-3">{renderContratos()}</div>
    </div>
  );
};

export default Contratos;
