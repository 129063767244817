import api from "./api";

const route = "/checklists";

export default {
  getChecklists: (idProyecto) => api.get(`${route}/proyecto/${idProyecto}`),
  getSingleChecklist: (idProyecto, idChecklist) =>
    api.get(`${route}/${idProyecto}/${idChecklist}`),
  postChecklist: (checklist) => api.post(route, { ...checklist }),
  putChecklist: (checklist) => api.put(route, { ...checklist }),
  postTemplate: (checklist, idChecklist) =>
    api.post(`${route}/${checklist}/${idChecklist}/template`, {}),
  postChecklistSection: (idProyecto, section) =>
    api.post(`${route}/${idProyecto}/sections`, { ...section }),
  putChecklistSection: (idProyecto, section) =>
    api.put(`${route}/${idProyecto}/sections`, { ...section }),
  deleteChecklist: (idProyecto, idChecklist) =>
    api.delete(`${route}/${idProyecto}/${idChecklist}`),
  deleteChecklistSection: (idProyecto, idChecklistSection) =>
    api.delete(`${route}/${idProyecto}/section/${idChecklistSection}`),
};
