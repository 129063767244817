import React, { useReducer, createContext } from "react";
import {
  CANCEL_EDIT,
  EDIT_CONCEPTOS,
  EDIT_CONCEPTOS_EXTRA,
  EDIT_PARTIDAS_PRECIARIO,
  EDIT_CONCEPTOS_ESTIMACION,
  SET_VIEW,
} from "../types/preciario";
import FinanzasReducer from "../reducers/FinanzasReducer";

const initialState = {
  editMode: null,
  preciario: null,
  view: "catalogo"
};

export const PreciarioContext = createContext(initialState);

export const PreciarioProvider = ({ children }) => {
  const [state, dispatch] = useReducer(FinanzasReducer, initialState);

  const setView = (view) => {
    dispatch({ type: SET_VIEW, payload: view })
  }

  const cancelEdit = () => {
    dispatch({ type: CANCEL_EDIT });
  };

  const editConceptos = (idPartida) => {
    dispatch({ type: EDIT_CONCEPTOS, payload: idPartida });
  };

  const editConceptosExtra = () => {
    dispatch({ type: EDIT_CONCEPTOS_EXTRA });
  };

  const editPartidasPreciario = () => {
    dispatch({ type: EDIT_PARTIDAS_PRECIARIO });
  };

  const editConceptosEstimacion = () => {
    dispatch({ type: EDIT_CONCEPTOS_ESTIMACION });
  };

  return (
    <PreciarioContext.Provider
      value={{
        ...state,
        setView,
        cancelEdit,
        editConceptos,
        editConceptosExtra,
        editPartidasPreciario,
        editConceptosEstimacion,
      }}
    >
      {children}
    </PreciarioContext.Provider>
  );
};
