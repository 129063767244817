import React, { createContext, useReducer, useContext } from "react";
import WarehousesService from "../services/WarehousesService";
import WarehousesReducer from "../reducers/WarehousesReducer";
import {
  WAREHOUSES_RECEIVED,
  CREATE_WAREHOUSE,
  UPDATE_WAREHOUSE,
  SET_WAREHOUSE,
} from "../types/warehouses";
import { SHOW_SPINNER, HIDE_SPINNER } from "../actions/types";
import { ModalContext } from "./ModalContext";

const initialState = {
  warehouses: [],
  warehouse: {
    name: "",
    products: [],
  },
};

export const WarehousesContext = createContext(initialState);

export const WarehousesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(WarehousesReducer, initialState);
  const { alert, success, clearModal } = useContext(ModalContext);

  const getWarehouses = () => {
    WarehousesService.getWarehouses()
      .then((response) => {
        const { warehouses } = response.data;
        dispatch({ type: WAREHOUSES_RECEIVED, payload: warehouses });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleWarehouse = (warehouse_id) => {
    WarehousesService.getSingleWarehouses(warehouse_id)
      .then((response) => {
        const { warehouse } = response.data;
        dispatch({ type: SET_WAREHOUSE, payload: warehouse });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const createWarehouse = (warehouseData) => {
    dispatch({ type: CREATE_WAREHOUSE, payload: warehouseData });
    clearModal();
  };

  const updateWarehouse = (warehouseData, warehouse_id) => {
    dispatch({
      type: UPDATE_WAREHOUSE,
      payload: { warehouseData, warehouse_id },
    });
    clearModal();
  };

  const deleteWarehouse = (warehouse_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    WarehousesService.deleteWarehouse(warehouse_id)
      .then(() => {
        success("Warehouse deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <WarehousesContext.Provider
      value={{
        ...state,
        getWarehouses,
        getSingleWarehouse,
        createWarehouse,
        deleteWarehouse,
        updateWarehouse,
      }}
    >
      {children}
    </WarehousesContext.Provider>
  );
};
