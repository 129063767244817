import React, { useContext } from "react";
import { sortableHandle } from "react-sortable-hoc";
import AvancePickerMobile from "../cronograma/AvancePickerMobile";
import { SubdestajosContext } from "../../context/SubdestajosContext";
import { ModalContext } from "../../context/ModalContext";

const DragHandle = sortableHandle(() => (
  <span className="pr-1">
    <i className="fas fa-grip-lines"></i>
  </span>
));

const SubdestajoForm = ({ subdestajo }) => {
  const { confirm } = useContext(ModalContext);

  const { deleteSubDestajo, setPropiedadSubDestajo } =
    useContext(SubdestajosContext);

  const { idSubDestajo } = subdestajo;

  const handleDelete = () => {
    confirm("¿Eliminar subdestajo " + subdestajo.nombre + "?", () =>
      deleteSubDestajo(subdestajo.idSubDestajo)
    );
  };

  return (
    <tr>
      <td
        style={{ width: 50 }}
        className="draggable text-end align-middle pr-3"
      >
        <DragHandle />
      </td>
      <td className="text-end" colSpan="6">
        <table className="w-100">
          <tbody>
            <tr>
              <td style={{ width: 200 }}>
                <AvancePickerMobile
                  progreso={subdestajo.ponderacion}
                  setCantidad={(cantidad) =>
                    setPropiedadSubDestajo(
                      subdestajo.idSubDestajo,
                      "ponderacion",
                      cantidad
                    )
                  }
                  intervalo={1}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={subdestajo.nombre}
                  onChange={(e) =>
                    setPropiedadSubDestajo(
                      idSubDestajo,
                      "nombre",
                      e.target.value
                    )
                  }
                />
              </td>
              <td style={{ width: 50 }}>
                <button
                  className="btn btn-outline-danger"
                  onClick={handleDelete}
                >
                  <i className="fa fa-trash" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
};

export default SubdestajoForm;
