import { SET_DEVICE, SET_PLATFORM, SET_DEVICE_OS, SET_IS_MOBILE, SET_FCM_TOKEN } from "../types/capacitor";

const CapacitorReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_PLATFORM:
      return { ...state, platform: payload };
    case SET_DEVICE:
      return { ...state, device: payload };
    case SET_DEVICE_OS:
      return { ...state, device_OS: payload };
    case SET_IS_MOBILE:
      return { ...state, isMobileDevice: payload };
    case SET_FCM_TOKEN:
      return { ...state, fcm_token: payload };
    default:
      return { ...state };
  }
};
export default CapacitorReducer;
