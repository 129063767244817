import React from 'react'
import { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import ProductListOrderForm from '../../components/purchaseOrders/ProductListOrderForm';
import { OrdenesContext } from '../../context/OrdenesContext';

const ProductsOrderHandler = () => {
    const { deleteProductsOrder } = useContext(OrdenesContext);
    const { modalComponent, clearModal, confirm } = useContext(ModalContext)
    const handleCreateProductOrder = (orderId) => {
        modalComponent(
            "Agregar Producto a la Orden:",
            <ProductListOrderForm
                orderId={orderId}
                handleCancel={clearModal}
            />
        );

    };

    const handleEditProductOrder = (orderId, productoOrden) => {
        modalComponent(
            "Editar Orden de Producto:",
            <ProductListOrderForm
                orderId={orderId}
                productOrderToEdit={productoOrden}
                handleCancel={clearModal}
            />
        );
    };

    const handleDeleteProductOrder = (orderId, idProductoOrden) => {
        confirm("¿Eliminar Orden de Producto?", () =>
            deleteProductsOrder(idProductoOrden, orderId)
        );

    };


    return {
        handleCreateProductOrder,
        handleEditProductOrder,
        handleDeleteProductOrder
    };
}

export default ProductsOrderHandler;