import React, { useState, useEffect, useContext } from "react";
import SingleConceptoGenerador from "../../views/SingleConceptoGenerador";
import SearchableSelect from "../global/SearchableSelect";
import useProyecto from "../../hooks/useProyecto";
import usePreciario from "../../hooks/usePreciario";
import { useParams } from "react-router-dom";
import { ConceptosContext } from "../../context/ConceptosContext";
import { ModalContext } from "../../context/ModalContext";
import EstimacionVersionNuevoGenerador from "./EstimacionVersionNuevoGenerador";
import { ConceptosUbicacionesContext } from "../../context/ConceptosUbicacionesContext";

const EstimacionVersionConcepto = () => {
  const [selected, setSelected] = useState(null);

  const { idProyecto } = useProyecto();
  const { idContrato } = usePreciario();
  const { idEstimacionVersion } = useParams();

  const { clearModal, modalComponent } = useContext(ModalContext);
  const { conceptos, getConceptosEstimacionVersion } =
    useContext(ConceptosContext);
  const { saveConceptoUbicacion } = useContext(ConceptosUbicacionesContext);

  useEffect(() => {
    getConceptosEstimacionVersion(idProyecto, idEstimacionVersion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idEstimacionVersion]);

  const handleSubmitNuevo = (selectedConcepto) => {
    if (selectedConcepto) {
      saveConceptoUbicacion(
        idProyecto,
        idContrato,
        { ...selectedConcepto, idEstimacionVersion },
        handleCancelAgregar
      );
    }
  };

  const handleCancelAgregar = () => {
    clearModal();
    getConceptosEstimacionVersion(idEstimacionVersion);
  };

  const handleAgregarConcepto = () => {
    modalComponent(
      "Agregar Generador",
      <EstimacionVersionNuevoGenerador
        handleCancel={handleCancelAgregar}
        handleSubmit={handleSubmitNuevo}
      />
    );
  };

  function renderSelectConceptos() {
    if (Array.isArray(conceptos)) {
      return (
        <div className="card p-3 mb-3 shadow">
          <div className="row align-items-end">
            <div className="col-9 col-md-10">
              <h4 className="h6">Elige un concepto</h4>
              <SearchableSelect
                itemText="nombre"
                value={selected?.value}
                itemValue="idConcepto"
                modifier={setSelected}
                items={conceptos.map(concepto => ({...concepto, nombre: `[${concepto.clave}]: ${concepto.nombre}`}))}
              />
            </div>
            <div className="col-3 col-md-2 text-end">
              <button
                className="btn btn-outline-primary"
                onClick={handleAgregarConcepto}
              >
                <i className="fa fa-plus" />
              </button>
            </div>
          </div>
        </div>
      );
    }
    return <div className="spinner-border" />;
  }

  const renderConcepto = () => {
    if (selected && selected !== null) {
      return <SingleConceptoGenerador idConcepto={selected.value} />;
    }
  };

  return (
    <div>
      {renderSelectConceptos()}
      {renderConcepto()}
    </div>
  );
};

export default EstimacionVersionConcepto;
