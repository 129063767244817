import React from 'react';
import ApexCharts from 'react-apexcharts';

const ProductAnalyticsPieGraph = () => {

    const chartOptions = {
        labels: [
            "Preliminares",
            "Demoliciones",
            "Albañilleria",
            "Tablaroca"

        ],

    };

    return (
        <div className='card shadow p-3'>
            <div className='d-flex'>
                <ApexCharts type="pie" options={chartOptions} series={[15, 10, 35, 50]} width={400} />
            </div>
        </div>
    );
}

export default ProductAnalyticsPieGraph;
