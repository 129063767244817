import React, { useState, useContext, useEffect } from "react";
import { ProyectosContext } from "../../context/ProyectosContext";
import { UsuariosContext } from "../../context/UsuariosContext";
import UserPicker from "../users/UserPicker";
import UserItem from "../users/UserItem";

const Responsable = ({ item, itemLabel, modifier, handleSubmit }) => {
  const [showUsers, setShowUsers] = useState(false);
  const { proyecto } = useContext(ProyectosContext);
  const { users, getUsuariosProyecto } = useContext(UsuariosContext);
  const { idProyecto } = proyecto;

  useEffect(() => {
    getUsuariosProyecto(idProyecto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUsers]);

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowUsers(false);
    if (typeof handleSubmit === "function") {
      return handleSubmit(item);
    }
  };

  const handleUserPartida = (user) => {
    if (typeof modifier === "function") {
      modifier("responsable", user);
    }
  };

  const handleCancelResponsable = () => {
    setShowUsers(false);
    if (typeof modifier === "function") {
      modifier("responsable", null);
    }
  };

  const renderResponsable = () => {
    if (item.responsable && item.responsable !== null && !showUsers) {
      return (
        <div>
          <h6 className="bold">Responsable</h6>
          <div className="row my-3">
            {typeof item.responsable === "object" ? (
              <UserItem
                user={item.responsable}
                handleEdit={() => {
                  modifier("responsable", null);
                  modifier("idUsuario", null);
                }}
              />
            ) : (
              <UserItem
                user={{
                  nombre: item.nombre_responsable,
                  correo: item.correo_responsable,
                }}
                handleEdit={() => {
                  modifier("responsable", null);
                  modifier("idUsuario", null);
                }}
              />
            )}
          </div>
        </div>
      );
    }
    if (["admin", "coordinador"].includes(proyecto.permiso)) {
      if (showUsers) {
        return (
          <div className="row">
            <div className="container-fluid px-0">
              <h6 className="bold">Elegir un responsable</h6>
              <UserPicker
                users={users}
                user={item.responsable}
                modifier={handleUserPartida}
              />
              <div className="row align-items-center mt-3">
                <div className="col-6 ">
                  <button
                    disabled={item.responsable === null}
                    className="btn btn-sm btn-primary"
                    onClick={onSubmit}
                  >
                    Guardar
                  </button>
                </div>
                <div className="col-6 text-end ">
                  <button
                    onClick={handleCancelResponsable}
                    className="btn btn-sm btn-link text-muted"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }
      return (
        <div>
          <h6 className="bold mt-1 mb-1">Elegir un responsable</h6>
          <button
            className="btn btn-sm btn-link px-0 text-muted"
            onClick={() => setShowUsers(true)}
          >
            <i className="fa fa-edit"></i> Asignar {itemLabel}
          </button>
        </div>
      );
    }
    return (
      <div>
        <h6 className="bold">Responsable</h6>
        <p className="mb-0">Sin asignar.</p>
      </div>
    );
  };
  return <div className="container-fluid px-0">{renderResponsable()}</div>;
};

export default Responsable;
