import React, { useEffect, useContext, useState } from "react";
import { ProveedoresContext } from "../../context/ProveedoresContext";

const ProviderDetails = ({ order }) => {
  const productDetailImageStyles = {
    backgroundImage:
      'url("https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Liberty_Memorial_043.jpg/1280px-Liberty_Memorial_043.jpg")',
    height: "100%",
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: "10px",
    minHeight: "100px",
  };

  const [providerDetails, setProviderDetails] = useState();
  const { getSingleProveedor, proveedor } = useContext(ProveedoresContext);

  useEffect(() => {
    if (order && order.idProveedor) {
      getSingleProveedor(order.idProveedor);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    setProviderDetails(proveedor);
  }, [proveedor]);

  return (
    <div className="card shadow p-3 d-flex flex-row mb-3">
      <div className="me-3" style={{ width: "25%" }}>
        <div style={productDetailImageStyles} />
      </div>
      <div style={{ width: "80%" }}>
        <div className="w-100 d-flex justify-content-between mb-3">
          <h5 className="bold">{providerDetails?.nombre}</h5>
          <h5 className="bold">{providerDetails?.idProveedor}</h5>
        </div>
        <div className="d-flex">
          <div className="me-4">
            <div className="d-flex">
              <p
                style={{ maxWidth: "80px", width: "80px" }}
                className="bold me-3"
              >
                Contacto
              </p>
              <p className="mb-0">{providerDetails?.contact}</p>
            </div>
            <div className="d-flex">
              <p
                style={{ maxWidth: "80px", width: "80px" }}
                className="bold me-3"
              >
                Correo
              </p>
              <p className="mb-0">{providerDetails?.email}</p>
            </div>
            <div className="d-flex">
              <p
                style={{ maxWidth: "80px", width: "80px" }}
                className="bold me-3"
              >
                Teléfono
              </p>
              <p className="mb-0">{providerDetails?.numeroTelefono}</p>
            </div>
            <div className="d-flex">
              <p
                style={{ maxWidth: "80px", width: "80px" }}
                className="bold me-3"
              >
                rfc
              </p>
              <p className="mb-0">{providerDetails?.rfc}</p>
            </div>
          </div>

          <div className="ms-5">
            <div className="d-flex">
              <p
                style={{ maxWidth: "80px", width: "80px" }}
                className="bold me-3"
              >
                Domicilio
              </p>
              <p className="mb-0" style={{ maxWidth: "300px" }}>
                {}
              </p>
            </div>
            <div className="d-flex">
              <p
                style={{ maxWidth: "80px", width: "80px" }}
                className="bold me-3"
              >
                C.P
              </p>
              <p className="mb-0">{}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderDetails;
