import React from "react";

const ProgressBar = ({ progress }) => {
  return (
    <div className="d-flex align-items-center">
      <div className="progress flex-grow-1 border-0" role="progressbar" aria-label="Basic example" aria-valuemin="0" aria-valuemax="100" style={{ height: 5 }}>
        <div className="progress-bar bg-success" style={{ width: `${progress}%` }}>
        </div>
      </div>
      <span className="ml-2 ps-2" style={{fontWeight: "700"}}>{progress}%</span>
    </div>
  );
};

export default ProgressBar;
