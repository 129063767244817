import {
  SELECT_TABS,
  SET_BUTTONS,
  SET_SELECTED,
  SET_SUBTITLE,
  SET_TITLE,
  SET_TITLE_HANDLER,
  TOGGLE_SETTINGS,
} from "../types/menu";

export default (state, { type, payload }) => {
  switch (type) {
    case SET_TITLE:
      return { ...state, title: payload };
    case SET_TITLE_HANDLER:
      return { ...state, onClickTitle: payload };
    case SET_SUBTITLE:
      return { ...state, subtitle: payload };
    case SET_BUTTONS:
      return { ...state, buttons: payload };
    case SELECT_TABS:
      return { ...state, tabs: payload };
    case SET_SELECTED:
      return { ...state, selected: payload };
    case TOGGLE_SETTINGS:
      return { ...state, showSettings: !state.showSettings };
    default:
      return { ...state };
  }
};
