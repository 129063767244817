import {
  SET_NOMBRE_PLANTILLA,
  PLANTILLAS_RECEIVED,
  SET_PLANTILLA,
} from "../types/plantillas";

const INITIAL_STATE = {
  plantilla: "",
  nombrePlantilla: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_NOMBRE_PLANTILLA:
      return { ...state, nombrePlantilla: action.payload };
    case PLANTILLAS_RECEIVED:
      return { ...state, plantillas: action.payload };
    case SET_PLANTILLA:
      return { ...state, plantilla: action.payload };
    default:
      return { ...state };
  }
};
