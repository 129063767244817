import React from 'react'
import { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { OrdenesContext } from '../../context/OrdenesContext';
import ProductOrderUpdatesForm from '../../components/purchaseOrders/ProductOrderUpdatesForm';
import ProductOrdenForm from '../../components/ordenes/ProductOrdenForm';

const OrderHandler = () => {
    const { deleteOrder } = useContext(OrdenesContext);
    const { modalComponent, clearModal, confirm } = useContext(ModalContext)


    const handleCreateOrder = (product) => {
        modalComponent(`Agregar orden de '${product.article}'`, <ProductOrdenForm idProyecto={product.idProyecto} idProducto={product.idProducto} />);
    };

    const handleDeleteOrder = (orderId) => {
        confirm("¿Eliminar Orden?", () =>
            deleteOrder(orderId)
        );
    };

    const handleEditOrderUpdates = (order) => {
        modalComponent(
            "Actualizar estatus de la orden:",
            <ProductOrderUpdatesForm
                order={order}
                handleCancel={clearModal}
            />
        );
    };

    return {
        handleCreateOrder,
        handleDeleteOrder,
        handleEditOrderUpdates
    };
}

export default OrderHandler;