import api from "./api";

const route = "/conceptosmediciones";

export default {
  getConceptosMediciones: (idProyecto, idContrato, idEstimacionVersion) =>
    api.get(`${route}/${idProyecto}/${idContrato}/${idEstimacionVersion}`),
  getSingleConceptoMedicion: (conceptoMedicion_id) =>
    api.get(`${route}/${conceptoMedicion_id}`),
  postConceptoMedicion: (conceptoMedicion) =>
    api.post(route, { ...conceptoMedicion }),
  putConceptoMedicion: (conceptoMedicion) =>
    api.put(route, { ...conceptoMedicion }),
  deleteConceptoMedicion: (ConceptoMedicion) =>
    api.delete(`${route}/${ConceptoMedicion}`),
};
