import React, { useContext, useEffect } from "react";
import PillTabs from "../common/PillTabs";
import PreciarioTable from "./PreciarioTable";
import usePreciario from "../../hooks/usePreciario";
import PreciarioMobile from "../preciario/PreciarioMobile";
import PreciarioButtons from "../preciario/PreciarioButtons";
import { ConceptosContext } from "../../context/ConceptosContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import { PreciarioContext } from "../../context/PreciarioContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import { CapacitorContext } from "../../context/CapacitorContext";
import EstimacionesContrato from "../estimaciones/EstimacionesContrato";
import { EstimacionesContext } from "../../context/EstimacionesContext";
import { PartidasContratoContext } from "../../context/PartidasContratoContext";

const Preciario = ({ idContrato }) => {
  const { getPartidasContrato } = useContext(PartidasContratoContext);
  const { getConceptosContrato } = useContext(ConceptosContext);
  const { PartidaContratoName } = useContext(AppConfigContext);
  const { clearEstimacion } = useContext(EstimacionesContext);
  const { platform } = useContext(CapacitorContext);
  const { proyecto } = useContext(ProyectosContext);
  const { editMode } = useContext(PreciarioContext);
  const { idProyecto, permiso } = proyecto;

  const { view, query, setView, setQuery, partidas } = usePreciario();

  useEffect(() => {
    getConceptosContrato(idProyecto, idContrato);
    getPartidasContrato(idProyecto, idContrato);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idContrato]);

  useEffect(() => {
    if (view === "catalogo") {
      clearEstimacion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  useEffect(() => {
    if (permiso === "contratista" && platform !== "web") {
      setView("estimaciones");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderBotones() {
    if (
      ["admin", "coordinador"].includes(permiso) &&
      platform === "web" &&
      view === "catalogo"
    ) {
      return (
        <div className="row mt-3">
          <div className="container-fluid">
            <PreciarioButtons />
          </div>
        </div>
      );
    }
  }

  function renderPreciario() {
    if (
      Array.isArray(partidas) &&
      (platform === "web" || (platform !== "web" && permiso !== "contratista"))
    ) {
      if (editMode !== "partidas") {
        if (partidas.length === 0) {
          return (
            <p>No hay {PartidaContratoName} registradas para este contrato.</p>
          );
        }
      }
      if (platform === "web") return <PreciarioTable />;
      return <PreciarioMobile />;
    }
  }

  return (
    <div className="card p-3 shadow my-3">
      <div className="container-fluid mb-3">
        <PillTabs
          value={view}
          modifier={(value) => setView(value)}
          tabs={[
            {
              label: "Catálogo",
              value: "catalogo",
            },
            {
              label: "Estimaciones",
              value: "estimaciones",
            },
          ]}
        />
      </div>
      <div className="row align-items-end mb-3">
        <div
          className={`col-12 col-md-${
            view === "estimaciones" ? "6" : "12"
          } py-3`}
        >
          <input
            type="text"
            value={query}
            className="form-control bg-light"
            placeholder="Buscar partidas y conceptos"
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        {view === "estimaciones" && (
          <div className="col-12 col-md-6 py-3">
            <EstimacionesContrato
              idProyecto={idProyecto}
              idContrato={idContrato}
              permiso={permiso}
            />
          </div>
        )}
      </div>
      {renderPreciario()}
      {renderBotones()}
    </div>
  );
};

export default Preciario;
