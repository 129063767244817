import React, { useContext, useEffect } from "react";
import { UsuariosContext } from "../../context/UsuariosContext";
import { PermisosContext } from "../../context/PermisosContext";
import { ClientesContext } from "../../context/ClientesContext";
import { getValue } from "../../utils";
import { ModalContext } from "../../context/ModalContext";
import SearchableSelect from "../global/SearchableSelect";

const UsuarioClienteForm = ({ idCliente, handleCallback }) => {
  const { permisos, getPermisos } = useContext(PermisosContext);
  const { clearModal } = useContext(ModalContext);
  const { createUsuarioCliente } = useContext(ClientesContext);
  const { users, usuario, getUsers, setPropiedadUsuario } =
    useContext(UsuariosContext);

  useEffect(() => {
    getPermisos();
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (usuario !== null) {
      handleUsuario();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuario]);

  function handleUsuario() {
    if (!usuario.idUsuario && Array.isArray(users)) {
      if (users.length > 0) {
        setPropiedadUsuario("idUsuario", users[0].idUsuario);
      }
    }
    if (!usuario.idPermiso) {
      setPropiedadUsuario("idPermiso", 1);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    createUsuarioCliente({ ...usuario, idCliente }, handleCallback);
  }

  function renderUsers() {
    if (users) {
      const usersOptions = users.map((user) => {
        const nombre = user.nombre && user.correo
          ? `${user.nombre} : ${user.correo}`
          : user.nombre || user.correo || 'No Name';

        return {
          nombre,
          idUsuario: user.idUsuario
        };
      });

      return <SearchableSelect
        items={usersOptions}
        itemText={"nombre"}
        itemValue={"idUsuario"}
        modifier={(usuario) => setPropiedadUsuario("idUsuario", usuario.value)}
        value={getValue(usuario, "idUsuario")}
      />
    }

  }

  function renderPermisos() {
    if (Array.isArray(permisos)) {
      return <SearchableSelect
        items={permisos}
        itemText={"nombre"}
        itemValue={"idPermiso"}
        modifier={(permiso) => setPropiedadUsuario("idPermiso", permiso.value)}
        value={getValue(usuario, "idPermiso")}
      />
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="container">
          <label>Usuario</label>
          {renderUsers()}
        </div>
      </div>
      <div className="row pt-3">
        <div className="container">
          <label>Permiso</label>
          {renderPermisos()}
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <button
            type="button"
            onClick={clearModal}
            className="btn text-muted w-100"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn btn-primary w-100">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default UsuarioClienteForm;
