import React from "react";
import CheckBox from "../obra/CheckBox";

const Rubro = ({ completado, className, nombre, showBox }) => {
  return (
    <div className="row mx-0 align-items-center">
      <div className="container-fluid px-0">
        {(completado === 1 || showBox) && (
          <CheckBox checked={completado} disabled={true} />
        )}
        <span className={className}>
          {nombre && nombre !== null && nombre !== ""
            ? nombre
            : "No disponible"}
        </span>
      </div>
    </div>
  );
};

export default Rubro;
