import React, { useContext } from "react";
import moment from "moment";
import { formatMonto } from "../../utils";
import { AdjuntosContext } from "../../context/AdjuntosContext";

const FilaPago = ({ pago, index, editPago, cancelPago, permiso }) => {
  const {
    monto,
    idAdjunto,
    comentario,
    nombre_adjunto,
    fecha_cancelacion,
    fecha_hora_autorizacion,
  } = pago;

  const { setSingleAdjunto } = useContext(AdjuntosContext);

  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        {"$"}
        {formatMonto(monto)}
      </td>
      <td>{moment(fecha_hora_autorizacion).format("DD MMM YYYY")}</td>
      <td>{comentario}</td>
      <td>
        {idAdjunto &&
          (idAdjunto !== null) &
            6(
              <button
                className="btn btn-link text-primary"
                onClick={() =>
                  setSingleAdjunto({
                    idAdjunto,
                    nombre: nombre_adjunto,
                    tipo: "jpg",
                  })
                }
              >
                <i className="fa fa-eye"></i>
              </button>
            )}
      </td>
      <td>
        {fecha_cancelacion === null &&
          [("admin", "finanzas")].includes(permiso) && (
            <button
              className="btn btn-outline-primary me-2"
              onClick={() => editPago(pago)}
            >
              <i className="fa fa-edit"></i>
            </button>
          )}
        {fecha_cancelacion === null &&
          ["admin", "finanzas"].includes(permiso) && (
            <button
              className="btn btn-outline-danger"
              onClick={() => cancelPago(pago)}
            >
              <i className="fa fa-times"></i>
            </button>
          )}
        {fecha_cancelacion && fecha_cancelacion !== null && (
          <span className="text-danger small">
            CANCELADO: {moment(fecha_cancelacion).format("DD MMM YYYY")}{" "}
          </span>
        )}
      </td>
    </tr>
  );
};

export default FilaPago;
