import React, { useEffect, useState, useContext } from "react";
import ImageInput from "../common/ImageInput";
import { EntradasContext } from "../../context/EntradasContext";

const EvidenciaForm = ({ item, handleCancel, handleSubmit }) => {
  const [file, setFile] = useState(null);

  const { 
    previewAdjunto, 
    clearAdjuntos
  } = useContext(EntradasContext);

  const { 
    adjuntos
  } = useContext(EntradasContext);


  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(item, file);
  };

  useEffect(() => { 
    clearAdjuntos();

    if(file) {
      let reader = new FileReader();
      const name = file.name;

      reader.onload = (e) => {
        let src = e.target.result;
        previewAdjunto(name, src);
      };

      reader.readAsDataURL(file);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const renderAdjunto = () => {
    if(adjuntos?.length > 0) {
      const adjunto = adjuntos[0];

      return(
        <div
          className="card border-0 position-relative me-2 mb-2 file-preview"
          style={{
            backgroundImage: `url(${adjunto.src})`,
            backgroundPosition: 'center',
            display: "inline-block",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '100px',
          }}
        >
        </div>
      )
    }
  }

  return (
    <form className="position-relative pt-3" onSubmit={onSubmit}>
      <div className=" d-flex flex-column justify-content-around align-items-center">
        {renderAdjunto()}
        <ImageInput modifier={setFile} />
      </div>
      <div className="row mt-5">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn text-muted w-100"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button className="btn btn-primary w-100" type="submit">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default EvidenciaForm;
