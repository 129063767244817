import {
  IonModal,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonIcon,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useContext, useState } from "react";
import { DestajosContext } from "../../context/DestajosContext";
import { PartidasContext } from "../../context/PartidasContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import { SubdestajosContext } from "../../context/SubdestajosContext";
import "./guardarMovil.css";

const GuardarMovil = ({ showModal, setShowModal }) => {
  const [nombre, setNombre] = useState("");

  const { partidas } = useContext(PartidasContext);
  const { destajos } = useContext(DestajosContext);
  const { subdestajos } = useContext(SubdestajosContext);
  const { crearPlantilla } = useContext(ProyectosContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    crearPlantilla(partidas, destajos, subdestajos, nombre);
    setShowModal(false);
  };

  return (
    <IonModal
      isOpen={showModal}
      onDidDismiss={() => setShowModal(false)}
      initialBreakpoint={0.9}
      breakpoints={[0.9, 0.95]}
    >
      <IonContent className="py-4 ps-4">
        <IonGrid>
          <form
            className="py-4 ps-4"
            style={{ paddingRight: "6vw" }}
            onSubmit={handleSubmit}
          >
            <IonRow>
              <IonCol>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h4 className="titlePlantilla mt-3">Guardar Plantilla</h4>
                  <IonIcon
                    icon={closeOutline}
                    style={{ cursor: "pointer", fontSize: "20px" }}
                    onClick={() => setShowModal(false)}
                  />
                </div>
                <p className="mb-4 subtitlePlantilla h6">
                  La plantilla guardará la siguiente información
                </p>
                <IonLabel className="mb-2 h6 titleInputPlantilla">
                  NOMBRE DE LA PLANTILLA
                </IonLabel>
                <IonInput
                  type="text"
                  value={nombre}
                  className="form-control mb-4"
                  placeholder="Ejemplo: Cafeterías"
                  onIonChange={(e) => setNombre(e.target.value)}
                ></IonInput>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonRow style={{width: "100%"}}>
                <IonCol>
                  <h5 className="titleCuadradoPlantilla">PARTIDAS</h5>
                  <div
                    className="fondoIndividualesPlantilla"
                    style={{ height: "80px"}}
                  >
                    <IonGrid style={{ backgroundColor: "transparent"}}>
                      <IonRow>
                        <IonCol style={{ textAlign: "left" }}>
                          <IonLabel className="">Nombre</IonLabel>
                        </IonCol>
                        <IonCol style={{ textAlign: "left" }}>
                          <IonLabel className="">Ponderación</IonLabel>
                        </IonCol>
                        <IonCol style={{ textAlign: "left" }}>
                          <IonLabel className="">Orden</IonLabel>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol style={{ textAlign: "left" }}>
                          <IonLabel className="">Destajos</IonLabel>
                        </IonCol>
                        <IonCol style={{ textAlign: "left" }}>
                          <IonLabel className="">Punto Crítico</IonLabel>
                        </IonCol>
                        <IonCol></IonCol>{" "}
                        {/* Agregamos un IonCol vacío como marcador de posición */}
                      </IonRow>
                    </IonGrid>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow style={{width: "100%"}}>
                <IonCol size="12">
                  <h5 className="titleCuadradoPlantilla">DESTAJOS</h5>
                  <div
                    className="fondoIndividualesPlantilla"
                    style={{ height: "80px"}}
                  >
                    <IonGrid style={{ backgroundColor: "transparent"}}>
                      <IonRow>
                        <IonCol style={{ textAlign: "left" }}>
                          <IonLabel>Nombre</IonLabel>
                        </IonCol>
                        <IonCol style={{ textAlign: "left" }}>
                          <IonLabel>Ponderación</IonLabel>
                        </IonCol>
                        <IonCol style={{ textAlign: "left" }}>
                          <IonLabel>Orden</IonLabel>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol style={{ textAlign: "left" }}>
                          <IonLabel>Subdestajos</IonLabel>
                        </IonCol>
                        <IonCol></IonCol>
                        <IonCol></IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow style={{width: "100%"}}>
                <IonCol size="12">
                  <h5 className="titleCuadradoPlantilla">SUBDESTAJOS</h5>
                  <div
                    className="fondoIndividualesPlantilla"
                    style={{ height: "80px"}}
                  >
                    <IonGrid style={{ backgroundColor: "transparent"}}>
                      <IonRow>
                        <IonCol style={{ textAlign: "left" }}>
                          <IonLabel>Nombre</IonLabel>
                        </IonCol>
                        <IonCol style={{ textAlign: "left" }}>
                          <IonLabel>Ponderación</IonLabel>
                        </IonCol>
                        <IonCol style={{ textAlign: "left" }}>
                          <IonLabel>Orden</IonLabel>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol></IonCol>
                        <IonCol></IonCol>
                        <IonCol></IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </IonCol>
              </IonRow>
            </IonRow>
            <IonRow>
              <IonCol className="d-flex justify-content-end  mt-3">
                <button
                  className="btn"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  CANCELAR
                </button>
                <button className="btn btn-primary">
                  <i className="fa fa-save"></i> GUARDAR
                </button>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default GuardarMovil;
