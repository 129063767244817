import React, { useState, useContext, useEffect } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { RevisionesContext } from "../../context/RevisionesContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import { ChecklistContext } from "../../context/ChecklistContext";
import { AdjuntosContext } from "../../context/AdjuntosContext";
import { ModalContext } from "../../context/ModalContext";
import EvidenciaForm from "../items/EvidenciaForm";
import ItemRowForm from "../items/ItemRowForm";
import ItemForm from "../items/ItemForm";
import ItemRow from "../items/ItemRow";
import arrayMove from "array-move";
import { ItemsContext } from "../../context/ItemsContext";
import "../../css/ChecklistSection.css";
import moment from "moment";
import { getSectionItems } from "../../utils/checklists";
import ChecklistSectionForm from "../checklistsection/ChecklistSectionForm";
import Folder from "../../views/Folder";
import useProyecto from "../../hooks/useProyecto";
import { CapacitorContext } from "../../context/CapacitorContext";
import useChecklists from "../../hooks/useChecklists";

const ChecklistSection = ({
  section,
  idSection,
  handleCallback,
}) => {
  const [showItems, setShowItems] = useState(false);
  const { idProyecto } = useProyecto();
  const {
    query,
    estado,
    editMode,
    checklist,
    expandAll,
    getSingleChecklist,
    postChecklistSection,
    deleteChecklistSection,
  } = useContext(ChecklistContext);

  const { handleComments, handleCreateItem } = useChecklists();

  const { idChecklist } = checklist;

  const {
    items,
    setItem,
    deleteItem,
    itemsByRevision,
    setPropertyItem,
    putChecklistItems,
    getItemsByRevision,
  } = useContext(ItemsContext);

  const { uploadSingleAdjunto } = useContext(AdjuntosContext);
  const { platform } = useContext(CapacitorContext);

  const { alert } = useContext(ModalContext);

  const { revision, updateItemByRevision, addItemToRevision } =
    useContext(RevisionesContext);
  const { proyecto } = useContext(ProyectosContext);
  const { confirm, clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getItemsByRevision(revision?.idRevision, idProyecto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revision]);

  useEffect(() => {
    setShowItems(expandAll);
  }, [expandAll]);

  const handleComplete = (idItem, completed) => {
    // completeItem(idProyecto, idItem, idChecklist, handleCallback);
    const itemOnRev = findItemOnRevision(idItem);
    const idRevisionItem = itemOnRev?.idRevisionItem;

    const revisionUpdated = {
      completedAt: moment().format(),
    };

    if (completed) revisionUpdated.completedAt = null;
    if (itemOnRev) {
      updateItemByRevision(idProyecto, idRevisionItem, revisionUpdated);
    } else {
      alert("Es necesario adjuntar evidencia para completar");
    }
  };

  const findItemOnRevision = (idItem) => {
    const itemOnRev = itemsByRevision?.find((obj) => {
      return obj.idItem === idItem;
    });

    return itemOnRev;
  };

  const handleCancel = () => {
    clearModal();
    getSingleChecklist(idProyecto, idChecklist);
  };

  const handleEditSection = () => {
    modalComponent(
      "Editar Sección",
      <ChecklistSectionForm
        section={section}
        idProyecto={idProyecto}
        idChecklist={idChecklist}
        handleCancel={handleCancel}
        postItem={postChecklistSection}
        idChecklistSection={section.idChecklistSection}
      />
    );
  };

  const handleEdit = (item) => {
    setItem(item);
    modalComponent(
      "Editar Item",
      <ItemForm
        idProyecto={idProyecto}
        idChecklist={idChecklist}
        handleCancel={handleCancel}
      />
    );
  };

  const handleUpload = (item) => {
    setItem(item);
    const itemRev = findItemOnRevision(item.idItem);
    if (itemRev) {
      return modalComponent(
        "Evidencias",
        <Folder idFolder={itemRev.idFolder} hideFolderButton />
      );
    }
    modalComponent(
      "Agregar Evidencia",
      <EvidenciaForm
        item={item}
        handleCancel={handleCancel}
        handleSubmit={(item, file) =>
          handleUploadEvidencia(idProyecto, item, file)
        }
      />
    );
  };

  const handleUploadEvidencia = async (idProyecto, item, file) => {
    const itemRev = findItemOnRevision(item.idItem);
    const itemData = {
      idItem: item.idItem,
      estado: item.estado,
    };

    if (itemRev) {
      uploadSingleAdjunto(idProyecto, itemRev.idFolder, file);
    } else {
      const { data } = await addItemToRevision(idProyecto, itemData, revision);
      uploadSingleAdjunto(data.idFolder, file);
    }
  };

  const handleDeleteSection = () => {
    confirm(
      `¿Estás seguro que deseas eliminar la sección ${section.nombre}? Esta acción NO puede deshacerse y todos los items serán eliminados.`,
      () =>
        deleteChecklistSection(
          idProyecto,
          idChecklist,
          section.idChecklistSection
        )
    );
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newItems = arrayMove(items, oldIndex, newIndex).map(
        (item, index) => {
          return { ...item, idItem: index + 1 };
        }
      );
      putChecklistItems(idProyecto, newItems, handleCancel);
    }
  };

  const SortableItem = SortableElement(({ item }) => {
    let CustomComponent = editMode ? ItemRowForm : ItemRow;
    return (
      <CustomComponent
        item={item}
        focused={item}
        key={item.idItem}
        handleEdit={handleEdit}
        idProyecto={idProyecto}
        deleteItem={deleteItem}
        permiso={proyecto.permiso}
        handleUpload={handleUpload}
        completeItem={handleComplete}
        handleCallback={handleCallback}
        handleComments={handleComments}
        setPropertyItem={setPropertyItem}
      />
    );
  });

  const SortableList = SortableContainer(({ items, isEditable }) => {
    return (
      <div>
        {items.map((item, index) => (
          <SortableItem
            key={`item-${item.idItem}`}
            index={index}
            item={item}
            disabled={!isEditable}
          />
        ))}
      </div>
    );
  });

  const renderItems = () => {
    if (Array.isArray(items) && showItems) {
      let itemsRender = getSectionItems(
        items,
        section.idChecklistSection,
        query
      );
      itemsRender = itemsRender.filter(
        (item) => String(item.idItem)[0] !== "d"
      );
      if (itemsRender.length === 0 && !editMode) {
        return <p className="mb-0 p-2">No hay items registrados.</p>;
      }
      if (estado !== "todo") {
        itemsRender = itemsRender.filter((item) => {
          if (estado === "bueno") {
            return item.estado > 6;
          }
          return item.estado !== null && item.estado < 7;
        });
      }
      return (
        <SortableList
          lockAxis="y"
          items={itemsRender}
          onSortEnd={onSortEnd}
          isEditable={editMode}
        />
      );
    }
  };

  const renderSectionDropdown = () => {
    return (
      <div className="dropdown col-4 text-end  show-mobile">
        <button
          className="btn btn-sm btn-outline-secondary px-0"
          type="button"
          id={`dropdownMenuButton-${idSection}`}
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style={{ color: "#db8c63", border: "none", fontSize: "20px" }}
        >
          <i className="fas fa-ellipsis-h text-primary fs-2"></i>
        </button>

        <div
          className="dropdown-menu"
          aria-labelledby={`dropdownMenuButton-${idSection}`}
        >
          <button
            onClick={() => handleCreateItem(section.idChecklistSection)}
            className="dropdown-item mb-2"
          >
            <i className="fa fa-plus me-2"></i>
            Agregar
          </button>

          <button className="dropdown-item mb-2" onClick={handleEditSection}>
            <i className="fa fa-edit me-2"></i>
            Editar
          </button>

          <button
            className="dropdown-item text-danger"
            onClick={handleDeleteSection}
          >
            <i className="fa fa-trash me-2"></i>
            Eliminar
          </button>
        </div>
      </div>
    );
  };

  if (platform === "web") {
    return (
      <div className="container-fluid">
        <div
          className="row align-items-center px-0 py-2 d-flex"
          style={{ borderTop: "2px solid #f4f6f8" }}
        >
          <div className="col-1 col-md-1 px-0">
            <button
              onClick={() => setShowItems(!showItems)}
              className="btn btn-primary"
            >
              <i className={`fa fa-chevron-${showItems ? "up" : "down"}`}></i>
            </button>
          </div>
          <div
            className={`col-11 col-md-11 ${
              platform === "web"
                ? `align-items-center 
            d-flex justify-content-between `
                : ""
            }`}
          >
            <div className="row">
              <div className="col-8">
                <div className="row h-100 align-items-center">
                  <h5 className="mb-0 small">{section.nombre}</h5>
                </div>
              </div>
              <div className="col-4 px-0 text-end hide-mobile">
                <button
                  onClick={() => handleCreateItem(section.idChecklistSection)}
                  className="btn btn-sm btn-outline-primary me-2 hide-mobile"
                >
                  <i className="fa fa-plus"></i>
                </button>

                <button
                  className="btn btn-sm btn-outline-dark me-2 hide-mobile"
                  onClick={handleEditSection}
                >
                  <i className="fa fa-edit"></i>
                </button>

                <button
                  className="btn btn-sm btn-outline-danger me-2 hide-mobile"
                  onClick={handleDeleteSection}
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
              {renderSectionDropdown()}
            </div>
          </div>
        </div>
        {renderItems()}
      </div>
    );
  } else {
    return (
      <div className="container-fluid border-top ">
        <div
          className="row align-items-center px-0 py-2 d-flex"
          style={{ borderTop: "2px solid #f4f6f8" }}
        >
          <div className="col-1 col-md-1 px-0">
            <button
              onClick={() => setShowItems(!showItems)}
              className="btn btn-primary"
            >
              <i className={`fa fa-chevron-${showItems ? "up" : "down"}`}></i>
            </button>
          </div>
          <div className={`col-11 col-md-11 `}>
            <div className="row">
              <div className="col-8 ">
                <div className="d-flex h-100 align-items-center">
                  <h5 className="mb-0 small">{section.nombre}</h5>
                </div>
              </div>

              {renderSectionDropdown()}
            </div>
          </div>
        </div>
        {renderItems()}
      </div>
    );
  }
};

export default ChecklistSection;
