import React, { useContext, useEffect, useState } from "react";
import SearchableSelect from "../global/SearchableSelect";
import { ProductsContext } from "../../context/ProductsContext";
import { OrdenesContext } from "../../context/OrdenesContext";

const ProductListOrderForm = ({
  orderId,
  productOrderToEdit,
  handleCancel,
}) => {
  const { createProductsOrder, editProductsOrder } = useContext(OrdenesContext);
  const { getProducts, products } = useContext(ProductsContext);

  const [productId, setProductId] = useState("");
  const [cantidad, setCantidad] = useState("");
  const [precioUnitario, setPrecioUnitario] = useState("");
  const [moneda, setMoneda] = useState("");
  const [descuento, setDescuento] = useState("");
  const [descuentoPorcentaje, setDescuentoPorcentaje] = useState(false);

  useEffect(() => {
    if (!productOrderToEdit) {
      getProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (productOrderToEdit) {
      setProductId(productOrderToEdit.productId);
      setCantidad(productOrderToEdit.cantidad);
      setPrecioUnitario(productOrderToEdit.precioUnitario);
      setMoneda(productOrderToEdit.moneda);
      setDescuento(productOrderToEdit.descuento);
      setDescuentoPorcentaje(productOrderToEdit.descuentoPorcentaje);
    }
  }, [productOrderToEdit]);

  const handleProductChange = (selectedProduct) => {
    setProductId(selectedProduct.value);
  };

  const handleAmountChange = (e) => {
    setCantidad(e.target.value);
  };

  const handlePrecioUnitarioChange = (e) => {
    setPrecioUnitario(e.target.value);
  };

  const handleMonedaChange = (e) => {
    setMoneda(e.target.value);
  };

  const handleDescuentoChange = (e) => {
    setDescuento(e.target.value);
  };

  const handleDescuentoPorcentajeChange = (e) => {
    setDescuentoPorcentaje(e.target.checked);
  };

  const handleSubmit = () => {
    const productOrderData = {
      cantidad,
      precioUnitario,
      moneda,
      descuento,
      descuentoPorcentaje,
    };

    if (!productOrderToEdit) {
      createProductsOrder(orderId, {
        idProducto: productId,
        ...productOrderData,
      });
    } else {
      editProductsOrder(productOrderToEdit.idProductoOrden, {
        ...productOrderData,
        idOrden: productOrderToEdit.idOrden,
      });
    }
  };

  return (
    <>
      <div className="mb-5">
        {!productOrderToEdit && (
          <div className="mb-3">
            <label>Producto:</label>
            <SearchableSelect
              className="form-control bg-light py-2"
              items={products}
              itemText="article"
              itemValue="idProducto"
              modifier={handleProductChange}
              value={productId}
            />
          </div>
        )}
        <div className="mb-3">
          <label>Cantidad:</label>
          <input
            className="form-control bg-light py-2"
            type="text"
            name="cantidad"
            value={cantidad}
            onChange={handleAmountChange}
          />
        </div>
        <div className="mb-3">
          <label>Precio Unitario:</label>
          <input
            className="form-control bg-light py-2"
            type="text"
            name="precioUnitario"
            value={precioUnitario}
            onChange={handlePrecioUnitarioChange}
          />
        </div>
        <div className="mb-3">
          <label>Moneda:</label>
          <input
            className="form-control bg-light py-2"
            type="text"
            name="moneda"
            value={moneda}
            onChange={handleMonedaChange}
          />
        </div>
        <div className="mb-3">
          <label>Descuento Porcentaje:</label>
          <input
            className="ms-2 form-check-input"
            type="checkbox"
            name="descuentoPorcentaje"
            checked={descuentoPorcentaje}
            onChange={handleDescuentoPorcentajeChange}
          />
          <input
            className="form-control bg-light py-2"
            type="text"
            name="descuento"
            value={descuento}
            onChange={handleDescuentoChange}
          />
        </div>
      </div>

      <div className="w-100 d-flex mt-3">
        <button className="btn me-1 w-50" onClick={handleCancel}>
          Cancelar
        </button>
        <button
          className="btn btn-primary ms-1 w-50"
          type="submit"
          disabled={false}
          onClick={handleSubmit}
        >
          {!productOrderToEdit
            ? "Agregar Orden de Producto"
            : "Editar Orden de Producto"}
        </button>
      </div>
    </>
  );
};

export default ProductListOrderForm;
