import React, { useContext, useEffect } from "react";
import { AlertasContext } from "../../context/AlertasContext";
import { ModalContext } from "../../context/ModalContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import AlertaForm from "../alertas/AlertaForm";

const ItemAlertas = ({ idKey, idItem }) => {
  const { alertas, createAlerta, getItemAlertas } = useContext(AlertasContext);
  const { modalComponent } = useContext(ModalContext);
  const { proyecto } = useContext(ProyectosContext);
  const { idProyecto } = proyecto;

  useEffect(() => {
    getItemAlertas(idProyecto, { [idKey]: idItem });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreate = () => {
    createAlerta();
    modalComponent("Crear Alerta", <AlertaForm />);
  };

  const renderAlertas = () => {
    if (Array.isArray(alertas)) {
      if (alertas.length === 0) {
        return <p style={{ color: "#acacac" }}>No hay alertas creadas.</p>;
      }
      return alertas.map((alerta) => (
        <p>
          <span className="bold">#{alerta.idAlerta}:</span> {alerta.contenido}
        </p>
      ));
    }
  };
  return (
    <div className="mt-3 mb-4">
      <h6 className="bold mt-1 mb-1">Alertas</h6>
      {renderAlertas()}
      <button onClick={handleCreate} className="btn btn-primary">
        <i className="fa fa-plus"></i>
      </button>
    </div>
  );
};
export default ItemAlertas;
