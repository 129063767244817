
import React from "react";
import { stopPropagation } from "../../utils";
import CheckBox from "../obra/CheckBox";
import UserPicker from "../users/UserPicker";
import { SortableHandle } from "react-sortable-hoc";

const ItemRowForm = ({
  item,
  setPropertyItem,
  deleteItem,
  editMode,
  isEditable
}) => {
  const { idItem, completado, nombre } = item;

  const handleDelete = (e) => {
    e.stopPropagation();
    deleteItem(idItem);
  };

  const DragHandle = SortableHandle(() => (
    <i className="fa fa-bars" style={{ cursor: "grab" }}></i>
  ));

  return (
    <div className="row mx-0 align-items-center p-2 hover-light">
      <div className="col-2 col-md-1">
        <CheckBox checked={completado} disabled />
      </div>
      <div className="col-10 col-md-6">
        <input
          type="text"
          value={nombre}
          className="form-control"
          onChange={(e) => setPropertyItem(idItem, "nombre", e.target.value)}
        />
      </div>
      <div className="col-6 col-md-2">
        <UserPicker />
      </div>
      <div className="col-6 col-md-2">
        <input type="date" className="form-control" onClick={stopPropagation} />
      </div>
      <div className="col-6 col-md-1">
        {isEditable && <DragHandle />}
        <button
          className="btn btn-sm btn-outline-danger"
          onClick={handleDelete}
        >
          <i className="fa fa-trash"></i>
        </button>
      </div>
    </div>
  );
};

export default ItemRowForm;
