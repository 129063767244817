import React, { useContext, useEffect } from "react";
import { AlertasContext } from "../context/AlertasContext";
import { ModalContext } from "../context/ModalContext";
import { MenuContext } from "../context/MenuContext";
import UserCard from "../components/users/UserCard";
import { hideModal } from "../utils";
import moment from "moment";

const Alertas = ({ idProyecto }) => {
  const { alertas, getAlertas, deleteAlerta, getAlertasProyecto } = useContext(AlertasContext);
  const { selectTabs, setSelected } = useContext(MenuContext);
  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    setSelected({ title: "Alertas" });
    getAlertas(idProyecto);
    selectTabs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(!isNaN(idProyecto)) {
      getAlertasProyecto(idProyecto);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto])

  const handleDelete = (alerta) => {
    modalComponent(
      "Eliminar Alerta",
      <div>
        <p>
          ¿Eliminar alerta #{alerta.idAlerta}? Se eliminirá para todos los
          usuarios que reciben esta alerta actualmente.
        </p>
        <p>Esta acción NO puede deshacerse.</p>
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-danger"
              onClick={() => deleteAlerta(idProyecto, alerta.idAlerta)}
            >
              <i className="fa fa-trash"></i> Eliminar
            </button>
          </div>
          <div className="col-6 text-end">
            <button className="btn btn-link text-secondary" onClick={hideModal}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderAlertas = () => {
    if (Array.isArray(alertas)) {
      if (alertas.length === 0) {
        return <p>No hay alertas registradas.</p>;
      }
      return alertas.map((alerta) => (
        <div className="row mx-0 border-bottom py-1 align-items-center">
          <div className="col-3">
            {alerta.Entrada !== null ? alerta.Entrada.contenido : ""}
          </div>
          <div className="col-3 small">
            {moment(alerta.createdAt).format("DD MMM YYYY")}
          </div>
          <div className="col-3">
            <UserCard user={alerta.Usuario !== null ? alerta.Usuario : ""} />
          </div>
          <div className="col-3">
            <button className="btn">
              <i className="fa fa-edit"></i>
            </button>
            <button className="btn" onClick={() => handleDelete(alerta)}>
              <i className="fa fa-trash"></i>
            </button>
          </div>
        </div>
      ));
    }
  };
  return (
    <div className="container-fluid py-3">
      <div>{renderAlertas()}</div>
    </div>
  );
};

export default Alertas;
