import React, { useState, useContext, useEffect } from "react";
import Retraso from "../bitacora/Retraso";
import BitacoraInput from "../bitacora/BitacoraInput";
import { RetrasosContext } from "../../context/RetrasosContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import { AuthContext } from "../../context/AuthContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import { getValue } from "../../utils";

const Retrasos = ({ partida }) => {
  const [retrasosPartida, setRetrasosPartida] = useState(null);

  const { user } = useContext(AuthContext);
  const appconfig = useContext(AppConfigContext);
  const { proyecto } = useContext(ProyectosContext);
  const { retrasos, getRetrasos, postRetraso } = useContext(RetrasosContext);

  const { idProyecto, permiso } = proyecto;

  useEffect(() => {
    if (partida !== null && Array.isArray(retrasos)) {
      const current = retrasos.filter(
        (retraso) => retraso.idPartida === partida.idPartida
      );
      setRetrasosPartida(current);
    }
  }, [partida, retrasos]);

  function renderRetrasos() {
    if (Array.isArray(retrasosPartida)) {
      if (retrasosPartida.length === 0) {
        return "No hay comentarios de retrasos para esta partida";
      }
      return retrasosPartida.map((retraso) => (
        <Retraso
          key={"retraso-" + retraso.idEntrada}
          retraso={retraso}
          uid={user.uid}
          hideImage
        />
      ));
    }
    return "No hay comentarios de retrasos para esta partida";
  }

  function handlePostRetraso(contenido) {
    postRetraso(
      idProyecto,
      {
        contenido,
        retraso: true,
        idPartida: partida.idPartida,
      },
      () => getRetrasos(idProyecto)
    );
  }

  function renderInput() {
    if (["admin", "supervisor"].includes(permiso))
      return (
        <BitacoraInput
          modifier={handlePostRetraso}
          placeholder="Escribe un nuevo comentario"
        />
      );
  }

  function retrasado() {
    if (getValue(appconfig, "progress") === "money") {
      return parseFloat(partida.progreso) / 100 < partida.programado;
    }
    return partida.real < partida.programado;
  }

  return (
    <div className="bg-light p-3">
      <h5>{partida.nombre}</h5>
      <h6 className={retrasado() ? "text-danger" : "text-success"}>
        {retrasado() ? " Retraso\n" : " A tiempo\n"}
      </h6>
      {renderRetrasos()}
      {renderInput()}
    </div>
  );
};

export default Retrasos;
