import React, { useContext, useState } from "react";
import ConceptoEstimacion from "./ConceptoEstimacion";
import ConceptoAutorizado from "./ConceptoAutorizado";
import { formatMonto, getValue } from "../../utils";
import ConceptoEstimado from "./ConceptoEstimado";
import { Link } from "react-router-dom";
import { ContratosContext } from "../../context/ContratosContext";
import { ModalContext } from "../../context/ModalContext";
import SubConceptoForm from "./SubConceptoForm";
import { ConceptosContext } from "../../context/ConceptosContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import usePreciario from "../../hooks/usePreciario";
import { getTipo } from "../../utils/conceptos";

const ConceptoPreciario = ({
  permiso,
  editMode,
  concepto,
  idProyecto,
  estimacion,
  modalComponent,
  showEstimaciones,
  setPropiedadConcepto,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [hoverConcept, setHoverConcept] = useState(false);

  const { contrato } = useContext(ContratosContext);

  const appconfig = useContext(AppConfigContext);

  const { loading, deleteConcepto } = useContext(ConceptosContext);

  const { clearModal } = useContext(ModalContext);

  const { showConceptoDetail } = usePreciario();

  function renderEstimacion() {
    if (showEstimaciones) {
      if (
        editMode === "conceptos-estimacion" &&
        ["admin", "supervisor"].includes(permiso)
      ) {
        return (
          <ConceptoEstimado
            permiso={permiso}
            concepto={concepto}
            estimacion={estimacion}
            idProyecto={idProyecto}
            modifier={setPropiedadConcepto}
          />
        );
      }
      if (
        editMode === "conceptos-estimacion" &&
        ["admin", "finanzas", "obras"].includes(permiso)
      )
        return (
          <ConceptoAutorizado
            concepto={concepto}
            modifier={setPropiedadConcepto}
            estimacion={estimacion}
            idProyecto={idProyecto}
            modalComponent={modalComponent}
          />
        );
      return <ConceptoEstimacion concepto={concepto} />;
    }
  }

  const { clave, unidad, cantidad, precio_actual, importe, costo } = concepto;

  const tipo = getTipo(concepto);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const renderOpenBtn = () => {
    if (concepto.subconceptos?.length > 0) {
      return (
        <button
          className="btn btn-primary p-0 me-2"
          style={{ width: "30px", height: "30px" }}
          onClick={toggleOpen}
        >
          <i className={open ? "fa fa-chevron-up" : "fa fa-chevron-down"}></i>
        </button>
      );
    }
  };

  const renderSubConceptosActions = () => {
    if (getValue(appconfig, "subconceptos_enabled", "boolean")) {
      return (
        <>
          <button
            className="btn btn-primary p-0 me-1"
            style={{ width: "30px", height: "30px" }}
            onClick={handleAddSubConcepto}
          >
            +<i className={""}></i>
          </button>
          {concepto.subconcepto && (
            <>
              <button
                className="btn btn-primary p-0 me-1"
                style={{ width: "30px", height: "30px" }}
                onClick={handleEditSubConcepto}
              >
                <i className={"fas fa-edit"}></i>
              </button>

              <button
                className="btn btn-danger p-0"
                style={{ width: "30px", height: "30px" }}
                onClick={handleDeleteSubconcepto}
              >
                <i className={"fas fa-trash"}></i>
              </button>
            </>
          )}
        </>
      );
    }
  };

  const renderActions = () => {
    if (getValue(appconfig, "subonceptos_enabled", "boolean")) {
      return <td>{renderSubConceptosActions()}</td>;
    }
  };

  const renderSubConceptos = () => {
    if (open) {
      return <>{children}</>;
    }
  };

  const handleAddSubConcepto = () => {
    modalComponent(
      "Añadir subconcepto",
      <SubConceptoForm
        idConceptoPadre={concepto.idConcepto}
        idPartidaContrato={concepto.idPartidaContrato}
      />
    );
  };

  const handleEditSubConcepto = () => {
    modalComponent(
      "Editar subconcepto",
      <SubConceptoForm editMode={true} currentData={concepto} />
    );
  };

  const deleteSubconcepto = () => {
    deleteConcepto(idProyecto, contrato.idContrato, concepto.idConcepto);
  };

  const handleDeleteSubconcepto = () => {
    modalComponent(
      "Eliminar subconcepto",
      <div className="row">
        <h3>¿Estas seguro de eliminar el subconcepto?</h3>
        <p>La información no podrá ser recuperada</p>

        <div className="row">
          <button
            type="button"
            className="btn btn-danger  col-6"
            disabled={loading}
            onClick={deleteSubconcepto}
          >
            Eliminar
          </button>

          <button
            type="button"
            className="btn col-6"
            onClick={clearModal}
            disabled={loading}
          >
            Cancelar
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <tr
        className={`concepto-row border-bottom 
        concepto-${tipo.toLowerCase()}`}
      >
        {getValue(appconfig, "subconceptos_enabled", "boolean") && (
          <td>{renderOpenBtn()}</td>
        )}
        <td>{clave}</td>
        <td className="nombre-concepto">
          {getValue(appconfig, "subconceptos_enabled", "boolean") ||
          getValue(appconfig, "conceptos_generadores", "boolean") ? (
            <Link
              to={`./concepto/${concepto.idConcepto}`}
              onClick={() => showConceptoDetail(concepto)}
              className="text-underline text-primary"
            >
              {String(concepto.nombre)}
            </Link>
          ) : (
            <p
              onMouseEnter={() => setHoverConcept(true)}
              onMouseLeave={() => setHoverConcept(false)}
              style={{
                maxHeight: hoverConcept ? "unset" : "70px",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              className={`mb-0 `}
            >
              {concepto.nombre}
            </p>
          )}
        </td>
        <td>{unidad}</td>
        <td>{cantidad}</td>
        <td>
          {"$"}
          {formatMonto(precio_actual !== null ? precio_actual : concepto.precio)}
        </td>
        {getValue(appconfig, "costs_enabled", "boolean") && (
          <td>
            {"$"}
            {costo}
          </td>
        )}
        <td>
          {"$"}
          {formatMonto(importe)}
        </td>
        {renderActions()}
        {renderEstimacion()}
      </tr>
      {renderSubConceptos()}
    </>
  );
};

export default ConceptoPreciario;
