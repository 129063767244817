import React, { useContext, useEffect } from "react";
import Folder from "./Folder";
import { MenuContext } from "../context/MenuContext";

const Proyecto = ({ idProyecto }) => {
  const { setSelected } = useContext(MenuContext);

  useEffect(() => {
    setSelected({ title: "proyecto" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Folder
      title="Proyecto"
      idFolder="proyecto"
      idProyecto={idProyecto}
      hideFolderButton
    />
  );
};

export default Proyecto;
