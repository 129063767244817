import React from "react";

const TriggerForm = ({
  index,
  trigger,
  objectType,
  deleteTrigger,
  setPropertyTrigger,
}) => {
  const renderType = () => {
    if (objectType === "Partida" || objectType === "Proyecto") {
      return (
        <div className="col">
          <select className="form-control">
            <option value="time">Tiempo Transcurrido</option>
            <option value="diferential">Diferencial</option>
            <option value="progress">Progreso</option>
          </select>
        </div>
      );
    }
    if (objectType !== "Carpeta") {
      return (
        <div className="col">
          <select className="form-control">
            <option value="diferential">Diferencial</option>
            <option value="progress">Progreso</option>
          </select>
        </div>
      );
    }
  };

  const renderOperand = () => {
    if (objectType !== "Carpeta") {
      return (
        <select
          className="form-control"
          value={trigger.operand}
          onChange={(e) =>
            setPropertyTrigger(trigger.idTrigger, "operand", e.target.value)
          }
        >
          <option value=">=">Mayor o igual que</option>
          <option value="<=">Menor o igual que</option>
        </select>
      );
    }
    return (
      <select className="form-control">
        <option value="not null">Archivo subido</option>
        <option value="null">Archivo eliminado</option>
      </select>
    );
  };

  const renderTreshold = () => {
    if (objectType !== "Carpeta") {
      return (
        <div className="row align-items-center">
          <div className="col-8">
            <input
              type="number"
              className="form-control"
              value={trigger.treshold}
              max={100}
              onChange={(e) =>
                setPropertyTrigger(
                  trigger.idTrigger,
                  "treshold",
                  e.target.value
                )
              }
            />
          </div>
          <div className="col-4">%</div>
        </div>
      );
    }
  };
  return (
    <div className="row mx-0 align-items-center mb-3">
      <h6 className="bold mb-0 px-0">
        Condicion #{isNaN(trigger.idTrigger) ? index + 1 : trigger.idTrigger}
      </h6>
      <div className="col-10 mt-2 px-0">
        <div className="row align-items-center">
          {renderType()}
          <div className="col">{renderOperand()}</div>
          <div className="col">{renderTreshold()}</div>
        </div>
      </div>
      <div className="col-2 mt-2 text-end">
        <button
          type="button"
          className="btn btn-sm btn-outline-danger"
          onClick={() => deleteTrigger(trigger.idTrigger)}
        >
          <i className="fa fa-trash"></i>
        </button>
      </div>
    </div>
  );
};

export default TriggerForm;
