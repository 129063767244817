import api from "./api";

const route = "/conceptosubicaciones";

export default {
  getConceptosUbicaciones: (idProyecto, idContrato, idEstimacionVersion) =>
    api.get(`${route}/${idProyecto}/${idContrato}/${idEstimacionVersion}`),
  getConceptosUbicacionesConcepto: (idProyecto, idEstimacionVersion, idConcepto) =>
    api.get(`${route}/${idProyecto}/ubicaciones/${idEstimacionVersion}/concepto/${idConcepto}`),
  getSingleConceptosUbicacion: (idConceptoUbicacion) =>
    api.get(`${route}/${idConceptoUbicacion}`),
  postConceptosUbicacion: (conceptosUbicacion) =>
    api.post(route, { ...conceptosUbicacion }),
  postConceptoUbicacion: (idProyecto, idContrato, idEstimacionVersion, conceptoubicacion) =>
    api.post(`${route}/${idProyecto}/${idContrato}/${idEstimacionVersion}`, { ...conceptoubicacion }),
  deleteConceptosUbicacion: (ConceptosUbicacion) =>
    api.delete(`${route}/${ConceptosUbicacion}`),
};
