import React, { createContext, useContext, useReducer } from "react";
import ContratosService from "../services/ContratosService";
import { ModalContext } from "./ModalContext";
import {
  EDIT_CONTRATO,
  CREATE_CONTRATO,
  SELECT_CONTRATO,
  SELECTING_CONTRATO,
  CONTRATOS_RECIBIDOS,
  CANCEL_EDIT_CONTRATO,
  SET_PROPERTY_CONTRATO,
} from "../types/contratos";
import { HIDE_SPINNER, SHOW_SPINNER } from "../actions/types";
import ContratosReducer from "../reducers/ContratosReducer";

const initialState = {
  contratos: null,
  contrato: null,
  editMode: false,
};

export const ContratosContext = createContext(initialState);

export const ContratosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ContratosReducer, initialState);

  const { success, alert, clearModal } = useContext(ModalContext);

  const getContratosProyecto = (idProyecto) => {
    ContratosService.getContratos(idProyecto).then((res) => {
      const { contratos } = res.data;
      dispatch({ type: CONTRATOS_RECIBIDOS, payload: contratos });
    });
  };

  const selectContrato = (idProyecto, idContrato) => {
    dispatch({ type: SELECTING_CONTRATO });
    ContratosService.getContrato(idProyecto, idContrato).then((res) => {
      const { contrato } = res.data;
      dispatch({ type: SELECT_CONTRATO, payload: contrato });
    });
  };

  const createContrato = (num) => {
    dispatch({ type: CREATE_CONTRATO, payload: num });
  };

  const editContrato = (contrato) => {
    dispatch({ type: EDIT_CONTRATO, payload: contrato });
  };

  const setPropiedadContrato = (key, value) => {
    dispatch({ type: SET_PROPERTY_CONTRATO, payload: { key, value } });
  };

  const postContrato = (idProyecto, contrato) => {
    if (contrato.numero === "") {
      return alert("El contrato debe tener un número");
    }
    if (contrato.proveedor === "" && isNaN(parseInt(contrato.idProveedor))) {
      return alert("El contrato debe tener un proveedor");
    }
    dispatch({ type: SHOW_SPINNER });
    let service = ContratosService.putContrato;
    if (isNaN(contrato.idContrato)) {
      service = ContratosService.postContrato;
    }
    service(idProyecto, contrato)
      .then(() => {
        clearModal();
        getContratosProyecto(idProyecto);
        success("Contrato guardado con éxito");
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const cancelEditContrato = () => {
    dispatch({ type: CANCEL_EDIT_CONTRATO });
  };

  return (
    <ContratosContext.Provider
      value={{
        ...state,
        editContrato,
        postContrato,
        selectContrato,
        createContrato,
        cancelEditContrato,
        getContratosProyecto,
        setPropiedadContrato,
      }}
    >
      {children}
    </ContratosContext.Provider>
  );
};
