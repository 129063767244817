import React, { createContext, useContext, useReducer } from "react";
import ChecklistReducer from "../reducers/ChecklistReducer";
import ChecklistsService from "../services/ChecklistsService";
import {
  CHECKLISTS_RECIBIDOS,
  SET_PROPERTY_CHECKLIST,
  SINGLE_CHECKLIST_RECIBIDO,
  SET_LAST_ITEM,
  SET_LAST_POST,
  SET_PROGRESS,
  SET_MODE,
  EXPAND_ALL,
  SET_ESTADO,
} from "../types/checklist";
import { ModalContext } from "./ModalContext";
import PlantillasService from "../services/PlantillasService";
import moment from "moment";

const checklistSchema = {
  idChecklist: "nuevo",
  fecha_inicio: null,
  fecha_fin: null,
  nombre: "",
};

const initialState = {
  checklists: null,
  checklist: null,
  editMode: false,
  entrada: null,
  estado: null,
  query: "",
  mode: "config",
};

export const ChecklistContext = createContext(initialState);

export const ChecklistProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ChecklistReducer, initialState);

  const { success, alert, clearModal } = useContext(ModalContext);

  const getChecklistsProyecto = (idProyecto) => {
    ChecklistsService.getChecklists(idProyecto).then((res) => {
      const { checklists } = res.data;
      dispatch({ type: CHECKLISTS_RECIBIDOS, payload: checklists });
    });
  };
  const getSingleChecklist = (idProyecto, idChecklist) => {
    ChecklistsService.getSingleChecklist(idProyecto, idChecklist).then(
      (res) => {
        const { checklist, progreso, entrada, item } = res.data;
        dispatch({ type: SET_PROGRESS, payload: progreso });
        dispatch({ type: SET_LAST_ITEM, payload: item });
        dispatch({ type: SET_LAST_POST, payload: entrada });
        dispatch({ type: SINGLE_CHECKLIST_RECIBIDO, payload: checklist });
      }
    );
  };

  const setPropertyChecklist = (key, value) => {
    dispatch({ type: SET_PROPERTY_CHECKLIST, payload: { key, value } });
  };

  const editChecklist = (checklist) => {
    dispatch({ type: SINGLE_CHECKLIST_RECIBIDO, payload: checklist });
  };

  const clearChecklist = () => {
    dispatch({ type: SINGLE_CHECKLIST_RECIBIDO, payload: null });
  };

  const createChecklist = () => {
    dispatch({ type: SINGLE_CHECKLIST_RECIBIDO, payload: checklistSchema });
  };

  const setMode = mode => {
    dispatch({ type: SET_MODE, payload: mode });
  }

  const setExpandAll = value => {
    dispatch({ type: EXPAND_ALL, payload: value });
  }

  const setEstado = estado => {
    dispatch({ type: SET_ESTADO, payload: estado })
  }

  const postChecklist = (checklist) => {
    let service = ChecklistsService.putChecklist;
    if (isNaN(parseInt(checklist.idChecklist))) {
      service = ChecklistsService.postChecklist;
    }
    service(checklist)
      .then(() => {
        clearModal();
        getChecklistsProyecto(checklist.idProyecto);
        success("Checklist guardado.");
      })
      .catch(alert);
  };

  const postChecklistSection = (idProyecto, section) => {
    let service = ChecklistsService.putChecklistSection;
    if (isNaN(parseInt(section.idChecklistSection))) {
      service = ChecklistsService.postChecklistSection;
    }
    service(idProyecto, section)
      .then(() => {
        clearModal();
        success("Sección guardada.");
        getSingleChecklist(idProyecto, section.idChecklist);
      })
      .catch(alert);
  };

  const deleteChecklist = (idProyecto, idChecklist) => {
    ChecklistsService.deleteChecklist(idProyecto, idChecklist).then(() => {
      clearModal();
      getChecklistsProyecto(idProyecto);
      success("Checklist eliminado.");
    });
  };

  const deleteChecklistSection = (
    idProyecto,
    idChecklist,
    idChecklistSection
  ) => {
    ChecklistsService.deleteChecklistSection(
      idProyecto,
      idChecklistSection
    ).then(() => {
      success("Sección eliminada.");
      getSingleChecklist(idProyecto, idChecklist);
    });
  };

  const postPlantillaChecklist = (nombre, sections, items) => {
    let plantilla = {};
    plantilla.sections = sections.map((section) => {
      let currentItems = items.filter(
        (item) => item.idChecklistSection === section.idChecklistSection
      );
      currentItems = currentItems.map((item) => {
        const duracion = moment(item.fecha_fin)
          .utc()
          .diff(moment(item.fecha_inicio).utc(), "days");
        return { duracion, nombre: item.nombre, responsable: item.responsable };
      });
      section.items = currentItems;
      return { nombre: section.nombre, items: currentItems };
    });
    plantilla.nombre = nombre;
    plantilla.tipo = "checklists";
    PlantillasService.postPlantilla(plantilla).then((res) => {
      success("Plantilla guardada.");
      clearModal();
    });
  };

  return (
    <ChecklistContext.Provider
      value={{
        ...state,
        setMode,
        setEstado,
        setExpandAll,
        editChecklist,
        postChecklist,
        clearChecklist,
        deleteChecklist,
        createChecklist,
        getSingleChecklist,
        postChecklistSection,
        setPropertyChecklist,
        getChecklistsProyecto,
        deleteChecklistSection,
        postPlantillaChecklist,
      }}
    >
      {children}
    </ChecklistContext.Provider>
  );
};
