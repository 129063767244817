import React, { useContext, useEffect } from "react";
import { PartidasContext } from "../../context/PartidasContext";
import { PartidasContratoContext } from "../../context/PartidasContratoContext";
import { getValue } from "../../utils";
import useProyecto from "../../hooks/useProyecto";

const PartidaPreciarioForm = ({ partida }) => {
  const { partidas, getPartidasProyecto } = useContext(PartidasContext);
  const { deletePartida, setPropiedadPartida } = useContext(
    PartidasContratoContext
  );
  const { idPartidaContrato } = partida;
  const { idProyecto } = useProyecto();

  useEffect(() => {
    if (!Array.isArray(partidas)) {
      getPartidasProyecto(idProyecto);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPartidas = () => {
    if (Array.isArray(partidas)) {
      return [
        <option value={null} key="null">
          Sin Relación
        </option>,
        <option value="true">Crear Nueva Partida</option>,
        ...partidas.map((partida) => (
          <option key={partida.idPartida} value={partida.idPartida}>
            {partida.nombre}
          </option>
        )),
      ];
    }
  };

  return (
    <tr>
      <td>
        <input
          type="text"
          value={getValue(partida, "clave")}
          maxLength={140}
          className="form-control"
          onChange={(e) =>
            setPropiedadPartida(idPartidaContrato, "clave", e.target.value)
          }
        />
      </td>
      <td>
        <input
          type="text"
          value={getValue(partida, "nombre")}
          maxLength={140}
          className="form-control"
          onChange={(e) =>
            setPropiedadPartida(idPartidaContrato, "nombre", e.target.value)
          }
        />
      </td>
      <td>
        <select
          value={getValue(partida, "idPartida")}
          className="form-control"
          onChange={(e) => {
            const { value } = e.target;
            if (isNaN(parseInt(value))) {
              setPropiedadPartida(idPartidaContrato, "idPartida", null);
              setPropiedadPartida(
                idPartidaContrato,
                "cronograma",
                e.target.value
              );
            } else {
              setPropiedadPartida(idPartidaContrato, "cronograma", true);
              setPropiedadPartida(
                idPartidaContrato,
                "idPartida",
                e.target.value
              );
            }
          }}
        >
          {renderPartidas()}
        </select>
      </td>
      <td>
        <button
          className="btn btn-outline-danger"
          onClick={() => deletePartida(idPartidaContrato)}
        >
          <i className="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  );
};

export default PartidaPreciarioForm;
