
import api from './api';

const route = '/warehouses';

export default {
  getWarehouses: () => api.get(route),
  getSingleWarehouse: (warehouse_id) => api.get(`${route}/${warehouse_id}`),
  postWarehouse: (warehouse) => api.post(route, { ...warehouse}),
  putWarehouse: (warehouse) => api.put(route, { ...warehouse}),
  deleteWarehouse: (Warehouse) => api.delete(`${route}/${Warehouse}`),
};
