import React, { useContext } from "react";
import { formatMonto, getValue } from "../../utils";
import Folder from "../../views/Folder";
import { ModalContext } from "../../context/ModalContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import CreatableSelect from "react-select/creatable";
import { Link } from "react-router-dom";
import usePreciario from "../../hooks/usePreciario";

const ConceptoEstimado = ({
  permiso,
  concepto,
  modifier,
  estimacion,
  idProyecto,
}) => {
  const {
    idConcepto,
    idAdjunto,
    precio_estimado,
    supervisado,
    autorizado,
    all_precios,
    precio_autorizado,
  } = concepto;
  const { idContrato } = usePreciario();

  const conceptoPrecios = all_precios
    .map((obj) => ({
      value: obj.Precio?.precio_unitario,
      label: `$${obj.Precio?.precio_unitario}`,
    }))
    .filter((precio) => (precio.value ? precio : null));

  const { modalComponent } = useContext(ModalContext);
  const appconfig = useContext(AppConfigContext);

  function showModal() {
    const { idFolder, numero } = estimacion;
    modalComponent(
      "Elegir Evidencia",
      <Folder
        title={`Estimacion #${numero}`}
        idFolder={idFolder}
        idProyecto={idProyecto}
        hideFolderButton
        onClick={(adjunto) =>
          modifier(concepto.idConcepto, "idAdjunto", adjunto.idAdjunto)
        }
      />
    );
  }

  function renderCol(col) {
    const anterior = `${col}Anterior`;
    const value = parseFloat(concepto[col]) - parseFloat(concepto[anterior]);
    if (value > 0) return value;
    return 0;
  }

  function renderImporte() {
    const autorizadoActual =
      parseFloat(concepto.autorizado) - parseFloat(concepto.autorizadoAnterior);
    if (autorizadoActual > 0) {
      return autorizadoActual * parseFloat(concepto.precio_autorizado);
    }
    return 0;
  }

  return (
    <>
      <td className="bg-secondary ps-3">{concepto.estimadoAcumulado}</td>
      <td className="bg-secondary">
        {getValue(appconfig, "conceptos_generadores", "boolean") ? (
          <Link
            to={`/obra/${idProyecto}/finanzas/${idContrato}/generador/${idConcepto}/estimacion/${estimacion.idEstimacion}`}
            className="text-primary text-underline small"
          >
            <i className="fa fa-edit" />
          </Link>
        ) : (
          <input
            min={0}
            type="number"
            value={getValue(concepto, "estimado")}
            className="form-control"
            onChange={(e) => {
              modifier(idConcepto, "estimado", e.target.value);
              if (!getValue(appconfig, "supervise_estimates", "boolean")) {
                modifier(idConcepto, "supervisado", e.target.value);
              }
              if (!getValue(appconfig, "authorize_estimates", "boolean")) {
                modifier(idConcepto, "autorizado", e.target.value);
              }
            }}
          />
        )}
      </td>
      {getValue(appconfig, "editable_prices", "boolean") && (
        <td className="bg-secondary">
          <CreatableSelect
            defaultValue={{
              value: precio_estimado,
              label: `$${precio_estimado}`,
            }}
            onChange={(newValue) => {
              modifier(idConcepto, "precio_estimado", newValue.value);
            }}
            options={conceptoPrecios}
          />
        </td>
      )}

      {getValue(appconfig, "costs_enabled", "boolean") && (
        <td className="bg-secondary">
          <div className="input-group">
            <span className="input-group-text" id="basic-addon1">
              $
            </span>
            <input
              min={0}
              type="number"
              value={getValue(concepto, "costo_estimado")}
              className="form-control border"
              onChange={(e) => {
                modifier(idConcepto, "costo_estimado", e.target.value);
              }}
            />
          </div>
        </td>
      )}

      <td className="bg-secondary">{renderCol("estimado")}</td>

      {getValue(appconfig, "supervise_estimates", "boolean") && [
        <td key="supervisado-anterior" className="bg-secondary">
          {concepto.supervisadoAcumulado}
        </td>,
        <td key="supervisado" className="bg-secondary">
          <input
            min={0}
            type="number"
            value={getValue(concepto, "supervisado")}
            className="form-control"
            onChange={(e) =>
              modifier(idConcepto, "supervisado", e.target.value)
            }
          />
        </td>,
        <td key="supervisado-acumulado" className="bg-secondary">
          {renderCol("supervisado")}
        </td>,
      ]}
      {getValue(appconfig, "authorize_estimates", "boolean") && [
        <td key="autorizado-anterior" className="bg-secondary">
          {concepto.autorizadoAcumulado}
        </td>,
        <td key="autorizado" className="bg-secondary">
          {["admin", "finanzas"].includes(permiso) && supervisado !== null ? (
            <input
              min={0}
              type="number"
              value={getValue(concepto, "autorizado")}
              className="form-control"
              onChange={(e) =>
                modifier(idConcepto, "autorizado", e.target.value)
              }
            />
          ) : (
            formatMonto(autorizado)
          )}
        </td>,
        <td key="autorizado-acumulado" className="bg-secondary">
          {renderCol("autorizado")}
        </td>,
        <td key="precio-autorizado" className="bg-secondary">
          {["admin", "finanzas"].includes(permiso) && supervisado !== null ? (
            <input
              min={0}
              type="number"
              value={getValue(concepto, "precio_autorizado")}
              className="form-control"
              onChange={(e) =>
                modifier(idConcepto, "precio_autorizado", e.target.value)
              }
            />
          ) : (
            <>${formatMonto(precio_autorizado)}</>
          )}
        </td>,
      ]}
      <td className="bg-secondary">${formatMonto(renderImporte())}</td>
      <td className="bg-secondary">
        <button className="btn btn-outline-secondary" onClick={showModal}>
          {idAdjunto && idAdjunto !== null ? (
            <i className="fa fa-edit" />
          ) : (
            <i className="fa fa-image" />
          )}
        </button>
      </td>
    </>
  );
};

export default ConceptoEstimado;
