import React, { useContext, useState } from "react";
import useProyecto from "../../hooks/useProyecto";
import { getEstadosByPermiso } from "../../utils/estimacionesversiones";
import { EstimacionVersionesContext } from "../../context/EstimacionVersionesContext";
import { ModalContext } from "../../context/ModalContext";
import { CambioEstimacionVersionesContext } from "../../context/CambioEstimacionVersionsContext";

const EstimacionVersionCierreForm = ({ handleCancel, handleCallback }) => {
  const [comentario, setComentario] = useState("");
  const [estado, setEstado] = useState("");
  const [step, setStep] = useState("");
  const [code, setCode] = useState("");

  const { idProyecto, permiso } = useProyecto();

  const { alert } = useContext(ModalContext);
  const { estimacionversion, updateEstimacionVersion } = useContext(
    EstimacionVersionesContext
  );
  const {
    cambioestimacionversion,
    setCambioEstimacionVersion,
    verifyCambioEstimacionVersion,
  } = useContext(CambioEstimacionVersionesContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (comentario === "") {
      return alert("Debes incluir un comentario");
    }
    setStep("verification");
    updateEstimacionVersion(
      idProyecto,
      { ...estimacionversion, estado, comentario },
      handleSubmitCallback
    );
  };

  const handleSubmitCallback = (cambio) => {
    setCambioEstimacionVersion(cambio);
  };

  const handleVerification = (e) => {
    e.preventDefault();
    verifyCambioEstimacionVersion(
      idProyecto,
      cambioestimacionversion.idCambioEstimacionVersion,
      code,
      handleCallback
    );
  };

  const renderOptions = () => {
    const options = getEstadosByPermiso(permiso, estimacionversion.estado);
    return options.map((current) => (
      <option value={current.value} key={current.value}>
        {current.label}
      </option>
    ));
  };

  const renderForm = () => {
    if (step !== "verification")
      return (
        <form onSubmit={handleSubmit}>
          <label>Estado</label>
          <select
            value={estado}
            className="form-control mb-3"
            onChange={(e) => setEstado(e.target.value)}
          >
            {renderOptions()}
          </select>
          <label>Comentario</label>
          <textarea
            rows="3"
            value={comentario}
            className="form-control mb-3"
            onChange={(e) => setComentario(e.target.value)}
          />
          <div className="row">
            <div className="col">
              <button
                type="button"
                onClick={handleCancel}
                className="btn w-100 text-muted"
              >
                Cancelar
              </button>
            </div>
            <div className="col">
              <button type="submit" className="btn w-100 btn-primary">
                Guardar
              </button>
            </div>
          </div>
        </form>
      );
    if (cambioestimacionversion === null)
      return <div className="spinner-border" />;
    return (
      <form onSubmit={handleVerification}>
        <label>Código de Verificación</label>
        <input
          type="text"
          value={code}
          className="form-control"
          onChange={(e) => setCode(e.target.value)}
        />
        <div className="row">
          <div className="col">
            <button
              type="button"
              onClick={handleCancel}
              className="btn w-100 text-muted"
            >
              Cancelar
            </button>
          </div>
          <div className="col">
            <button type="submit" className="btn w-100 btn-primary">
              Verificar
            </button>
          </div>
        </div>
      </form>
    );
  };

  return <div>{renderForm()}</div>;
};

export default EstimacionVersionCierreForm;
