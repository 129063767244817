import React, { useEffect, useState, useContext } from "react";
import ProviderDetails from "../components/purchaseOrders/ProviderDetails";
import ProductList from "../components/purchaseOrders/ProductList";
import ProductOrderDetails from "../components/purchaseOrders/ProductOrderDetails";
import ProductOrderUpdates from "../components/purchaseOrders/ProductOrderUpdates";
import { useParams } from "react-router-dom";
import { OrdenesContext } from "../context/OrdenesContext";
import OrderHandler from "../utils/ordendes/OrderHandler";

const PurchaseOrder = ({ idProyecto }) => {
  const { idOrden } = useParams();
  const { orden, getSingleOrder, getProductsOrder, currentProductsOrder } =
    useContext(OrdenesContext);
  const { handleEditOrderUpdates } = OrderHandler();

  const [orderCompleted, setOrderCompleted] = useState(false);
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    getProductsOrder(idOrden);
    getSingleOrder(idProyecto, idOrden);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idOrden]);

  useEffect(() => {
    setProductList(currentProductsOrder);
  }, [currentProductsOrder]);

  useEffect(() => {
    if (orden && orden.status === "recibido") setOrderCompleted(true);
  }, [orden]);

  return (
    <div className="d-flex flex-row">
      <div style={{ width: "60%", marginRight: "10px", padding: "20px" }}>
        <p className="bold mb-0 p-0">Proveedor</p>
        <ProviderDetails orderCompleted={orderCompleted} order={orden} />
        <ProductList
          orderCompleted={orderCompleted}
          order={orden}
          productList={productList}
        />
      </div>
      <div style={{ width: "40%", padding: "20px" }}>
        <div className="d-flex justify-content-between">
          <p className="bold mb-0 p-0">Detalles</p>
          {!orderCompleted && (
            <div>
              <button className="btn ">
                <i className="fa fa-edit"></i>
              </button>
              <button className="btn">
                <i className="fa fa-trash"></i>
              </button>
            </div>
          )}
        </div>
        <ProductOrderDetails
          order={orden}
          orderCompleted={orderCompleted}
          productList={productList}
        />
        <div className="mt-4 mb-3 d-flex w-100 justify-content-between">
          <p className="bold ">Actividad</p>
          <button
            className="btn btn-primary"
            onClick={() => handleEditOrderUpdates(orden)}
          >
            <i className="fa fa-edit"></i>
            Actualizar Status de la Orden
          </button>
        </div>
        <ProductOrderUpdates status={orden.status} order={orden} />
      </div>
    </div>
  );
};

export default PurchaseOrder;
