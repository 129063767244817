import React, { useState, useContext, useEffect } from "react";
import CheckBox from "../obra/CheckBox";
import { ModalContext } from "../../context/ModalContext";
import { PlantillasContext } from "../../context/PlantillasContext";

const PlantillaRow = ({
  selected,
  disabled,
  plantilla,
  handleCallback,
  selectPlantilla,
  deletePlantilla,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [editedNombre, setEditedNombre] = useState(null);
  const { updatePlantilla } = useContext(PlantillasContext);
  const { confirm, clearModal } = useContext(ModalContext);

  useEffect(() => {
    if (editMode) {
      setEditedNombre(plantilla.nombre);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  const handleSave = () => {
    if (editedNombre !== null && editedNombre !== plantilla.nombre) {
      updatePlantilla(plantilla.idPlantilla, editedNombre, handleCallback);
    }
  };

  const toggleEditMode = (cancel) => {
    if (editMode && !cancel) {
      handleSave();
    }
    setEditMode(!editMode);
  };

  const handleDelete = (plantilla) => {
    clearModal();
    setTimeout(() => {
      confirm(
        `¿Estás seguro que deseas eliminar la plantilla ${plantilla.nombre}? Esta acción no se puede deshacer.`,
        () => deletePlantilla(plantilla.idPlantilla)
      );
    }, 500);
  };
  return (
    <div
      className="row align-items-center mb-3"
      key={"plantilla-" + plantilla.idPlantilla}
    >
      <div className="col-8 px-0">
        {editMode && editedNombre !== null ? (
          <input
            type="text"
            value={editedNombre}
            className="form-control"
            onChange={(e) => setEditedNombre(e.target.value)}
          />
        ) : (
          <span>{plantilla.nombre}</span>
        )}
      </div>
      <div className="col-4 text-end">
        {!disabled && (
          <CheckBox
            checked={plantilla.idPlantilla === selected}
            modifier={() => selectPlantilla(plantilla.idPlantilla)}
          />
        )}
        {editMode && (
          <button
            onClick={() => toggleEditMode(true)}
            className="btn btn-sm btn-outline-danger"
          >
            <i className="fa fa-times"></i>
          </button>
        )}
        {disabled && (
          <button
            onClick={() => toggleEditMode()}
            className="btn btn-sm ms-2 btn-outline-secondary"
          >
            <i className={`fa fa-${editMode ? "save" : "edit"}`}></i>
          </button>
        )}
        {!editMode && (
          <button
            onClick={() => handleDelete(plantilla)}
            className="btn btn-sm ms-2 btn-outline-danger"
          >
            <i className="fa fa-trash"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default PlantillaRow;
