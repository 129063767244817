import React, { useContext } from "react";
import ConceptoProgressBar from "../global/ConceptoProgressBar";
import { getValue } from "../../utils";
import { AppConfigContext } from "../../context/AppConfigContext";

const ProgresoPartidaChart = ({ partida, handleClick }) => {
  const appconfig = useContext(AppConfigContext);

  const real = getValue(appconfig, "progress") === "money" ?  Number(partida.progreso) : Number(partida.real * 100);
  const programado = Number(partida.programado) * 100;

  return (
    <div className="mb-4">
      <div className="row mx-0 align-items-center">
        <div className="col-6 ">
          <p className="mb-0">{partida.nombre}</p>
        </div>
        <div className="col-6  text-end">
          <p className="mb-0">
            {real}% / {programado}%
          </p>{" "}
        </div>
      </div>
      <ConceptoProgressBar
        label={`${real}% / ${programado}%`}
        idPartida={partida.idPartida}
        handleClick={handleClick}
        programado={programado}        
        real={real}
      />
    </div>
  );
};

export default ProgresoPartidaChart;
