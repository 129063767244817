import React, { useContext, useReducer } from "react";
import { createContext } from "react";
import { EDIT_PAGO, HIDE_SPINNER, SHOW_SPINNER } from "../actions/types";
import {
  PAGO_TO_CANCEL,
  PAGOS_RECIBIDOS,
  SET_PROPERTY_PAGO,
} from "../types/pagos";
import PagosReducer from "../reducers/PagosReducer";
import moment from "moment";
import PagosService from "../services/PagosService";
import { ModalContext } from "./ModalContext";

const initialState = {
  pagos: null,
  pago: null,
};

export const PagosContext = createContext(initialState);

export const PagosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PagosReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getPagosProyecto = (idProyecto) => {
    PagosService.getPagosProyecto(idProyecto)
      .then((res) => {
        const { pagos } = res.data;
        dispatch({ type: PAGOS_RECIBIDOS, payload: pagos });
      })
      .catch(alert);
  };

  const getPagosContrato = (idProyecto, idContrato) => {
    PagosService.getPagosContrato(idProyecto, idContrato).then((res) => {
      const { pagos } = res.data;
      dispatch({ type: PAGOS_RECIBIDOS, payload: pagos });
    });
  };

  const createPagoContrato = () => {
    const schema = {
      monto: 0,
      idPago: "nuevo",
      comentario: "",
      fecha_liberado: "",
      fecha_cancelacion: "",
      fecha_autorizacion: "",
    };
    dispatch({ type: EDIT_PAGO, payload: schema });
  };

  const editPago = (pago) => {
    dispatch({ type: EDIT_PAGO, payload: pago });
  };

  const setPropiedadPago = (key, value) => {
    dispatch({ type: SET_PROPERTY_PAGO, payload: { key, value } });
  };

  const postPagoContrato = (idProyecto, idContrato, pago, callback) => {
    if (pago.monto === "" || pago.monto === 0) {
      alert("El pago debe tener un monto.");
    }
    dispatch({ type: SHOW_SPINNER });
    if (pago.idPago === "nuevo") {
      if (pago.fecha_autorizado === "") {
        pago.fecha_autorizado = moment();
      }
      PagosService.postPagoContrato(idProyecto, idContrato, pago)
        .then(() => {
          success("Pago guardado.");
          if (callback) callback();
          dispatch({ type: HIDE_SPINNER });
        })
        .catch((error) => {
          alert(error);
          dispatch({ type: HIDE_SPINNER });
        });
    } else {
      if (pago.fecha_liberado == null)
        pago.fecha_liberado = moment().format("YYYY-MM-DD");
      PagosService.putPagoContrato(idProyecto, idContrato, pago)
        .then(() => {
          success("Pago guardado.");
          if (callback) callback();
          dispatch({ type: HIDE_SPINNER });
        })
        .catch((error) => {
          alert(error);
          dispatch({ type: HIDE_SPINNER });
        });
    }
  };

  const cancelPago = (pago) => {
    dispatch({ type: PAGO_TO_CANCEL, payload: pago });
  };

  const clearPago = () => {
    dispatch({ type: EDIT_PAGO, payload: null });
  };

  const postCancelPago = (idProyecto, idContrato, pago, callback) => {
    const { idPago, fecha_cancelacion, comentario } = pago;
    PagosService.postPagoCancelado(
      idProyecto,
      idContrato,
      idPago,
      fecha_cancelacion,
      comentario
    )
      .then((res) => {
        success("Pago cancelado con éxito.");
        if (typeof callback === "function") {
          callback();
        }
        clearModal();
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <PagosContext.Provider
      value={{
        ...state,
        editPago,
        clearPago,
        cancelPago,
        postCancelPago,
        getPagosProyecto,
        setPropiedadPago,
        postPagoContrato,
        getPagosContrato,
        createPagoContrato,
      }}
    >
      {children}
    </PagosContext.Provider>
  );
};
