import React, { useContext } from "react";
import ChecklistSection from "./ChecklistSection";
import useProyecto from "../../hooks/useProyecto";
import { ItemsContext } from "../../context/ItemsContext";
import { filterChecklistSections } from "../../utils/checklists";
import { ChecklistContext } from "../../context/ChecklistContext";

const ChecklistItems = () => {
  const { query, checklist } = useContext(ChecklistContext);
  const { items } = useContext(ItemsContext);

  const { proyecto } = useProyecto();

  const renderItems = () => {
    if (checklist !== null && proyecto !== null) {
      if (Array.isArray(checklist.sections)) {
        let sectionsRender = [...checklist.sections];
        if (sectionsRender.length === 0) {
          return (
            <p className="mx-2 px-2">
              No hay secciones que mostrar aún. Agrega una.
            </p>
          );
        }
        if (query && query !== "" && query !== null) {
          sectionsRender = filterChecklistSections(
            sectionsRender,
            query,
            items
          );
        }
        return sectionsRender.map((section) => (
          <ChecklistSection
            section={section}
            key={section.idChecklistSection}
            idSection={section.idChecklistSection}
          />
        ));
      }
    }
  };

  return <div>{renderItems()}</div>;
};

export default ChecklistItems;
