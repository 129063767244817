import React, { useContext, useEffect } from "react";
import { UsuariosContext } from "../../context/UsuariosContext";
import { PermisosContext } from "../../context/PermisosContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import AdjuntosService from "../../services/AdjuntosService";
import ImageInput from "../common/ImageInput";
import SearchableSelect from "../global/SearchableSelect";

const UsuarioForm = ({ handleCancel }) => {
  const { usuario, postUsuario, setPropiedadUsuario } =
    useContext(UsuariosContext);
  const { permisos, getPermisos } = useContext(PermisosContext);
  const { S3_ENDPOINT } = useContext(AppConfigContext);

  useEffect(() => {
    getPermisos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(permisos)) {
      handlePermiso();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permisos]);

  const handlePermiso = () => {
    if (usuario.idPermiso === null) {
      const current = permisos[1];
      setPropiedadUsuario("idPermiso", current.idPermiso);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (usuario.file && usuario.file !== null) {
      let formData = AdjuntosService.getFormData(usuario.file);
      // console.log(usuario.file);
      console.log(formData);
      AdjuntosService.postAdjunto(formData).then((res) => {
        const { idAdjunto } = res.data;
        postUsuario({ ...usuario, idAdjunto }, handleCancel);
      });
    } else {
      postUsuario(usuario, handleCancel);
    }
  };

  const renderPermisos = () => {
    if (Array.isArray(permisos)) {
      return (
        <div className="mb-3">
          <SearchableSelect
            items={permisos}
            itemText={"nombre"}
            itemValue={"idPermiso"}
            modifier={(permiso) =>
              setPropiedadUsuario("defaultType", permiso.value)
            }
            value={usuario?.defaultType}
          />
        </div>
      );
    }
  };

  const getAdjuntoValue = () => {
    if (usuario?.tipo_adjunto !== null) {
      return `${S3_ENDPOINT}/files/adjuntos/${usuario?.idAdjunto}.${usuario?.tipo_adjunto}`;
    }
  };

  return (
    <form onSubmit={onSubmit} className="container-fluid px-0">
      <div className="d-flex">
        <div className="col-8">
          <label className="mb-2 titleInputPlantilla">NOMBRE</label>
          <input
            type="text"
            value={usuario?.nombre}
            className="form-control mb-4"
            placeholder="Juan Pérez"
            onChange={(e) => setPropiedadUsuario("nombre", e.target.value)}
          />
        </div>
        <div className="col-4 ps-3">
          <label className="mb-2 ps-2 titleInputPlantilla">FOTO</label>

          <div className="d-flex flex-row align-items-center">
            <ImageInput
              hideLabel
              value={getAdjuntoValue()}
              modifier={(file) => setPropiedadUsuario("file", file)}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-8 px-0">
          <label className="mb-2 titleInputPlantilla">CORREO</label>
          <input
            type="email"
            value={usuario?.correo}
            className="form-control mb-3"
            placeholder="hola@ejemplo.com"
            onChange={(e) => setPropiedadUsuario("correo", e.target.value)}
          />
        </div>

        <div className="col-12 col-md-4 ps-md-4 mb-3 px-0">
          <label className="mb-2 titleInputPlantilla">PERMISO</label>
          {renderPermisos()}
        </div>
      </div>

      <div className="row">
        <div className="col-6 ">
          <button 
            className="btn w-100" 
            type="button" 
            onClick={handleCancel}
          >
            CANCELAR
          </button>
        </div>

        <div className="col-6 ">
          <button className="btn w-100 btn-primary">
            <i className="fa fa-save"></i> GUARDAR
          </button>
        </div>
      </div>
    
    </form>
  );
};

export default UsuarioForm;
