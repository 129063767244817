import React from "react";
import Switch from "react-switch";
import TriggerForm from "./TriggerForm";

const TriggersAlerta = ({
  alerta,
  objectType,
  deleteTrigger,
  setPropertyAlerta,
  setPropertyTrigger,
  createAlertTrigger,
}) => {
  const renderTriggers = () => {
    if (Array.isArray(alerta.triggers)) {
      return alerta.triggers.map((trigger, index) => (
        <TriggerForm
          key={index}
          index={index}
          trigger={trigger}
          objectType={objectType}
          deleteTrigger={deleteTrigger}
          setPropertyTrigger={setPropertyTrigger}
        />
      ));
    }
  };

  const renderAllTriggers = () => {
    if (Array.isArray(alerta.triggers)) {
      if (alerta.triggers.length > 1) {
        return (
          <label className="d-flex align-items-center my-2">
            <Switch
              checked={alerta.all_triggers}
              className="me-2"
              onChange={(checked) => setPropertyAlerta("all_triggers", checked)}
            />{" "}
            Enviar alerta solo cuando se cumplen todas las condiciones
          </label>
        );
      }
    }
  };

  return (
    <div>
      {renderTriggers()}{" "}
      <button
        type="button"
        onClick={createAlertTrigger}
        className="btn btn-sm btn-outline-primary my-2"
      >
        <i className="fa fa-plus me-1"></i> Agregar Condición
      </button>
      {renderAllTriggers()}
    </div>
  );
};

export default TriggersAlerta;
