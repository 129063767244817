import React, { useContext, useEffect } from "react";
import { UsuariosContext } from "../../context/UsuariosContext";
import SelectedUser from "../global/SelectedUser";
import UserPicker from "./UserPicker";

const MultiUserPicker = ({ idProyecto, selected, setSelected }) => {
  const { users, getUsuariosProyecto } = useContext(UsuariosContext);

  useEffect(() => {
    getUsuariosProyecto(idProyecto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleUser = (user) => {
    let current = [...selected];
    let index = current.findIndex(
      ({ idUsuario }) => idUsuario === user.idUsuario
    );
    if (index !== -1) {
      current.splice(index, 1);
    } else {
      current.push(user);
    }
    setSelected(current);
  };

  const renderUsers = () => {
    return selected.map((user) => (
      <SelectedUser user={user} toggleUser={toggleUser} />
    ));
  };
  return (
    <div className="px-1 pb-3">
      <UserPicker
        users={users}
        modifier={toggleUser}
        placeholder="Elegir usuarios..."
      />
      <div className="container-fluid" style={{ marginTop: -12 }}>
        {renderUsers()}
      </div>
    </div>
  );
};

export default MultiUserPicker;
