import React, { useContext, useEffect, useState } from "react";
import SearchableSelect from "../global/SearchableSelect";
import { ConceptosContext } from "../../context/ConceptosContext";
import useProyecto from "../../hooks/useProyecto";
import usePreciario from "../../hooks/usePreciario";

const EstimacionVersionNuevoGenerador = ({ handleCancel, handleSubmit }) => {
  const [selected, setSelected] = useState(null);

  const { idProyecto } = useProyecto();
  const { idContrato } = usePreciario();

  const { conceptos, getConceptosContrato } = useContext(ConceptosContext);

  useEffect(() => {
    getConceptosContrato(idProyecto, idContrato);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto, idContrato]);

  const handleSelected = () => {
    const selectedConcepto = conceptos.find(
      (concepto) => concepto.idConcepto === selected.value
    );
    handleSubmit(selectedConcepto);
  };

  return (
    <div>
      <h4 className="h6">Elige un concepto del Contrato</h4>
      <SearchableSelect
        itemText="nombre"
        value={selected?.value}
        itemValue="idConcepto"
        modifier={setSelected}
        items={conceptos}
      />
      <div className="row mt-4">
        <div className="col-6">
          <button onClick={handleCancel} className="btn w-100 text-muted">
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button
            onClick={handleSelected}
            className="btn btn-primary w-100"
            disabled={selected === null}
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default EstimacionVersionNuevoGenerador;
