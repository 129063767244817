import { mainColor } from "./colors";

export const getFolderIcon = (color) => {
  return `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="420" height="350" viewBox="0 0 420 350">
        <defs>
            <filter id="c" x="-15" y="23" width="450" height="332.703" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha"/><feGaussianBlur stdDeviation="5" result="d"/>
                <feFlood flood-opacity="0.4"/>
                <feComposite operator="in" in2="d"/>
                <feComposite in="SourceGraphic"/></filter>
            <clipPath id="f"><rect width="420" height="350"/>
            </clipPath>
        </defs>
        <g id="e" clip-path="url(#f)">
            <path d="M15,0H118a15,15,0,0,1,15,15V47a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V15A15,15,0,0,1,15,0Z" transform="translate(287)" 
            fill="${color ? color : mainColor}"/>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#c)">
                <rect width="420" height="302.703" rx="15" transform="translate(0 35)" fill="${
                  color ? color : mainColor
                }"/>
            </g>
        </g>
    </svg>
`;
};

export const getFolderIconSrc = (color) => {
  let folder = getFolderIcon(color);
  let blob = new Blob([folder], { type: "image/svg+xml" });
  let url = URL.createObjectURL(blob);
  return url;
};
