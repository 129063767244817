import React, { useState } from "react";
import Pagination from "./Pagination";

const Table = ({ data, query, renderColumns, renderItems }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  
  const currentData = data
  ? data.slice(indexOfFirstItem, indexOfLastItem)
  : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderData = () => {
    if (currentData.length > 0) {
      return (
        <tbody>
          {renderItems()}
        </tbody>
      )
    }

    let message = "Aún no has agregado usuarios.";
    if (query && query !== null) {
      message = "No hay usuarios que concidan con tu búsqueda.";
    }

    return (
      <tbody>
        <tr>
          <td className="ps-5">{message}</td>
        </tr>
      </tbody>
    );
  };

  return (
    <div className="card table-responsive pb-5 shadow position-relative border-0 mt-3">
      <table className="table">
        <thead className="table-usuarios">
          <tr className="">
            {renderColumns()}
          </tr>
        </thead> 

        {renderData()}

      </table>
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={data ? data.length : 0}
        currentPage={currentPage}
        onPageChange={paginate}
      />
    </div>
  );
};

export default Table;
