import {
  CREATE_ITEM,
  DELETE_ITEM,
  ITEMS_RECIBIDOS,
  SET_ITEM,
  SET_ITEMS_BY_REVISION,
  SET_PROPERTY_ITEM,
} from "../types/items";

const itemSchema = {
  idItem: "",
};

const ItemsReducer = (state, { type, payload }) => {
  switch (type) {
    case ITEMS_RECIBIDOS:
      return { ...state, items: payload };
    case SET_ITEM:
      return { ...state, item: payload };
    case SET_ITEMS_BY_REVISION:
      return { ...state, itemsByRevision: payload };
    case CREATE_ITEM:
      return { ...state, item: itemSchema };
    case SET_PROPERTY_ITEM: {
      const { key, value } = payload;
      const item = { ...state.item };
      item[key] = value;
      return { ...state, item };
    }
    case DELETE_ITEM: {
      let { items } = { ...state };
      items = [...items];
      let itemIndex = items.findIndex(
        (item) => String(item.idItem) === String(payload)
      );
      if (itemIndex !== -1) {
        items[itemIndex].idItem = `del-${payload}`;
      }
      return { ...state, items };
    }
    default:
      return { ...state };
  }
};
export default ItemsReducer;
