/**
 * Checklist
 */
export const SINGLE_CHECKLIST_RECIBIDO = "SINGLE_CHECKLIST_RECIBIDO";
export const SET_PROPERTY_CHECKLIST = "SET_PROPERTY_CHECKLIST";
export const CHECKLISTS_RECIBIDOS = "CHECKLISTS_RECIBIDOS";
export const SET_LAST_ITEM = "SET_LAST_ITEM";
export const SET_LAST_POST = "SET_LAST_POST";
export const SET_PROGRESS = "SET_PROGRESS";
export const SET_ESTADO = "SET_ESTADO";
export const EXPAND_ALL = "EXPAND_ALL";
export const SET_MODE = "SET_MODE";