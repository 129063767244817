import React, { useContext } from "react";
import { sortableHandle } from "react-sortable-hoc";
import AvancePickerMobile from "../cronograma/AvancePickerMobile";
import { ModalContext } from "../../context/ModalContext";
import { DestajosContext } from "../../context/DestajosContext";

const DragHandle = sortableHandle(() => (
  <span className="pr-1">
    <i className="fas fa-grip-lines"></i>
  </span>
));

const DestajoForm = ({ destajo }) => {
  const { confirm } = useContext(ModalContext);
  const { deleteDestajo, setPropertyDestajo } = useContext(DestajosContext);

  function handleDeleteDestajo() {
    confirm("¿Eliminar destajo " + destajo.nombre + "?", () =>
      deleteDestajo(destajo.idDestajo)
    );
  }

  return (
    <tr draggable>
      <td className="draggable text-end align-middle pr-3">
        <DragHandle />
      </td>
      <td className="text-end">
        <table className="w-100">
          <tbody>
            <tr>
              <td style={{ width: 100, minWidth: 200 }}>
                <AvancePickerMobile
                  intervalo={1}
                  progreso={destajo.ponderacion}
                  setCantidad={(cantidad) =>
                    setPropertyDestajo(
                      destajo.idDestajo,
                      "ponderacion",
                      cantidad
                    )
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={destajo.nombre}
                  className="form-control"
                  onChange={(e) =>
                    setPropertyDestajo(
                      destajo.idDestajo,
                      "nombre",
                      e.target.value
                    )
                  }
                />
              </td>
              <td style={{ width: 50, minWidth: 50 }}>
                <button
                  className="btn btn-outline-danger"
                  onClick={handleDeleteDestajo}
                >
                  <i className="fa fa-trash" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
};

export default DestajoForm;
