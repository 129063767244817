import React, { useState, useEffect, Fragment, useContext } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { EditModeContext } from "../../context/EditModeContext";
import { SubdestajosContext } from "../../context/SubdestajosContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import { ModalContext } from "../../context/ModalContext";
import SubdestajoTabla from "./SubdestajoTabla";
import SubdestajoForm from "./SubdestajoForm";
import Rubro from "../common/Rubro";
import { getValue } from "../../utils";
import { AppConfigContext } from "../../context/AppConfigContext";

const SubdestajosContainer = SortableContainer((props) =>
  props.renderSubdestajos()
);

const SubDestajoFila = SortableElement((props) => (
  <SubdestajoForm
    idProyecto={props.idProyecto}
    subdestajo={props.subdestajo}
    idDestajo={props.idDestajo}
    key={"subdestajo-" + props.subdestajo.idSubDestajo}
    setPropiedadSubDestajo={props.setPropiedadSubDestajo}
    deleteSubDestajo={props.deleteSubDestajo}
    confirm={props.confirm}
  />
));

const DestajoTabla = ({ destajo, getProyecto }) => {
  const [subdestajosDestajo, setSubdestajosDestajo] = useState(null);
  const [showSubDestajos, setShowSubDestajos] = useState(false);

  const {
    subdestajos,
    postSubDestajos,
    deleteSubDestajo,
    reordenarSubdestajos,
    postAvanceSubDestajo,
    createNuevoSubDestajo,
    setPropiedadSubDestajo,
  } = useContext(SubdestajosContext);

  const appconfig = useContext(AppConfigContext);

  const { editMode, editModeOn, viewLevel } = useContext(EditModeContext);
  const { proyecto } = useContext(ProyectosContext);
  const { confirm } = useContext(ModalContext);
  const { idProyecto, permiso } = proyecto;

  useEffect(() => {
    if (Array.isArray(subdestajos)) {
      handleSubdestajos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subdestajos]);

  function handleSubdestajos() {
    let current = subdestajos
      .filter((subdestajo) => subdestajo.idDestajo === destajo.idDestajo)
      .sort((a, b) => (a.orden > b.orden ? 1 : -1));
    setSubdestajosDestajo(current);
  }

  function toggleSubdestajos() {
    setShowSubDestajos(!showSubDestajos);
    if (!showSubDestajos) viewLevel("subdestajos");
    else viewLevel("destajos");
  }

  function handleCreate() {
    createNuevoSubDestajo(destajo.idDestajo, subdestajosDestajo.length);
  }

  function handlePostSubdestajos() {
    postSubDestajos(idProyecto, subdestajosDestajo, getProyecto);
  }

  function renderSubDestajos() {
    if (showSubDestajos) {
      return (
        <SubdestajosContainer
          renderSubdestajos={renderSubdestajos}
          showSubDestajos={showSubDestajos}
          toggleSubdestajos={toggleSubdestajos}
          onSortEnd={reordenarSubdestajos}
          pressDelay={100}
          useDragHandle
        />
      );
    }
  }

  function renderSubdestajos() {
    if (Array.isArray(subdestajosDestajo)) {
      let subdestajos = subdestajosDestajo.filter(
        (subdestajo) => String(subdestajo.idSubDestajo)[0] !== "d"
      );
      if (editMode === "des-" + destajo.idDestajo) {
        return subdestajos.map((subdestajo, index) => (
          <SubDestajoFila
            index={index}
            idProyecto={idProyecto}
            subdestajo={subdestajo}
            idDestajo={destajo.idDestajo}
            key={"subdestajo-" + subdestajo.idSubDestajo}
            setPropiedadSubDestajo={setPropiedadSubDestajo}
            deleteSubDestajo={deleteSubDestajo}
            confirm={confirm}
          />
        ));
      } else {
        return subdestajos.map((subdestajo) => (
          <SubdestajoTabla
            permiso={permiso}
            idProyecto={idProyecto}
            subdestajo={subdestajo}
            getProyecto={getProyecto}
            key={"subdestajo-" + subdestajo.idSubDestajo}
            postAvanceSubDestajo={postAvanceSubDestajo}
          />
        ));
      }
    }
    return [];
  }

  function renderButtons() {
    let components = [];
    if (showSubDestajos) {
      if (editMode === "des-" + destajo.idDestajo) {
        components = [
          <button
            className="btn btn-primary me-2"
            onClick={handlePostSubdestajos}
            key={"save-subdestajos-" + destajo.idDestajo + "-btn"}
          >
            Guardar
          </button>,
          <button
            key={"add-subdestajos-" + destajo.idDestajo + "-btn"}
            className="btn btn-outline-primary"
            onClick={handleCreate}
          >
            <i className="fa fa-plus"></i>
            Agregar Subdestajo
          </button>,
          <button
            className="btn btn-outline-danger ms-2"
            key={"cancelDestajo" + destajo.idDestajo}
            onClick={() =>
              confirm(
                "¿Está seguro que desea cancelar? No se guardarán los cambios hechos a todos los Subdestajos del Destajo " +
                  destajo.nombre +
                  ".",
                getProyecto
              )
            }
          >
            Cancelar
          </button>,
        ];
      } else if (!editMode && ["admin", "coordinador"].includes(permiso)) {
        components = [
          <button
            onClick={() => editModeOn(`des-${destajo.idDestajo}`)}
            className="btn btn-outline-primary"
            key={"edit-destajos-" + destajo.idDestajo + "-btn"}
          >
            <i className="fa fa-edit me-2"></i>
            Editar Subdestajos
          </button>,
        ];
      }
      return (
        <tr>
          <td />
          <td style={{ minWidth: 330 }} colSpan="4">
            <div className="ps-5">{components}</div>
          </td>
        </tr>
      );
    }
  }

  const renderProgress = () => {
    if (getValue(appconfig, "progress") === "money") {
      return parseInt(destajo.ejecutado * 100);
    }
    return parseInt(destajo.progreso * 100);
  };

  return (
    <Fragment>
      <tr className="border-top bg-primary-light">
        <td className="ponderacion">
          <span className="h5">{parseInt(destajo.ponderacion)}%</span>
        </td>
        <td className="rubro">
          <div className="row mx-0">
            <div className="col-2 px-0">
              <button
                className="btn btn-sm btn-light border"
                onClick={toggleSubdestajos}
              >
                <i
                  className={`fas fa-chevron-${
                    showSubDestajos ? "up" : "down"
                  } text-secondary toggle`}
                />
              </button>
            </div>
            <div className="col-10 px-0">
              <Rubro
                completado={destajo.completado}
                nombre={destajo.nombre}
                className="h5"
              />
            </div>
          </div>
        </td>
        <td className="text-center h5" style={{ width: 180, minWidth: 180 }}>
          {renderProgress()}
          {"%"}
        </td>
        <td>{getValue(destajo, "fecha_inicio", "date")}</td>
        <td>{getValue(destajo, "fecha_fin", "date")}</td>
        <td />
        <td />
        {/*
          <td>
            <button className="btn btn-sm btn-light border me-2">
              <i className="fa fa-bell"></i>
            </button>
            <button className="btn btn-sm btn-light border me-2">
              <i className="fa fa-user"></i>
            </button>
            <button className="btn btn-sm btn-light border">
              <i className="fa fa-comment"></i>
            </button>
          </td>
        */}
      </tr>
      {renderSubDestajos()}
      {renderButtons()}
    </Fragment>
  );
};

export default DestajoTabla;
