import React from "react";

const ColorBox = ({ color, block }) => {
  const renderContent = () => {
    return (
      <span className="text-start px-0 mb-0 py-0">
        <span className={color.className + " colorBox shadow-sm"}></span>
        <span className="small pr-2">{color.title}</span>
      </span>
    );
  };

  return (
    <span className={`${block ? "d-block" : "d-inline"} px-1 me-2`}>
      {renderContent()}
    </span>
  );
};

export default ColorBox;
