import React from "react";

const AlertDatePicker = ({ alerta, setPropertyAlerta }) => {
  return (
    <div className="row mx-0 mb-3">
      <div className="col-6 ">
        <label>Desde</label>
        <input
          type="date"
          value={alerta.min_date}
          className="form-control"
          onChange={(e) => setPropertyAlerta("min_date", e.target.value)}
        />
      </div>
      <div className="col-6 ">
        <label>Hasta</label>
        <input
          type="date"
          value={alerta.expiration}
          className="form-control"
          onChange={(e) => setPropertyAlerta("expiration", e.target.value)}
        />
      </div>
    </div>
  );
};

export default AlertDatePicker;
