import React, { useState, useEffect } from "react";

const AvancePickerMobile = ({ limite, progreso, intervalo, setCantidad }) => {
  const [inputProgreso, setInputProgreso] = useState("");

  useEffect(() => {
    setInputProgreso(progreso);
  }, [progreso]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (typeof setCantidad === "function") {
      setCantidad(inputProgreso);
    }
  };

  const handleClick = (cantidad) => {
    setCantidad(parseFloat(progreso) + parseFloat(cantidad));
  };

  const renderLeftButton = () => {
    if (inputProgreso === progreso || inputProgreso === "") {
      return (
        <div className="col-2 px-0">
          <button
            style={{ height: "33px" }}
            className="btn btn-light w-100"
            disabled={progreso === 0}
            onClick={() => handleClick(parseFloat(intervalo) * -1)}
          >
            -
          </button>
        </div>
      );
    }
  };

  const renderRightButton = () => {
    if (inputProgreso === progreso || inputProgreso === "") {
      return (
        <div className="col-2 px-0">
          <button
            style={{ height: "33px" }}
            className="btn btn-light w-100"
            disabled={progreso >= limite}
            onClick={() => handleClick(parseFloat(intervalo))}
          >
            +
          </button>
        </div>
      );
    }
    if (inputProgreso !== "") {
      return (
        <div className="col-3 px-0">
          <button className="btn w-100 btn-primary" onClick={handleSubmit}>
            <i className="fa fa-save"></i>
          </button>
        </div>
      );
    }
  };

  return (
    <div className="container-fluid px-0 avance-picker ps-2">
      <div className="row mx-0 align-items-center">
        {renderLeftButton()}
        <div className="col-5 px-0">
          <input
            type="number"
            placeholder={progreso}
            className="form-control text-center br-0 px-1"
            onChange={(e) => setInputProgreso(e.target.value)}
          />
        </div>
        {renderRightButton()}
      </div>
    </div>
  );
};

export default AvancePickerMobile;
