import React, { useContext } from "react";
import { PartidasContext } from "../../context/PartidasContext";
import ChartCard from "../analiticas/ChartCard";
import Chart from "react-apexcharts";

const GraficaCostos = () => {
  const { partidas } = useContext(PartidasContext);

  function renderGrafica() {
    if (Array.isArray(partidas)) {
      const ejecutado = partidas.map((partida) => ({
        x: partida.nombre,
        y: parseFloat(partida.ejecutado).toFixed(2),
        goals: [
          {
            name: "Autorizado",
            value: parseFloat(partida.autorizado).toFixed(2),
          },
        ],
      }));
      const autorizado = partidas.map((partida) => ({
        x: partida.nombre,
        y: parseFloat(partida.autorizado).toFixed(2),
        goals: [
          {
            name: "Autorizado",
            value: parseFloat(partida.autorizado).toFixed(2),
          },
        ],
      }));
      return (
        <Chart
          type="bar"
          options={{
            colors: ["#007991", "#CC1B7F"],
            dataLabels: {
              enabled: false,
              enabledOnSeries: 1,
            },
            legend: {
              show: true,
              showForSingleSeries: true,
              customLegendItems: ["Ejecutado", "Autorizado"],
            },
          }}
          series={[
            {
              name: "Ejecutado",
              data: ejecutado,
            },

            {
              name: "Autorizado",
              data: autorizado,
            },
          ]}
        />
      );
    }
  }

  return (
    <ChartCard>
      <h4 className="mb-3">Control de Costos</h4>
      {renderGrafica()}
    </ChartCard>
  );
};

export default GraficaCostos;
