import React, { useContext } from "react";
import FolderForm from "./FolderForm";
import { ModalContext } from "../../context/ModalContext";
import { FolderContext } from "../../context/FolderContext";
import FileUpload from "./FileUpload";
import { AdjuntosContext } from "../../context/AdjuntosContext";

const FolderButtons = ({
  filter,
  idProyecto,
  fetchFolder,
  hideFolderButton,
}) => {
  const { folder, createSubFolder } = useContext(FolderContext);
  const { setAdjuntos, uploadAdjuntos } = useContext(AdjuntosContext);
  const { show, clearModal, modalComponent } = useContext(ModalContext);

  const handleUploadFiles = (files) => {
    const renameTo = typeof filter === "function" ? "croquis" : null;
    uploadAdjuntos(idProyecto, folder.idFolder, files, fetchFolder, renameTo);
  };

  const uploadFilesModal = () => {
    modalComponent(
      "Subir Archivos",
      <FileUpload modifier={setAdjuntos} handleSubmit={handleUploadFiles} />
    );
  };

  const handleUpload = () => {
    let timeout = 0;
    if (show) {
      clearModal();
      timeout += 1000;
    }
    setTimeout(uploadFilesModal, timeout);
  };

  const handleCreateSubFolder = () => {
    createSubFolder();
    modalComponent(
      "Nueva Carpeta",
      <FolderForm
        idFolder="nuevo"
        idPadre={folder.idFolder}
        handleCallback={fetchFolder}
      />
    );
  };

  return (
    <div className="text-end">
      {!hideFolderButton && (
        <button
          onClick={handleCreateSubFolder}
          disabled={folder ? false : true}
          className="btn btn-primary me-2"
        >
          <i className="fa fa-plus"></i>
        </button>
      )}
      <button onClick={handleUpload} className="btn btn-primary">
        <i className="fa fa-upload"></i>
      </button>
    </div>
  );
};

export default FolderButtons;
