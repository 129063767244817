import {
  PROGRAMADO_DIA_RECEIVED,
  PROGRAMADO_MES_RECEIVED,
  PROGRAMADO_SEMANA_RECEIVED,
  REAL_DIA_RECEIVED,
  REAL_MES_RECEIVED,
  REAL_SEMANA_RECEIVED,
  RESET_PROGRESO_OBRA,
} from "../actions/types";
import {
  EDIT_PROYECTO,
  VIEW_OPTIONS,
  HIDE_OPTIONS,
  PROYECTOS_RECEIVED,
  CREATE_NUEVA_PROYECTO,
  SET_PROPERTY_PROYECTO,
  SINGLE_PROYECTO_RECEIVED,
} from "../types/proyectos";

const schema = {
  idProyecto: null,
  nombre: "",
  calle: "",
  idAdjunto: null,
};

export default (state, { type, payload }) => {
  switch (type) {
    case SINGLE_PROYECTO_RECEIVED:
      return { ...state, proyecto: payload };
    case PROYECTOS_RECEIVED:
      return { ...state, proyectos: payload, proyecto: null };
    case VIEW_OPTIONS:
      return { ...state, options: payload };
    case HIDE_OPTIONS:
      return { ...state, options: null };
    case CREATE_NUEVA_PROYECTO:
      return {
        ...state,
        proyecto: schema,
      };
    case EDIT_PROYECTO:
      return { ...state, proyecto: payload };
    case SET_PROPERTY_PROYECTO: {
      const { key, value } = payload;
      const proyecto = { ...state.proyecto };
      proyecto[key] = value;
      return { ...state, proyecto };
    }
    case RESET_PROGRESO_OBRA:
      return { ...state, progresoObra: null };
    case PROGRAMADO_DIA_RECEIVED:
      return {
        ...state,
        progresoObra: {
          ...state.progresoObra,
          programadoDia: payload,
        },
      };
    case REAL_DIA_RECEIVED:
      return {
        ...state,
        progresoObra: {
          ...state.progresoObra,
          realDia: payload,
        },
      };
    case PROGRAMADO_SEMANA_RECEIVED:
      return {
        ...state,
        progresoObra: {
          ...state.progresoObra,
          programadoSemana: payload,
        },
      };
    case REAL_SEMANA_RECEIVED:
      return {
        ...state,
        progresoObra: {
          ...state.progresoObra,
          realSemana: payload,
        },
      };
    case PROGRAMADO_MES_RECEIVED:
      return {
        ...state,
        progresoObra: {
          ...state.progresoObra,
          programadoMes: payload,
        },
      };
    case REAL_MES_RECEIVED:
      return {
        ...state,
        progresoObra: {
          ...state.progresoObra,
          realMes: payload,
        },
      };
    default:
      return { ...state };
  }
};
