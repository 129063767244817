import React, { useEffect, useState } from 'react'
import ProductsHandler from "../../../utils/products/ProductsHandler";

const ProductDetail = ({ product }) => {

    const [productDetail, setProductDetail] = useState('')

    useEffect(() => {
        setProductDetail(product)
    }, [product])


    const {
        handleEditProduct,
        handleDeleteProduct,
    } = ProductsHandler();

    const deleteFunction = () => {
        const goBack = true
        handleDeleteProduct(product.idProducto, goBack);
    }

    const editFunction = () => handleEditProduct(product);

    const productDetailImageStyles = {
        // backgroundImage: 'url(product.img)',
        backgroundImage: 'url("https://upload.wikimedia.org/wikipedia/commons/0/01/Brick.jpg")',
        height: '100%',
        width: '40%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: '10px'
    }

    return (
        <div className='card shadow d-flex flex-row p-3 h-100'>
            <div style={productDetailImageStyles} />
            <div className='ms-3' style={{ width: '60%' }}>
                <div className='mb-1'>
                    <h3>
                        {productDetail?.article}
                    </h3>
                    <p className='text-truncate small'>
                        Sint elit enim elit dolor veniam ea magna consequat esse.
                        Veniam occaecat nisi qui ipsum pariatur mollit. Proident eiusmod exercitation Lorem Lorem magna excepteur sint. Veniam laborum minim eu ex laboris officia aliquip amet ipsum culpa.
                    </p>
                </div>
                <div className='d-flex flex-column justify-content-between' >
                    <div>
                        <div className='d-flex justify-content-between'>
                            <b><p>Máximo</p> </b> <p>{productDetail?.maximum}</p>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <b><p>Mínimo</p> </b> <p>{productDetail?.minimum}</p>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <b><p>$PU</p> </b> <p>{productDetail?.unitPrice}</p>
                        </div>
                    </div>
                    <div>
                        <button className="btn text-primary ms-0 " onClick={editFunction}>
                            <i className="fa fa-edit"></i>
                        </button>
                        <button className="btn text-primary " onClick={deleteFunction}>
                            <i className="fa fa-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductDetail;