import { MONTOS_RECIBIDOS } from "../actions/types";

const MontosReducer = (state, { type, payload }) => {
  switch (type) {
    case MONTOS_RECIBIDOS:
      return { ...state, montos: payload };
    default:
      return { ...state };
  }
};
export default MontosReducer;
