import {
  EDIT_USUARIO,
  CLEAR_USUARIO,
  USERS_RECEIVED,
  SET_CORREO_USER,
  CREATE_NEW_USER,
  SET_PERMISO_USER,
  RESET_SEARCH_USERS,
  SET_PROPERTY_USUARIO,
} from "../types/usuarios";

const INITIAL_STATE = {
  users: [],
  usuario: null,
};

export default (state = INITIAL_STATE, action) => {
  let users = [];
  let user = {};
  switch (action.type) {
    case USERS_RECEIVED:
      return { ...state, users: action.payload };
    case CREATE_NEW_USER:
      return { ...state, users: [...state.users, action.payload] };
    case SET_CORREO_USER:
      users = [...state.users];
      user = users.find((user) => user.uid === action.payload.uid);
      if (user) user.correo = action.payload.correo;
      return { ...state, users };
    case SET_PERMISO_USER:
      users = [...state.users];
      user = users.find((user) => user.uid === action.payload.uid);
      if (user) user.permiso = action.payload.permiso;
      return { ...state, users };
    case RESET_SEARCH_USERS:
      return { ...state, query: "", searchResult: undefined };
    case EDIT_USUARIO:
      return { ...state, usuario: action.payload };
    case SET_PROPERTY_USUARIO:
      const { key, value } = action.payload;
      const usuario = { ...state.usuario };
      usuario[key] = value;
      return { ...state, usuario };
    case CLEAR_USUARIO:
      return { ...state, usuario: null };
    default:
      return { ...state };
  }
};
