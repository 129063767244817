import React from 'react';

const ProductInventoryLevel = () => {

    const progress = '55%'

    return (
        <div className="card shadow p-3 mb-3" style={{ width: "100%", display: 'flex', alignItems: 'center', flexDirection: 'row', position: 'relative' }}>
            <div style={{ width: '90%', backgroundColor: '#c2c2c2', color: '#c2c2c2', borderRadius: '10px', marginRight: '10px' }}>
                {"-"}
                <div style={{ width: progress, backgroundColor: '#b7f524', color: '#b7f524', borderRadius: '10px', position: 'absolute', top: '16px', left: '15px' }}>
                    {"-"}
                </div>
            </div>
            <div style={{ width: '10%' }}>
                <p className='p-0 m-0' style={{ minWidth: '38px' }}>
                    {progress}
                </p>
            </div>
        </div>
    );
}

export default ProductInventoryLevel;
