import React, { useContext, useEffect } from "react";
import { PartidasContext } from "../../context/PartidasContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import SearchableSelect from "../common/SearchableSelect";

const SelectPartida = ({ selected, modifier }) => {
  const { partidas, getPartidasProyecto } = useContext(PartidasContext);
  const { proyecto } = useContext(ProyectosContext);
  const { idProyecto } = proyecto;

  useEffect(() => {
    if (!Array.isArray(partidas)) {
      getPartidasProyecto(idProyecto);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto]);

  return (
    <div className="mb-3">
      <label>Selecciona una Partida</label>
      <SearchableSelect
        items={partidas}
        labelKey="nombre"
        selected={selected}
        selectItem={modifier}
      />
    </div>
  );
};

export default SelectPartida;
