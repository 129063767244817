import api from "./api";

const route = "/pagos";

export default {
  getPagosContrato: (idProyecto, idContrato) =>
    api.get(`${route}/${idProyecto}/${idContrato}`),
  getPagosProyecto: (idProyecto) => api.get(`${route}/${idProyecto}`),
  postPagoContrato: (idProyecto, idContrato, pago) =>
    api.post(`${route}/${idProyecto}/${idContrato}`, { ...pago }),
  putPagoContrato: (idProyecto, idContrato, pago) =>
    api.put(`${route}/${idProyecto}/${idContrato}/pago/${pago.idPago}`, { ...pago }),
  postPagoCancelado: (idProyecto, idContrato, idPago, fecha_cancelacion, comentario) =>
    api.post(`${route}/${idProyecto}/${idContrato}/cancelar/${idPago}`, { fecha_cancelacion, comentario }),
};
