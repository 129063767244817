import React from "react";

const OrdenCard = ({ orden, permiso, handleApprove }) => {
  const renderButton = () => {
    if (permiso === "coordinador" && orden.status === "pendiente") {
      return (
        <button
          className="btn btn-primary"
          onClick={(e) => {
            e.stopPropagation();
            handleApprove(orden.idOrden);
          }}
        >
          Aprobar orden de cambio
        </button>
      );
    }
  };

  return (
    <div className="card shadow-sm mb-3">
      <div className="card-body">
        <h3>{orden.title}</h3>
        <p>{orden.description}</p>
        <p>{orden.responsable}</p>
        <p>Status: {orden.status}</p>
        {renderButton()}
      </div>
    </div>
  );
};
export default OrdenCard;
