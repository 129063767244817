import React from "react";
import Switch from "react-switch";
import DateTimePicker from "../common/DateTimePicker";

const AlertDateForm = ({ alerta, repeat, setRepeat, setPropertyAlerta }) => {
  const renderRepeat = () => {
    if (repeat) {
      return (
        <div className="row">
          <div className="col-4">
            <label>Cada</label>
            <input type="number" className="form-control" />
          </div>
          <div className="col-4">
            <label>Periodo</label>
            <select className="form-control">
              <option value="minutes">Minutos</option>
              <option value="hour">Horas</option>
              <option value="day">Días</option>
              <option value="week">Semanas</option>
              <option value="month">Meses</option>
            </select>
          </div>
          <div className="col-4">
            <label># Veces</label>
            <input type="number" className="form-control" />
          </div>
        </div>
      );
    }
  };
  return (
    <div className="mb-3">
      <DateTimePicker
        value={alerta.fecha_hora}
        modifier={(date) => setPropertyAlerta("fecha_hora", date)}
      />
      <label className="d-flex align-items-center mb-3">
        <Switch checked={repeat} onChange={setRepeat} />
        <span className="ms-2">Repetir Alerta</span>
      </label>
      {renderRepeat()}
    </div>
  );
};
export default AlertDateForm;
