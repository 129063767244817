import React, { useContext, useEffect, useState } from "react";
import { ProyectosContext } from "../../context/ProyectosContext";
import { AdjuntosContext } from "../../context/AdjuntosContext";
import { Document, Page, pdfjs } from "react-pdf";
import { FILES_ENDPOINT, hideAdjuntoView, showAdjuntoView } from "../../utils";
import moment from "moment";
import "./adjuntoView.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AdjuntoView = () => {
  const [src, setSrc] = useState("");
  const [scoped, setScoped] = useState(false);

  const { proyecto } = useContext(ProyectosContext);

  const { adjunto, clearSingleAdjunto, downloadAdjunto, getAdjuntoProyecto } =
    useContext(AdjuntosContext);

  useEffect(() => {
    if (adjunto !== null && proyecto !== null) {
      setSrc("");
      showAdjuntoView();
      getAdjuntoProyecto(
        proyecto.idProyecto,
        adjunto.idAdjunto,
        handleCallback
      );
    } else if (adjunto === null) {
      hideAdjuntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjunto?.idAdjunto]);

  const handleCallback = (response) => {
    if (response !== null) {
      return setSrc(response);
    }
    setScoped(true);
    setSrc(`${FILES_ENDPOINT}/${adjunto.idAdjunto}.${adjunto.tipo}`);
  };

  const handleDownload = () => {
    if (!scoped) {
      return downloadAdjunto(proyecto.idProyecto, adjunto);
    }
    const link = document.createElement("a");
    link.href = src;
    link.download = true;
    document.body.append(link);
    link.click();
  };

  const renderView = () => {
    if (adjunto !== null) {
      let tipo = adjunto.tipo;
      if (tipo) tipo = String(tipo).toLowerCase();
      if (tipo === "pdf") {
        return (
          <Document file={src}>
            <Page
              pageNumber={1}
              renderAnnotationLayer={false}
              className="m-auto"
            />
          </Document>
        );
      }
      if (!["gif", "jpg", "png", "jpeg", "webp"].includes(tipo)) {
        return (
          <p className="my-3">
            Este tipo de archivo no se puede previsualizar. Por favor, descarga
            el archivo.
          </p>
        );
      }
      if (src === "") {
        return (
          <div className="align-items-center mb-3">
            <div className="spinner-border"></div>
            <span className="ml-3">Cargando vista previa...</span>
          </div>
        );
      }
      return (
        <img
          src={src}
          alt={adjunto.nombre}
          className="d-block m-auto mw-100 mt-4"
        />
      );
    }
  };

  const renderUserName = () => {
    if (adjunto !== null) {
      if (adjunto.usuario) {
        if (adjunto.usuario.nombre !== null) {
          return adjunto.usuario.nombre;
        }
        return adjunto.usuario.correo;
      }
    }
  };

  const renderDate = () => {
    return moment(adjunto.createdAt).utc().format("DD MMM YYYY HH:mm");
  };

  const renderContent = () => {
    if (adjunto && adjunto !== null) {
      return (
        <div className="modal-body">
          {renderView()}
          <div className="row align-items-center">
            <div className="col-12 col-lg-12 my-2">
              <div className="d-flex justify-content-between align-items-center">
                <div className="w-75 overflow-hidden">
                  <h5 className="bold mb-0">
                    {adjunto !== null ? adjunto.nombre : "Adjunto"}
                  </h5>
                  <p className="mb-0">
                    {renderDate()} - {renderUserName()}
                  </p>
                </div>
                <button onClick={handleDownload} className="btn btn-primary">
                  <i className="fa fa-download"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-link text-muted"
              onClick={clearSingleAdjunto}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                fontSize: "1rem",
              }}
            >
              <i className="fa fa-times" style={{ color: "#db8c63" }} />
            </button>
          </div>
          <div className="mt-3" style={{ maxWidth: "90%" }}></div>
        </div>
      );
    }
  };

  return (
    <div
      role="dialog"
      tabIndex="-1"
      id="adjunto-view"
      aria-labelledby="modal"
      aria-hidden={adjunto === null ? "true" : "false"}
      className="modal modal-lg overflow-hidden fade"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content rounded">
          {/* Añadir esta línea */}
          {renderContent()}
        </div>
        {/* Añadir esta línea */}
      </div>
    </div>
  );
};

export default AdjuntoView;
