import api from "./api";

const route = "/items";

const ItemsService = {
  getItemsOnRevision: (idRevision, idProyecto) => 
    api.get(`${route}/revision/${idRevision}/${idProyecto}`),
  getItemsChecklist: (idProyecto, idChecklist) =>
    api.get(`${route}/${idProyecto}/checklist/${idChecklist}`),
  putChecklistItems: (idProyecto, items) =>
    api.put(`${route}/${idProyecto}/items`, { items }),
  postItemChecklist: (idProyecto, item) =>
    api.post(`${route}/${idProyecto}`, { ...item }),
  completeItem: (idProyecto, idItem) =>
    api.put(`${route}/${idProyecto}/${idItem}/complete`),
  putItem: (idProyecto, item) =>
    api.put(`${route}/${idProyecto}/${item.idItem}`, { ...item }),
  deleteItem: (idProyecto, idItem) =>
    api.delete(`${route}/${idProyecto}/item/${idItem}`),
};
export default ItemsService;
