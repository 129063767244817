import api from "./api";

const route = "/estimaciones";

export default {
  getEstimaciones: (idProyecto, idContrato) =>
    api.get(`${route}/${idProyecto}/${idContrato}`),
  getEstimacion: (idProyecto, idContrato, idEstimacion) =>
    api.get(`${route}/${idProyecto}/${idContrato}/estimacion/${idEstimacion}`),
  postEstimacion: (idProyecto, idContrato, estimacion) =>
    api.post(`${route}/${idProyecto}/${idContrato}`, { ...estimacion }),
  putEstimacion: (idProyecto, idContrato, estimacion) =>
    api.put(
      `${route}/${idProyecto}/${idContrato}/estimacion/${estimacion.idEstimacion}`,
      {
        ...estimacion,
      }
    ),
  deleteEstimacion: (idProyecto, idContrato, idEstimacion) =>
    api.delete(
      `${route}/${idProyecto}/${idContrato}/estimacion/${idEstimacion}`
    ),
};
