import React, { useContext, useEffect } from "react";
import PagoForm from "./PagoForm";
import TablaPagos from "./TablaPagos";
import CancelPagoForm from "./CancelPagoForm";
import { PagosContext } from "../../context/PagosContext";
import { ModalContext } from "../../context/ModalContext";
import { CapacitorContext } from "../../context/CapacitorContext";
import ListaPagos from "../pagos/ListaPagos";
import useProyecto from "../../hooks/useProyecto";

const Pagos = ({ idProyecto, idContrato }) => {
  const { pagos, editPago, clearPago, getPagosContrato, createPagoContrato } =
    useContext(PagosContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { platform } = useContext(CapacitorContext);
  const { permiso } = useProyecto();

  useEffect(() => {
    fetchPagos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idContrato]);

  function fetchPagos() {
    getPagosContrato(idProyecto, idContrato);
  }

  function handleCallback() {
    clearModal();
    fetchPagos();
  }

  function handleCancel() {
    clearModal();
    clearPago();
  }

  function handleCreatePago() {
    createPagoContrato();
    modalComponent(
      "Agregar Pago",
      <PagoForm
        idProyecto={idProyecto}
        idContrato={idContrato}
        handleCancel={handleCancel}
        handleCallback={handleCallback}
      />
    );
  }

  function handleEditPago(pago) {
    editPago(pago);
    const title = isNaN(parseInt(pago.idPago)) ? "Agregar" : "Editar";
    modalComponent(
      `${title} Pago`,
      <PagoForm
        idProyecto={idProyecto}
        idContrato={idContrato}
        handleCancel={handleCancel}
        handleCallback={handleCallback}
      />
    );
  }

  function handleCancelPago(pago) {
    editPago(pago);
    modalComponent(
      "Cancelar Pago",
      <CancelPagoForm
        idProyecto={idProyecto}
        idContrato={idContrato}
        handleCancel={handleCancel}
        handleCallback={fetchPagos}
      />
    );
  }

  function renderBoton(permiso) {
    if (permiso && permiso !== null) {
      if (["admin", "finanzas"].includes(permiso)) {
        return (
          <button className="btn btn-primary" onClick={handleCreatePago}>
            <i className="fa fa-plus"></i>
          </button>
        );
      }
    }
  }

  function renderPagos() {
    if (platform === "web") {
      return (
        <div className="card shadow p-3">
          <TablaPagos
            pagos={pagos}
            permiso={permiso}
            idProyecto={idProyecto}
            idContrato={idContrato}
            editPago={handleEditPago}
            cancelPago={handleCancelPago}
          />
        </div>
      );
    }
    return <ListaPagos pagos={pagos} handleEdit={handleEditPago} />;
  }

  return (
    <div className="container-fluid px-0 pb-3">
      <div className="row align-items-center mb-2 mt-4">
        <div className="col">
          <h3 className="bold mb-0">Pagos</h3>
        </div>
        <div className="col text-end">{renderBoton(permiso)}</div>
      </div>
      {renderPagos()}
    </div>
  );
};

export default Pagos;
