import React, { useContext } from "react";
import { ProveedoresContext } from "../../context/ProveedoresContext";
import ProveedorForm from "../../components/proveedores/ProveedorForm";
import { ModalContext } from "../../context/ModalContext";
import { useNavigate } from "react-router-dom";

const ProveedoresHandler = () => {
  const { setProveedor, deleteProveedor } = useContext(ProveedoresContext);
  const { modalComponent, clearModal } = useContext(ModalContext);
  const navigate = useNavigate();

  const handleCancel = () => {
    clearModal();
  };

  const handleCreateProveedor = () => {
    modalComponent(
      "Añadir Proveedor:",
      <ProveedorForm handleCancel={handleCancel} title={"Agregar Proveedor"} />
    );
  };

  const handleEditProveedor = (proveedor) => {
    setProveedor(proveedor);
    modalComponent(
      `Editar Proveedor:`,
      <ProveedorForm
        title={"Editar Proveedor"}
        handleCancel={handleCancel}
        idProveedor={proveedor.idProveedor}
      />
    );
  };

  const handleDeleteProveedor = (idProveedor, goBack) => {
    const onDelete = () => {
      deleteProveedor(idProveedor);
      if (goBack) {
        navigate(-1);
      }
    };

    modalComponent(
      "¿Eliminar Proveedor?",
      <div className="w-100 d-flex mt-3">
        <button className="btn me-1 w-50" onClick={clearModal}>
          Cancelar
        </button>
        <button className="btn btn-danger ms-1 w-50" onClick={onDelete}>
          Eliminar Proveedor
        </button>
      </div>
    );
  };

  return {
    handleCreateProveedor,
    handleEditProveedor,
    handleDeleteProveedor,
  };
};

export default ProveedoresHandler;
