import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const ConceptoEstimadoChart = ({ total, estimaciones }) => {
  const [currentData, setCurrentData] = useState([{ data: [] }]);



  useEffect(() => {
    if(estimaciones && total) {
      const estimados = estimaciones?.map(({ updatedAt, estimado }) => ({
        x: updatedAt,
        y: (parseFloat(estimado) / parseFloat(total)) * 100,
      }));

      setCurrentData([
        {
          name: "Estimado",
          data: estimados,
        }
      ])
    }
  }, [estimaciones, total]);


  return (
    <Chart
      type="area"
      options={{
        stroke: {
          curve: "smooth",
        },
        colors: ["#007991", "#89D405"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        yaxis: {
          min: 0,
          max: 100,
          labels: {
            formatter: (val) => `${parseFloat(val).toFixed(2)}%`,
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            show: true,
            hideOverlappingLabels: true,
            showDuplicates: false,
            offset: -1,
          },
        },
      }}
      series={currentData}
    />
  );
};

export default ConceptoEstimadoChart;
