import React, { useState, useContext, useEffect } from "react";
import Folder from "./Folder";
import { useParams } from "react-router-dom";
import { unidades } from "../utils/conceptos";
import useProyecto from "../hooks/useProyecto";
import { ModalContext } from "../context/ModalContext";
import { ConceptosContext } from "../context/ConceptosContext";
import { EstimacionesContext } from "../context/EstimacionesContext";
import BitacoraConcepto from "../components/conceptos/BitacoraConceptos";
import ConceptoGenerador from "../components/conceptos/ConceptoGenerador";
import ConceptoMedicionForm from "../components/conceptos/ConceptoMedicionForm";
import { EstimacionVersionesContext } from "../context/EstimacionVersionesContext";
import { ConceptosUbicacionesContext } from "../context/ConceptosUbicacionesContext";
import EstimacionVersionHeader from "../components/estimacionesversiones/EstimacionVersionHeader";
import PillTabs from "../components/common/PillTabs";
import { formatMonto } from "../utils";
import { CapacitorContext } from "../context/CapacitorContext";
import ConceptoMedicionFormMobile from "../components/conceptos/ConceptoMedicionFormMobile";

const SingleConceptoGenerador = ({ idConcepto }) => {
  const [edited, setEdited] = useState(null);
  const [fileView, setFileView] = useState("reporte");
  const [currentFolder, setCurrentFolder] = useState(null);
  const { platform } = useContext(CapacitorContext);
  const { concepto, getSingleConcepto, getConceptosContrato } =
    useContext(ConceptosContext);
  const { selectEstimacion } = useContext(EstimacionesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { estimacionversion } = useContext(EstimacionVersionesContext);
  const {
    conceptosubicaciones,
    createConceptoUbicacion,
    getConceptosUbicacionConcepto,
  } = useContext(ConceptosUbicacionesContext);

  const { idProyecto, permiso } = useProyecto();
  const { idContrato, idEstimacion, idEstimacionVersion } = useParams();

  useEffect(() => {
    if (Array.isArray(conceptosubicaciones)) {
      const newConcepto = conceptosubicaciones.find(({ idConceptoUbicacion }) =>
        isNaN(parseInt(idConceptoUbicacion))
      );
      if (newConcepto) setEdited(newConcepto.idConceptoUbicacion);
    }
    if (Array.isArray(conceptosubicaciones) && estimacionversion !== null) {
      handleFolder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conceptosubicaciones, estimacionversion]);

  /*
  useEffect(() => {
    getConceptosContrato(idProyecto, idContrato);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idContrato]);
  */

  useEffect(() => {
    fetchConcepto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idConcepto]);

  useEffect(() => {
    selectEstimacion(idProyecto, idContrato, idEstimacion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idEstimacion]);

  useEffect(() => {
    if (edited !== null && platform !== "web") {
      const conceptoGenerador = conceptosubicaciones.find(
        (concepto) => concepto.idConceptoUbicacion === edited
      );
      modalComponent(
        "Editar Medicion",
        <ConceptoMedicionFormMobile
          idProyecto={idProyecto}
          idContrato={idContrato}
          handleCancel={handleCancelEdit}
          handleCallback={handleSaveCallback}
          idEstimacionVersion={idEstimacionVersion}
          key={conceptoGenerador.idConceptoUbicacion}
          concepto={{ ...conceptoGenerador, ...concepto }}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited, platform]);

  const fetchConcepto = () => {
    getSingleConcepto(idProyecto, idConcepto);
    getConceptosUbicacionConcepto(idProyecto, idEstimacionVersion, idConcepto);
  };

  const handleSaveCallback = () => {
    setEdited(false);
    fetchConcepto();
  };

  const handleFolder = () => {
    const conceptoFolder = estimacionversion.folders.find(
      (folder) => folder.idConcepto === idConcepto
    );
    if (conceptoFolder) {
      setCurrentFolder(conceptoFolder);
    }
  };

  const handleComments = (concepto) => {
    modalComponent(
      "Comentarios",
      <BitacoraConcepto
        idFolder={currentFolder.idFolder}
        idConceptoUbicacion={concepto.idConceptoUbicacion}
      />
    );
  };

  const handleCancelEdit = () => {
    getConceptosUbicacionConcepto(idProyecto, idEstimacionVersion, idConcepto);
    setEdited(null);
    clearModal();
  };

  const renderFolder = () => {
    if (currentFolder !== null) {
      const filter =
        fileView === "croquis"
          ? (adjunto) => adjunto.nombre.includes("CROQUIS")
          : (adjunto) => !adjunto.nombre.includes("CROQUIS");
      const estado =
        estimacionversion && estimacionversion !== null
          ? estimacionversion.estado
          : "";
      return (
        <Folder
          size="sm"
          idFolder={currentFolder.idFolder}
          hideFolderButton
          hideActions={
            (permiso === "contratista" && estado !== "creada") ||
            (permiso === "supervisor" && estado !== "enviada") ||
            (permiso === "obras" && estado !== "supervisada")
          }
          filter={filter}
        />
      );
    }
  };

  const renderTotal = () => {
    if (Array.isArray(conceptosubicaciones) && concepto !== null) {
      const keys = unidades[concepto.unidad]
        ? unidades[concepto.unidad]
        : ["lote"];
      let total = 0;
      conceptosubicaciones.forEach((concepto) => {
        let currentTotal = 1.0;
        keys.forEach((key) => {
          currentTotal *= parseFloat(concepto[key]);
        });
        total += currentTotal;
      });

      return (
        <tr className="border">
          <td colSpan={keys.length + 4} className="border bold text-end">
            Total
          </td>
          <td className="border bold">
            {formatMonto(isNaN(total) ? 0 : total)}
          </td>
          <td className="border bg-light" />
        </tr>
      );
    }
  };

  const renderGeneradores = () => {
    if (Array.isArray(conceptosubicaciones)) {
      return conceptosubicaciones.map((conceptoGenerador) => {
        if (
          edited === conceptoGenerador.idConceptoUbicacion &&
          platform === "web"
        ) {
          return (
            <ConceptoMedicionForm
              idProyecto={idProyecto}
              idContrato={idContrato}
              handleCancel={handleCancelEdit}
              handleCallback={handleSaveCallback}
              idEstimacionVersion={idEstimacionVersion}
              key={conceptoGenerador.idConceptoUbicacion}
              concepto={{ ...conceptoGenerador, ...concepto }}
            />
          );
        }
        return (
          <ConceptoGenerador
            idProyecto={idProyecto}
            idContrato={idContrato}
            handleEdit={setEdited}
            disabled={edited !== null}
            handleComments={handleComments}
            key={conceptoGenerador.idConceptoUbicacion}
            concepto={{ ...conceptoGenerador, ...concepto }}
          />
        );
      });
    }
  };

  const getHeaders = () => {
    if (concepto && concepto !== null) {
      if (unidades[concepto.unidad]) {
        return unidades[concepto.unidad].map((key) => (
          <th key={key} className="text-capitalize border">
            {key}
          </th>
        ));
      }
      return <th>Lote</th>;
    }
  };

  const renderActions = () => {
    if (estimacionversion.estado === "creada" && permiso === "contratista") {
      return (
        <div className="row">
          <div className="container-fluid px-md-0 text-end my-3">
            <button
              disabled={edited !== null}
              className="btn btn-outline-primary me-2"
            >
              <i className="fa fa-upload me-1" />
              Subir Excel
            </button>
            <button
              disabled={edited !== null}
              onClick={createConceptoUbicacion}
              className="btn btn-outline-primary"
            >
              <i className="fa fa-plus me-1" /> Medicion
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="container-fluid px-0">
      <div className="card shadow my-3 py-3 px-md-4 px-2">
        <EstimacionVersionHeader
          view="generador"
          title="HOJA GENERADORA DE VOLUMEN DE OBRA"
        />
        {renderActions()}
        <div className="row">
          <div className="col-12 col-xl-6 px-md-0">
            <div className="border bg-light">
              <h3 className="h6 bold w-100 mt-2 text-center">
                REPORTE FOTOGRAFICO
              </h3>
              <div className="container-fluid py-2 px-4">
                <PillTabs
                  value={fileView}
                  modifier={setFileView}
                  tabs={[
                    {
                      label: "ARCHIVOS / FOTOS",
                      value: "reporte",
                    },
                    {
                      label: "CROQUIS",
                      value: "croquis",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="container-fluid border p-2">{renderFolder()}</div>
          </div>
          <div className="col-12 col-xl-6 px-md-0">
            <div className="table-responsive">
              <table className="table">
                <thead style={{ height: 85 }}>
                  <tr className="bg-light border">
                    <th colSpan={4} className="text-center border">
                      UBICACIÓN
                    </th>
                    <th colSpan={5} className="text-center border">
                      MEDICIONES
                    </th>
                  </tr>
                  <tr className="bg-light border position-relative">
                    {platform !== "web" && <th className="border">Menú</th>}
                    <th className="border">Eje</th>
                    <th className="border">Tramo</th>
                    <th className="border">Tipo</th>
                    <th className="border">Nivel</th>
                    {getHeaders()}
                    <th className="border">Total</th>
                    {platform === "web" && <th className="border">Acciones</th>}
                  </tr>
                </thead>
                <tbody>
                  {renderGeneradores()}
                  {renderTotal()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleConceptoGenerador;
