import React from "react";

const SelectSheet = ({ sheetName, resetSheet, sheetNames, setSheetName }) => {
  const renderSelectSheet = () => {
    if (sheetName !== "") {
      return (
        <div className="card p-3 shadow my-3">
          <div className="container-fluid px-0">
            <h5 className="bold">Hoja Elegida</h5>
            <p className="mb-1">{sheetName}</p>
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={resetSheet}
            >
              <i className="fa fa-edit"></i> Cambiar Hoja
            </button>
          </div>
        </div>
      );
    }
    if (Array.isArray(sheetNames)) {
      return (
        <div className="card p-3 shadow my-3">
          <label>Hoja del Archivo</label>

          <select
            className="form-control"
            onChange={(event) => setSheetName(event.target.value)}
          >
            <option>Selecciona una hoja</option>
            {
              sheetNames.map(name => {
                return (
                  <option key={name}>{name}</option>
                )
              })
            }
          </select>
        </div>
      );
    }
  };
  return <div>{renderSelectSheet()}</div>;
};

export default SelectSheet;
