import React, { createContext, useContext, useReducer } from "react";
import PartidasReducer from "../reducers/PartidasReducer";
import PartidasService from "../services/PartidasService";
import {
  SET_PARTIDA,
  DELETE_PARTIDA,
  PARTIDAS_RECEIVED,
  REORDENAR_PARTIDAS,
  CREATE_NUEVA_PARTIDA,
  SET_PROPERTY_PARTIDA,
  SET_PROPERTY_SINGLE_PARTIDA,
} from "../types/partidas";
import { ModalContext } from "./ModalContext";
import { validarFechasPartidas, validarPorcentajeTotal } from "../utils";

const schema = (numPartidas, idProyecto) => ({
  idProyecto: parseInt(idProyecto),
  idPartida: "nueva-" + numPartidas,
  nombre: "Nueva Partida",
  ponderacion: 0,
  punto_critico: false,
  fecha_inicio: "",
  fecha_fin: "",
  programado: 0,
  real: 0,
  orden: numPartidas + 1,
});

const initialState = {
  partidas: null,
  partida: null,
};

export const PartidasContext = createContext(initialState);

export const PartidasProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PartidasReducer, initialState);

  const { success, alert } = useContext(ModalContext);

  const getPartidasProyecto = (idProyecto) => {
    PartidasService.getPartidas(idProyecto).then((res) => {
      const { partidas } = res.data;
      dispatch({ type: PARTIDAS_RECEIVED, payload: partidas });
    });
  };

  const getSinglePartida = (idProyecto, idPartida) => {
    PartidasService.getSinglePartida(idProyecto, idPartida).then((res) => {
      const { partida } = res.data;
      dispatch({ type: SET_PARTIDA, payload: partida });
    });
  };

  const createPartida = () => {
    dispatch({ type: CREATE_NUEVA_PARTIDA });
  };

  const clearPartidas = () => {
    dispatch({ type: PARTIDAS_RECEIVED, payload: null });
  };

  const setPropertySinglePartida = (key, value) => {
    dispatch({
      type: SET_PROPERTY_SINGLE_PARTIDA,
      payload: { key, value },
    });
  };

  const setPropertyPartida = (idPartida, key, value) => {
    dispatch({
      type: SET_PROPERTY_PARTIDA,
      payload: { idPartida, key, value },
    });
  };

  const reorderPartidas = (partidas) => {
    for (let i = 0; i < partidas.length; i++) partidas[i].orden = i;
    dispatch({ type: PARTIDAS_RECEIVED, payload: partidas });
  };

  const reordenarPartidas = ({ oldIndex, newIndex }) => {
    dispatch({ type: REORDENAR_PARTIDAS, payload: { oldIndex, newIndex } });
  };

  const postPartidasProyecto = (idProyecto, partidas, callback) => {
    const partidasDelete = partidas.filter(
      (partida) => String(partida.idPartida)[0] === "d"
    );
    if (!validarFechasPartidas(partidas)) {
      return alert("Verifica que las fechas de las partidas sean correctas.");
    }
    if (
      partidasDelete.length < partidas.length &&
      !validarPorcentajeTotal(partidas, "idPartida")
    ) {
      return alert(
        "La suma de las ponderaciones debe ser 100. Verfífica las ponderaciones."
      );
    }
    PartidasService.postPartidas(idProyecto, partidas)
      .then(() => {
        success("Partidas guardadas.");
        getPartidasProyecto(idProyecto);
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch(alert);
  };

  const deletePartida = (idPartida) => {
    dispatch({ type: DELETE_PARTIDA, payload: idPartida });
  };

  const setPartida = (partida) => {
    dispatch({ type: SET_PARTIDA, payload: partida });
  };

  const clearSinglePartida = () => {
    dispatch({ type: SET_PARTIDA, payload: null });
  };

  const postSinglePartida = (idProyecto, partida, callback) => {
    let service = PartidasService.putSinglePartida;
    if (isNaN(partida.idPartida)) {
      service = PartidasService.postSinglePartida;
    }
    let data = { ...partida };
    if (typeof data.responsable === "object" && data.responsable !== null) {
      data.responsable = data.responsable.idUsuario;
    }
    service(idProyecto, partida).then(() => {
      success("Partida guardada.");
      if (typeof callback === "function") {
        callback();
      }
    });
  };

  const createNuevaPartida = (numPartidas, idProyecto) => {
    dispatch({
      type: CREATE_NUEVA_PARTIDA,
      payload: schema(numPartidas, idProyecto),
    });
  };

  return (
    <PartidasContext.Provider
      value={{
        ...state,
        setPartida,
        deletePartida,
        createPartida,
        clearPartidas,
        reorderPartidas,
        getSinglePartida,
        reordenarPartidas,
        postSinglePartida,
        createNuevaPartida,
        clearSinglePartida,
        setPropertyPartida,
        getPartidasProyecto,
        postPartidasProyecto,
        setPropertySinglePartida,
      }}
    >
      {children}
    </PartidasContext.Provider>
  );
};
