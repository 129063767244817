import React, { useContext, useEffect } from "react";
import { formatMonto, getValue } from "../../utils";
import { DestajosContext } from "../../context/DestajosContext";
import { AppConfigContext } from "../../context/AppConfigContext";

const ConceptoForm = ({ idProyecto, concepto, modifier, deleteConcepto }) => {
  const { destajos, getDestajos } = useContext(DestajosContext);
  const appconfig = useContext(AppConfigContext);
  const {
    idConcepto,
    idDestajo,
    clave,
    unidad,
    precio,
    importe,
    cantidad,
    costo,
  } = concepto;

  useEffect(() => {
    if (!Array.isArray(destajos)) {
      getDestajos(idProyecto);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDestajos = () => {
    if (Array.isArray(destajos)) {
      return [
        <option key="null" value={null}>
          Sin Destajo
        </option>,
        ...destajos.map((destajo) => (
          <option key={destajo.idDestajo} value={destajo.idDestajo}>
            {destajo.nombre}
          </option>
        )),
      ];
    }
  };

  const handleChangeImporte = (key, value) => {
    if (key === "precio") {
      const precioUnitario = value / cantidad;
      modifier(idConcepto, "precio_unitario", precioUnitario);
    }

    if (key === "cantidad") {
      const precioUnitario = precio / value;
      modifier(idConcepto, "precio_unitario", precioUnitario);
    }

    modifier(idConcepto, key, value);
  };

  return (
    <tr>
      <td>
        <input
          name="clave"
          value={clave}
          maxLength={140}
          placeholder="Clave"
          className="form-control"
          onChange={(e) => modifier(idConcepto, "clave", e.target.value)}
        />
      </td>
      <td>
        <input
          name="concepto"
          value={concepto.nombre ? concepto.nombre : ""}
          placeholder="Nombre"
          maxLength={140}
          className="form-control"
          onChange={(e) => {
            modifier(idConcepto, "concepto", e.target.value);
            modifier(idConcepto, "nombre", e.target.value);
          }}
        />
      </td>
      <td>
        <input
          name="unidad"
          value={unidad}
          placeholder="Unidad"
          maxLength={140}
          className="form-control"
          onChange={(e) => modifier(idConcepto, "unidad", e.target.value)}
        />
      </td>
      <td>
        {String(idConcepto).includes("nuevo") ? (
          <input
            min={0}
            type="number"
            name="cantidad"
            placeholder="Cantidad"
            value={cantidad}
            className="form-control"
            onChange={(e) =>
              handleChangeImporte("cantidad", Number(e.target.value))
            }
          />
        ) : (
          <>{cantidad}</>
        )}
      </td>
      <td>
        {String(idConcepto).includes("nuevo") ? (
          <input
            min={0}
            name="precio"
            type="number"
            placeholder="$Precio"
            value={precio}
            className="form-control"
            onChange={(e) =>
              handleChangeImporte("precio", Number(e.target.value))
            }
          />
        ) : (
          <>${formatMonto(precio)}</>
        )}
      </td>
      {getValue(appconfig, "costs_enabled", "boolean") && (
        <td>
          {String(idConcepto).includes("nuevo") ? (
            <input
              min={0}
              name="costo"
              type="number"
              placeholder="$Costo"
              value={costo}
              className="form-control"
              onChange={(e) =>
                handleChangeImporte("costo", Number(e.target.value))
              }
            />
          ) : (
            <>${formatMonto(costo)}</>
          )}
        </td>
      )}
      <td>
        {"$"}
        {String(idConcepto).includes("nuevo")
          ? formatMonto(precio / cantidad)
          : formatMonto(importe)}
      </td>
      <td>
        <select
          value={idDestajo}
          className="form-control"
          onChange={(e) => modifier(idConcepto, "idDestajo", e.target.value)}
        >
          {renderDestajos()}
        </select>
      </td>
      <td className="text-center">
        <button className="btn btn-sm btn-outline-danger" onClick={deleteConcepto}>
          <i className="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  );
};

export default ConceptoForm;
