import React, { useContext } from 'react';
import './purchaseOrders.css'
import { AppConfigContext } from '../../context/AppConfigContext';

const ProductOrderUpdates = ({ status, order }) => {
    const { primary } = useContext(AppConfigContext);
    const productDetailImageStyles = {
        backgroundImage: 'url("https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Liberty_Memorial_043.jpg/1280px-Liberty_Memorial_043.jpg")',
        height: '50px',
        width: '50px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: '50%',
        minWidth: '50px'
    };

    const statusUpdates = ["solicitado", "aprobado", "recibido"]

    const formatStatusText = (status) => {
        const formattedStatus = status.charAt(0).toUpperCase() + status.slice(1);
        return formattedStatus + " Por: "
    }

    const renderStatusSteps = () => {
        if (status) {
            return statusUpdates.map((currentStatus, index) => {
                if (index <= statusUpdates.indexOf(status)) {
                    return (
                        <div className='d-flex flex-row' key={index}>
                            <div style={{ width: '15%' }}>
                                {renderStatusSeparators(currentStatus)}
                            </div>
                            <div className="card shadow p-3 mb-3" style={{ width: '85%' }} key={index}>
                                <p className='bold mb-1'>
                                    {formatStatusText(currentStatus)}
                                </p>
                                <div className='d-flex'>
                                    <div className='me-3'>
                                        <div style={productDetailImageStyles} />
                                    </div>
                                    <div className='w-100'>
                                        <div>
                                            <p className='bold mb-1'>{order.orderUpdates?.actor?.nombre}</p>
                                        </div>
                                        <div className='d-flex justify-content-between w-100'>
                                            <p className='mb-0 small'>{order.orderUpdates?.actor?.correo}</p>
                                            <p className='mb-0 small'>{order.orderUpdates?.updateDate}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return <></>;
                }
            });
        } else {
            return 'No hay actualizaciones';
        }
    };

    const renderStatusSeparators = (text) => {

        return <div className='order-update-steps'>
            <div className={`order-update-step-line ${text}`} style={{ backgroundColor: primary }} />
            <div className='order-update-step' style={{ backgroundColor: primary }} />
        </div>
    };

    return (
        <div>
            <div className='h-100'>
                {renderStatusSteps()}
            </div>
        </div>
    );
};

export default ProductOrderUpdates;