import {
  SET_PROVEEDOR,
  CREATE_PROVEEDOR,
  DELETE_PROVEEDOR,
  PROVEEDORES_RECEIVED,
  SET_PROPERTY_PROVEEDOR,
} from "../types/proveedores";

const schema = {
  razon_social: "",
  rfc: "",
};

const ProveedoresReducer = (state, { type, payload }) => {
  switch (type) {
    case PROVEEDORES_RECEIVED:
      return { ...state, proveedores: payload };
    case SET_PROPERTY_PROVEEDOR: {
      const { key, value } = payload;
      const proveedor = { ...state.proveedor };
      proveedor[key] = value;
      return { ...state, proveedor };
    }
    case SET_PROVEEDOR:
      return { ...state, proveedor: payload };
    case CREATE_PROVEEDOR:
      return {
        ...state,
        proveedor: schema,
      };
    case DELETE_PROVEEDOR:
      return {
        ...state,
        proveedor: {},
      };
    default:
      return { ...state };
  }
};
export default ProveedoresReducer;
