import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import logo from "../../assets/logo_login.png";
import ProveedorActions from "./ProveedorActions";

const ProveedorCard = ({
  handleDelete,
  handleEdit,
  proveedor,
  platform,
  imgSrc,
}) => {
  const { S3_ENDPOINT } = useContext(AppConfigContext);

  let currentImgSrc = `${S3_ENDPOINT}/files/adjuntos/${proveedor.idAdjunto}.${proveedor.tipo_adjunto}`;

  if (proveedor.idAdjunto === null || proveedor.tipo_adjunto === null) {
    currentImgSrc = logo;
  }

  const renderContent = () => {
    if (platform === "web") {
      return (
        <div className="card-body position-relative d-flex flex-column justify-content-center align-items-center">
          <ProveedorActions
            proveedor={proveedor}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
          <img
            src={imgSrc ? imgSrc : currentImgSrc}
            alt={proveedor.razon_social}
            className="card-img-top rounded-circle mb-3"
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
          />
          <h5 className="card-title text-center">{proveedor.razon_social}</h5>
          <p className="card-text text-primary text-capitalize">
            {proveedor.rfc !== null ? proveedor.rfc : "N/D"}
          </p>
        </div>
      );
    }
    return (
      <div className="card-body position-relative d-flex flex-row justify-content-start align-items-center">
        <img
          src={imgSrc ? imgSrc : currentImgSrc}
          alt={proveedor.razon_social || proveedor.nombre_usuario}
          className="card-img-top rounded-circle mb-3"
          style={{
            maxWidth: "75px",
            objectFit: "cover",
            marginRight: "15px",
          }}
        />
        <div>
          <ProveedorActions
            proveedor={proveedor}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
          <h5 className="card-title text-start">{proveedor.razon_social}</h5>
          <p className="card-text text-primary text-capitalize">
            {proveedor.rfc !== null ? proveedor.rfc : "N/D"}
          </p>
        </div>
      </div>
    );
  };

  return <div className="card proveedor-card mb-3">{renderContent()}</div>;
};

export default ProveedorCard;
