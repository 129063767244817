import React from "react";
import Mensaje from "./Mensaje";

const Retraso = ({ retraso, hideImage }) => {
  return (
    <div className="container-fluid px-0">
      <div
        className="bg-primary text-white text-center d-block m-auto round5"
        style={{ maxWidth: 100 }}
      >
        {retraso.fecha}
      </div>
      <Mensaje retraso={true} entrada={retraso} hideImage={hideImage} />
    </div>
  );
};

export default Retraso;
