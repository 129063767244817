import React from "react";
import CustomIcon from "../global/CustomIcon";
import { useNavigate } from "react-router-dom";

const ContratoCard = ({ contrato }) => {
  const navigate = useNavigate();
  return (
    <div
      className="card shadow no-decoration text-dark"
      onClick={() =>
        navigate(`/obra/${contrato.idProyecto}/finanzas/${contrato.idContrato}`)
      }
    >
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-8">
            <div className="bg-light p-2 border d-inline-block">
              <CustomIcon icon="folder-dark" />
            </div>
          </div>
          <div className="col-4 text-end">
            <button
              className="btn text-primary"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <i className="fa fa-ellipsis-h"></i>
            </button>
          </div>
        </div>
        <h3 className="h5">{contrato.numero}</h3>
      </div>
    </div>
  );
};

export default ContratoCard;
