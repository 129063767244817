import api from "./api";

const route = "/cambiosestimacionversion";

export default {
  getCambioEstimacionVersiones: (idProyecto, idEstimacionVersion) =>
    api.get(`${route}/${idProyecto}/historial/${idEstimacionVersion}`),
  getSingleCambioEstimacionVersion: (idCambioEstimacionVersion) =>
    api.get(`${route}/${idCambioEstimacionVersion}`),
  postCambioEstimacionVersion: (cambioestimacionversion) =>
    api.post(route, { ...cambioestimacionversion }),
  verifyCambioEstimacionVersion: (idProyecto, idCambioEstimacionVersion, code) =>
    api.post(`${route}/${idProyecto}/${idCambioEstimacionVersion}/verify`, {
      idCambioEstimacionVersion,
      code,
    }),
  putCambioEstimacionVersion: (cambioestimacionversion) =>
    api.put(route, { ...cambioestimacionversion }),
  deleteCambioEstimacionVersion: (cambioestimacionversion) =>
    api.delete(`${route}/${cambioestimacionversion}`),
};
