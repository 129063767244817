import React from "react";
import moment from "moment";

const CurvaCritica = ({ aTiempo, sqSize, real, partidas }) => {
  //Obtener angulo en radianes basado en un porcentaje
  const getDegrees = (porcentaje) => {
    return Math.PI - (porcentaje / 100) * Math.PI;
  };

  //Obtener coordenada Y basado en un radio y angulo en radianes
  const getCoordY = (radius, degrees) => {
    return radius * Math.sin(degrees);
  };

  //Obtener coordenada X basado en un radio y angulo en radianes
  const getCoordX = (radius, degrees) => {
    return radius * Math.cos(degrees);
  };

  const getDiasTotales = () => {
    if (partidas)
      return moment(getFechaMax()).diff(moment(getFechaMin()), "days");
  };

  const getDiasAcum = () => {
    if (partidas) {
      if (partidas.length > 1) {
        const diferencias = partidas.map((partida) => {
          const diff = moment(partida.fecha_fin).diff(
            moment(partida.fecha_inicio),
            "days"
          );
          return diff !== 0 ? diff : 1;
        });
        return diferencias.reduce((a, b) => a + b);
      }
    }
  };

  const getFechaMin = () => {
    if (partidas) {
      let min = moment();
      for (let i = 0; i < partidas.length; i++) {
        let fecha_inicio = moment(partidas[i].fecha_inicio);
        if (fecha_inicio.isBefore(min)) min = fecha_inicio;
      }
      return min;
    }
  };

  const getFechaMax = () => {
    if (partidas) {
      let max = moment("1990-01-01");
      for (let i = 0; i < partidas.length; i++) {
        let fecha_fin = moment(partidas[i].fecha_fin);
        if (fecha_fin.isAfter(max)) max = fecha_fin;
      }
      return max;
    }
  };

  const strokeWidth = 8;
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (sqSize - strokeWidth) / 2;
  // Enclose cicle in a circumscribing square
  const viewBox = `70 0 ${sqSize * 1.4} ${sqSize / 2}`;
  //Coordenadas centrales del circulo
  const circleCenterX = sqSize / 4;
  const circleCenterY = sqSize / 2;

  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;

  //Longitud del arco de fondo. Es la circunferencia de la mitad del circulo porque es un medio circulo
  const dashOffsetBg = dashArray - dashArray * 0.5;

  //Porcentaje completado para circulo rojo. Se multiplica por 0.5 por que es un medio circulo
  const dashOffset = dashArray - dashArray * real * 0.51;

  //Porcentaje Acumulado para agregar lineas y circulos por partida
  let porcAcum = 0;

  let fill = "circle-progress-fill";
  let stroke = "progreso-naranja";
  if (aTiempo) {
    fill = "circle-ontime-fill";
    stroke = "progreso-real";
  }

  const dias = getDiasTotales();

  let diasAcum = getDiasAcum();

  if (!diasAcum) diasAcum = 1;

  const transcurridos = moment().isAfter(moment(getFechaMax()))
    ? dias
    : moment().diff(moment(getFechaMin()), "days");

  return (
    <svg
      width={sqSize}
      height={sqSize / 2.5}
      viewBox={viewBox}
      style={{ margin: "auto", padding: 0 }}
    >
      <defs>
        {/*
            Sombra de circulo que indica completado
          */}
        <filter id="f2" width={sqSize * 1.5} height={sqSize / 2}>
          <feOffset result="offOut" in="SourceGraphic" dx={0} dy={0} />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation={5} />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
      </defs>
      <text fontSize="16" y={circleCenterY + 35} x={sqSize / 4 - 14}>
        0%
      </text>
      <text fontSize="16" y={circleCenterY + 35} x={sqSize * 1.2}>
        100%
      </text>
      {/*
          Circulo gris de fondo
        */}
      <circle
        id="bg"
        className="circle-background shadow"
        cx={circleCenterX}
        cy={circleCenterY}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(180 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffsetBg,
        }}
      />
      {/*
          Circulo de color que indica completado
        */}
      <circle
        className={`circle-progress shadow ${stroke}`}
        filter="url(#f2)"
        cx={circleCenterX}
        cy={circleCenterY}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(180 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
        }}
      />
      {/*
          Relleno de la grafica.
          Un medio circulo rotado con respecto al porcentaje completado.
          La rotación se logra modificando las coordenadas de inicio del path.
          Sintaxis de Path: https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths
        */}
      <svg height={circleCenterY} width={circleCenterX + radius * 2}>
        <path
          d={`M
                ${
                  circleCenterX +
                  //Diferencia entre punto inicial y coordenada del punto
                  (circleCenterX +
                    radius * 2 -
                    (getCoordX(
                      radius,
                      getDegrees((transcurridos / dias) * 100)
                    ) + //offset de circulo
                      sqSize * 0.75))
                }
                
                ${
                  //Posicion inicial más la coordenada del punto.
                  circleCenterY +
                  getCoordY(radius, getDegrees((transcurridos / dias) * 100))
                } 
                    
                A 1 1 0 0 1 
                
                ${
                  getCoordX(radius, getDegrees((transcurridos / dias) * 100)) +
                  sqSize * 0.75
                }
                
                ${
                  circleCenterY -
                  getCoordY(radius, getDegrees((transcurridos / dias) * 100))
                }`}
          className={fill}
        />
      </svg>
      {/*
          Linea que se conecta con el 0% color rojo
        */}
      <line
        x1={circleCenterX}
        x2={circleCenterX + sqSize * 0.5}
        y1={circleCenterY}
        y2={circleCenterY}
        className={stroke}
        strokeWidth={`${strokeWidth}px`}
      />
      {partidas.map((partida, index) => {
        let diff = moment(partida.fecha_fin).diff(
          moment(partida.fecha_inicio),
          "days"
        );
        if (diff === 0) diff = 1;
        porcAcum += (diff / diasAcum) * 100;
        //gradoas acumulados convertidos a radianes
        let degrees = getDegrees(porcAcum);
        //coordenada x del punto respecto al pie grande con su offset
        let pcx = sqSize * 0.75 + getCoordX(radius, degrees);
        //coordenada y del punto respecto al pie grande con su offset
        let pcy = circleCenterY - getCoordY(radius, degrees);
        let progressY =
          circleCenterY - getCoordY(radius, degrees) * partida.real;
        let progressX =
          sqSize * 0.75 + getCoordX(radius, degrees) * partida.real;
        //Una linea con un circulo al final, con el nombre de la partida
        return (
          <g key={index}>
            <line
              x1={pcx}
              x2={circleCenterX + sqSize * 0.5}
              y1={pcy}
              y2={circleCenterY}
              className="circle-background"
              strokeWidth={`${strokeWidth}px`}
            />
            <line
              x1={circleCenterX + sqSize * 0.5}
              x2={progressX}
              y1={circleCenterY}
              y2={progressY}
              className={stroke}
              strokeWidth={`${strokeWidth}px`}
            />
            <circle
              className={
                parseFloat(partida.real) === 1.0 ? stroke : "circle-background"
              }
              fill="none"
              cx={pcx}
              cy={pcy}
              r={5}
              strokeWidth={`${strokeWidth}px`}
              key={index}
            />
            {/* Posicion de texto */}
            <text
              x={
                pcx > radius + circleCenterX
                  ? pcx + 20
                  : pcx - (130 + partida.nombre.length * 1.5)
              }
              y={pcy < 50 ? pcy - 20 : pcy}
              fontSize='16'
            >
              {partida.nombre}
            </text>
          </g>
        );
      })}
      {/*
          circulo central donde se juntan todas las lineas
        */}
      <circle
        className={stroke}
        cx={circleCenterX + radius + 2.5}
        cy={circleCenterY}
        r={2}
        strokeWidth={`${strokeWidth * 1.1}px`}
        fill={fill}
      />
    </svg>
  );
};

export default CurvaCritica;
