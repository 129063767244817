import React, { useState } from "react";
import { stopPropagation } from "../../utils";
import UserItem from "./UserItem";

const UserPicker = ({ user, users, placeholder, modifier }) => {
  const [query, setQuery] = useState("");

  const handleModifier = (user) => {
    setQuery("");
    if (typeof modifier === "function") {
      modifier(user);
    }
  };

  const searchUsers = (users, query) => {
    let currentQuery = String(query).toLowerCase();
    let currentUsers = users.filter(
      (user) =>
        String(user.nombre).toLowerCase().startsWith(currentQuery) ||
        String(user.correo).toLowerCase().startsWith(currentQuery) ||
        String(user.nombre).toLowerCase().includes(currentQuery) ||
        String(user.correo).toLowerCase().includes(currentQuery)
    );
    return currentUsers;
  };

  const renderUsers = () => {
    if (Array.isArray(users) && query !== "") {
      let currentUsers = searchUsers(users, query);
      return currentUsers.map((user) => (
        <li
          key={user.idUsuario}
          className="card br-0 p-1 px-2"
          onClick={() => handleModifier(user)}
        >
          <p className="mb-0 small bold">{user.nombre}</p>
          <p className="mb-0 small text-muted">{user.correo}</p>
        </li>
      ));
    }
  };

  const renderContent = () => {
    if (user && user !== null) {
      return <UserItem user={user} showEmail />;
    }
    return (
      <div className="dropdown">
        <input
          type="text"
          value={query}
          onClick={stopPropagation}
          className="form-control small"
          onChange={(e) => setQuery(e.target.value)}
          placeholder={placeholder ? placeholder : "Elegir usuario"}
        />
        <ul className="unstyled">{renderUsers()}</ul>
      </div>
    );
  };

  return <div className="container-fluid px-0">{renderContent()}</div>;
};

export default UserPicker;
