import React from "react";

const ConceptoAutorizado = ({ concepto, modifier }) => {
  const { idConcepto, estimado, supervisado, autorizado, idAdjunto } = concepto;

  return (
    <>
      <td>{estimado}</td>
      <td>{supervisado}</td>
      <td>
        {estimado && estimado !== null && (
          <input
            type="number"
            value={autorizado}
            className="form-control"
            onChange={(e) => modifier(idConcepto, "autorizado", e.target.value)}
          />
        )}
      </td>
      <td>
        <button className="btn btn-outline-secondary">
          {idAdjunto && idAdjunto !== null && <i className="fa fa-image" />}
        </button>
      </td>
    </>
  );
};

export default ConceptoAutorizado;
