export const applyAppConfig = (appconfig) => {
  if (appconfig?.primary) setupColor(appconfig.primary);
  if (appconfig?.app_name && appconfig?.app_tagline)
    setupTitle(appconfig.app_name, appconfig.app_tagline);
};

export const setupColor = (color) => {
  document.documentElement.style.setProperty(`--primary`, color);
  document.documentElement.style.setProperty(`--ion-color-primary`, color);
};

export const setupTitle = (app_name, app_tagline) => {
  document.title = `${app_name}  | ${app_tagline}`;
};
