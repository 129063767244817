import React, { useContext, useEffect } from "react";
import { EntradasContext } from "../../context/EntradasContext";
import { AuthContext } from "../../context/AuthContext";
import BitacoraInput from "../bitacora/BitacoraInput";
import Entradas from "../bitacora/Entradas";
import { RevisionesContext } from "../../context/RevisionesContext";
import { ItemsContext } from "../../context/ItemsContext";
import { AdjuntosContext } from "../../context/AdjuntosContext";
import useProyecto from "../../hooks/useProyecto";

const BitacoraItem = () => {
  const { idProyecto } = useProyecto();

  const { revision, addItemToRevision, isItemOnRevision } =
    useContext(RevisionesContext);

  const { item, itemsByRevision, getItemsByRevision } =
    useContext(ItemsContext);

  const { max, entradas, postEntrada, getEntradas } =
    useContext(EntradasContext);

  const { uploadSingleAdjunto } = useContext(AdjuntosContext);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetchEntradas();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsByRevision]);

  const handleSubmit = async (message, adjuntos) => {
    const [isOnRevision, revisionItem] = isItemOnRevision();

    const itemData = {
      idItem: item.idItem,
      estado: item.estado,
    };

    if (isOnRevision) {
      const entradaData = { message, ...itemData };

      if (adjuntos.length > 0) {
        adjuntos.forEach(async (file) => {
          const data = await postAdjuntoOnRevision(revisionItem.idFolder, file);
          entradaData.idAdjunto = data.idAdjunto;

          await postEntradaOnRevision(entradaData, revisionItem);
        });
      } else {
        postEntradaOnRevision(entradaData, revisionItem);
      }
    } else {
      const { data } = await addItemToRevision(idProyecto, itemData, revision);
      const entradaData = { message };

      if (adjuntos.length > 0) {
        adjuntos.forEach(async (file) => {
          const adjuntoData = await postAdjuntoOnRevision(data.idFolder, file);
          entradaData.idAdjunto = adjuntoData.idAdjunto;
          await postEntradaOnRevision(entradaData, data);
        });
      } else {
        postEntradaOnRevision(entradaData, data);
      }
    }
  };

  const postAdjuntoOnRevision = async (idFolder, adjunto) => {
    return await uploadSingleAdjunto(idProyecto, idFolder, adjunto);
  };

  const postEntradaOnRevision = async (entradaData, revisionItem) => {
    entradaData.idRevisionItem = revisionItem.idRevisionItem;
    await postEntrada(idProyecto, entradaData);
    await getItemsByRevision(revision.idRevision, idProyecto);
    fetchEntradas();
  };

  const fetchEntradas = async () => {
    const [isOnRevision, revisionItem] = isItemOnRevision();
    getEntradas(idProyecto, 0, 25, { idRevisionItem: revisionItem?.idRevisionItem });
  };

  return (
    <div
      style={{
        height: '70vh'
      }}
    >
      <div 
        className="row"
        style={{
          height: 'calc(100% - 52px)'
        }}
      >
        <Entradas
          scrollCallback={fetchEntradas}
          idUsuario={user.idUsuario}
          idProyecto={idProyecto}
          entradas={entradas}
          user={user}
          max={max}
        />
      </div>
      
      <BitacoraInput modifier={handleSubmit} />
    </div>
  );
};

export default BitacoraItem;
