import {
  USUARIOS_CLIENTE_RECIBIDOS,
  CLIENTES_RECIBIDOS,
  CLIENTE_RECIBIDO,
  SAVE_USER_CLIENTE,
  SET_PROPERTY_CLIENTE,
  CREATE_USUARIO_CLIENTE,
  DELETE_CLIENTE,
  OBRAS_CLIENTE_RECIBIDAS,
  CLEAR_CLIENTE,
  CREATE_OBRA_CLIENTE,
  SET_OBRA_CLIENTE,
  CLEAR_OBRA_CLIENTE,
  OBRAS_SINCLIENTE_RECIBIDAS,
} from "../types/clientes";
import { SET_PROPERTY_USUARIO } from "../types/usuarios";

export default (state, { type, payload }) => {
  switch (type) {
    case CLIENTES_RECIBIDOS:
      return { ...state, clientes: payload };
    case CLIENTE_RECIBIDO:
      return { ...state, cliente: payload };
    case USUARIOS_CLIENTE_RECIBIDOS:
      return { ...state, usuarios: payload, usuario: null };
    case SAVE_USER_CLIENTE:
      const usuarios = [...state.usuarios];
      const idx = usuarios.findIndex(
        (usuario) => usuario.idUsuario === payload.idUsuario
      );
      if (idx !== -1) {
        usuarios[idx] = payload;
      } else {
        usuarios.push(payload);
      }
      return { ...state, usuarios };
    case SET_PROPERTY_CLIENTE:
      const { key, value } = payload;
      const cliente = { ...state.cliente };
      cliente[key] = value;
      return { ...state, cliente };
    case CREATE_USUARIO_CLIENTE:
      return { ...state, usuario: payload };
    case SET_PROPERTY_USUARIO:
      const usuario = { ...state.usuario };
      usuario[payload.key] = payload.value;
      return { ...state, usuario };
    case DELETE_CLIENTE:
      const clientes = [...state.clientes];
      let index = clientes.findIndex(
        (cliente) => cliente.idCliente === payload
      );
      if (index !== -1) clientes.splice(index, 1);
      return { ...state, clientes };
    case OBRAS_CLIENTE_RECIBIDAS:
      return { ...state, obras: payload, obra: null };
    case CREATE_OBRA_CLIENTE:
      return { ...state, obra: { idProyecto: null } };
    case SET_OBRA_CLIENTE:
      return { ...state, obra: { idProyecto: payload } };
    case CLEAR_OBRA_CLIENTE:
      return { ...state, obra: null };
    case OBRAS_SINCLIENTE_RECIBIDAS:
      return { ...state, sinCliente: payload };
    case CLEAR_CLIENTE:
      return { ...state, cliente: null };
    default:
      return state;
  }
};
