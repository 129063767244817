import React, { useContext, useState, useEffect } from "react";
import { ProductsContext } from "../../../context/ProductsContext";
import ProductTableRow from "./ProductTableRow";
import "../product.css";

const ProductTable = ({ projectId }) => {
  const { getProducts, getProjectProducts, products } =
    useContext(ProductsContext);
  const [productsToDisplay, setProductsToDisplay] = useState([]);

  useEffect(() => {
    if (projectId) {
      getProjectProducts(projectId);
    } else {
      getProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (Array.isArray(products)) {
      if (projectId) {
        const filteredProducts = products.filter(
          (product) => product.idProyecto === projectId
        );
        setProductsToDisplay(filteredProducts);
      } else {
        setProductsToDisplay([...products]);
      }
    }
  }, [products, projectId]);

  const renderProducts = () => {
    if (Array.isArray(products)) {
      return productsToDisplay.map((product) => (
        <ProductTableRow
          key={product.idProducto}
          product={product}
          depthColor={0}
          parentId={null}
        />
      ));
    }
  };

  return (
    <div className="container-fluid">
      <div className="card p-3 my-3">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="bg-light bold">
                <th className="px-3 py-2">SKU</th>
                <th className="px-3 py-2">ARTÍCULO</th>
                <th className="px-3 py-2">UNIDAD</th>
                <th className="px-3 py-2">CANTIDAD</th>
                <th className="px-3 py-2">ÓRDENES</th>
                <th className="px-3 py-2">MÁXIMO</th>
                <th className="px-3 py-2">MÍNIMO</th>
                <th className="px-3 py-2">$PU</th>
                <th className="px-3 py-2">$IMPORTE</th>
                <th className="px-3 py-2">ACCIONES</th>
              </tr>
            </thead>
            <tbody>{renderProducts()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProductTable;
