import React, { createContext, useReducer, useContext } from "react";
import OrdenesService from "../services/OrderService";
import OrdenesReducer from "../reducers/OrdenesReducer";
import {
  RECEIVED_ORDERS,
  SET_ORDER,
  SET_PROPERTY_ORDER,
  DELETE_ORDER,
  SET_PRODUCTS_ORDER,
} from "../types/ordenes";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../actions/types";

const initialState = {
  ordenes: [],
  orden: {},
  filters: {},
  currentProductsOrder: []
};

export const OrdenesContext = createContext(initialState);

export const OrdenesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(OrdenesReducer, initialState);

  const { success, clearModal } = useContext(ModalContext);

  const getOrdenes = (idProyecto) => {
    OrdenesService.getOrdenes(idProyecto)
      .then((response) => {
        const { ordenes } = response.data;
        dispatch({ type: RECEIVED_ORDERS, payload: ordenes });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getOrdenesDeProducto = (idProducto) => {
    OrdenesService.getOrdenesDeProducto(idProducto).then((res) => {
      const { ordenesDeProducto } = res.data
      dispatch({ type: RECEIVED_ORDERS, payload: ordenesDeProducto });
    }).catch(alert)
  };


  const setOrder = (order) => {
    dispatch({ type: SET_ORDER, payload: order });
  };


  const getSingleOrder = (idProyecto, idOrder) => {
    const orderMethod = idProyecto ?
      OrdenesService.getProjectOrder :
      OrdenesService.getOrder

    orderMethod(idProyecto, idOrder).then((res) => {
      const { orden } = res.data
      setOrder(orden);
    }).catch(alert)
  };

  const setPropertyOrdenes = (idOrder, key, value) => {
    dispatch({ type: SET_PROPERTY_ORDER, payload: { idOrder, key, value } });
  };

  const saveOrder = (idProyecto = null, idProducto, orden) => {
    dispatch({ type: SHOW_SPINNER });
    OrdenesService.postOrder(idProyecto, idProducto, orden)
      .then(() => {
        dispatch({ type: HIDE_SPINNER });
        getOrdenesDeProducto(idProducto);
        success("Order saved.");
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        getOrdenes(idProyecto);
        alert(error);
      }).finally(clearModal);
  };

  const updateOrder = (idOrder, orderData) => {
    OrdenesService.putOrder(idOrder, orderData).then((response) => {
      success("Orden Editada")
      const { updatedOrden } = response.data;
      dispatch({ type: SET_ORDER, payload: updatedOrden });
    }).catch(error => alert(error)).finally(clearModal);
  };

  const deleteOrder = (idOrder) => {
    OrdenesService.deleteOrder(idOrder).then((response) => {
      success("Orden Borrada")
      const { ordenes } = response.data;
      dispatch({ type: DELETE_ORDER, payload: ordenes });
    }).catch(error => alert(error));
  };

  const approveOrder = (idProyecto, idOrder) => {
    OrdenesService.approveOrder(idProyecto, idOrder);
  };

  //producto ordenes
  const createProductsOrder = (orderId, newProductOrder) => {
    OrdenesService.createProductsOrder(orderId, newProductOrder)
      .then((res) => {
        const { ProductoOrdenes } = res.data;
        dispatch({ type: SET_PRODUCTS_ORDER, payload: ProductoOrdenes });
        success("Orden de Producto Añadida");
      })
      .catch((error) => {
        alert(error);
      })
      .finally(() => {
        clearModal();
      });
  };

  const editProductsOrder = (productOrderId, productOrderData) => {
    OrdenesService.editProductsOrder(productOrderId, productOrderData).then((res) => {
      const { updatedProductoOrden } = res.data
      dispatch({ type: SET_PRODUCTS_ORDER, payload: updatedProductoOrden });
    }).catch(alert).finally(clearModal);
    success("Orden de Producto Editada")
  };

  const deleteProductsOrder = (productOrderId, orderId) => {
    OrdenesService.deleteProductsOrder(productOrderId).then(() => {
      getProductsOrder(orderId)
    }).catch(alert)
    success("Orden de Producto Eliminada")
  };

  const getProductsOrder = (idOrder) => {
    OrdenesService.getProductsOrder(idOrder).then((res) => {
      const { ProductoOrden } = res.data
      dispatch({ type: SET_PRODUCTS_ORDER, payload: ProductoOrden });
    }).catch(alert);
  };

  return (
    <OrdenesContext.Provider
      value={{
        ...state,
        setOrder,
        saveOrder,
        getOrdenes,
        getOrdenesDeProducto,
        updateOrder,
        deleteOrder,
        approveOrder,
        getSingleOrder,
        setPropertyOrdenes,
        createProductsOrder,
        editProductsOrder,
        deleteProductsOrder,
        getProductsOrder
      }}
    >
      {children}
    </OrdenesContext.Provider>
  );
};
