import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ModalContext } from "../context/ModalContext";
import ChartCard from "../components/analiticas/ChartCard";
import { ConceptosContext } from "../context/ConceptosContext";
import ConceptoForm from "../components/conceptos/ConceptoForm";
import ConceptoEstimadoChart from "../components/conceptos/ConceptoEstimadoChart";
import EstimacionesConcepto from "../components/estimaciones/EstimacionesConcepto";
import ConceptoInfo from "../components/conceptos/ConceptoInfo";
import useProyecto from "../hooks/useProyecto";

const SingleConceptoEstimaciones = () => {
  const { idProyecto } = useProyecto();
  const { modalComponent } = useContext(ModalContext);
  const { concepto, getSingleConcepto, getConceptosContrato } =
    useContext(ConceptosContext);

  const { idConcepto, idContrato } = useParams();

  useEffect(() => {
    getSingleConcepto(idProyecto, idConcepto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getConceptosContrato(idProyecto, idContrato);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idContrato]);

  const handleEdit = () => {
    modalComponent("Editar Concepto", <ConceptoForm />);
  };

  return (
    <div className="container-fluid h-100 overflow-y-auto">
      <div className="row my-2">
        <div className="col-12 col-md-6">
          <h1 className="h2 bold">Concepto {concepto?.clave}</h1>
        </div>
        <div className="col-12 col-md-6 text-end">
          <button onClick={handleEdit} className="btn btn-primary mb-2">
            <i className="fa fa-edit me-1"></i>{" "}
            <span className="hide-mobile">Editar </span>
          </button>
        </div>
      </div>
      <p>{concepto?.concepto}</p>
      {concepto && concepto !== null ? (
        <div className="row">
          <div className="col-12 col-md-6">
            <ChartCard>
              <ConceptoInfo />
            </ChartCard>
            <ChartCard>
              <EstimacionesConcepto />
            </ChartCard>
          </div>
          <div className="col-12 col-md-6">
            <ChartCard titulo="Avance">
              <ConceptoEstimadoChart
                total={concepto?.cantidad}
                estimaciones={concepto?.estimados}
              />
            </ChartCard>
          </div>
        </div>
      ) : (
        <div className="spinner-border" />
      )}
    </div>
  );
};

export default SingleConceptoEstimaciones;
