import {
  SET_ORDER,
  CREATE_ORDER,
  RECEIVED_ORDERS,
  SET_PROPERTY_ORDER,
  GET_SINGLE_ORDER,
  DELETE_ORDER,
  SET_PRODUCTS_ORDER
} from "../types/ordenes";

const schema = {
  idOrden: "",
};

const OrdenesReducer = (state, { type, payload }) => {
  switch (type) {
    case GET_SINGLE_ORDER:
      const singleOrder = state.ordenes.find((order) => payload === order.idOrden);
      return { ...state, orden: singleOrder };
    case RECEIVED_ORDERS:
      return { ...state, ordenes: payload };
    case SET_ORDER:
      return { ...state, orden: payload };
    case CREATE_ORDER:
      return { ...state, order: schema };
    case DELETE_ORDER:
      return { ...state, ordenes: payload };
    case SET_PROPERTY_ORDER:
      const { key, value } = payload;
      const order = { ...state.order };
      order[key] = value;
      return {
        ...state,
        order,
      };
    case SET_PRODUCTS_ORDER:
      return { ...state, currentProductsOrder: payload };
    default:
      return { ...state };
  }
};

export default OrdenesReducer;
