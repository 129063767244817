import moment from "moment";
import React, { useContext, useState } from "react";
import { CapacitorContext } from "../../context/CapacitorContext";
import { FILES_ENDPOINT, formatMonto } from "../../utils";
import UltimoAvance from "../movil/UltimoAvance";
import UserItem from "../users/UserItem";
import useChecklists from "../../hooks/useChecklists";

const ChecklistDashboard = ({ progreso }) => {
  const [showModal, setShowModal] = useState(false);

  const { platform } = useContext(CapacitorContext);

  const { item, entrada } = useChecklists();

  const hasImage = () => {
    const { Adjunto } = item;
    return Adjunto && Adjunto !== null;
  };

  const renderLastComment = () => {
    if (entrada && entrada !== null) {
      return (
        <div className="card p-4 shadow-sm my-3">
          <h5>Último Comentario</h5>
          <div className="row align-items-center">
            <div className="col-12 col-md-8 ">
              <UserItem user={entrada.Usuario} platform={platform} />
            </div>
            <div className="col-12 col-md-4 text-end">
              <p className="small mb-0 text-muted">
                {moment(entrada.updatedAt).format("DD MMM YYYY HH:mm")}
              </p>
            </div>
          </div>
          <p className="bg-light mt-2 mb-1 border p-3">{entrada.contenido}</p>
        </div>
      );
    }
  };

  const renderLastItem = () => {
    if (item && item !== null) {
      const title = platform === "web" ? "Último Avance" : "";
      return (
        <div className="">
          <h5 className="h5" style={{ fontWeight: "600" }}>
            {" "}
            {title}{" "}
          </h5>
          <div
            className={`${
              platform === "web"
                ? "card shadow-sm p-4 mb-3 border-0 shadow-sm "
                : "border-0  shadow-sm p-3 mb-3"
            }`}
          >
            <div className="row">
              {renderImage()}
              <div className={`col-12 ${hasImage() ? "col-xl-6" : ""}`}>
                <div>
                  <UserItem user={item.responsable} />
                  <span className="text-primary small h6">
                    {" "}
                    {moment(item.updatedAt).format("DD MMM YYYY HH:mm")}
                  </span>
                  <p className="mb-0">{item.nombre}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderImage = () => {
    if (hasImage()) {
      const { Adjunto } = item;
      return (
        <div className="col-12 col-xl-6 pb-3">
          <img
            alt="Evidencia"
            className="mw-100 w-100 d-block m-auto"
            src={`${FILES_ENDPOINT}/${Adjunto.idAdjunto}.${Adjunto.tipo}`}
            style={{ height: "100%", objectFit: "cover" }}
          />
        </div>
      );
    }
  };

  const ultimoAvance = renderLastItem();

  const comentarios = renderLastComment();

  const renderProgress = () => {
    if (platform === "web") {
      return (
        <div className="row align-items-center">
          <div className="col-md-12 col-lg-6">{renderLastItem()}</div>
          <div className="col-md-12 col-lg-6">
            <h5 className="h5" style={{ fontWeight: "600" }}>
              Progreso
            </h5>
            <div className="card border-0 shadow-sm">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div
                    className="progress flex-grow-1"
                    style={{ height: "10px" }}
                  >
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${formatMonto(progreso)}%`,
                        backgroundColor: "#c4f551",
                      }}
                      aria-valuenow={progreso}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <span className="ms-2 fs-6 fw-bold text-black">
                    {formatMonto(progreso)}%
                  </span>
                </div>
              </div>
            </div>
            {renderLastComment()}
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-md-11 col-lg-5 mb-3 ps-2">
          <h5 className="h4 titleInputPlantilla">Progreso</h5>
          <div className="">
            <div className="card-body">
              <div
                className="d-flex align-items-center"
                style={{ width: "100%" }}
              >
                <div
                  className="progress flex-grow-1"
                  style={{ height: "15px" }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${formatMonto(progreso)}%`,
                      backgroundColor: "#c4f551",
                    }}
                    aria-valuenow={progreso}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <span className="ms-2 fs-6 fw-bold text-black position-relative ps-3">
                  {formatMonto(progreso)}%
                  <button className="btn" onClick={() => setShowModal(true)}>
                    <i className="fa fa-info-circle ps-3"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="px-0">
      {renderProgress()}
      <UltimoAvance
        showModal={showModal}
        setShowModal={setShowModal}
        UltimoAvance={ultimoAvance}
        comentarios={comentarios}
      />
    </div>
  );
};
export default ChecklistDashboard;
