import api from "./api";

const route = "/proveedores";

const ProveedoresService = {
  getAllProveedores: () => api.get(`${route}/`),
  getSingleProveedor: (idProveedor) => api.get(`${route}/${idProveedor}`),
  getProveedoresProyecto: (idProyecto) => api.get(`${route}/proyecto/${idProyecto}`),
  postProveedor: (proveedorData) => api.post(`${route}/`, { ...proveedorData }),
  putProveedor: (proveedor) => api.put(`${route}/${proveedor.idProveedor}`, { ...proveedor }),
  deleteProveedor: (idProveedor) => api.delete(`${route}/${idProveedor}`),
};

export default ProveedoresService;