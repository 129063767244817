
export const RECEIVED_PRODUCTS = "RECEIVED_PRODUCTS";
export const CLONE_PRODUCT = "CLONE_PRODUCT";
export const GET_SINGLE_PRODUCT = "GET_SINGLE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const SET_PROPERTY_PRODUCT = "SET_PROPERTY_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const ADD_PRODUCT_INSUMO = "ADD_PRODUCT_INSUMO";
export const DELETE_PRODUCT_INSUMO = "DELETE_PRODUCT_INSUMO";
export const GET_PRODUCT_INSUMOS = "GET_PRODUCT_INSUMOS";
export const UPDATE_PRODUCT_INSUMOS = "UPDATE_PRODUCT_INSUMOS"
