import { Link } from "react-router-dom";
import React from "react";
import Chart from "react-apexcharts";
import DateRange from "../global/DateRange";
import UserItem from "../users/UserItem";

const ChecklistCard = ({ checklist }) => {
  const { idChecklist, nombre } = checklist;

  const renderChart = () => {
    let { completados, items } = checklist;
    if (completados === null) completados = 0;
    if (items !== null && items > 0) {
      let progreso = ((parseInt(completados) / parseInt(items)) * 100).toFixed(
        2
      );
      return (
        <div className="chart-wrapper">
          <Chart
            type="radialBar"
            options={{
              stroke: {
                lineCap: "round",
              },
              colors: ["#89d405"],
              dataLabels: {
                enabled: false,
              },
              labels: [`${completados} / ${items}`],
              fill: {
                type: "gradient",
                gradient: {
                  shade: "dark",
                  stops: [0, 100],
                  gradientToColors: ["#B3FF00"],
                },
              },
              plotOptions: {
                radialBar: {
                  track: {
                    background: ["#ddd"],
                  },
                },
              },
              chart: {
                offsetX: 0,
                offsetY: -5,
                width: "100%",
              },
            }}
            series={[progreso]}
          />
        </div>
      );
    } else {
      return(
        <div className="row align-items-center justify-content-center">
          <i className="far fa-file-alt" style={{fontSize: '40px', width: 'max-content'}}/>
        </div>
      )
    }
  };

  return (
    <div className="card shadow hover-light mb-3">
      <Link to={`./${idChecklist}`}>
        <div className="row align-items-center">
          <div className="col-4 px-0">
            {renderChart()}
          </div>
          <div className="col-8 px-0">
            <div className="p-4">
              <h4>{nombre}</h4>
              <DateRange
                end_date={checklist.fecha_fin}
                start_date={checklist.fecha_inicio}
              />
              <div className="row mt-3">
                <div className="col-12 col-lg-6 px-0">
                  {checklist.buen_estado !== null && (
                    <span className="btn btn-sm btn-outline-success disabled me-2">
                      <i className="fa fa-check me-1"></i>{" "}
                      {checklist.buen_estado}
                    </span>
                  )}
                  {checklist.mantenimiento !== null && (
                    <span className="btn btn-sm btn-outline-warning disabled me-2">
                      <i className="fa fa-exclamation-circle me-1"></i>{" "}
                      {checklist.mantenimiento}
                    </span>
                  )}
                </div>
                {
                  checklist.nombre_responsable && (
                    <div className="col-12 col-lg-6 px-0">
                      <UserItem size="sm" user={{ nombre: checklist.nombre_responsable }} />
                    </div>
                  )
                }
               
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default ChecklistCard;
