import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { MenuContext } from "../context/MenuContext";
import UserItem from "../components/users/UserItem";
import { ProyectosContext } from "../context/ProyectosContext";
import { CapacitorContext } from "../context/CapacitorContext";

const Navbar = ({ menuOpen }) => {
  const { selected } = useContext(MenuContext);
  const { user, signOut } = useContext(AuthContext);
  const { proyecto } = useContext(ProyectosContext);
  const { platform } = useContext(CapacitorContext);

  const selectedValid =
    selected && selected !== null && typeof selected === "object";

  const renderTitle = () => {
    if (proyecto && proyecto !== null) {
      if (selectedValid && platform === "web")
        return (
          <div className="ps-3">
            <h3 className="mb-0 text-capitalize">{selected.title}</h3>
            {selected.subtitle && (
              <p className="mb-0 text-capitalize">{selected.subtitle}</p>
            )}
          </div>
        );
      return (
        <div className="ps-3">
          <h3 className="mb-0 text-capitalize">{proyecto.nombre}</h3>
        </div>
      );
    }
    if (typeof selected === "string") {
      return <h3 className="ps-3 mb-0 text-capitalize">{selected}</h3>;
    }
  };

  return (
    <div
      id="navbar"
      className={
        `fixed-top navbar navbar-light p-2 bg-white 
        ${platform !== 'web' ? 'tablet-navbar ' : ''}
      `}
      style={{
        marginLeft: menuOpen ? '200px' : '100px',
        width: menuOpen ? 'calc(100vw - 200px)' : 'calc(100vw - 100px)'
      }}
    >
      <div className="col-6">{renderTitle()}</div>
      <div className="col-6 text-end">
        <div style={{ maxWidth: 250 }} className="ms-auto me-end">
          <UserItem user={user} handleSignOut={signOut}>
            <p className="small mb-0 text-capitalize text-muted">
              {user.permiso}
            </p>
          </UserItem>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
