import React, { createContext, useContext, useReducer } from "react";
import {
  EDIT_ESTIMACION,
  CLEAR_ESTIMACION,
  CREATE_ESTIMACION,
  SELECT_ESTIMACION,
  TOGGLE_ESTIMACIONES,
  ESTIMACIONES_RECIBIDAS,
  SET_PROPERTY_ESTIMACION,
} from "../types/estimaciones";
import { HIDE_SPINNER, SHOW_SPINNER } from "../actions/types";
import EstimacionesService from "../services/EstimacionesService";
import EstimacionesReducer from "../reducers/EstimacionesReducer";
import { ModalContext } from "./ModalContext";

const initialState = {
  showEstimaciones: false,
  estimaciones: null,
  estimacion: null,
};

export const EstimacionesContext = createContext(initialState);

export const EstimacionesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(EstimacionesReducer, initialState);
  const { success, alert, clearModal } = useContext(ModalContext);

  const getEstimacionesContrato = (idProyecto, idContrato) => {
    EstimacionesService.getEstimaciones(idProyecto, idContrato).then((res) => {
      const { estimaciones } = res.data;
      dispatch({ type: ESTIMACIONES_RECIBIDAS, payload: estimaciones });
    });
  };

  const createEstimacion = () => {
    dispatch({ type: CREATE_ESTIMACION });
  };

  const setPropiedadEstimacion = (key, value) => {
    dispatch({ type: SET_PROPERTY_ESTIMACION, payload: { key, value } });
  };

  const postEstimacion = (idProyecto, idContrato, estimacion, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let { idEstimacion } = estimacion;
    let service = EstimacionesService.putEstimacion;
    if (isNaN(parseInt(idEstimacion))) {
      service = EstimacionesService.postEstimacion;
    }
    service(idProyecto, idContrato, estimacion)
      .then(() => {
        clearModal();
        success("Estimacion guardada con éxito.");
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const postEstimacionGeneradores = (
    idProyecto,
    idContrato,
    idEstimacion,
    file,
    callback
  ) => {
    dispatch({ type: SHOW_SPINNER });
    EstimacionesService.postGeneradoresEstimacion(
      idProyecto,
      idContrato,
      idEstimacion,
      file
    )
      .then(() => {
        success("Estimacion guardada con éxito.");
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const toggleEstimaciones = () => {
    dispatch({ type: TOGGLE_ESTIMACIONES });
  };

  const selectEstimacion = (idProyecto, idContrato, idEstimacion) => {
    EstimacionesService.getEstimacion(
      idProyecto,
      idContrato,
      idEstimacion
    ).then((res) => {
      const { estimacion, conceptos, conceptosExtra } = res.data;
      dispatch({
        type: SELECT_ESTIMACION,
        payload: { estimacion, conceptos, conceptosExtra },
      });
    });
  };

  const editEstimacion = (estimacion) => {
    dispatch({ type: EDIT_ESTIMACION, payload: estimacion });
  };

  const deleteEstimacion = (idProyecto, idContrato, idEstimacion) => {
    EstimacionesService.deleteEstimacion(idProyecto, idContrato, idEstimacion)
      .then((res) => {
        const { estimaciones } = res.data;
        dispatch({ type: ESTIMACIONES_RECIBIDAS, payload: estimaciones });
      })
      .catch(alert);
  };

  const clearEstimacion = () => {
    dispatch({ type: CLEAR_ESTIMACION });
  };

  return (
    <EstimacionesContext.Provider
      value={{
        ...state,
        editEstimacion,
        postEstimacion,
        clearEstimacion,
        deleteEstimacion,
        selectEstimacion,
        createEstimacion,
        toggleEstimaciones,
        setPropiedadEstimacion,
        getEstimacionesContrato,
        postEstimacionGeneradores,
      }}
    >
      {children}
    </EstimacionesContext.Provider>
  );
};
