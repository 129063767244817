import React from "react";

const DropDown = ({
  title,
  selected,
  modifier,
  btnOutlined,
  children,
  items,
}) => {
  return (
    <div className="dropdown">
      <div data-bs-toggle="dropdown" aria-expanded="false">
        <button
          className={`btn w-100 ${
            btnOutlined ? "btn-outline-light" : "btn-light"
          } dropdown-toggle`}
          type="button"
        >
          {title}
        </button>
      </div>

      <ul
        className="dropdown-menu p-0 border border-primary"
        style={{ minWidth: "190px" }}
      >
        {children}
        {items?.map((item) => (
          <button
            key={item.value}
            onClick={() => modifier(item.value)}
            className={`w-100 btn btn-${
              selected === item.value ? item.className : "outline-dark"
            } rounded-0`}
          >
            {item.label} <i className={`${item.icon} me-2`}></i>
          </button>
        ))}
      </ul>
    </div>
  );
};

export default DropDown;
