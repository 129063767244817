import React, { createContext, useContext, useReducer } from "react";
import ModalReducer from "../reducers/ModalReducer";
import {
  SHOW_MODAL,
  SET_RESPONSE,
  HIDE_MODAL,
  MODAL_COMPONENT,
  SHOW_ALERT,
  SHOW_SUCCESS,
  CLEAR_ALERT,
  CLEAR_SUCCESS,
  CLEAR_MODAL,
} from "../types/modal";
import { hideModal, showModal } from "../utils";
import { CapacitorContext } from "./CapacitorContext";

const initialState = {
  show: false,
  content: "",
  callback: "",
  response: "",
  showAlert: false,
  alertContent: "",
};

export const ModalContext = createContext(initialState);

export const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ModalReducer, initialState);

  const { platform } = useContext(CapacitorContext);

  function confirm(content, callback) {
    dispatch({
      type: SHOW_MODAL,
      payload: { title: "Precaución", content, callback },
    });
    showModal();
  }

  function alert(content) {
    if (typeof content === "object") {
      content = content.toString();
    }
    if (!String(content).includes("401") && !String(content).includes("400")) {
      dispatch({ type: SHOW_ALERT, payload: content });
      setTimeout(() => dispatch({ type: CLEAR_ALERT }), 5000);
    }
  }

  function success(content) {
    dispatch({ type: SHOW_SUCCESS, payload: content });
    setTimeout(() => {
      dispatch({ type: CLEAR_SUCCESS });
    }, 3000);
  }

  function hideAlert() {
    dispatch({ type: CLEAR_ALERT });
  }

  function modalComponent(title, component, onClose, callback) {
    if (
      (title && title !== "" && title !== null) ||
      (component && component !== "" && component !== null)
    ) {
      dispatch({
        type: MODAL_COMPONENT,
        payload: { title, component, onClose, callback },
      });
      if (platform === "web") showModal();
    }
  }

  function setResponse(response) {
    dispatch({ type: SET_RESPONSE, payload: response });
    dispatch({ type: HIDE_MODAL });
  }

  function clear() {
    dispatch({ type: CLEAR_MODAL });
  }

  function clearModal() {
    if (platform === "web") {
      const modal = document.getElementById("modal");
      if (modal && modal !== null) {
        if (modal.classList.contains("show")) {
          hideModal();
        }
      }
    }
    dispatch({ type: CLEAR_MODAL });
  }

  function clearSuccess() {
    dispatch({ type: CLEAR_SUCCESS });
  }

  function clearAlert() {
    dispatch({ type: CLEAR_ALERT });
  }
  return (
    <ModalContext.Provider
      value={{
        ...state,
        confirm,
        success,
        hideAlert,
        alert,
        setResponse,
        clear,
        modalComponent,
        clearModal,
        clearSuccess,
        clearAlert,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
