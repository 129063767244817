import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../context/ModalContext";
import RevisionForm from "../revisiones/RevisionForm";
import DropDown from "../common/DropDown";
import { RevisionesContext } from "../../context/RevisionesContext";
import useProyecto from "../../hooks/useProyecto";
import { ItemsContext } from "../../context/ItemsContext";
import { CapacitorContext } from "../../context/CapacitorContext";

const ChecklistsRevisions = ({ idChecklist }) => {
  const { idProyecto } = useProyecto();
  const { platform } = useContext(CapacitorContext);
  const { modalComponent } = useContext(ModalContext);
  const { getItemsByRevision } = useContext(ItemsContext);
  const { revision, revisiones, getRevisiones, setCurrentRevision } =
    useContext(RevisionesContext);

  const [revDropdownTitle, setRevDropdownTitle] = useState("checklist");

  useEffect(() => {
    getRevisiones(idChecklist, idProyecto);
    return clearRevision;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto, idChecklist]);

  useEffect(() => {
    if (revision) {
      getItemsByRevision(revision.idRevision, idProyecto);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revision]);

  const clearRevision = () => {
    setCurrentRevision(null);
  };

  const handleRevisionModal = () => {
    if (platform === "web") {
      modalComponent(
        "Generar Revision",
        <RevisionForm idChecklist={idChecklist} idProyecto={idProyecto} />
      );
    }
  };

  const handleCurrentRevision = (revision) => {
    setCurrentRevision(revision);
    setRevDropdownTitle(`Revisión: ${revision.numero}`);
  };

  const handleChecklistBtn = () => {
    setCurrentRevision(null);
    setRevDropdownTitle("checklist");
  };

  const renderRevisionOptions = () => {
    const currentRevId = revision?.idRevision;
    return revisiones?.map((rev) => {
      const isActive = currentRevId === rev.idRevision;

      return (
        <button
          key={rev.idRevision}
          className={`w-100 btn rounded-0 btn-light ${
            isActive ? "text-primary" : null
          }`}
          onClick={() => handleCurrentRevision(rev)}
        >
          Revisión: {rev.numero}
        </button>
      );
    });
  };

  return (
    <DropDown title={revDropdownTitle} btnOutlined={false}>
      <button
        onClick={handleChecklistBtn}
        className={`w-100 btn rounded-0 btn-light
                    ${
                      revDropdownTitle === "checklist" ? "text-primary" : null
                    }`}
      >
        Checklist
      </button>
      {renderRevisionOptions()}
      <button
        className={`w-100 btn rounded-0 btn-primary`}
        onClick={handleRevisionModal}
      >
        Generar Revisión
      </button>
    </DropDown>
  );
};

export default ChecklistsRevisions;
