import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import {
  SET_ESTIMACIONVERSION,
  CREATE_ESTIMACIONVERSION,
  ESTIMACIONVERSIONES_RECEIVED,
  SET_PROPERTY_ESTIMACIONVERSION,
} from "../types/estimacionversiones";

const schema = {};

const EstimacionVersionesReducer = (state, { type, payload }) => {
  switch (type) {
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    case ESTIMACIONVERSIONES_RECEIVED:
      return { ...state, estimacionversiones: payload };
    case SET_ESTIMACIONVERSION:
      return { ...state, estimacionversion: payload };
    case CREATE_ESTIMACIONVERSION:
      return { ...state, estimacionversion: schema };
    case SET_PROPERTY_ESTIMACIONVERSION: {
      const { key, value } = payload;
      const estimacionversion = { ...state.estimacionversion };
      estimacionversion[key] = value;
      return { ...state, estimacionversion };
    }
    default:
      return { ...state };
  }
};

export default EstimacionVersionesReducer;
