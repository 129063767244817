import React from 'react';

const Terms = () => {
    return (
        <div className='container py-3 h-100' style={{ overflowY: "scroll" }}>
            <h1>Aviso de Privacidad</h1>
            <h2>Introducción</h2>
            <p>En TDC de Monterrey S.A. de C.V. (en adelante "nosotros" o "nuestra empresa"), valoramos la privacidad de nuestros usuarios. Esta Política de Privacidad describe cómo recopilamos, utilizamos, y protegemos la información personal que nos proporciona a través de nuestra aplicación móvil "Arqvisor" (en adelante "la app").</p>
            <h3>Información Recopilada</h3>
            <h4>Datos Personales</h4>
            <p>Al utilizar la app, podemos recopilar los siguientes datos personales:</p>
            <ul>
                <li>Nombre: Para identificar al usuario.</li>
                <li>Correo Electrónico: Para comunicarnos con el usuario y gestionar el inicio de sesión.</li>
                <li>Identificador del Dispositivo: Para mejorar la funcionalidad y seguridad de la app.</li>
                <li>Foto y Video (opcionales): Para permitir a los usuarios subir evidencias fotográficas y de video relacionadas con el uso de la app.</li>
            </ul>
            <h3>Uso de la Información</h3>
            <p>Los datos personales recopilados son utilizados exclusivamente para las siguientes finalidades:</p>
            <ul>
                <li>Inicio de Sesión: Permitir el acceso seguro a la app.</li>
                <li>Subir Información: Facilitar la carga de fotos y videos como evidencias dentro de la app.</li>
                <li>Notificaciones: Enviar notificaciones relevantes sobre el uso de la app.</li>
            </ul>
            <h3>Rastreo de Terceros</h3>
            <p>Arqvisor no utiliza ningún tipo de rastreo de terceros. Nos comprometemos a no compartir ni vender la información personal de nuestros usuarios a terceros para fines de mercadotecnia.</p>
            <h3>Seguridad de los Datos</h3>
            <p>Implementamos medidas de seguridad técnicas y organizativas adecuadas para proteger la información personal de nuestros usuarios contra el acceso no autorizado, la alteración, divulgación o destrucción de la información.</p>
            <h3>Cambios en la Política de Privacidad</h3>
            <p>Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. Cualquier cambio será comunicado a través de la app y/o mediante el correo electrónico proporcionado por el usuario.</p>
            <h3>Contacto</h3>
            <p>Si tiene alguna pregunta o inquietud sobre esta Política de Privacidad, puede contactarnos a través del siguiente correo electrónico: direccion@tdcdemonterrey.com.</p>
            <p>Al utilizar Arqvisor, usted acepta los términos descritos en esta Política de Privacidad.</p>
        </div>
    )
}
export default Terms;