import { RETRASOS_RECEIVED, POST_RETRASO } from "../actions/types";

const INITIAL_STATE = {
  retrasos: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case RETRASOS_RECEIVED:
      return { ...state, retrasos: payload };
    case POST_RETRASO:
      return { ...state, retrasos: [...state.retrasos, payload] };
    default:
      return state;
  }
};
