import React, { useReducer, createContext, useContext } from "react";
import {
  SET_PRECIARIO,
  CREATE_PARTIDA_PRECIARIO,
  DELETE_PARTIDA_PRECIARIO,
  SEARCH_PARTIDAS_PRECIARIO,
  PARTIDAS_PRECIARIO_RECIBIDO,
  SET_PROPERTY_PARTIDA_PRECIARIO,
} from "../types/partidasContrato";
import PartidasPreciarioService from "../services/PartidasPreciarioService";
import PartidasContratoReducer from "../reducers/PartidasContratoReducer";
import { HIDE_SPINNER, SHOW_SPINNER } from "../actions/types";
import { ModalContext } from "./ModalContext";

const initialState = {
  spinner: false,
  partidas: null,
  preciario: null,
};

export const PartidasContratoContext = createContext(initialState);

export const PartidasContratoProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PartidasContratoReducer, initialState);

  const { success, alert } = useContext(ModalContext);

  const getPartidasPreciario = (idProyecto) => {
    PartidasPreciarioService.getPartidasPreciario(idProyecto).then((res) => {
      const { partidas } = res.data;
      dispatch({ type: PARTIDAS_PRECIARIO_RECIBIDO, payload: partidas });
    });
  };

  const getPartidasContrato = (idProyecto, idContrato) => {
    PartidasPreciarioService.getPartidasContrato(idProyecto, idContrato).then(
      (res) => {
        const { partidas } = res.data;
        dispatch({ type: PARTIDAS_PRECIARIO_RECIBIDO, payload: partidas });
      }
    );
  };

  const setPartidas = (partidas) => {
    dispatch({ type: PARTIDAS_PRECIARIO_RECIBIDO, payload: partidas });
  };
  const setPreciario = (preciario) => {
    dispatch({ type: SET_PRECIARIO, payload: preciario });
  };

  const clearPreciario = () => {
    dispatch({ type: SET_PRECIARIO, payload: null });
  };

  const createPartidaPreciario = () => {
    dispatch({ type: CREATE_PARTIDA_PRECIARIO });
  };

  const searchPartidasPreciario = (query) => {
    dispatch({ type: SEARCH_PARTIDAS_PRECIARIO, payload: query });
  };

  const setPropiedadPartida = (idPartidaContrato, key, value) => {
    dispatch({
      type: SET_PROPERTY_PARTIDA_PRECIARIO,
      payload: { idPartidaContrato, key, value },
    });
  };

  const deletePartida = (idPartida) => {
    dispatch({ type: DELETE_PARTIDA_PRECIARIO, payload: idPartida });
  };

  const postPartidasPreciario = (
    idProyecto,
    idContrato,
    partidas,
    callback
  ) => {
    dispatch({ type: SHOW_SPINNER });
    PartidasPreciarioService.postPartidasPreciario(
      idProyecto,
      idContrato,
      partidas
    )
      .then(() => {
        dispatch({ type: HIDE_SPINNER });
        getPartidasContrato(idProyecto, idContrato);
        success("Partidas guardadas con éxito");
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const postPreciarioFile = (idProyecto, idContrato, file) => {
    dispatch({ type: SHOW_SPINNER });
    const formData = new FormData();
    formData.append("adjunto", file);
    PartidasPreciarioService.postPreciario(idProyecto, idContrato, formData)
      .then(() => {
        getPartidasContrato(idProyecto, idContrato);
        success("Preciario cargado con éxito.");
        dispatch({ type: HIDE_SPINNER });
      })
      .catch(alert);
  };

  return (
    <PartidasContratoContext.Provider
      value={{
        ...state,
        setPartidas,
        setPreciario,
        deletePartida,
        clearPreciario,
        postPreciarioFile,
        setPropiedadPartida,
        getPartidasContrato,
        getPartidasPreciario,
        postPartidasPreciario,
        createPartidaPreciario,
        searchPartidasPreciario,
      }}
    >
      {children}
    </PartidasContratoContext.Provider>
  );
};
