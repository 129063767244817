//Alerts
export const GLOBAL_ALERTAS_RECEIVED = "GLOBAL_ALERTAS_RECEIVED";
export const SET_PROPERTY_TRIGGER = "SET_PROPERTY_TRIGGER";
export const CREATE_ALERT_TRIGGER = "CREATE_ALERT_TRIGGER";
export const DELETE_TRIGGER_ALERT = "DELETE_TRIGGER_ALERT";
export const SHOW_SPINNER_ALERTAS = "SHOW_SPINNER_ALERTAS";
export const HIDE_SPINNER_ALERTAS = "HIDE_SPINNER_ALERTAS";
export const SET_PROPERTY_ALERTA = "SET_PROPERTY_ALERTA";
export const CLEAR_SINGLE_ALERT = "CLEAR_SINGLE_ALERT";
export const TOGGLE_ALERT_CARD = "TOGGLE_ALERT_CARD";
export const ALERTAS_RECEIVED = "ALERTAS_RECEIVED";
export const CREATE_ALERTA = "CREATE_ALERTA";
export const CLEAR_ALERTS = "CLEAR_ALERTS";
export const CHECK_ALERTA = "CHECK_ALERTA";
