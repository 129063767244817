export const EDIT_ESTIMACION = "EDIT_ESTIMACION";
export const SAVE_ESTIMACION = "SAVE_ESTIMACION";
export const CLEAR_ESTIMACION = "CLEAR_ESTIMACION";
export const CREATE_ESTIMACION = "CREATE_ESTIMACION";
export const APPEND_ESTIMACION = "APPEND_ESTIMACION";
export const SELECT_ESTIMACION = "SELECT_ESTIMACION";
export const TOGGLE_ESTIMACIONES = "TOGGLE_ESTIMACIONES";
export const ESTIMACIONES_RECIBIDAS = "ESTIMACIONES_RECIBIDAS";
export const SET_PROPERTY_ESTIMACION = "SET_PROPERTY_ESTIMACION";
export const EDIT_CONCEPTOS_ESTIMACION = "EDIT_CONCEPTOS_ESTIMACION";
export const SAVE_CONCEPTOS_ESTIMACION = "SAVE_CONCEPTOS_ESTIMACION";
export const SET_CONCEPTO_PRECIOS = "SET_CONCEPTO_PRECIOS";
