import React from "react";
import { Link } from "react-router-dom";

const MenuTab = ({ tab, handler, isOpen, selected }) => {
  const getTitle = () => {
    if (tab.component) return tab.component;
    if (tab.title) {
      return <span className="ps-3">{tab.title}</span>;
    }
  };

  const renderIcon = () => {
    if (tab.icon) {
      return (
        <i className={`${tab.icon} ${selected ? "text-white" : "text-dark"}`} />
      );
    }
    //return <CustomIcon src={tab.src} />;
  };

  return (
    <Link
      to={tab.link}
      onClick={handler}
      className={`btn text-center mb-3 menu-tab${isOpen ? "-open" : ""} ${
        selected ? "btn-primary" : ""
      }`}
    >
      <div
        className={`d-flex h-100 align-items-center ${
          isOpen ? "" : "justify-content-center"
        }`}
      >
        {renderIcon()}
        {isOpen && getTitle()}
      </div>
    </Link>
  );
};

export default MenuTab;
