import React, { useContext, useEffect } from "react";
import { UserDevicesContext } from "../context/UserDevicesContext";
import DeviceForm from "../components/devices/DeviceForm";
import { CapacitorContext } from "../context/CapacitorContext";
import { findUserDeviceIndexByToken } from "../utils/user_devices";
import { ModalContext } from "../context/ModalContext";

const usePushNotifications = () => {
  const { modalComponent } = useContext(ModalContext);
  const { platform, registered, device_token, registerNotifications } =
    useContext(CapacitorContext);
  const { user_devices, setUserDevice, getUserDevices } =
    useContext(UserDevicesContext);

  useEffect(() => {
    getUserDevices();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    registerNotifications(platform);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform]);

  useEffect(() => {
    if (Array.isArray(user_devices) && device_token !== null) {
      handleUserDevice(user_devices, device_token);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device_token, user_devices]);

  const handleEditDevice = (current_device) => {
    setUserDevice(current_device);
    modalComponent("Register Device", <DeviceForm />);
  };

  const handleUserDevice = (user_devices, device_token) => {
    const device_index = findUserDeviceIndexByToken(user_devices, device_token);
    if (device_index === -1) {
      const current_device = user_devices[device_index];
      handleEditDevice(current_device);
    }
  };

  return { registered, device_token };
};

export default usePushNotifications;
