import React, { useContext } from "react";
import FilaTotales from "./FilaTotales";
import ConceptoExtra from "./ConceptoExtra";
import PartidaPreciario from "./PartidaPreciario";
import ConceptoExtraForm from "./ConceptoExtraForm";
import PreciarioHeader from "../preciario/PreciarioHeader";
import PartidaPreciarioForm from "./PartidaPreciarioForm";
import usePreciario from "../../hooks/usePreciario";
import { AppConfigContext } from "../../context/AppConfigContext";
import { EstimacionesContext } from "../../context/EstimacionesContext";

const PreciarioTable = () => {
  const { view, editMode, partidas, conceptos } = usePreciario();
  const { estimacion, toggleEstimaciones } = useContext(EstimacionesContext);
  const { authorize_estimates } = useContext(AppConfigContext);

  const showEstimaciones = view === "estimaciones";

  function renderPartidas() {
    if (Array.isArray(partidas)) {
      //Edit Partidas
      if (editMode === "partidas") {
        return partidas.map((partida) => (
          <PartidaPreciarioForm
            partida={partida}
            key={partida.idPartidaContrato}
          />
        ));
      }

      let components = [];

      components = partidas
        .filter(({ extra }) => !extra || extra === null)
        .map((partida) => (
          <PartidaPreciario
            partida={partida}
            estimacion={estimacion}
            key={partida.idPartidaContrato}
            showEstimaciones={showEstimaciones}
          />
        ))

      const partidasExtra = partidas.filter(({ extra }) => extra && extra !== null);

      if(partidasExtra.length > 0) {
        const extraComponents = partidasExtra.map((partida) => (
          <PartidaPreciario
            partida={partida}
            estimacion={estimacion}
            key={partida.idPartidaContrato}
            showEstimaciones={showEstimaciones}
          />
        ));
        components = [...components, ...extraComponents];
      }
      components.push(<FilaTotales key="totales" conceptos={conceptos} />);
      return components;
    }
  }

  return (
    <div className="table-responsive border th-border-0 align-items-top tabla-preciario">
      <table className="table mb-0">
        <PreciarioHeader
          editMode={editMode}
          showEstimaciones={showEstimaciones}
          toggleEstimaciones={toggleEstimaciones}
          authorize_estimates={authorize_estimates}
        />
        <tbody>{renderPartidas()}</tbody>
      </table>
    </div>
  );
};

export default PreciarioTable;
