import React, { useState } from "react";

const AdjuntoForm = ({ initialValue, handleSubmit, handleCancel }) => {
  const [renameTo, setRenameTo] = useState(initialValue);

  return (
    <div className="conainer-fliud">
      <input
        type="text"
        className="form-control mb-3"
        value={renameTo}
        onChange={(e) => setRenameTo(e.target.value)}
      />
      <div className="row">
        <div className="col-6">
          <button onClick={handleCancel} className="btn w-100 text-muted">Cancelar</button>
        </div>
        <div className="col-6">
          <button onClick={() => handleSubmit(renameTo)} className="btn w-100 btn-primary">Guardar</button>
        </div>
      </div>
    </div>
  );
};

export default AdjuntoForm;
