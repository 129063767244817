import React, { useEffect, useContext, useState } from "react";
import { ProveedoresContext } from "../context/ProveedoresContext";
import { useParams } from "react-router-dom";
import ProveedoresHandler from "../utils/proveedores/ProveedoresHandler";

const SingleProveedor = () => {
  const [providerDetails, setProviderDetails] = useState({});
  const { getSingleProveedor, proveedor } = useContext(ProveedoresContext);
  const { idProveedor } = useParams();

  const { handleEditProveedor, handleDeleteProveedor } = ProveedoresHandler();

  const providerImageStyles = {
    backgroundImage:
      'url("https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Liberty_Memorial_043.jpg/1280px-Liberty_Memorial_043.jpg")',
    height: "100%",
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: "10px",
    minHeight: "100px",
  };

  useEffect(() => {
    if (idProveedor) {
      getSingleProveedor(idProveedor);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProveedor]);

  useEffect(() => {
    setProviderDetails(proveedor);
  }, [proveedor]);

  return (
    <div className="card shadow p-3 d-flex flex-row mb-3 w-75">
      <div className="me-3" style={{ width: "35%" }}>
        <div style={providerImageStyles} />
      </div>
      <div style={{ width: "80%" }}>
        <div className="w-100 d-flex justify-content-between mb-3">
          <h5 className="bold">{providerDetails?.nombre}</h5>
          <h5 className="bold">{providerDetails?.idProveedor}</h5>
        </div>
        <div className="d-flex">
          <div className="me-5">
            <div className="d-flex">
              <p
                style={{ maxWidth: "80px", width: "80px" }}
                className="bold me-3"
              >
                Contacto
              </p>
              <p className="mb-0">{providerDetails?.contact}</p>
            </div>
            <div className="d-flex">
              <p
                style={{ maxWidth: "80px", width: "80px" }}
                className="bold me-3"
              >
                Correo
              </p>
              <p className="mb-0">{providerDetails?.email}</p>
            </div>
            <div className="d-flex">
              <p
                style={{ maxWidth: "80px", width: "80px" }}
                className="bold me-3"
              >
                Teléfono
              </p>
              <p className="mb-0">{providerDetails?.numeroTelefono}</p>
            </div>
            <div className="d-flex">
              <p
                style={{ maxWidth: "80px", width: "80px" }}
                className="bold me-3"
              >
                rfc
              </p>
              <p className="mb-0">{providerDetails?.rfc}</p>
            </div>
          </div>

          <div className="ms-3">
            <div className="d-flex">
              <p
                style={{ maxWidth: "80px", width: "80px" }}
                className="bold me-3"
              >
                Domicilio
              </p>
              <p className="mb-0" style={{ maxWidth: "300px" }}>
                {providerDetails?.domicilio}
              </p>
            </div>
            <div className="d-flex">
              <p
                style={{ maxWidth: "80px", width: "80px" }}
                className="bold me-3"
              >
                C.P
              </p>
              <p className="mb-0">{providerDetails?.codigoPostal}</p>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button
            className="btn me-1"
            onClick={() => handleEditProveedor(proveedor)}
          >
            <i className="fa fa-edit text-primary"></i>
          </button>
          <button
            className="btn"
            onClick={() => handleDeleteProveedor(idProveedor, true)}
          >
            <i className="fa fa-trash text-primary"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleProveedor;
