import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";

const ObraDetails = ({ obra, open }) => {
  const { S3_ENDPOINT } = useContext(AppConfigContext);
  
  return (
    <div className="container-fluid obra-details d-flex justify-content-center" style={{ maxWidth: 150 }}>
      <img
        src={`${S3_ENDPOINT}/files/adjuntos/${obra.idAdjunto}.${obra.tipo_adjunto}`}
        className={`profile-image ${open ? "large" : "small"}`}
        alt=""
        style={{ borderRadius: "50%" }}
      />
    </div>
  );
};

export default ObraDetails;
