import React from "react";

const CheckBox = ({ args, checked, disabled, modifier }) => {
  //Modifier: An action to make changes in the parent component or through Redux
  function toggle() {
    handleModifier(!checked);
  }

  function handleModifier(value) {
    if (modifier) {
      if (args !== undefined) {
        if (Array.isArray(args)) {
          modifier(...args, value);
        } else modifier(args, value);
      } else modifier(value);
    }
  }

  return (
    <button
      className={
        (checked ? "checked" : "unchecked") + "  checkbox no-padding me-2 mt-1"
      }
      disabled={disabled}
      onClick={toggle}
    >
      <i className={`fa-${checked ? "check fas text-white": "square far text-primary"}`}></i>
    </button>
  );
};

export default CheckBox;
