import React, { useContext, useEffect } from "react";
import BitacoraItem from "../components/checklists/BitacoraIItem";
import { ChecklistContext } from "../context/ChecklistContext";
import { ItemsContext } from "../context/ItemsContext";
import { ModalContext } from "../context/ModalContext";
import ItemForm from "../components/items/ItemForm";
import useProyecto from "./useProyecto";
import { MenuContext } from "../context/MenuContext";
import PlantillaForm from "../components/plantillas/PlantillaForm";

const useChecklists = () => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const checklistsContext = useContext(ChecklistContext);
  const { setSelected } = useContext(MenuContext);

  const { setItem, postItem, createItem, getItemsChecklist } =
    useContext(ItemsContext);

  const { estado, checklist, setExpandAll, getSingleChecklist } =
    checklistsContext;

  const idChecklist =
    checklist && checklist !== null ? checklist.idChecklist : null;
  const { idProyecto } = useProyecto();

  useEffect(() => {
    if (estado !== "todo") {
      setExpandAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estado]);

  useEffect(() => {
    if (checklist && checklist !== null) {
      setSelected({ title: checklist.nombre });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklist]);

  const fetchChecklist = (idChecklist) => {
    getSingleChecklist(idProyecto, idChecklist);
    getItemsChecklist(idProyecto, idChecklist);
  };

  const handlePlantilla = () => {
    modalComponent(
      "Guardar Plantilla",
      <PlantillaForm idProyecto={idProyecto} tipo={"checklist"} />
    );
  };

  const handleCreateItem = (idChecklistSection) => {
    createItem();
    modalComponent(
      "Agregar Item",
      <ItemForm
        postItem={postItem}
        idProyecto={idProyecto}
        idChecklist={idChecklist}
        handleCancel={clearModal}
        idChecklistSection={idChecklistSection}
      />
    );
  };

  const handleComments = (item) => {
    setItem(item);
    modalComponent("Comentarios", <BitacoraItem idProyecto={idProyecto} />);
  };

  return {
    ...checklistsContext,
    fetchChecklist,
    handleComments,
    handlePlantilla,
    handleCreateItem,
  };
};

export default useChecklists;
