import React from "react";

const UploadSheet = ({ file, setFile }) => {

  const renderInput = () => {
    if (!file || file === null || file === undefined) {
      return (
        <input
          type="file"
          accept="spreadsheet"
          className="form-control"
          onChange={(e) => setFile(e.target.files[0])}
        />
      );
    }
    return (
      <div className="container-fluid px-0">
        <h5 className="bold">Archivo Cargado</h5>
        <p>{file?.name}</p>
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={() => setFile(null)}
        >
          <i className="fa fa-edit me-1"></i>Cambiar Archivo
        </button>
      </div>
    );
  };

  return <div className="card p-3 shadow mb-3">{renderInput()}</div>;
};

export default UploadSheet;
