import React, { useContext, useEffect } from "react";
import { EstimacionVersionesContext } from "../context/EstimacionVersionesContext";
import useProyecto from "../hooks/useProyecto";
import usePreciario from "../hooks/usePreciario";
import StatusBadge from "../components/common/StatusBadge";
import { ModalContext } from "../context/ModalContext";
import { Link, useNavigate, useParams } from "react-router-dom";

const EstimacionVersiones = () => {
  const navigate = useNavigate();
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { estimacionversiones, getEstimacionVersiones } = useContext(
    EstimacionVersionesContext
  );

  const { idContrato, idEstimacion } = useParams();
  const { idProyecto, permiso } = useProyecto();
  const { estimacion } = usePreciario();

  useEffect(() => {
    getEstimacionVersiones(idProyecto, idContrato, idEstimacion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateVersion = () => {
    modalComponent(
      "Nueva Version",
      <div>
        <p>¿Estás seguro que deseas crear una nueva versión?</p>
        <p>
          Una nueva versión reemplazará a la anterior. Esta acción NO puede
          deshacerse.
        </p>
        <div className="row">
          <div className="col-6">
            <button onClick={clearModal} className="btn text-muted w-100">
              Cancelar
            </button>
          </div>
          <diov className="col-6">
            <button
              onClick={() => {
                navigate(`./subir`);
                clearModal();
              }}
              className="btn btn-primary w-100"
            >
              Crear
            </button>
          </diov>
        </div>
      </div>
    );
  };

  const renderVersiones = () => {
    if (Array.isArray(estimacionversiones)) {
      if (estimacionversiones.length === 0) {
        return <p>Aún no se han agregado versiones de esta estimación.</p>;
      }
      return estimacionversiones.map((version) => (
        <div key={version.idEstimacionVersion} className="col-12 col-md-6 col-xl-4 mb-3">
          <div className="card shadow py-4 px-3">
            <div className="row mx-0 align-items-center">
              <div className="col-10">
                <h3 className="h4">Version #{version.version}</h3>
                <div style={{ width: 150 }}>
                  <StatusBadge                    
                    text={version.estado}
                  />
                </div>
              </div>
              <div className="col-2 text-end">
                <Link to={`./${version.idEstimacionVersion}`}>
                  <i className="fa fa-chevron-right fa-2x text-primary" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  };

  return (
    <div className="container-fluid">
      <div className="row mb-3 px-0">
        <div className="col-12 col-md-9">
          <h1 className="h3">Versiones de Estimacion #{estimacion?.numero}</h1>
        </div>
        <div className="col-12 col-md-3 text-end">
          {permiso === "contratista" && (
            <button onClick={handleCreateVersion} className="btn btn-primary">
              <i className="fa fa-plus" />
            </button>
          )}
        </div>
      </div>
      <div className="row">{renderVersiones()}</div>
    </div>
  );
};

export default EstimacionVersiones;
