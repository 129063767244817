import React, { useContext, useEffect } from "react";
import { EstimacionesContext } from "../../context/EstimacionesContext";
import { ConceptosContext } from "../../context/ConceptosContext";
import EstimacionVersionHeader from "./EstimacionVersionHeader";
import BitacoraConcepto from "../conceptos/BitacoraConceptos";
import { ModalContext } from "../../context/ModalContext";
import { formatMonto, getValue } from "../../utils";
import useProyecto from "../../hooks/useProyecto";
import ConceptoPrecioAutorizadoForm from "../conceptos/ConceptoPrecioAutorizadoForm";
import { EstimacionVersionesContext } from "../../context/EstimacionVersionesContext";

const EstimacionVersionResumen = ({ idEstimacionVersion }) => {
  const { estimacion } = useContext(EstimacionesContext);
  const { estimacionversion } = useContext(EstimacionVersionesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { conceptos, getConceptosEstimacionVersion } =
    useContext(ConceptosContext);

  const { idProyecto, permiso } = useProyecto();

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idEstimacionVersion]);

  const fetchData = () => {
    getConceptosEstimacionVersion(idProyecto, idEstimacionVersion);
  }

  const handleCallback = () => {
    clearModal();
    fetchData();
  }

  const handleComments = (concepto) => {
    modalComponent(
      "Comentarios",
      <BitacoraConcepto
        idFolder={null}
        idConcepto={concepto.idConcepto}
        idEstimacionVersion={idEstimacionVersion}
      />
    );
  };

  const handleEditPrecio = (concepto) => {
    modalComponent(
      "Autorizar Precio",
      <ConceptoPrecioAutorizadoForm
        handleCallback={handleCallback}
        handleCancel={clearModal}
        concepto={concepto}
      />
    );
  };

  const renderPrecioControl = (concepto) => {
    if (concepto.extra && permiso === "finanzas" && estimacionversion.estado === "autorizada") {
      return (
        <div className="row mx-0">
          <div className="col-12 col-md-9 px-0">
            ${formatMonto(concepto.precio_control)}
          </div>
          <div className="col-12 col-md-3 px-0">
            <button onClick={() => handleEditPrecio(concepto)} className="btn btn-sm btn-outline-primary">
              <i className="fa fa-edit" />
            </button>
          </div>
        </div>
      );
    }
    return <span>${formatMonto(concepto.precio_control)}</span>;
  };

  const renderConceptos = () => {
    if (Array.isArray(conceptos)) {
      return conceptos.map((concepto) => (
        <tr key={concepto.idConcepto}>
          <td style={{ minWidth: 150 }}>{concepto.clave}</td>
          <td>{concepto.unidad}</td>
          <td>{concepto.cantidad}</td>
          <td>{concepto.estimadoAnterior}</td>
          <td style={{ minWidth: 150 }}>${formatMonto(concepto.precio)}</td>
          {getValue(estimacion, "tipo") === "extra" && (
            <td style={{ minWidth: 200 }}>{renderPrecioControl(concepto)}</td>
          )}
          <td>{concepto.estimado}</td>
          <td>{concepto.supervisado}</td>
          <td>{concepto.autorizado}</td>
          <td>${formatMonto(concepto.importe)}</td>
          <td>
            <button
              onClick={() => {
                handleComments(concepto);
              }}
              className="btn btn-sm btn-outline-primary ms-2"
            >
              <i className="fa fa-comment" />
            </button>
          </td>
        </tr>
      ));
    }
  };

  return (
    <div className="estimacion-resumen card px-md-4 px-2 py-3 shadow mb-3">
      <EstimacionVersionHeader title="HOJA RESUMEN GENERADOR" view="portada" />
      <div className="row my-3">
        <div className="table-responsive px-0">
          <table className="table">
            <thead>
              <tr className="bold text-center small bg-light">
                <td style={{ width: 100 }}>CLAVE</td>
                <td>UNIDAD</td>
                <td style={{ width: 100 }}>CANTIDAD CONTRATADA</td>
                <td style={{ width: 100 }}>CANTIDAD ACUMULADA ANTERIOR</td>
                <td>
                  {getValue(estimacion, "tipo") === "extra"
                    ? "PRECIO CONTRATISTA"
                    : "PRECIO"}
                </td>
                {getValue(estimacion, "tipo") === "extra" && (
                  <td>PRECIO CONTROL</td>
                )}
                <td>CONTRATISTA</td>
                <td>SUPERVISION</td>
                <td>OBRAS</td>
                <td>IMPORTE</td>
                <td>ACCIONES</td>
              </tr>
            </thead>
            <tbody>{renderConceptos()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EstimacionVersionResumen;
