import React from "react";

const ConceptoProgressBar = ({ idPartida, programado, real, handleClick }) => {
  return (
    <div
      className="progress mw-100 position-relative"
      role="progressbar"
      aria-label="Basic example"
      aria-valuenow="75"
      aria-valuemin="0"
      aria-valuemax="100"
      style={{ height: 25 }}
      onClick={() => handleClick(idPartida)}
    >
      <div
        className="progress-bar programado-gradiente position-absolute"
        style={{ width: `${programado}%`, height: 25 }}
      ></div>
      <div
        className="progress-bar real-gradiente position-absolute"
        style={{ width: `${real}%`, height: 25 }}
      ></div>
    </div>
  );
};
export default ConceptoProgressBar;
