import {
  SET_CONCEPTOSUBICAICON,
  CREATE_CONCEPTOSUBICACION,
  CONCEPTOSUBICAICONS_RECEIVED,
  SET_PROPERTY_CONCEPTOSUBICAICON,
  REMOVE_CONCEPTOUBICACION,
} from "../types/conceptosubicaciones";

const conceptoUbicacionSchema = (num) => ({
  idConceptoUbicacion: `nuevo-${num}`,
  eje: "",
  tipo: "",
  tramo: "",
  nivel: "",
  lote: "",
  alto: "",
  ancho: "",
  largo: "",
});

const conceptosUbicacionsReducer = (state, { type, payload }) => {
  switch (type) {
    case REMOVE_CONCEPTOUBICACION: {
      const conceptosubicaciones = [...state.conceptosubicaciones];
      const index = conceptosubicaciones.findIndex(
        ({ idConceptoUbicacion }) => idConceptoUbicacion === payload
      );
      if (index !== -1) {
        conceptosubicaciones.splice(index, 1);
      }
      return { ...state, conceptosubicaciones };
    }
    case CONCEPTOSUBICAICONS_RECEIVED:
      return { ...state, conceptosubicaciones: payload };
    case SET_CONCEPTOSUBICAICON:
      return { ...state, conceptoubicacion: payload };
    case CREATE_CONCEPTOSUBICACION: {
      let conceptosubicaciones = state.conceptosubicaciones;
      if (!Array.isArray(conceptosubicaciones)) {
        conceptosubicaciones = [];
      }
      conceptosubicaciones = [...conceptosubicaciones];
      const newConcepto = conceptoUbicacionSchema(
        state.conceptosubicaciones.length + 1
      );
      return {
        ...state,
        conceptosubicaciones: [...conceptosubicaciones, newConcepto],
      };
    }
    case SET_PROPERTY_CONCEPTOSUBICAICON: {
      const conceptosubicaciones = [...state.conceptosubicaciones];
      const currentConceptoIndex = conceptosubicaciones.findIndex(concepto => concepto.idConceptoUbicacion === payload.idConceptoUbicacion);
      if(currentConceptoIndex !== -1) {
        conceptosubicaciones[currentConceptoIndex][payload.key] = payload.value;
      }
      return { ...state, conceptosubicaciones };
    }
    default:
      return { ...state };
  }
};

export default conceptosUbicacionsReducer;
