import React, { useContext } from "react";
import PreviewPartida from "./PreviewPartida";
import { PartidasContratoContext } from "../../context/PartidasContratoContext";

const PreviewPartidasTable = ({
  idProyecto,
  idContrato,
  handleSubmitCallback,
}) => {
  const { spinner, partidas, postPartidasPreciario } = useContext(
    PartidasContratoContext
  );

  const handleSubmitPartidas = () => {
    postPartidasPreciario(
      idProyecto,
      idContrato,
      partidas,
      handleSubmitCallback
    );
  };

  const renderPartidas = () => {
    if (Array.isArray(partidas)) {
      let partidasRender = [...partidas];
      partidasRender = partidasRender.filter(
        (partida) => String(partida.idPartidaContrato)[0] !== "d"
      );
      return (
        <div className="card p-3 my-3 shadow">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr className="border bg-light bold py-2">
                  <th className="col">Clave</th>
                  <th className="col">Nombre</th>
                  <th className="col">Acciones</th>
                  <th className="col">¿Cronograma?</th>
                  <th className="col">Fecha Inicio</th>
                  <th className="col">Fecha Fin</th>
                </tr>
              </thead>
              <tbody>
                {partidasRender.map((partida) => (
                  <PreviewPartida
                    key={partida.idPartidaContrato}
                    partida={partida}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <div className="container-fluid px-0 my-3">
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={handleSubmitPartidas}
              disabled={spinner}
            >
              <i className="fa fa-save me-1"></i>{" "}
              {spinner ? (
                <div className="spinner-border"></div>
              ) : (
                "Guardar Partidas"
              )}
            </button>
          </div>
        </div>
      );
    }
  };
  return <div>{renderPartidas()}</div>;
};

export default PreviewPartidasTable;
