import React from 'react'
import ProductInventoryLevel from './ProductInventoryLevel';
import ProductAnalyticsPieGraph from './ProductAnalyticsPieGraph';
import ProductAnalyticsConsumptionGraph from './ProductAnalyticsConsumptionGraph';

const ProductAnalytics = () => {
    return (

        <>
            <h2 className="mb-3 bold">Reportes</h2>
            <div className='d-flex'>

                <div className='w-25 me-2'>
                    <p className='bold'>Nivel de inventario</p>
                    <ProductInventoryLevel />
                    <p className='bold'>Consumo por proyecto</p>
                    <ProductAnalyticsPieGraph />
                </div>

                <div className='w-75 ms-2'>
                    <p className='bold'>Consumo Programado vs. Real</p>
                    <ProductAnalyticsConsumptionGraph />
                </div>



            </div>
        </>
    );
}

export default ProductAnalytics;