import { useContext, useEffect, useState } from "react";
import { mainColor } from "../utils/colors";
import { setupColor } from "../utils/appconfig";
import { AuthContext } from "../context/AuthContext";
import { ProyectosContext } from "../context/ProyectosContext";
import { AppConfigContext } from "../context/AppConfigContext";
import { UsuariosContext } from "../context/UsuariosContext";

const useProyecto = () => {
  const [permiso, setPermiso] = useState(null);

  const { user } = useContext(AuthContext);
  const appconfig = useContext(AppConfigContext);
  const { users, getUsuariosProyecto } = useContext(UsuariosContext);
  const { proyecto, getSingleProyecto, clearSingleProyecto } =
    useContext(ProyectosContext);

  useEffect(() => {
    let permiso = null;
    if (user && user !== null) {
      if (user.permiso === "admin") setPermiso(user.permiso);
    }
    if (proyecto && proyecto !== null && permiso === null) {
      setPermiso(proyecto.permiso);
    }
  }, [user, proyecto]);

  const fetchProyecto = (idProyecto) => {
    getUsuariosProyecto(proyecto && proyecto !== null ? proyecto.idProyecto : idProyecto);
    getSingleProyecto(proyecto && proyecto !== null ? proyecto.idProyecto : idProyecto);
  };


  useEffect(() => {
    if (proyecto?.color && proyecto?.color !== null) {
      setupColor(proyecto.color);
    } else if (appconfig?.primary && appconfig?.primary !== null) {
      setupColor(appconfig.primary);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proyecto]);

  const color =
    proyecto?.color && proyecto?.color !== null ? proyecto?.color : mainColor;

  return {
    color,
    permiso,
    proyecto,
    fetchProyecto,
    usuarios: users,
    clearSingleProyecto,
    idProyecto: proyecto !== null ? proyecto.idProyecto : null,
  };
};

export default useProyecto;
