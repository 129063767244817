import {
  EDIT_ESTIMACION,
  CREATE_ESTIMACION,
  SELECT_ESTIMACION,
  TOGGLE_ESTIMACIONES,
  ESTIMACIONES_RECIBIDAS,
  SET_PROPERTY_ESTIMACION,
  CLEAR_ESTIMACION,
} from "../types/estimaciones";

const getPrevious = (partidas, conceptos, conceptosExtra) => {
  let prevConceptos = conceptos;
  let prevPartidas = partidas;
  let prevExtras = conceptosExtra;
  if (prevConceptos === null || !prevConceptos) prevConceptos = [];
  if (prevPartidas === null || !prevPartidas) prevPartidas = [];
  if (prevExtras === null || !prevExtras) prevExtras = [];
  prevConceptos = JSON.parse(JSON.stringify([...prevConceptos]));
  prevPartidas = JSON.parse(JSON.stringify([...prevPartidas]));
  prevExtras = JSON.parse(JSON.stringify([...prevExtras]));
  return { prevConceptos, prevPartidas, prevExtras };
};

const EstimacionesReducer = (state, { type, payload }) => {
  switch (type) {
    //Estimaciones
    case SELECT_ESTIMACION:
      const { estimacion, conceptos } = payload;
      let currentConceptos =
        state.prevConceptos !== null ? state.prevConceptos : state.conceptos;
      //Initialize tu Array
      if (currentConceptos === null || !currentConceptos) {
        currentConceptos = [];
      }
      //Deep clone of conceptos
      currentConceptos = JSON.parse(JSON.stringify([...currentConceptos]));
      let conceptosEstimacion = JSON.parse(
        JSON.stringify([...currentConceptos])
      );
      //Initialize tu Array
      if (conceptosEstimacion === null || !conceptosEstimacion) {
        conceptosEstimacion = [];
      }
      //Append estimate information
      conceptosEstimacion = conceptosEstimacion.map((concepto) => {
        let conceptoEstimado = conceptos.find(
          (conceptoEst) => conceptoEst.idConcepto === concepto.idConcepto
        );
        if (conceptoEstimado) {
          concepto = {
            ...concepto,
            ...conceptoEstimado,
            idEstimacion: estimacion.idEstimacion,
          };
        }
        return concepto;
      });
      return {
        ...state,
        estimacion,
        conceptos: conceptosEstimacion,
        ...getPrevious(state.partidas, state.conceptos),
      };
    case CLEAR_ESTIMACION:
      return {
        ...state,
        estimacion: null,
        partidas: state.prevPartidas,
        conceptos: state.prevConceptos,
      };
    case ESTIMACIONES_RECIBIDAS:
      return { ...state, estimaciones: payload };
    case TOGGLE_ESTIMACIONES:
      return { ...state, showEstimaciones: !state.showEstimaciones };
    case EDIT_ESTIMACION:
      let editMode = "estimacion";
      if (payload === null) editMode = null;
      return {
        ...state,
        editMode,
        estimacionEditada: payload,
      };
    case CREATE_ESTIMACION:
      const numero =
        state.estimaciones !== null ? state.estimaciones.length + 1 : 1;
      return {
        ...state,
        estimacionEditada: { idEstimacion: "nueva", fecha: "", numero },
      };
    case SET_PROPERTY_ESTIMACION:
      const estimacionEditada = { ...state.estimacionEditada };
      estimacionEditada[payload.key] = payload.value;
      return { ...state, estimacionEditada };
    default:
      return { ...state };
  }
};
export default EstimacionesReducer;
