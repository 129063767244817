import React, { useEffect, useState } from "react";
import Select from "react-select";

const SearchableSelect = ({
  items,
  itemText,
  itemValue,
  modifier,
  error,
  value,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const selectOptions = items?.map((item) => ({
    value: item[itemValue],
    label: typeof itemText === "function" ? itemText(item) : item[itemText],
  }));

  useEffect(() => {
    if (value && selectOptions) {
      const initialOption = selectOptions.filter((option) => {
        return option.value === value;
      });

      setSelectedItem(initialOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (selectedOption) => {
    setSelectedItem(selectedOption);
    if (modifier) {
      modifier(selectedOption);
    }
  };

  return (
    <div className="searchable-select">
      <Select
        options={selectOptions}
        value={selectedItem}
        onChange={handleChange}
        isSearchable
        placeholder="Selecciona una opción"
        className={"react-select-container"}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            border: "none",
            boxShadow: `${error ? "0 0 0 1.5px #dc3545" : "none"}`,
            backgroundColor: "rgb(248, 249, 250)",
            borderRadius: "0.375rem",
          }),
        }}
      />
    </div>
  );
};

export default SearchableSelect;
