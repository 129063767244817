import api from "./api";

const route = "/destajos";

export default {
  getDestajos: (idProyecto) => api.get(`${route}/${idProyecto}`),
  getSingleDestajo: (idProyecto, idDestajo) =>
    api.get(`${route}/${idProyecto}/destajo/${idDestajo}`),
  postDestajos: (idProyecto, destajos) =>
    api.post(`${route}/${idProyecto}`, { destajos }),
  postSingleDestajo: (idProyecto, destajo) =>
    api.post(`${route}/${idProyecto}/destajo/${destajo.idDestajo}`, {
      ...destajo,
    }),
  putSingleDestajo: (idProyecto, destajo) =>
    api.put(`${route}/${idProyecto}/destajo/${destajo.idDestajo}`, {
      ...destajo,
    }),
};
