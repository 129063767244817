import api from "./api";
import { getArgs } from "../utils";

const route = "/alertas";

export default {
  getAlertas: () => api.get(route),
  getAlertasProyecto: (idProyecto) => api.get(`${route}/${idProyecto}`),
  getItemAlertas: (idProyecto, filters) =>
    api.get(`${route}/${idProyecto}?${getArgs(filters)}`),
  postAlerta: (idProyecto, alerta) =>
    api.post(`${route}/${idProyecto}`, { ...alerta }),
  confirmAlerta: (idProyecto, idAlerta) =>
    api.put(`${route}/${idProyecto}/${idAlerta}/confirm`),
  deleteAlerta: (idProyecto, idAlerta) =>
    api.delete(`${route}/${idProyecto}/${idAlerta}`),
};
