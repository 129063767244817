import React, { useContext, useEffect, useRef } from "react";
import MenuTab from "./MenuTab";
import ObraDetails from "./ObraDetails";
import { MenuContext } from "../../context/MenuContext";
import { AuthContext } from "../../context/AuthContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import { AppConfigContext } from "../../context/AppConfigContext";

const Menu = ({ idProyecto, menuOpen, setMenuOpen  }) => {
  const menuRef = useRef(null);

  const { user } = useContext(AuthContext);

  const { proyecto } = useContext(ProyectosContext);

  const { navbar_logo, S3_ENDPOINT } = useContext(AppConfigContext);

  const { tabs, selected, setSelected } = useContext(MenuContext);

  useEffect(() => {
    //getAlertas(idProyecto);
  }, [idProyecto]);

  // const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const isNotHome = () => {
    if (idProyecto && idProyecto !== null) {
      return <MenuTab tab={{ title: "Inicio", link: "/" }} />;
    }
  };

  const renderTabs = () => {
    if (tabs) {
      return tabs
        .filter((tab) => !tab.permiso || tab.permiso === user.permiso)
        .map((tab, index) => (
          <MenuTab
            key={index}
            tab={tab}
            selected={tab.name === selected}
            handler={() => setSelected(tab)}
            isOpen={menuOpen}
          />
        ));
    }
  };

  const renderImage = () => {
    if (proyecto && window.location.pathname.includes("obra")) {
      if (proyecto.idAdjunto && proyecto.idAdjunto !== null) {
        return <ObraDetails open={menuOpen} obra={proyecto} />;
      }
    } else {
      return (
        <div className="container-fluid obra-details" style={{ maxWidth: 150 }}>
          <img
            src={`${S3_ENDPOINT}/files/adjuntos/${navbar_logo}`}
            className={`profile-image m-auto d-block ${
              menuOpen ? "large" : "small"
            }`}
            alt=""
            style={{ borderRadius: "50%" }}
          />
        </div>
      );
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  return (
    <div
      ref={menuRef}
      className={`d-flex flex-column shadow align-items-center sidebar bg-white ${
        menuOpen ? "open" : "closed"
      }`}
    >
      {renderImage()}
      <div className="w-75 px-2">
        <div
          className="row btn fw-normal d-flex px-2 my-3 align-items-center"
          onClick={handleDrawerToggle}
          tabIndex={0}
        >
          <div className="col-8 px-0 text-center">
            <i className="fa fa-bars"></i>
          </div>
          <div className="col-4 px-0">
            <i className={`fa fa-chevron-${menuOpen ? "left" : "right"}`} />
          </div>
        </div>
      </div>

      <div className="menu-content">
        {isNotHome()}
        {renderTabs()}
      </div>
    </div>
  );
};

export default Menu;
