import { Link } from "react-router-dom";
import React, { useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { AppConfigContext } from "../context/AppConfigContext";

const Recuperar = () => {
  const [email, setEmail] = useState("");

  const { spinner, recoverPassword } = useContext(AuthContext);

  const { bg_login, logo_login, S3_ENDPOINT } = useContext(AppConfigContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    recoverPassword(email);
  };

  return (
    <div
      className="container-fluid overflow-hidden"
      style={{ backgroundColor: "#f9f9fa" }}
    >
      <div className="row vh-100 align-items-center">
        <div
          className="col col-sm-12 col-md-6 vh-100 bg-image hide-mobile"
          style={{
            backgroundImage: `url("${S3_ENDPOINT}/files/adjuntos/${bg_login}")`,
          }}
        >
          <div className="row h-100 align-items-center">
            <img
              src={`${S3_ENDPOINT}/files/adjuntos/${logo_login}`}
              className="d-block w-100 m-auto pt-5"
              style={{ maxWidth: 700 }}
              alt="Logo"
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="container">
            <div className="login-container">
              <form onSubmit={handleSubmit} className="card shadow p-3">
                <div className="card-body">
                  <div className="row">
                    <h3>Restablecer Contraseña</h3>
                    <p className="mb-0">
                      Envíaremos un correo con las instrucciones
                    </p>
                    <label className="text-uppercase px-0 mb-1 mt-3 mb-3 h5 ps-3">
                      Correo electrónico
                    </label>
                    <div className="position-relative">
                      <input
                        type="text"
                        value={email}
                        className="form-control mb-3"
                        placeholder="ejemplo@ejemplo.com"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <button
                    className="btn btn-primary py-3 w-100"
                    disabled={spinner}
                  >
                    {spinner ? (
                      <div className="spinner-border"></div>
                    ) : (
                      "ENVIAR CORREO"
                    )}
                  </button>
                  <Link
                    to="/"
                    className="btn text-secondary fw-normal mt-3 no-decoration w-100"
                  >
                    ¿Recordaste tu contraseña?
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recuperar;
