import React, { useState } from "react";
import SearchBar from "../global/SearchBar";

const ProductFilters = () => {
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [selectedMin, setSelectedMin] = useState("");

  const [productFilterArgs, setProductFilterArgs] = useState({});

  const handleProjectChange = (event) => {
    const newSelectedProject = event.target.value;
    setProductFilterArgs((prevFilterArgs) => ({
      ...prevFilterArgs,
      project: newSelectedProject,
    }));
    setSelectedProject(newSelectedProject);
  };

  const handleWarehouseChange = (event) => {
    const newSelectedWarehouse = event.target.value;
    setProductFilterArgs((prevFilterArgs) => ({
      ...prevFilterArgs,
      warehouse: newSelectedWarehouse,
    }));
    setSelectedWarehouse(newSelectedWarehouse);
  };

  const handleMinimumChange = (event) => {
    const newSelectedMin = event.target.value;
    setProductFilterArgs((prevFilterArgs) => ({
      ...prevFilterArgs,
      min: newSelectedMin,
    }));
    setSelectedMin(newSelectedMin);
  };

  const handleSearchChange = (query) => {
    setProductFilterArgs((prevFilterArgs) => ({
      ...prevFilterArgs,
      query: query,
    }));
  };

  return (
    <div className="d-flex w-75">
      <SearchBar onSearch={handleSearchChange} />
      <select
        className="form-control bg-white ms-3 w-50 "
        value={selectedProject}
        onChange={handleProjectChange}
      >
        <option value={"1"}> Project 1</option>
        <option value={"2"}> Project 2</option>
      </select>
      <select
        className="form-control bg-white ms-3 w-50"
        value={selectedWarehouse}
        onChange={handleWarehouseChange}
      >
        <option value={"1"}> Almacen 1</option>
        <option value={"2"}> Almacen 2</option>
      </select>
      <select
        className="form-control bg-white ms-3 w-50"
        value={selectedMin}
        onChange={handleMinimumChange}
      >
        <option value={"true"}>True</option>
        <option value={"false"}>False</option>
      </select>
    </div>
  );
};

export default ProductFilters;
