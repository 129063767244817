import React, { useContext } from "react";
import { formatMonto } from "../../utils";
import { unidades } from "../../utils/conceptos";
import useProyecto from "../../hooks/useProyecto";
import { EstimacionVersionesContext } from "../../context/EstimacionVersionesContext";
import { CapacitorContext } from "../../context/CapacitorContext";

const ConceptoGenerador = ({
  concepto,
  disabled,
  handleEdit,
  handleComments,
}) => {
  const { estimacionversion } = useContext(EstimacionVersionesContext);
  const { platform } = useContext(CapacitorContext);
  const { permiso } = useProyecto();

  const renderCampos = () => {
    const keys = unidades[concepto.unidad]
      ? unidades[concepto.unidad]
      : ["lote"];
    return keys.map((key) => (
      <td className="border" key={key}>
        {concepto[key]}
      </td>
    ));
  };

  const renderEdit = () => {
    const estado =
      estimacionversion && estimacionversion !== null
        ? estimacionversion.estado
        : "";
    console.log(permiso, estado);
    if (
      (permiso === "contratista" && estado === "creada") ||
      (permiso === "supervisor" && estado === "enviada") ||
      (permiso === "obras" && estado === "conciliada")
    ) {
      return (
        <button
          disabled={disabled}
          className="btn btn-sm btn-outline-dark"
          onClick={() => handleEdit(concepto.idConceptoUbicacion)}
        >
          <i className="fa fa-edit" />
        </button>
      );
    }
  };

  const renderTotal = () => {
    const keys = unidades[concepto.unidad]
      ? unidades[concepto.unidad]
      : ["lote"];
    let total = 1.0;
    keys.forEach((key) => {
      total *= parseFloat(concepto[key]);
    });
    return formatMonto(total);
  };

  return (
    <tr className="border position-relative" style={{ overflowX: "scroll" }}>
      {platform !== "web" && (
        <td className="border">
          {renderEdit()}
          <button
            onClick={() => {
              handleComments(concepto);
            }}
            className="btn btn-sm btn-outline-primary mt-2 d-block"
          >
            <i className="fa fa-comment" />
          </button>
        </td>
      )}
      <td className="border">{concepto.eje}</td>
      <td className="border">{concepto.tramo}</td>
      <td className="border">{concepto.tipo}</td>
      <td className="border">{concepto.nivel}</td>
      {renderCampos()}
      <td className="border">{renderTotal()}</td>
      {platform === "web" && (
        <td className="border">
          {renderEdit()}
          <button
            onClick={() => {
              handleComments(concepto);
            }}
            className="btn btn-sm btn-outline-primary ms-2"
          >
            <i className="fa fa-comment" />
          </button>
        </td>
      )}
    </tr>
  );
};

export default ConceptoGenerador;
