import React, { useContext } from "react";
import {
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
} from "@ionic/react";
import moment from "moment";
import { formatMonto } from "../../utils";
import { ConceptosContext } from "../../context/ConceptosContext";

const EstimacionesConcepto = () => {
  const { concepto } = useContext(ConceptosContext);

  const renderEstimaciones = () => {
    const { estimaciones } = concepto;
    if (Array.isArray(estimaciones)) {
      return estimaciones.map((estimacion) => (
        <IonAccordion key={estimacion.numero} value={estimacion.idEstimacion}>
          <IonItem slot="header" color="light">
            <IonLabel>
              Estimación #{estimacion.numero} -{" "}
              {moment(estimacion.fecha).format("DD MMM YYYY")}
            </IonLabel>
          </IonItem>
          <div slot="content" className="container-fluid py-2">
            <div className="row align-items-center border">
              <div className="col bold bg-light py-2">Anterior</div>
              <div className="col">{formatMonto(concepto.anterior)}</div>
            </div>
            <div className="row align-items-center border">
              <div className="col bold bg-light py-2">Estimado</div>
              <div className="col">{formatMonto(concepto.estimado)}</div>
            </div>
            <div className="row align-items-center border">
              <div className="col bold bg-light py-2">Supervisado</div>
              <div className="col">{formatMonto(concepto.supervisado)}</div>
            </div>
            <div className="row align-items-center border">
              <div className="col bold bg-light py-2">Autorizado</div>
              <div className="col">{formatMonto(concepto.autorizado)}</div>
            </div>
            <div className="row align-items-center border">
              <div className="col bold bg-light py-2">Importe</div>
              <div className="col">${formatMonto(concepto.importe)}</div>
            </div>
          </div>
        </IonAccordion>
      ));
    }
  };

  return (
    <div>
      <h3 className="h5">Estimaciones</h3>
      <IonAccordionGroup>{renderEstimaciones()}</IonAccordionGroup>
    </div>
  );
};

export default EstimacionesConcepto;
