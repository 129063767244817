import React from "react";
import { IonItem, IonLabel, IonButton } from "@ionic/react";

const PartidaContratoMobile = ({ partida, collapsed, setPartida, toggleConceptos }) => {
  return (
    <IonItem key={partida.idPartidaContrato}>
      <IonLabel className="ion-text-wrap">
        <button
          slot="end"
          fill="light"
          className="btn btn-sm btn-primary me-1"
          onClick={() => toggleConceptos(partida.idPartidaContrato)}
        >
          <i className={`fa fa-chevron-${collapsed ? "down" : "up"}`}></i>
        </button>
        <span className="ps-2 bold">{partida.nombre}</span>
      </IonLabel>
      <IonButton
        slot="end"
        fill="light"
        onClick={() => setPartida(partida)}
        className="border mx-1 px-1 bg-white border-0 text-primary"
      >
        <i className="fa fa-info"></i>
      </IonButton>
    </IonItem>
  );
};

export default PartidaContratoMobile;
