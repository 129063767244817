import React, { Fragment, useContext, useEffect } from "react";
import TrabajosRetrasados from "../components/analiticas/TrabajosRetrasados";
import CurvaCriticaObra from "../components/analiticas/CurvaCriticaObra";
import GraficaFinanzas from "../components/finanzas/GraficaFinanzas";
import GraficaCostos from "../components/finanzas/GraficaCostos";
import { AppConfigContext } from "../context/AppConfigContext";
import { ProyectosContext } from "../context/ProyectosContext";
import { PartidasContext } from "../context/PartidasContext";
import AvanceObra from "../components/analiticas/AvanceObra";
import ChartCard from "../components/analiticas/ChartCard";
import GanttChart from "../components/charts/GanttChart";
import { MenuContext } from "../context/MenuContext";
import { getValue } from "../utils";
import moment from "moment";

const Analiticas = ({ idProyecto }) => {
  const appconfig = useContext(AppConfigContext);
  const { setSelected } = useContext(MenuContext);
  const { partidas, getPartidasProyecto } = useContext(PartidasContext);
  const { proyecto, progresoObra, getSingleProyecto } =
    useContext(ProyectosContext);

  useEffect(() => {
    setSelected({ title: "analiticas" });
    getPartidasProyecto(idProyecto);
    getSingleProyecto(idProyecto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUltimoDia = () => {
    return moment(proyecto.fecha_fin).diff(proyecto.fecha_inicio, "days");
  };

  const renderDia = () => {
    if (progresoObra) {
      if (progresoObra.programadoDia) {
        return (
          <p className="mb-0">
            <b>Día Actual </b>
            {proyecto.diaActual} / {getUltimoDia()}
          </p>
        );
      }
    }
  };

  const renderUltimaActualizacion = () => {
    if (proyecto.ultimoAvance !== null) {
      let ultimoAvance = moment(proyecto.ultimoAvance).utc();
      if (ultimoAvance.isValid()) {
        return ultimoAvance.format("DD MMM YYYY");
      }
    }
  };

  const renderContent = () => {
    if (proyecto && proyecto !== null) {
      return (
        <div
          className="container-fluid px-0 h-100"
          style={{ overflowY: "auto", overflowX: "hidden" }}
        >
          <div className="row align-items-center mt-2">
            <div className="col-12 col-md-6 mb-2">
              <p className="mb-0">
                <b>Ultima Actualización:</b> {renderUltimaActualizacion()}
              </p>
            </div>
            <div className="col-12 col-md-6 mb-2">{renderDia()}</div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 px-1">
              <ChartCard titulo="Avance de Obra">
                <AvanceObra proyecto={proyecto} />
              </ChartCard>
            </div>
            <div className="col-12 col-lg-6 px-1">
              <ChartCard titulo="Ruta Crítica de Obra">
                <CurvaCriticaObra
                  real={proyecto.real}
                  partidas={partidas !== null ? partidas : []}
                  aTiempo={proyecto.real >= proyecto.programado}
                />
              </ChartCard>
            </div>
          </div>
          <ChartCard titulo="Diagrama de Gantt">
            <div className="col-12 w-100" style={{ overflowX: "auto" }}>
              <GanttChart idProyecto={idProyecto} partidas={partidas} />
            </div>
          </ChartCard>
          <GraficaFinanzas idProyecto={idProyecto} />
          {getValue(appconfig, "costs_enabled", "boolean") && (
            <GraficaCostos idProyecto={idProyecto} />
          )}
          <ChartCard>
            <TrabajosRetrasados
              idProyecto={idProyecto}
              partidas={partidas !== null ? partidas : []}
              permiso={proyecto.permiso}
            />
          </ChartCard>
        </div>
      );
    }
  };

  return <Fragment>{renderContent()}</Fragment>;
};

export default Analiticas;
