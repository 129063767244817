/**
 * Partidas Contrato
 */
export const PARTIDAS_PRECIARIO_RECIBIDO = "PARTIDAS_PRECIARIO_RECIBIDO";
export const CREATE_PARTIDA_PRECIARIO = "CREATE_PARTIDA_PRECIARIO";
export const EDIT_PARTIDAS_PRECIARIO = "EDIT_PARTIDAS_PRECIARIO";
export const SET_PROPERTY_PARTIDA_PRECIARIO = "SET_PROPERTY_PARTIDA_PRECIARIO";
export const SEARCH_PARTIDAS_PRECIARIO = "SEARCH_PARTIDAS_PRECIARIO";
export const POST_PRECIARIO_LOADING = "POST_PRECIARIO_LOADING";
export const DELETE_PARTIDA_PRECIARIO = "DELETE_PARTIDA_PRECIARIO";
/**
 * File
 */
export const CARGAR_PRECIARIO = "CARGAR_PRECIARIO";
export const SET_PRECIARIO = "SET_PRECIARIO";
export const CLEAR_PRECIARIO = "CLEAR_PRECIARIO";
