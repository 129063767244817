import React, { useContext } from "react";
import moment from "moment";
import useProyecto from "../../hooks/useProyecto";
import { formatMonto, getValue } from "../../utils";
import { PagosContext } from "../../context/PagosContext";

const PagoForm = ({ idProyecto, idContrato, handleCancel, handleCallback }) => {
  const { pago, postPagoContrato, setPropiedadPago } = useContext(PagosContext);
  const { permiso } = useProyecto();

  const handleSubmit = (e) => {
    e.preventDefault();
    postPagoContrato(idProyecto, idContrato, pago, handleCallback);
  };

  const renderMonto = () => {
    if (isNaN(parseInt(pago.idPago))) {
      return (
        <div className="input-group mb-3">
          <span className="input-group-text">$</span>
          <input
            step=".01"
            type="number"
            className="form-control border"
            value={getValue(pago, "monto")}
            onChange={(e) => setPropiedadPago("monto", e.target.value)}
          />
        </div>
      );
    }
    return (
      <p>
        {"$"}
        {formatMonto(pago.monto)}
      </p>
    );
  };

  const renderAutorizacion = () => {
    if (isNaN(parseInt(pago.idPago))) {
      return (
        <input
          type="date"
          className="form-control mb-3"
          value={getValue(pago, "fecha_hora_autorizacion", "date")}
          onChange={(e) =>
            setPropiedadPago("fecha_hora_autorizacion", e.target.value)
          }
        />
      );
    }
    return <p>{moment(pago.fecha_hora_autorizacion).format("DD MMM YYYY")}</p>;
  };

  return (
    <form onSubmit={handleSubmit} className="px-0">
      <label>Monto</label>
      {renderMonto()}
      <label>Fecha Autorizado</label>
      {renderAutorizacion()}
      <label>Concepto</label>
      <input
        type="text"
        className="form-control mb-3"
        value={getValue(pago, "comentario")}
        onChange={(e) => setPropiedadPago("comentario", e.target.value)}
      />
      <label>Comprobante</label>
      <input type="file" className="form-control" disabled={!["admin", "finanzas"].includes(permiso)} />
      <div className="row align-items-center mt-3">
        <div className="col-6">
          <button onClick={handleCancel} className="btn text-muted w-100">
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn btn-primary w-100">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default PagoForm;
