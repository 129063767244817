import React from "react";
import { S3_ENDPOINT } from "../../utils";

const CustomIcon = ({ icon, src }) => {
  return (
    <img
      alt="icon"
      className="custom-icon"
      src={`${S3_ENDPOINT}/icons/${icon ? `${icon}.png` : src}`}
    />
  );
};

export default CustomIcon;
