import React, { useContext, useEffect } from "react";
import { FolderContext } from "../../context/FolderContext";

const SelectFolder = ({ idProyecto, selected, modifier }) => {
  const { folders, getFoldersProyecto } = useContext(FolderContext);

  useEffect(() => {
    getFoldersProyecto(idProyecto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFoldersProyecto, idProyecto]);

  useEffect(() => {
    if (Array.isArray(folders) && selected === null) {
      let current = folders[0];
      if (current) {
        modifier(current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folders]);

  const renderFolders = () => {
    if (Array.isArray(folders)) {
      return folders.map((folder) => (
        <option key={folder.idFolder} value={folder.idFolder}>
          {folder.nombre}
        </option>
      ));
    }
  };

  return (
    <div className="container-fluid px-0">
      <label>Elige una Carpeta</label>
      <select
        value={selected}
        className="form-control mb-3"
        onChange={(e) => modifier(e.target.value)}
      >
        {renderFolders()}
      </select>
    </div>
  );
};

export default SelectFolder;
