import React, { useState } from "react";
import { search } from "../../utils";

const SearchableSelect = ({ items, selected, labelKey, selectItem }) => {
  const [query, setQuery] = useState("");
  const renderItems = () => {
    if (Array.isArray(items) && query !== "") {
      let itemsRender = search(items, query);
      if (itemsRender.length === 0) {
        return <p>No encontramos elementos.</p>;
      }
      return itemsRender.map((item, index) => (
        <li
          key={index}
          onClick={() => selectItem(item)}
          className="border py-1 px-2 small hover-primary"
        >
          {item[labelKey]}
        </li>
      ));
    }
  };

  const renderForm = () => {
    if (selected && selected !== null) {
      return (
        <div className="row mx-0 align-items-center bg-light border">
          <div className="col-1">
            <button className="btn" onClick={() => selectItem(null)}>
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className="col-11">{selected[labelKey]}</div>
        </div>
      );
    }
    return (
      <div>
        <input
          type="text"
          value={query}
          className="form-control"
          placeholder="Escribe para buscar..."
          onChange={(e) => setQuery(e.target.value)}
        />
        <ul className="list-style-none">{renderItems()}</ul>
      </div>
    );
  };

  return <div>{renderForm()}</div>;
};

export default SearchableSelect;
