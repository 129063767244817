import React from "react";

const SelectedUser = ({ user, toggleUser }) => {
  const renderUser = () => {
    if (user.nombre && user.nombre !== null) {
      return user.nombre;
    }
    return user.correo;
  };
  return (
    <span className="badge small text-dark bg-light border me-2">
      {renderUser()}
      <button
        className="btn px-0 py-0 mx-1 small"
        onClick={() => toggleUser(user)}
        type="button"
      >
        <i className="fa fa-times"></i>
      </button>
    </span>
  );
};
export default SelectedUser;
