import api from "./api";

const route = "/usuario";

const UsuarioService = {
  getUser: () => api.get(route),
  getUsuario: () => api.get(route),
  putUsuario: (usuario) => api.put(route, { ...usuario }),
  setToken: (token) => (api.defaults.headers.common["Authorization"] = token),
  setFcmToken: (token) => (api.defaults.headers.common["FCM_TOKEN"] = token),
  postUsuario: (nombre, correo, telefono, uid) =>
    api.post(route, { nombre, correo, telefono, uid }),
};

export default UsuarioService;
