import api from "./api";

const route = "/permisos";

export default {
  getPermisos: () => api.get(route),
  getPermisosProyectos: () => api.get(`${route}/proyectos`),
  postPermisoProyecto: (idUsuario, idProyecto, idPermiso) =>
    api.post(route, { idUsuario, idProyecto, idPermiso }),
  putPermisoProyecto: (idUsuario, idProyecto, idPermiso) =>
    api.put(route, { idUsuario, idProyecto, idPermiso }),
  deletePermisoProyecto: (idUsuario, idProyecto) =>
    api.delete(`${route}/${idProyecto}/${idUsuario}`),
};
