import React, { useState, useEffect, useContext } from "react";
import { ProyectosContext } from "../../context/ProyectosContext";
import { WarehousesContext } from "../../context/WarehousesContext";
import SearchableSelect from "../global/SearchableSelect";

const WarehouseForm = ({ warehouseToEdit, handleCancel }) => {
  const [selectedParentProject, setSelectedParentProject] = useState("");
  const [name, setName] = useState("");

  const { proyectos, getAllProyectos } = useContext(ProyectosContext);

  const projects = proyectos ? proyectos : [];

  const { createWarehouse, updateWarehouse } = useContext(WarehousesContext);

  useEffect(() => {
    if (warehouseToEdit) {
      setName(warehouseToEdit.name);
      setSelectedParentProject(warehouseToEdit.parentProject);
    }
  }, [warehouseToEdit]);

  useEffect(() => {
    getAllProyectos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (warehouseToEdit) {
      const warehouseData = {
        name: name,
        parentProject: selectedParentProject,
      };
      updateWarehouse(warehouseData, warehouseToEdit.id);
    } else {
      const newWarehouseData = {
        name: name,
        parentProject: selectedParentProject,
      };
      createWarehouse(newWarehouseData);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <p className="mb-1" htmlFor="parentProject">
          Proyecto:
        </p>
        <SearchableSelect
          items={projects}
          itemText={"nombre"}
          itemValue={"idProyecto"}
          modifier={(proyecto) =>
            setSelectedParentProject("idCliente", proyecto.value)
          }
          value={selectedParentProject}
          disabled={warehouseToEdit ? true : false}
        />
      </div>
      <div className="mb-3">
        <p className="mb-1" htmlFor="name">
          Nombre del Almacén:
        </p>
        <input
          className="form-control"
          type="text"
          id="name"
          value={name}
          onChange={handleNameChange}
          required
        />
      </div>

      <div className="w-100 d-flex mt-5">
        <button className="btn me-1 w-50" onClick={handleCancel}>
          Cancelar
        </button>
        <button className="btn btn-primary ms-1 w-50" type="submit">
          {" "}
          {warehouseToEdit ? "Editar Almacén" : "Añadir Almacén"}{" "}
        </button>
      </div>
    </form>
  );
};

export default WarehouseForm;
