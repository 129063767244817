import React from "react";
import ColorBox from "./ColorBox";

const ColorLegend = ({ block, colors }) => {
  return (
    <div>
      {colors.map((color, index) => (
        <ColorBox color={color} key={index} block={block} />
      ))}
    </div>
  );
};

export default ColorLegend;
