import React, { useContext, useEffect } from "react";
import { ProveedoresContext } from "../context/ProveedoresContext";
import ProveedoresTable from "../components/proveedores/ProveedoresTable";
import SearchBar from "../components/global/SearchBar";
import "../components/proveedores/proveedores.css";
import ProveedoresHandler from "../utils/proveedores/ProveedoresHandler";
import { MenuContext } from "../context/MenuContext";
import ProveedorCard from "../components/proveedores/ProveedorCard";
import { CapacitorContext } from "../context/CapacitorContext";

const Proveedores = ({ idProyecto }) => {
  const view = "grid";
  const { handleCreateProveedor } = ProveedoresHandler();

  const { platform } = useContext(CapacitorContext);
  const { selectTabs, setSelected } = useContext(MenuContext);
  const { proveedores, getAllProveedores } = useContext(ProveedoresContext);

  useEffect(() => {
    setSelected({ title: "proveedores" });
    getAllProveedores();
    selectTabs();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateProvider = () => {
    handleCreateProveedor(idProyecto);
  };

  const renderProveedores = () => {
    if (Array.isArray(proveedores)) {
      if (view === "table") {
        return <ProveedoresTable proveedores={proveedores} />;
      }
      return (
        <div className="row proveedores-wrapper">
          {proveedores.map((proveedor) => (
            <div
              key={proveedor.idProveedor}
              className="col-12 col-md-6 col-lg-4 col-xl-3"
            >
              <ProveedorCard proveedor={proveedor} platform={platform} />
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <div className="container-fluid px-0">
      <div className="row mb-3 align-items-center">
        <div className="col-8 col-md-9 col-lg-10 col-xl-11">
          <SearchBar />
        </div>
        <div className="col-4 col-md-3 col-lg-2 col-xl-1">
          <button
            className="btn btn-primary w-100"
            onClick={handleCreateProvider}
          >
            <i className="fa fa-plus"></i>
          </button>
        </div>
      </div>
      {renderProveedores()}
    </div>
  );
};

export default Proveedores;
