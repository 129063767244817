import React, { createContext, useReducer, useContext } from "react";
import ProductsReducer from "../reducers/ProductsReducer";
import ProductsService from "../services/ProductsService";
import {
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  RECEIVED_PRODUCTS,
  GET_SINGLE_PRODUCT,
  GET_PRODUCT_INSUMOS,
  UPDATE_PRODUCT_INSUMOS,
} from "../types/products";
import { ModalContext } from "./ModalContext";
const initialState = {
  products: null,
  product: null,
  currentProductInsumos: null,
};

export const ProductsContext = createContext(initialState);

export const ProductsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProductsReducer, initialState);
  const { success, clearModal } = useContext(ModalContext);

  const getProducts = () => {
    ProductsService.getProducts()
      .then((res) => {
        const { productos } = res.data;
        dispatch({ type: RECEIVED_PRODUCTS, payload: productos });
      })
      .catch(alert);
  };

  const getProjectProducts = (idProyecto) => {
    ProductsService.getProjectProducts(idProyecto)
      .then((res) => {
        const { productos } = res.data;
        dispatch({ type: RECEIVED_PRODUCTS, payload: productos });
      })
      .catch(alert);
  };

  const getSingleProduct = (idProducto, idProyecto) => {
    const method =
      idProyecto && idProyecto != null
        ? ProductsService.findSingleProjectProduct
        : ProductsService.findSingleProduct;

    method(idProyecto, idProducto)
      .then((res) => {
        const { producto } = res.data;
        dispatch({ type: GET_SINGLE_PRODUCT, payload: { producto } });
      })
      .catch(alert);
  };

  const saveProduct = (producto, callback) => {
    let method = () => {};
    if (isNaN(parseInt(producto.idProducto))) {
      method = producto.idProyecto
        ? ProductsService.postProjectProduct
        : ProductsService.postProduct;
    } else {
      method = ProductsService.putProduct;
    }
    method(producto)
      .then(() => {
        success("Producto Guardado.");
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch(alert)
      .finally(clearModal);
  };

  const createProduct = () => {
    dispatch({ type: CREATE_PRODUCT });
  };

  const deleteProduct = (idProducto) => {
    ProductsService.deleteProduct(idProducto).then(() => {
      success("Producto Borrado");
      dispatch({ type: DELETE_PRODUCT, payload: { idProducto } });
    });
    clearModal();
  };

  const cloneProduct = (idProducto, cloneChildren) => {
    ProductsService.cloneProduct(idProducto, cloneChildren).then((res) => {
      success("Producto Clonado.");
      const { clonedProducto } = res.data;
      dispatch({ type: CREATE_PRODUCT, payload: clonedProducto });
    });
    clearModal();
  };

  //INSUMOS
  const getProductInsumos = (idProductoPadre, callback) => {
    ProductsService.getInsumos(idProductoPadre)
      .then((res) => {
        const { insumos } = res.data;
        dispatch({
          type: GET_PRODUCT_INSUMOS,
          payload: { currentProductInsumos: insumos },
        });
        if (callback) {
          callback(insumos);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const addProductInsumo = (idProductoPadre, idProductoHijo, cantidad) => {
    ProductsService.postInsumo(idProductoPadre, {
      idProductoHijo,
      cantidad,
    }).then(() => {
      success("Insumo Agregado");
      dispatch({ type: UPDATE_PRODUCT_INSUMOS, payload: idProductoPadre });
    });
    clearModal();
  };

  const deleteProductInsumo = (idProductoPadre, idProductoHijo) => {
    ProductsService.deleteInsumos(idProductoPadre, idProductoHijo)
      .then(() => {
        success("Insumo Eliminado");
        dispatch({ type: UPDATE_PRODUCT_INSUMOS, payload: idProductoPadre });
      })
      .catch((error) => {
        alert(error);
      });
    clearModal();
  };

  const updateProductInsumo = (idProductoPadre, idProductoHijo, cantidad) => {
    ProductsService.editInsumo(idProductoPadre, { idProductoHijo, cantidad })
      .then(() => {
        success("Insumo Editado");
        dispatch({ type: UPDATE_PRODUCT_INSUMOS, payload: idProductoPadre });
      })
      .catch((error) => {
        alert(error);
      });
    clearModal();
  };

  return (
    <ProductsContext.Provider
      value={{
        ...state,
        getProducts,
        saveProduct,
        cloneProduct,
        createProduct,
        deleteProduct,
        getSingleProduct,
        addProductInsumo,
        getProductInsumos,
        deleteProductInsumo,
        updateProductInsumo,
        getProjectProducts,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
