import React from 'react'

const ProductOrderDetails = ({ order, productList }) => {


    const totalPrice = productList?.length > 0 && productList.reduce((accumulator, productOrder) => {
        return accumulator + productOrder.importe;
    }, 0);

    return (
        <div>
            <div className="card shadow p-3 mb-3">
                <div className='d-flex'>
                    <p style={{ maxWidth: '150px' }} className='bold me-3 w-50 mb-2'>Folio Interno</p>
                    <p className='mb-2'>{order.idOrden}</p>
                </div>
                <div className='d-flex'>
                    <p style={{ maxWidth: '150px' }} className='bold me-3 w-50 mb-2'>Fecha</p>
                    <p className='mb-2'>{order.updatedAt}</p>
                </div>
                <div className='d-flex'>
                    <p style={{ maxWidth: '150px' }} className='bold me-3 w-50 mb-2'>Entrega</p>
                    <p className='mb-2'>{order.fecha_entrega}</p>
                </div>
                <div className='d-flex'>
                    <p style={{ maxWidth: '150px' }} className='bold me-3 w-50 mb-2'>Concepto</p>
                    <p className='mb-2'>{order.description}</p>
                </div>
            </div>

            <div className="card shadow p-3">
                <div className='d-flex'>
                    <p style={{ maxWidth: '150px' }} className='bold me-3 w-50 mb-2'>Subtotal</p>
                    <p className='mb-2 w-50'>{totalPrice}</p>
                    <p className='mb-2 ms-3'>MXN</p>
                </div>
                <div className='d-flex'>
                    <p style={{ maxWidth: '150px' }} className='bold me-3 w-50 mb-2'>Impuestos</p>
                    <p className='mb-2 w-50'>0</p>
                    <p className='mb-2 ms-3'>MXN</p>
                </div>
                <div className='d-flex'>
                    <p style={{ maxWidth: '150px' }} className='bold me-3 w-50 mb-2'>Total</p>
                    <p className='mb-2 w-50'>{totalPrice}</p>
                    <p className='mb-2 ms-3'>MXN</p>
                </div>
                <div className='d-flex'>
                    <p style={{ maxWidth: '150px' }} className='bold me-3 w-50 mb-2'>Concepto</p>
                    <p className='mb-2 w-50'>{order.description}</p>
                </div>
                <div className='d-flex'>
                    <p style={{ maxWidth: '150px' }} className='bold me-3 w-50 mb-2'>Condiciones</p>
                    <p className='mb-2 w-50'>{order.status}</p>
                </div>
            </div>



        </div>
    );
}

export default ProductOrderDetails;