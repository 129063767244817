import React from "react";
import Tab from "./Tab";

const TabGroup = ({ tabs, selected, modifier }) => {
  function toggle(clicked) {
    if (Array.isArray(tabs)) {
      tabs.forEach((tab) => {
        if (clicked === tab.value) tab.selected = true;
        else tab.selected = false;
      });
    }
    if (typeof modifier === "function") {
      modifier(clicked);
    }
  }

  function renderTabs() {
    if (Array.isArray(tabs)) {
      return tabs.map((tab) => (
        <Tab
          key={"tab-" + tab.value}
          title={tab.title}
          selected={selected === tab.value}
          handler={() => toggle(tab.value)}
        />
      ));
    }
  }

  return <div className="row mb-3 justify-content-between text-center">{renderTabs()}</div>;
};

export default TabGroup;
