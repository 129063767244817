import React, { useContext } from "react";
import { formatMonto, getValue } from "../../utils";
import { AppConfigContext } from "../../context/AppConfigContext";

const FilaTotales = ({ conceptos, showEstimaciones }) => {
  const appconfig = useContext(AppConfigContext);

  function getTotal(key) {
    let total = 0;
    if (Array.isArray(conceptos)) {
      conceptos.forEach((concepto) => {
        total += parseFloat(concepto[key]);
      });
    }
    if (isNaN(total)) return 0;
    return total;
  }

  return (
    <tr className="bg-light">
      <td colSpan={5} className="bold text-uppercase">
        Totales
      </td>
      <td>${formatMonto(getTotal("importe"))}</td>
      {showEstimaciones && (
        <>
          <td className="ps-3">{getTotal("estimado")}</td>
          {getValue(appconfig, "supervise_estimates", "boolean") && (
            <td>{getTotal("supervisado")}</td>
          )}
          {getValue(appconfig, "authorize_estimates", "boolean") && (
            <td>{getTotal("autorizado")}</td>
          )}
          <td>${formatMonto(getTotal("importe_autorizado"))}</td>
          <td colSpan={6} />
        </>
      )}
    </tr>
  );
};

export default FilaTotales;
