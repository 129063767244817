
import React, { createContext, useReducer, useContext } from 'react';
import ConceptosMedicionesService from '../services/ConceptosMedicionesService';
import ConceptosMedicionesReducer from '../reducers/ConceptosMedicionesReducer';
import {
  CONCEPTOMEDICIONS_RECEIVED,
  SET_CONCEPTOMEDICION,
  CREATE_CONCEPTOMEDICION,
  SET_PROPERTY_CONCEPTOMEDICION,
} from "../types/conceptosmediciones";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  conceptosmediciones: null,
  conceptoMedicion: null,
};

export const ConceptosMedicionesContext = createContext(initialState);

export const ConceptosMedicionesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ConceptosMedicionesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getConceptosMediciones = (idProyecto, idContrato, idEstimacionVersion) => {
    ConceptosMedicionesService.getConceptosMediciones(idProyecto, idContrato, idEstimacionVersion)
      .then((response) => {
        const { conceptosmediciones } = response.data;
        dispatch({ type: CONCEPTOMEDICIONS_RECEIVED, payload: conceptosmediciones });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleConceptoMedicion = (conceptoMedicion_id) => {
    ConceptosMedicionesService.getSingleConceptoMedicion(conceptoMedicion_id)
      .then((response) => {
        const { conceptoMedicion } = response.data;
        dispatch({ type: SET_CONCEPTOMEDICION, payload: conceptoMedicion });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setConceptoMedicion = (conceptoMedicion) => {
    dispatch({ type: SET_CONCEPTOMEDICION, payload: conceptoMedicion });
  };

  const createConceptoMedicion = () => {
    dispatch({ type: CREATE_CONCEPTOMEDICION });
  };

  const setPropertyConceptoMedicion = (key, value) => {
    dispatch({ type: SET_PROPERTY_CONCEPTOMEDICION, payload: { key, value } });
  };

  const saveConceptoMedicion = (conceptoMedicion, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = ConceptosMedicionesService.putConceptoMedicion;
      if(isNaN(parseInt(conceptoMedicion.conceptoMedicion_id))) {
        service = ConceptosMedicionesService.postConceptoMedicion;
      }
      service(conceptoMedicion).then(() => {
        success("ConceptoMedicion saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteConceptoMedicion = (conceptoMedicion_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    ConceptosMedicionesService.deleteConceptoMedicion(conceptoMedicion_id).then(() => {
      success("ConceptoMedicion deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <ConceptosMedicionesContext.Provider
    value={{
      ...state,
      setConceptoMedicion,
      getConceptosMediciones,
      saveConceptoMedicion,
      deleteConceptoMedicion,
      createConceptoMedicion,
      getSingleConceptoMedicion,
      setPropertyConceptoMedicion,

    }}
  >
    {children}
  </ConceptosMedicionesContext.Provider>
);
};
