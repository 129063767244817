import React from "react";

const ProveedorActions = ({ proveedor, handleEdit, handleDelete }) => {
  return (
    <div className="dropdown position-absolute top-0 end-0 mt-2 me-2">
      <button
        className="btn btn-sm border-0"
        type="button"
        id={`dropdownMenuButton-${proveedor.idProveedor}`}
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style={{
          fontSize: "20px",
          backgroundColor: "white",
        }}
      >
        <i className="fa fa-ellipsis-h text-primary"></i>
      </button>
      <div
        className="dropdown-menu"
        aria-labelledby={`dropdownMenuButton-${proveedor.idProveedor}`}
      >
        <button className="dropdown-item" onClick={() => handleEdit(proveedor)}>
          <i className="fa fa-edit" /> Editar
        </button>
        <button
          className="dropdown-item"
          onClick={() => handleDelete(proveedor)}
        >
          <i className="fa fa-trash" /> Eliminar
        </button>
      </div>
    </div>
  );
};

export default ProveedorActions;
