import React, { useContext } from "react";
import SearchBar from "../../global/SearchBar";
import WarehouseForm from "../../warehouses/WarehouseForm";
import { ModalContext } from "../../../context/ModalContext";
import { WarehousesContext } from "../../../context/WarehousesContext";
import "../product.css";

const ProductWarehousesTable = () => {
    const { modalComponent, clearModal, confirm } = useContext(ModalContext);

    const { warehouses, deleteWarehouse } =
        useContext(WarehousesContext);

    const handleCancel = () => {
        clearModal();
    };


    const handleCreateWarehouse = () => {
        modalComponent(
            "Agregar Almacén:",
            <WarehouseForm
                handleCancel={handleCancel}
                title={"Agregar Almacén"}
            />
        );
    };

    const handleDeleteWarehouse = (warehouse_id) => {
        confirm("¿Eliminar Almacén?", () => deleteWarehouse(warehouse_id));
    };

    const handleEditWarehouse = (warehouse) => {
        modalComponent(
            "Editar Almacén:",
            <WarehouseForm
                handleCancel={handleCancel}
                warehouseToEdit={warehouse}
                title={"Editar Almacén"}
            />
        );
    };

    const renderActionButtons = (warehouse) => {
        const deleteFunction = () => { handleDeleteWarehouse(warehouse.id) };
        const editFunction = () => { handleEditWarehouse(warehouse) };

        return (
            <div style={{ minWidth: "125px" }}>
                <button className="btn">
                    <i className="fa fa-bell"></i>
                </button>
                <button className="btn" onClick={editFunction}>
                    <i className="fa fa-edit"></i>
                </button>
                <button className="btn" onClick={deleteFunction}>
                    <i className="fa fa-trash"></i>
                </button>
            </div>
        );
    };

    return (

        <div>
            <div className="w-100 d-flex flex-row align-items-center mb-3">
                <div className="w-50">
                    <h2 className="mb-3 bold">Almacenes</h2>
                </div>
                <div className="w-50 d-flex align-items-center justify-content-between">
                    <div className="w-75" style={{ paddingRight: "10px" }}>
                        <SearchBar />
                    </div>
                    <div className="w-25">
                        <button
                            className="btn btn-primary w-100"
                            onClick={handleCreateWarehouse}
                            style={{ minWidth: "118px" }}
                        >
                            + ALMACEN
                        </button>
                    </div>
                </div>
            </div>
            {warehouses && warehouses.length > 0 ?
                <div className="card shadow p-3">
                    <table className="text-center">
                        <thead>
                            <tr>
                                <th style={{ textAlign: "left" }}>Nombre</th>
                                <th style={{ textAlign: "center" }}>Cantidad</th>
                                <th style={{ textAlign: "center" }}>Ordenado</th>
                                <th style={{ textAlign: "center" }}>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {warehouses.map((warehouse, index) => (
                                <tr key={index}>
                                    <td width="74%" style={{ textAlign: "left" }}>{warehouse.name}</td>
                                    <td width="8%" align="center">
                                        300
                                    </td>
                                    <td width="8%" align="center">
                                        120
                                    </td>
                                    <td width="10%" align="center">
                                        {renderActionButtons(warehouse)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div> : <p>No hay almacenes registrados para este producto</p>}
        </div>
    );
};

export default ProductWarehousesTable;
