import api from "./api";

const route = "/clientes";

export default {
  getClientes: () => api.get(route),
  getCliente: (idCliente) => api.get(`${route}/${idCliente}`),
  getUsuariosCliente: (idCliente) => api.get(`${route}/${idCliente}/usuarios`),
  postCliente: (cliente) => api.post(route, { ...cliente }),
  putCliente: (cliente) => api.put(route, { ...cliente }),
  deleteCliente: (idCliente) => api.delete(`${route}/${idCliente}`),
  postObraCliente: (idCliente, idProyecto) =>
    api.post(`${route}/${idCliente}/${idProyecto}`),
  postUsuarioCliente: (usuario) =>
    api.post(`${route}/usuarios`, { ...usuario }),
  putUsuarioCliente: (usuario) => api.put(`${route}/usuarios`, { ...usuario }),
  deleteObraCliente: (idCliente, idProyecto) =>
    api.delete(`${route}/${idCliente}/${idProyecto}`),
  deleteUsuarioCliente: (idCliente, idUsuario) =>
    api.delete(`${route}/${idCliente}/usuarios/${idUsuario}`),
};
