import React, { useEffect, useState } from "react";
import { validateEmail } from "../../utils";
import SelectedUser from "../global/SelectedUser";

const AlertExtraUsers = ({ extraUsers, setExtraUsers }) => {
  const [customUser, setCustomUser] = useState(false);
  const [customEmail, setCustomEmail] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (error !== "") {
      setError("");
    }
    if (customEmail === ",") {
      setCustomEmail("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customEmail]);

  const handleKeyPress = (e) => {
    e.stopPropagation();
    if (e.which === 44 || e.which === 13) {
      e.preventDefault();
      if (validateEmail(customEmail)) {
        addUser(customEmail);
      } else {
        setError("El correo electrónico no es válido.");
      }
    }
  };

  const addUser = (customEmail) => {
    let current = extraUsers;
    let index = current.findIndex(({ correo }) => correo === customEmail);
    if (index === -1) {
      setExtraUsers([...current, { correo: customEmail }]);
      setCustomEmail("");
    } else {
      setError("Este usuario ya se encuentra en la lista.");
    }
  };

  const toggleUser = (user) => {
    let current = [...extraUsers];
    let index = current.findIndex(({ correo }) => correo === user.correo);
    if (index !== -1) {
      current.splice(index, 1);
    }
    setExtraUsers(current);
  };

  const toggleExtraUser = () => {
    setCustomUser(!customUser);
  };

  const renderAdditionalUser = () => {
    if (customUser) {
      return (
        <input
          type="email"
          value={customEmail}
          onKeyPress={handleKeyPress}
          className="form-control mb-3"
          placeholder="Escibe un correo electrónico"
          onChange={(e) => setCustomEmail(e.target.value)}
        />
      );
    }
  };

  const renderError = () => {
    if (error && error !== null) {
      return <p className="text-danger">{error}</p>;
    }
  };

  const renderExtraUsers = () => {
    if (Array.isArray(extraUsers)) {
      return extraUsers.map((user) => (
        <SelectedUser key={user.correo} user={user} toggleUser={toggleUser} />
      ));
    }
  };

  return (
    <div className="mb-2">
      <button
        type="button"
        className="btn btn-sm btn-outline-primary mb-3"
        onClick={toggleExtraUser}
      >
        <i className="fa fa-user me-2"></i> Alertar a usuario externo
      </button>
      {renderAdditionalUser()}
      {renderError()}
      <div className="mb-3">{renderExtraUsers()}</div>
    </div>
  );
};

export default AlertExtraUsers;
