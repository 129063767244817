import React, { createContext, useContext, useReducer } from "react";
import UsersReducer from "../reducers/UsuariosReducer";
import UsuariosService from "../services/UsuariosService";
import {
  EDIT_USUARIO,
  CLEAR_USUARIO,
  USERS_RECEIVED,
  SET_PROPERTY_USUARIO,
} from "../types/usuarios";
import { ModalContext } from "./ModalContext";

const initialState = { users: null, usuario: null };

const userSchema = {
  idUsuario: "nuevo",
  idPermiso: null,
  nombre: "",
  correo: "",
  disabled: false,
  permiso: "coordinador",
};

export const UsuariosContext = createContext(initialState);

export const UsuariosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UsersReducer, initialState);

  const { success, clearModal, alert } = useContext(ModalContext);

  const getUsers = () => {
    UsuariosService.getUsuarios()
      .then((res) => {
        const { usuarios } = res.data;
        dispatch({ type: USERS_RECEIVED, payload: usuarios });
      })
      .catch(alert);
  };

  const getUsuariosProyecto = (idProyecto) => {
    UsuariosService.getUsuariosProyecto(idProyecto)
      .then((res) => {
        const { usuarios } = res.data;
        dispatch({ type: USERS_RECEIVED, payload: usuarios });
      })
      .catch(alert);
  };

  const createUsuario = () => {
    dispatch({
      type: EDIT_USUARIO,
      payload: userSchema,
    });
  };

  const editUsuario = (usuario) => {
    dispatch({ type: EDIT_USUARIO, payload: usuario });
  };

  const deleteUsuario = (uidUser, callback) => {
    UsuariosService.deleteUsuario(uidUser)
      .then(() => {
        success("Usuario eliminado con éxito.");
        if (callback) callback();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const postUsuario = (usuario, callback) => {
    let service = UsuariosService.putUsuario;
    if (isNaN(usuario.idUsuario)) {
      service = UsuariosService.postUsuario;
    }
    
    service(usuario)
      .then(() => {
        getUsers();
        clearModal();
        clearUsuario();
        success("Usuario guardado.");
        if (typeof callback === "function") callback();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setPropiedadUsuario = (key, value) => {
    dispatch({ type: SET_PROPERTY_USUARIO, payload: { key, value } });
  };

  const clearUsuario = () => {
    dispatch({ type: CLEAR_USUARIO });
  };

  return (
    <UsuariosContext.Provider
      value={{
        ...state,
        getUsers,
        editUsuario,
        postUsuario,
        clearUsuario,
        deleteUsuario,
        createUsuario,
        getUsuariosProyecto,
        setPropiedadUsuario,
      }}
    >
      {children}
    </UsuariosContext.Provider>
  );
};
