import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { hasNotch } from "../../utils/ionic";
import { CapacitorContext } from "../../context/CapacitorContext";

const ErrorAlert = () => {
  const { device } = useContext(CapacitorContext);
  const { showAlert, alertContent, hideAlert } = useContext(ModalContext);

  return (
    <div
      className={`alert alert-danger alert-dismissible fixed-top ms-auto me-2 fade-show ${
        !showAlert ? "hidden" : ""
      }`}
      role="alert"
      style={{
        maxWidth: 500,
        zIndex: 2500,
        marginTop: hasNotch(device) ? 55 : 16,
      }}
    >
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-11">{alertContent}</div>
          <div className="col-1 text-end">
            <button className="btn btn-link" onClick={hideAlert}>
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorAlert;
