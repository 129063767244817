import React, { useContext, useEffect } from "react";
import { ChecklistContext } from "../../context/ChecklistContext";
import { ModalContext } from "../../context/ModalContext";
import moment from "moment";

const ChecklistForm = ({ idProyecto }) => {
  const { checklist, postChecklist, clearChecklist, setPropertyChecklist } =
    useContext(ChecklistContext);

  const { clearModal } = useContext(ModalContext);

  useEffect(() => {
    handleFechasChecklist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFechasChecklist = () => {
    if (checklist.fecha_inicio === null) {
      setPropertyChecklist("fecha_inicio", moment().format("YYYY-MM-DD"));
    }
    if (checklist.fecha_fin === null) {
      setPropertyChecklist(
        "fecha_fin",
        moment().add(1, "week").format("YYYY-MM-DD")
      );
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    checklist.idProyecto = idProyecto;
    postChecklist(checklist);
  };

  const handleCancel = () => {
    clearModal();
    clearChecklist();
  };

  return (
    <form onSubmit={onSubmit}>
      <label>Nombre</label>
      <input
        type="text"
        value={checklist.nombre}
        className="form-control mb-3"
        onChange={(e) => setPropertyChecklist("nombre", e.target.value)}
      />
      <label>Fecha Inicio</label>
      <input
        type="date"
        value={moment(checklist.fecha_inicio).format("YYYY-MM-D")}
        className="form-control mb-3"
        onChange={(e) => setPropertyChecklist("fecha_inicio", e.target.value)}
      />
      <label>Fecha Fin</label>
      <input
        type="date"
        value={moment(checklist.fecha_fin).format("YYYY-MM-D")}
        className="form-control mb-3"
        onChange={(e) => setPropertyChecklist("fecha_fin", e.target.value)}
      />
      <div className="row">
        <div className="col-6 ">
          <button
            type="button"
            onClick={() => console.log("click")}
            className="btn w-100 text-muted"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6 ">
          <button className="btn w-100 btn-primary" type="submit">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default ChecklistForm;
