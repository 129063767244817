import api from './api';

const route = '/productos';
const insumosRoute = '/insumos';

export default {
  getProducts: () => api.get(route),
  findSingleProduct: (_, idProducto) => api.get(`${route}/producto/${idProducto}`),
  findSingleProjectProduct: (idProyecto, idProducto) => api.get(`${route}/${idProyecto}/${idProducto}`),
  getProjectProducts: (idProyecto) => api.get(`${route}/${idProyecto}`),
  postProduct: (product) => api.post(`${route}/`, { ...product }),
  postProjectProduct: (product) => api.post(`${route}/${product.idProyecto}`, { ...product }),
  putProduct: (product) => api.put(`${route}/${product.idProyecto}`, { ...product }),
  deleteProduct: (idProducto) => api.delete(`${route}/${idProducto}`),
  cloneProduct: (idProducto, cloneChildren) => api.post(`${route}/clone/${idProducto}`, { cloneChildren }),


  //insumos
  postInsumo: (idProductoPadre, insumoData) => api.post(`${insumosRoute}/${idProductoPadre}/`, insumoData),
  getInsumos: (idProductoPadre) => api.get(`${insumosRoute}/${idProductoPadre}/`),
  deleteInsumos: (idProductoPadre, idProductoHijo) => api.delete(`${insumosRoute}/${idProductoPadre}/${idProductoHijo}`),
  editInsumo: (idProductoPadre, insumoData) => api.put(`${insumosRoute}/${idProductoPadre}/`, insumoData),
};
