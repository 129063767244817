import React, { createContext, useContext, useReducer } from "react";
import AlertasReducer from "../reducers/AlertasReducer";
import AlertasService from "../services/AlertasService";
import {
  CHECK_ALERTA,
  CLEAR_ALERTS,
  CREATE_ALERTA,
  ALERTAS_RECEIVED,
  TOGGLE_ALERT_CARD,
  CLEAR_SINGLE_ALERT,
  SET_PROPERTY_ALERTA,
  CREATE_ALERT_TRIGGER,
  DELETE_TRIGGER_ALERT,
  SET_PROPERTY_TRIGGER,
  SHOW_SPINNER_ALERTAS,
  HIDE_SPINNER_ALERTAS,
  GLOBAL_ALERTAS_RECEIVED,
} from "../types/alertas";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";

const initialState = {
  globalAlertas: null,
  alerta: null,
  alertas: null,
  showAlertasCard: false,
  showAlertasDot: false,
  showSpinner: false,
};

export const AlertasContext = createContext(initialState);

export const AlertasProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AlertasReducer, initialState);

  const { success, alert } = useContext(ModalContext);


  const getAlertas = () => {
    dispatch({ type: SHOW_SPINNER_ALERTAS });
    AlertasService.getAlertas()
      .then((res) => {
        const { alertas } = res.data;
        dispatch({ type: HIDE_SPINNER_ALERTAS });
        dispatch({ type: GLOBAL_ALERTAS_RECEIVED, payload: alertas });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER_ALERTAS });
        alert(error);
      });
  };


  const getAlertasProyecto = (idProyecto) => {
    dispatch({ type: SHOW_SPINNER_ALERTAS });
    AlertasService.getAlertasProyecto(idProyecto)
      .then((res) => {
        const { alertas } = res.data;
        dispatch({ type: HIDE_SPINNER_ALERTAS });
        dispatch({ type: GLOBAL_ALERTAS_RECEIVED, payload: alertas });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER_ALERTAS });
        alert(error);
      });
  };

  const getItemAlertas = (idProyecto, filters) => {
    dispatch({ type: SHOW_SPINNER_ALERTAS });
    AlertasService.getItemAlertas(idProyecto, filters)
      .then((res) => {
        const { alertas } = res.data;
        dispatch({ type: ALERTAS_RECEIVED, payload: alertas });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER_ALERTAS });
        alert(error);
      });
  };

  const clearSingleAlert = () => {
    dispatch({ type: CLEAR_SINGLE_ALERT });
  };

  const clearAlerts = () => {
    dispatch({ type: CLEAR_ALERTS });
  };

  const toggleAlertCard = () => {
    dispatch({ type: TOGGLE_ALERT_CARD });
  };

  const checkAlerta = (idProyecto, idAlerta) => {
    AlertasService.confirmAlerta(idProyecto, idAlerta)
      .then(() => {
        dispatch({ type: CHECK_ALERTA, payload: idAlerta });
      })
      .catch(alert);
  };

  const createAlerta = () => {
    dispatch({ type: CREATE_ALERTA });
  };

  const setPropertyAlerta = (key, value) => {
    dispatch({ type: SET_PROPERTY_ALERTA, payload: { key, value } });
  };

  const deleteTriggerAlert = (idTrigger) => {
    dispatch({ type: DELETE_TRIGGER_ALERT, payload: idTrigger });
  };

  const createAlertTrigger = () => {
    dispatch({ type: CREATE_ALERT_TRIGGER });
  };

  const postAlerta = (idProyecto, alerta) => {
    let service = AlertasService.putAlerta;
    if (isNaN(alerta.idAlerta)) {
      service = AlertasService.postAlerta;
    }
    service(idProyecto, alerta)
      .then(() => {
        hideModal();
        success("Alerta creada.");
      })
      .catch(alert);
  };

  const setPropertyTrigger = (idTrigger, key, value) => {
    if (key === "treshold" && value > 100) {
      value = 100;
    }
    dispatch({
      type: SET_PROPERTY_TRIGGER,
      payload: { idTrigger, key, value },
    });
  };

  const deleteAlerta = (idProyecto, idAlerta) => {
    AlertasService.deleteAlerta(idProyecto, idAlerta).then(() => {
      success("Alerta eliminada.");
      getAlertas(idProyecto);
      hideModal();
    });
  };

  return (
    <AlertasContext.Provider
      value={{
        ...state,
        postAlerta,
        getAlertas,
        clearAlerts,
        checkAlerta,
        deleteAlerta,
        createAlerta,
        getItemAlertas,
        toggleAlertCard,
        clearSingleAlert,
        setPropertyAlerta,
        getAlertasProyecto,
        deleteTriggerAlert,
        setPropertyTrigger,
        createAlertTrigger,
      }}
    >
      {children}
    </AlertasContext.Provider>
  );
};
