import React, { useState, useEffect, useContext } from "react";
import "../product.css";
import SearchBar from "../../global/SearchBar";
import ProductsHandler from "../../../utils/products/ProductsHandler";
import { ProductsContext } from "../../../context/ProductsContext";

const ProductInputList = ({ product }) => {
  const {
    handleCreateChildProduct,
    handleEditChildProduct,
    handleDeleteChildProduct,
  } = ProductsHandler();

  const { getProductInsumos, product: contextProduct } =
    useContext(ProductsContext);
  const [insumos, setInsumos] = useState([]);

  useEffect(() => {
    getProductInsumos(product.idProducto, (insumos) => {
      setInsumos(insumos);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contextProduct && contextProduct.idProducto === product.idProducto) {
      getProductInsumos(product.idProducto, (insumos) => {
        setInsumos(insumos);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextProduct]);

  const renderActionButtons = (insumo) => {
    const deleteFunction = () =>
      handleDeleteChildProduct(product.idProducto, insumo.producto.idProducto);
    const editFunction = () =>
      handleEditChildProduct(
        product.idProducto,
        insumo.producto,
        insumo.cantidad
      );

    return (
      <div>
        <button className="btn">
          <i className="fa fa-bell"></i>
        </button>
        <button className="btn" onClick={editFunction}>
          <i className="fa fa-edit"></i>
        </button>
        <button className="btn" onClick={deleteFunction}>
          <i className="fa fa-trash"></i>
        </button>
      </div>
    );
  };

  const renderInsumos = () => {
    return insumos?.length > 0 ? (
      <div className="card shadow p-3">
        <table className=" w-100 text-center">
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>INSUMO</th>
              <th>UNIDAD</th>
              <th>CANTIDAD</th>
              <th>MÁXIMO</th>
              <th>MÍNIMO</th>
              <th>ACCIONES</th>
            </tr>
          </thead>
          <tbody>
            {insumos?.length > 0 &&
              insumos.map((insumo) => (
                <tr key={insumo.producto.idProducto}>
                  <td style={{ textAlign: "left" }}>
                    {insumo.producto.article}
                  </td>
                  <td>{insumo.producto.unit}</td>
                  <td>{insumo.cantidad}</td>
                  <td>{insumo.producto.maximum}</td>
                  <td>{insumo.producto.minimum}</td>
                  <td>
                    <div className="w-100 d-flex justify-content-center center">
                      {renderActionButtons(insumo)}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    ) : (
      <p>No existen insumos</p>
    );
  };

  return (
    <div>
      <div className="w-100 d-flex flex-row align-items-center mb-3">
        <div className="w-50">
          <h2 className="mb-3 bold">Insumos</h2>
        </div>
        <div className="w-50 d-flex align-items-center justify-content-between">
          <div className="w-75" style={{ paddingRight: "10px" }}>
            <SearchBar />
          </div>
          <div className="w-25">
            <button
              className="btn btn-primary w-100"
              style={{ minWidth: "118px" }}
              onClick={() => handleCreateChildProduct(product.idProducto)}
            >
              + INSUMO
            </button>
          </div>
        </div>
      </div>
      {renderInsumos()}
    </div>
  );
};

export default ProductInputList;
