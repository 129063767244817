import { EDIT_PAGO, HIDE_SPINNER, SHOW_SPINNER } from "../actions/types";
import {
  SAVE_PAGO,
  PAGO_TO_CANCEL,
  PAGOS_RECIBIDOS,
  SET_PROPERTY_PAGO,
  PAGOS_GENERALES_RECIBIDOS,
} from "../types/pagos";

const PagosReducer = (state, { type, payload }) => {
  switch (type) {
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    case PAGOS_RECIBIDOS:
      return { ...state, pagos: payload };
    case EDIT_PAGO:
      return {
        ...state,
        pago: payload,
        editMode: payload === null ? null : "edit-pago",
      };
    case SET_PROPERTY_PAGO:
      const pago = { ...state.pago };
      pago[payload.key] = payload.value;
      return { ...state, pago };
    case SAVE_PAGO:
      let pagos = [...state.pagos];
      let idxPago = pagos.findIndex((pago) => pago.idPago === payload.idPago);
      if (idxPago === -1) pagos.push(payload);
      else pagos[idxPago] = { ...payload };
      return { ...state, pagos, pago: null, editMode: null, loading: false };
    case PAGO_TO_CANCEL:
      return { ...state, pago: payload, editMode: "cancel-pago" };
    case PAGOS_GENERALES_RECIBIDOS:
      return { ...state, pagosGenerales: payload };

    default:
      return { ...state };
  }
};
export default PagosReducer;
