import api from "./api";

const route = "/partidasPreciario";

export default {
  getPartidasPreciario: (idProyecto) => api.get(`${route}/${idProyecto}`),
  getPartidasContrato: (idProyecto, idContrato) =>
    api.get(`${route}/${idProyecto}/${idContrato}`),
  postPartidasPreciario: (idProyecto, idContrato, partidas) =>
    api.post(`${route}/${idProyecto}/${idContrato}`, {
      partidas,
    }),
  postPreciario: (idProyecto, idContrato, formData) =>
    api.post(`${route}/${idProyecto}/${idContrato}/preciario`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
};
