import api from "./api";

const route = "/subdestajos";

export default {
  getSubdestajos: (idProyecto) => api.get(`${route}/${idProyecto}`),
  getSingleSubdestajo: (idProyecto, idSubDestajo) =>
    api.get(`${route}/${idProyecto}/subdestajo/${idSubDestajo}`),
  postSubdestajos: (idProyecto, subdestajos) =>
    api.post(`${route}/${idProyecto}`, { subdestajos }),
  postSingleSubdestajo: (idProyecto, subdestajo) =>
    api.post(`${route}/${idProyecto}/subdestajo/${subdestajo.idSubDestajo}`, {
      ...subdestajo,
    }),
  putSingleSubdestajo: (idProyecto, subdestajo) =>
    api.put(`${route}/${idProyecto}/subdestajo/${subdestajo.idSubDestajo}`, {
      ...subdestajo,
    }),
};
