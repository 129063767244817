import React, { createContext, useReducer } from "react";
import EditModeReducer from "../reducers/EditModeReducer";
import { EDIT_MODE_ON, EDIT_MODE_OFF, SET_LEVEL } from "../types/editmode";

const initialState = {
  editMode: false,
  permiso: "",
  level: "",
  open: 0,
};

export const EditModeContext = createContext(initialState);

export const EditModeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(EditModeReducer, initialState);

  const editModeOn = (value) => {
    dispatch({ type: EDIT_MODE_ON, payload: value });
  };

  const editModeOff = () => {
    dispatch({ type: EDIT_MODE_OFF });
  };

  const viewLevel = (level) => {
    dispatch({ type: SET_LEVEL, payload: level });
  };

  return (
    <EditModeContext.Provider
      value={{ ...state, editModeOn, editModeOff, viewLevel }}
    >
      {children}
    </EditModeContext.Provider>
  );
};
