import React, { useContext } from "react";
import { EstimacionesContext } from "../../context/EstimacionesContext";
import { getValue } from "../../utils";
import { ModalContext } from "../../context/ModalContext";

const EstimacionForm = ({ idProyecto, idContrato, handleCallback }) => {
  const { postEstimacion, estimacionEditada, setPropiedadEstimacion } =
    useContext(EstimacionesContext);
  const { clearModal } = useContext(ModalContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    postEstimacion(idProyecto, idContrato, estimacionEditada, handleCallback);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Numero</label>
      <input
        type="text"
        className="form-control mb-3"
        value={getValue(estimacionEditada, "numero")}
        onChange={(e) => setPropiedadEstimacion("numero", e.target.value)}
      />
      <label>Tipo</label>
      <select
        onChange={(e) => setPropiedadEstimacion("tipo", e.target.value)}
        value={getValue(estimacionEditada, "tipo")}
        className="form-control mb-3"
      >
        <option value="normal">Normal / Aditiva</option>
        <option value="extra">Extra</option>
      </select>
      <label>Periodo de Ejecución</label>
      <div className="row">
        <div className="col-6">
          <label>Del</label>
          <input
            type="date"
            className="form-control mb-3"
            value={getValue(estimacionEditada, "fecha_inicio", "date")}
            onChange={(e) =>
              setPropiedadEstimacion("fecha_inicio", e.target.value)
            }
          />
        </div>
        <div className="col-6">
          <label>Al</label>
          <input
            type="date"
            className="form-control mb-3"
            value={getValue(estimacionEditada, "fecha_fin", "date")}
            onChange={(e) =>
              setPropiedadEstimacion("fecha_fin", e.target.value)
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <button type="submit" className="btn w-100 btn-primary">
            Guardar
          </button>
        </div>
        <div className="col-6">
          <button
            type="button"
            onClick={clearModal}
            className="btn w-100 text-muted"
          >
            Cancelar
          </button>
        </div>
      </div>
    </form>
  );
};

export default EstimacionForm;
