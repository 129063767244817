import React from 'react'

const ProductLastComment = ({ productComment }) => {
    return (
        <div>
            <p className='mb-1 bold'>Ultimo Comentatio</p>
            <div className='card py-2 px-3 shadow'>
                {/* { productComment } */}
                <p className='small'>Occaecat proident esse qui occaecat nostrud excepteur. Laborum esse commodo cupidatat culpa consectetur quis quis eiusmod aute dolore laborum pariatur esse.
                    Adipisicing non voluptate aute Lorem qui quis pariatur aliqua eu. Pariatur id ad sunt nostrud laboris.
                    Incididunt do sint sunt quis sunt. Pariatur velit irure magna veniam esse culpa ipsum laborum voluptate nostrud officia mollit dolore.</p>
            </div>
        </div>

    );
}

export default ProductLastComment;