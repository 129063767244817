import React, { useContext, useEffect, useState } from "react";
import Switch from "react-switch";
import { hideModal } from "../../utils";
import TriggersAlerta from "./TriggersAlerta";
import SelectFolder from "../folder/SelectFolder";
import SelectPartida from "../partidas/SelectPartida";
import MultiUserPicker from "../users/MultiUserPicker";
import SelectDestajo from "../destajos/SelectDestajos";
import { ModalContext } from "../../context/ModalContext";
import { AlertasContext } from "../../context/AlertasContext";
import SelectSubDestajo from "../subdestajos/SelectSubdestajo";
import { DestajosContext } from "../../context/DestajosContext";
import { PartidasContext } from "../../context/PartidasContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import { SubdestajosContext } from "../../context/SubdestajosContext";
import AlertDatePicker from "./AlertDatePicker";
import AlertExtraUsers from "./AlertExtraUsers";
import AlertDateForm from "./AlertCustomForm";
import moment from "moment";

const AlertaForm = () => {
  const [objectType, setObjectType] = useState("");
  const [extraUsers, setExtraUsers] = useState([]);
  const [receptores, setReceptores] = useState([]);
  const [repeat, setRepeat] = useState(false);
  const [objeto, setObjeto] = useState("");

  const { subdestajos } = useContext(SubdestajosContext);

  const { proyecto } = useContext(ProyectosContext);

  const { partidas } = useContext(PartidasContext);

  const { destajos } = useContext(DestajosContext);

  const { alert } = useContext(ModalContext);

  const {
    alerta,
    postAlerta,
    setPropertyAlerta,
    setPropertyTrigger,
    createAlertTrigger,
    deleteTriggerAlert,
  } = useContext(AlertasContext);

  useEffect(() => {
    if (alerta !== null) {
      handleObjectType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerta]);

  const handleObjectType = () => {
    if (alerta.idPartida !== null && objectType !== "Partida") {
      setObjectType("Partida");
      let partida = partidas.find(
        (partida) => parseInt(partida.idPartida) === parseInt(alerta.idPartida)
      );
      setObjeto(partida);
    } else if (alerta.idDestajo !== null && objectType !== "Destajo") {
      setObjectType("Destajo");
      let destajo = destajos.find(
        (destajo) => parseInt(destajo.idDestajo) === parseInt(alerta.idDestajo)
      );
      setObjeto(destajo);
    } else if (alerta.idSubDestajo !== null && objectType !== "SubDestajo") {
      setObjectType("SubDestajo");
      let subdestajo = subdestajos.find(
        (subdestajo) =>
          parseInt(subdestajo.idSubDestajo) === parseInt(alerta.idPartida)
      );
      setObjeto(subdestajo);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { idProyecto } = proyecto;
    if (alerta.contenido === "" || alerta.contenido === null) {
      return alert("El mensaje no puede estar vacío");
    }
    if (alerta.triggers.length === 0 && !moment(alerta.fecha_hora).isValid()) {
      return alert("Debes agregar una condición o una fecha a la alerta.");
    }
    let currentReceptores = [...receptores, ...extraUsers];
    postAlerta(idProyecto, { ...alerta, receptores: currentReceptores });
  };

  const renderSelectObject = () => {
    if (objectType === "Partida") {
      return <SelectPartida selected={objeto} modifier={setObjeto} />;
    }
    if (objectType === "Destajo") {
      return <SelectDestajo selected={objeto} modifier={setObjeto} />;
    }
    if (objectType === "SubDestajo") {
      return <SelectSubDestajo selected={objeto} modifier={setObjeto} />;
    }
    if (objectType === "Carpeta") {
      return (
        <SelectFolder idProyecto={proyecto.idProyecto} modifier={setObjeto} />
      );
    }
  };

  const renderUsers = () => {
    if (proyecto && proyecto !== null && !alerta.all_users) {
      const { idProyecto } = proyecto;
      return (
        <div className="row">
          <MultiUserPicker
            selected={receptores}
            idProyecto={idProyecto}
            setSelected={setReceptores}
          />
        </div>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="container-fluid px-0">
      <label className="bold">Mensaje</label>
      <input
        type="text"
        className="form-control mb-3"
        value={alerta.contenido !== null ? alerta.contenido : ""}
        onChange={(e) => setPropertyAlerta("contenido", e.target.value)}
      />
      <label>Objeto de Alerta</label>
      <select
        value={objectType}
        className="form-control mb-3"
        onChange={(e) => setObjectType(e.target.value)}
      >
        <option>Proyecto</option>
        <option>Partida</option>
        <option>Destajo</option>
        <option>SubDestajo</option>
        <option>Carpeta</option>
      </select>
      {renderSelectObject()}
      <AlertDateForm
        alerta={alerta}
        repeat={repeat}
        setRepeat={setRepeat}
        setPropertyAlerta={setPropertyAlerta}
      />
      <TriggersAlerta
        alerta={alerta}
        objectType={objectType}
        deleteTrigger={deleteTriggerAlert}
        setPropertyAlerta={setPropertyAlerta}
        createAlertTrigger={createAlertTrigger}
        setPropertyTrigger={setPropertyTrigger}
      />
      <label className="d-flex align-items-center mt-2 mb-3">
        <Switch
          className="me-2"
          checked={alerta.all_users}
          onChange={(checked) => setPropertyAlerta("all_users", checked)}
        />{" "}
        Enviar alerta a todos los usuarios involucrados en el proyecto.
      </label>
      {renderUsers()}
      <AlertExtraUsers extraUsers={extraUsers} setExtraUsers={setExtraUsers} />
      <AlertDatePicker alerta={alerta} setPropertyAlerta={setPropertyAlerta} />
      <div className="row">
        <div className="col-6">
          <button className="btn btn-primary w-100">Guardar</button>
        </div>
        <div className="col-6 text-center">
          <button
            type="button"
            onClick={hideModal}
            className="btn btn-link text-muted"
          >
            Cancelar
          </button>
        </div>
      </div>
    </form>
  );
};

export default AlertaForm;
