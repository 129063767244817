export const modes = [
  { label: "Configuración", key: "config" },
  { label: "Revisión", key: "check" },
];

export const itemStatus = [
  {
    label: "Todo",
    value: "todo",
    icon: "fa fa-circle",
    className: "primary",
  },
  {
    label: "Buen Estado",
    value: "bueno",
    icon: "fa fa-check-circle",
    className: "success",
  },
  {
    label: "Mantenimiento",
    value: "mantenimiento",
    icon: "fa fa-info-circle",
    className: "warning",
  },
];

export const getSectionItems = (items, idChecklistSection, query) => {
  if (Array.isArray(items)) {
    let sectionItems = items.filter(
      (item) => String(item.idChecklistSection) === String(idChecklistSection)
    );
    if (query && query !== "" && query !== null) {
      const currentQuery = String(query).toLowerCase();
      sectionItems = sectionItems.filter((item) => {
        let nombre = String(item.nombre).toLowerCase();
        return nombre.startsWith(currentQuery) || nombre.includes(currentQuery);
      });
    }
    return sectionItems;
  }
};

export const filterChecklistSections = (sections, query, items) => {
  const currentQuery = String(query).toLowerCase();
  return sections.filter((section) => {
    let nombre = String(section.nombre).toLowerCase();
    return (
      nombre.startsWith(currentQuery) ||
      nombre.includes(currentQuery) ||
      getSectionItems(items, section.idChecklistSection, currentQuery)
    );
  });
};
