import React, { useContext, useEffect } from "react";
import { IonList, IonItem, IonIcon, IonLabel } from "@ionic/react";
import { folder, chevronForwardOutline } from "ionicons/icons";
import { MenuContext } from "../context/MenuContext";
import { redirect } from "react-router-dom";

const Folders = ({ idProyecto }) => {
  const { setSelected } = useContext(MenuContext);

  useEffect(() => {
    setSelected({ title: "folders" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid px-0">
      <div className="mx-n-15">
        <IonList inset={true} lines="full" className="shadow-sm">
          <IonItem onClick={() => redirect(`/obra/${idProyecto}/supervision`)}>
            <IonIcon slot="start" icon={folder} color="primary" />
            <IonLabel>Supervisión</IonLabel>
            <IonIcon slot="end" icon={chevronForwardOutline} />
          </IonItem>
          <IonItem onClick={() => redirect(`/obra/${idProyecto}/catalogo`)}>
            <IonIcon slot="start" icon={folder} color="primary" />
            <IonLabel>Catálogo</IonLabel>
            <IonIcon slot="end" icon={chevronForwardOutline} />
          </IonItem>
          <IonItem onClick={() => redirect(`/obra/${idProyecto}/proyecto`)}>
            <IonIcon slot="start" icon={folder} color="primary" />
            <IonLabel>Proyecto</IonLabel>
            <IonIcon slot="end" icon={chevronForwardOutline} />
          </IonItem>
        </IonList>
      </div>
    </div>
  );
};

export default Folders;
