import React from "react";
import CheckBox from "../obra/CheckBox";
import { IonItem, IonLabel, IonButton } from "@ionic/react";
import AvancePickerMobile from "./AvancePickerMobile";

const SubdestajoMobile = ({ subdestajo, setSubdestajo }) => {
  const isComplete = (subdestajo) => {
    return (
      parseFloat(subdestajo.real) === 1 || parseFloat(subdestajo.progreso) === 1
    );
  };

  const setCantidad = (cantidad) => {
    cantidad -= parseInt(subdestajo.progreso);
    createAvance(cantidad);
  };

  const createAvance = (cantidad) => {};

  return (
    <IonItem key={subdestajo.idDestajo} className="py-2" lines="none">
      <CheckBox checked={isComplete(subdestajo)} disabled />
      <IonLabel className="ion-text-wrap ps-2" color="primary">
        <span className="bold">{subdestajo.nombre}</span>
      </IonLabel>
      <IonButton
        slot="end"
        fill="light"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setSubdestajo(subdestajo);
        }}
        className="border-0 mx-0 px-1 bg-white"
        style={{ color: "#b76d4f" }}
      >
        <i className="fa fa-info"></i>
      </IonButton>
      <AvancePickerMobile
        intervalo={1}
        setCantidad={setCantidad}
        progreso={subdestajo.progreso}
      />
    </IonItem>
  );
};

export default SubdestajoMobile;
