import React, { createContext, useContext, useReducer } from "react";
import PlantillasReducer from "../reducers/PlantillasReducer";
import PlantillasService from "../services/PlantillasService";
import { PLANTILLAS_RECEIVED, SET_PLANTILLA } from "../types/plantillas";
import { ModalContext } from "./ModalContext";

const initialState = {
  plantillas: null,
  plantilla: null,
};

export const PlantillasContext = createContext(initialState);

export const PlantillasProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PlantillasReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getPlantillas = (tipo) => {
    PlantillasService.getPlantillas(tipo)
      .then((res) => {
        const { plantillas } = res.data;
        dispatch({ type: PLANTILLAS_RECEIVED, payload: plantillas });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const crearPlantillaChecklist = (nombre, idChecklist) => {
    PlantillasService.postPlantilla(nombre, idChecklist)
      .then(() => {
        success(`Plantilla ${nombre} guardada exitosamente.`);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const crearPlantillaCronograma = (
    partidas,
    destajos,
    subdestajos,
    nombre
  ) => {
    if (!nombre) {
      return alert("Por favor, agrega un nombre a la plantilla.");
    }
    let plantilla = {};
    plantilla.partidas = partidas.map((partida) => {
      let currPartida = {
        nombre: partida.nombre,
        ponderacion: partida.ponderacion * 100,
        punto_critico: partida.punto_critico,
        orden: partida.orden,
      };
      let currDestajos = destajos.filter(
        (destajo) => destajo.idPartida === partida.idPartida
      );
      currPartida.destajos = currDestajos.map((destajo) => {
        let currDestajo = {
          nombre: destajo.nombre,
          ponderacion: destajo.ponderacion * 100,
          orden: destajo.orden,
        };
        let currSubDestajos = subdestajos.filter(
          (subdestajo) => subdestajo.idDestajo === destajo.idDestajo
        );
        currDestajo.subDestajos = currSubDestajos.map((subdestajo) => {
          let currSubdestajo = {
            nombre: subdestajo.nombre,
            ponderacion: subdestajo.ponderacion * 100,
            orden: subdestajo.orden,
          };
          return currSubdestajo;
        });
        return currDestajo;
      });
      return currPartida;
    });
    plantilla.nombre = nombre;
    plantilla.tipo = "cronograma";
    PlantillasService.postPlantilla(plantilla)
      .then(() => {
        success(`Plantilla ${plantilla.nombre} guardada exitosamente.`);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const selectPlantilla = (id) => {
    dispatch({ type: SET_PLANTILLA, payload: id });
  };

  const setPlantilla = (idProyecto, idPlantilla) => {
    PlantillasService.setPlantilla(idProyecto, idPlantilla)
      .then(() => {
        success("Plantilla aplicada con exito.");
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setPlantillaChecklist = (idProyecto, idChecklist, idPlantilla) => {
    PlantillasService.setPlantillaChecklist(
      idProyecto,
      idChecklist,
      idPlantilla
    )
      .then(() => {
        success("Plantilla aplicada con exito.");
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setPreciarioObra = (idProyecto, callback) => {
    PlantillasService.postPreciario(idProyecto)
      .then((res) => {
        success("Conceptos de preciario cargados con éxito.");
        if (callback) callback();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const updatePlantilla = (idPlantilla, nombre, callback) => {
    PlantillasService.putPlantilla(idPlantilla, nombre).then((res) => {
      success("Plantilla guardada");
      if (typeof callback === "function") {
        callback();
      }
    });
  };

  const deletePlantilla = (idPlantilla) => {
    PlantillasService.deletePlantilla(idPlantilla).then(() => {
      success("Plantilla eliminada.");
      clearModal();
    });
  };

  return (
    <PlantillasContext.Provider
      value={{
        ...state,
        setPlantilla,
        getPlantillas,
        updatePlantilla,
        deletePlantilla,
        selectPlantilla,
        setPreciarioObra,
        setPlantillaChecklist,
        crearPlantillaChecklist,
        crearPlantillaCronograma,
      }}
    >
      {children}
    </PlantillasContext.Provider>
  );
};
