import React, { useContext, useEffect } from "react";
import { CambioEstimacionVersionesContext } from "../../context/CambioEstimacionVersionsContext";
import EstimacionVersionHeader from "./EstimacionVersionHeader";
import useProyecto from "../../hooks/useProyecto";
import QRCode from "react-qr-code";

const EstimacionVersionPortada = ({ idEstimacionVersion }) => {
  const { cambioestimacionversiones, getCambioEstimacionVersiones } =
    useContext(CambioEstimacionVersionesContext);
  const { idProyecto } = useProyecto();

  useEffect(() => {
    getCambioEstimacionVersiones(idProyecto, idEstimacionVersion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto, idEstimacionVersion]);

  const renderFirmaSupervision = () => {
    const cambioSupervisor = cambioestimacionversiones?.find(
      (cambio) =>
        cambio.estado_anterior === "enviada" &&
        ["revisada", "revisada-sin-supervisor"].includes(cambio.estado_nuevo)
    );
    if (cambioSupervisor && cambioSupervisor !== null) {
      if (cambioSupervisor.hash !== null)
        return <QRCode value={cambioSupervisor.hash} />;
    }
  };

  const renderFirmaContratista = () => {
    const enviadaContratista = cambioestimacionversiones?.find(
      (cambio) =>
        cambio.estado_anterior === "creada" && cambio.estado_nuevo === "enviada"
    );
    const conciliadaSupervisor = cambioestimacionversiones?.find(
      (cambio) =>
        ["revisada", "revisada-sin-supervisor"].includes(
          cambio.estado_anterior
        ) &&
        [
          "conciliada",
          "conciliada-sin-contratista",
          "conciliada-sin-supervisor",
        ].includes(cambio.estado_nuevo)
    );
    const conciliadaObras = cambioestimacionversiones?.find(
      (cambio) =>
        ["autorizada", "autorizada-sin-obras"].includes(
          cambio.estado_anterior
        ) &&
        [
          "conciliada-con-obras",
          "conciliada-con-obras-sin-contratista",
        ].includes(cambio.estado_nuevo)
    );
    const cambios = [
      enviadaContratista,
      conciliadaSupervisor,
      conciliadaObras,
    ].filter((item) => item && item !== null);
    return cambios
      .map((cambio) =>
        cambio.hash !== null ? <QRCode value={cambio.hash} /> : null
      )
      .filter((item) => item !== null);
  };

  const renderFirmaObras = () => {
    const cambioObras = cambioestimacionversiones?.find(
      (cambio) =>
        [
          "conciliada",
          "conciliada-sin-contratista",
          "conciliada-sin-supervisor",
        ].includes(cambio.estado_anterior) &&
        ["autorizada", "autorizada-sin-obras"].includes(cambio.estado_nuevo)
    );
    if (cambioObras && cambioObras !== null) {
      if (cambioObras.hash !== null) return <QRCode value={cambioObras.hash} />;
    }
  };

  const renderFirmaGerencia = () => {
    const cambioGerencia = cambioestimacionversiones?.find(
      (cambio) =>
        [
          "conciliada-con-obras",
          "conciliada-con-obras-sin-contratista",
        ].includes(cambio.estado_anterior) && cambio.estado_nuevo === "liberada"
    );
    if (cambioGerencia && cambioGerencia !== null) {
      if (cambioGerencia.hash !== null)
        return <QRCode value={cambioGerencia.hash} />;
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container-fluid px-0">
          <EstimacionVersionHeader title="HOJA DE PORTADA" view="portada" />
        </div>
      </div>
      <div className="row">
        <div className="container-fluid px-0">
          <div className="row my-3">
            <div className="col-6 col-xl-3 px-0">
              <table className="table border">
                <thead className="bg-secondary bold">
                  <tr className="text-center">
                    <td style={{ height: 120 }}>
                      RECIBE SUPERVISIÓN DE EMPRESA CONTRATISTA
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ height: 200 }}>
                    <td>{renderFirmaContratista()}</td>
                  </tr>
                  <tr>
                    <td>Firma:</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-6 col-xl-3 px-0">
              <table className="table border">
                <thead className="bg-secondary bold">
                  <tr className="text-center">
                    <td style={{ height: 120 }}>
                      RECIBE GERENCIA DE OBRAS E INSTALACIONES Y/O MANTENIMIENTO
                      DE SUPERVISIÓN
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ height: 200 }}>
                    <td>{renderFirmaSupervision()}</td>
                  </tr>
                  <tr>
                    <td>Firma:</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-6 col-xl-3 px-0">
              <table className="table border">
                <thead className="bg-secondary bold">
                  <tr className="text-center">
                    <td style={{ height: 120 }}>
                      RECIBE GERENCIA DE ADMON Y CONTROL DE GERENCIA DE OBRAS E
                      INSTALACIONES Y/O MANTENIMIENTO
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ height: 200 }}>
                    <td>{renderFirmaObras()}</td>
                  </tr>
                  <tr>
                    <td>Firma:</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-6 col-xl-3 px-0">
              <table className="table border">
                <thead className="bg-secondary bold">
                  <tr className="text-center">
                    <td style={{ height: 120 }}>
                      Vo.Bo. GERENCIA DE ADMINISTRACIÓN Y CONTROL
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ height: 200 }}>
                    <td>{renderFirmaGerencia()}</td>
                  </tr>
                  <tr>
                    <td>Firma:</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstimacionVersionPortada;
