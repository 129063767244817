import React, { useContext, useEffect } from "react";
import { DestajosContext } from "../../context/DestajosContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import ItemDetail from "./ItemDetail";

const DestajoDetail = ({ idProyecto, destajo, callback }) => {
  const { proyecto } = useContext(ProyectosContext);
  const { getSingleDestajo, postSingleDestajo, setPropertySingleDestajo } =
    useContext(DestajosContext);

  useEffect(() => {
    fetchDestajo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDestajo = () => {
    getSingleDestajo(idProyecto, destajo.idDestajo);
  };

  return (
    <div className="container-fluid py-4">
      <ItemDetail
        id="idDestajo"
        item={destajo}
        proyecto={proyecto}
        itemLabel="Destajo"
        callback={callback}
        saveItem={postSingleDestajo}
        modifier={setPropertySingleDestajo}
      />
    </div>
  );
};

export default DestajoDetail;
