
import {
  SET_CONCEPTOMEDICION,
  CREATE_CONCEPTOMEDICION,
  CONCEPTOMEDICIONS_RECEIVED,
  SET_PROPERTY_CONCEPTOMEDICION,
} from "../types/conceptosmediciones";

const schema = {

}

const conceptosmedicionesReducer = (state, { type, payload }) => {
  switch (type) {
    case CONCEPTOMEDICIONS_RECEIVED:
      return { ...state, conceptosmediciones: payload };
    case SET_CONCEPTOMEDICION:
      return { ...state, conceptoMedicion: payload };
    case CREATE_CONCEPTOMEDICION:
      return { ...state, conceptoMedicion: schema };
    case SET_PROPERTY_CONCEPTOMEDICION: {
      const { key, value } = payload;
      const conceptoMedicion = { ...state.conceptoMedicion };
      conceptoMedicion[key] = value;
      return { ...state, conceptoMedicion };
    }
    default:
      return { ...state};
  }
};

export default conceptosmedicionesReducer;
