export const CLIENTES_RECIBIDOS = "CLIENTES_RECIBIDOS";
export const CLIENTE_RECIBIDO = "CLIENTE_RECIBIDO";
export const USUARIOS_CLIENTE_RECIBIDOS = "USUARIOS_CLIENTE_RECIBIDOS";
export const SAVE_USER_CLIENTE = "SAVE_USER_CLIENTE";
export const REMOVE_USER_CLIENTE = "REMOVE_USER_CLIENTE";
export const SET_PROPERTY_CLIENTE = "SET_PROPERTY_CLIENTE";
export const CREATE_USUARIO_CLIENTE = "CREATE_USUARIO_CLIENTE";
export const DELETE_CLIENTE = "DELETE_CLIENTE";
export const OBRAS_CLIENTE_RECIBIDAS = "OBRAS_CLIENTE_RECIBIDAS";
export const CREATE_OBRA_CLIENTE = "CREATE_OBRA_CLIENTE";
export const CLEAR_CLIENTE = "CLEAR_CLIENTE";
export const SET_OBRA_CLIENTE = "SET_OBRA_CLIENTE";
export const CLEAR_OBRA_CLIENTE = "CLEAR_OBRA_CLIENTE";
export const OBRAS_SINCLIENTE_RECIBIDAS = "OBRAS_SINCLIENTE_RECIBIDAS";
