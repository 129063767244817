import React, { useContext, useEffect } from "react";
import { OrdenesContext } from "../context/OrdenesContext";
import { ProyectosContext } from "../context/ProyectosContext";
import { AuthContext } from "../context/AuthContext";
import OrdenCard from "../components/ordenes/OrdenCard";
import { MenuContext } from "../context/MenuContext";

const OrderList = ({ idProyecto }) => {
  const { user } = useContext(AuthContext);
  const { proyecto } = useContext(ProyectosContext);
  const { ordenes, getOrdenes, approveOrder } = useContext(OrdenesContext);
  const { setSelected } = useContext(MenuContext);

  useEffect(() => {
    setSelected({ title: 'ordenes' })
    getOrdenes(idProyecto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApprove = async (idOrder) => {
    approveOrder(proyecto.idProyecto, idOrder);
    // Realiza acciones adicionales después de aprobar la orden, como actualizar el estado
  };

  const renderOrdenes = () => {
    if (Array.isArray(ordenes)) {

      return ordenes.map((orden) => (
        <OrdenCard
          key={orden.id}
          orden={orden}
          handleApprove={handleApprove}
          permiso={proyecto !== null ? proyecto.permiso : user.permiso}
        />
      ));
    }
  };

  return <div className="container-fluid px-0 pt-2">{renderOrdenes()}</div>;
};

export default OrderList;
