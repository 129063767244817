import moment from "moment";
import React, { useContext } from "react";
import useProyecto from "../../hooks/useProyecto";
import { ConceptosContext } from "../../context/ConceptosContext";
import { ContratosContext } from "../../context/ContratosContext";
import { EstimacionesContext } from "../../context/EstimacionesContext";
import { EstimacionVersionesContext } from "../../context/EstimacionVersionesContext";
import { formatMonto } from "../../utils";

const EstimacionVersionHeader = ({ title, view }) => {
  const { proyecto, usuarios } = useProyecto();
  const { contrato } = useContext(ContratosContext);
  const { concepto } = useContext(ConceptosContext);
  const { estimacion } = useContext(EstimacionesContext);
  const { estimacionversion } = useContext(EstimacionVersionesContext);

  const renderSupervision = () => {
    if (Array.isArray(usuarios)) {
      return usuarios
        .filter((usuario) => usuario.permiso === "supervisor")
        .map((usuario) => usuario.nombre)
        .join(", ");
    }
  };

  const renderView = () => {
    if (view === "portada") {
      return (
        <>
          <tr className="border">
            <td className="border">CONTRATO</td>
            <td>{contrato?.folio}</td>
          </tr>
          <tr className="border">
            <td className="border">NUMERO DE REGISTRO DEL CONTRATISTA</td>
            <td>{contrato?.Proveedor?.num_registro}</td>
          </tr>
          <tr className="border">
            <td className="border">IMPORTE DEL PAQUETE:</td>
            <td>
              ${formatMonto((() => {
                const { estado, autorizado, supervisado, estimado } =
                  estimacionversion;
                if (
                  [
                    "autorizada",
                    "autorizada-sin-obras",
                    "conciliada-con-obras",
                    "conciliada-con-obras-sin-contratista",
                    "liberada",
                  ].includes(estado) &&
                  autorizado !== null
                ) {
                  return autorizado;
                }
                if (
                  [
                    "revisada",
                    "revisada-sin-supervisor",
                    "conciliada",
                    "conciliada-sin-contratista",
                    "conciliada-sin-supervisor",
                  ].includes(estado) &&
                  supervisado !== null
                ) {
                  return supervisado;
                }
                return estimado;
              })())}
            </td>
          </tr>
        </>
      );
    }
    if (view === "generador") {
      return (
        <>
          <tr className="border bg-primary text-white">
            <td>CONCEPTO:</td>
          </tr>
          <tr className="border">
            <td>{concepto?.concepto}</td>
          </tr>
          <tr className="border">
            <td>CONTRATO: {contrato?.folio}</td>
          </tr>
        </>
      );
    }
    return (
      <>
        <tr className="border">
          <td className="border">CONTRATO</td>
          <td>{contrato?.folio}</td>
        </tr>
        <tr className="border">
          <td className="border">NUMERO DE REGISTRO DEL CONTRATISTA</td>
          <td>{contrato?.Proveedor?.num_registro}</td>
        </tr>
        <tr>
          <td>IMPORTE SOLICITADO</td>
          <td></td>
        </tr>
        <tr>
          <td>IMPORTE AUTORIZADO</td>
          <td></td>
        </tr>
      </>
    );
  };

  return (
    <div className="row">
      <div className="hide-mobile col-12 col-md-6 col-xl-4 px-md-0">
        <table className="table mb-md-0 border">
          <tbody>
            <tr className="text-end bg-black text-white">
              <td>SUBDIRECCIÓN DE INMUEBLES Y SERVICIOS</td>
            </tr>
            <tr className="text-end bg-dark text-white">
              <td>GERENCIA DE ADMINISTRACIÓN Y CONTROL</td>
            </tr>
            <tr className="text-end bg-dark text-white">
              <td>GERENCIA DE OBRAS E INSTALACIONES</td>
            </tr>
            <tr className="text-end bg-secondary">
              <td>GERENCIA DE MANTENIMIENTO</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="hide-mobile col-12 col-md-6 col-xl-4 px-md-0">
        <table className="table mb-md-0 border text-center">
          <tbody>
            <tr className="border bg-primary text-white">
              <td>{title}</td>
            </tr>
            <tr className="border">
              <td>EMPRESA CONTRATISTA</td>
            </tr>
            <tr className="border">
              <td>{contrato?.Proveedor?.razon_social}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-12 col-md-6 col-xl-4 px-md-0">
        <table className="table mb-md-0 border text-center">
          <tbody>
            <tr className="hide-mobile border bg-primary text-white">
              <td colSpan={5}>HOJA</td>
            </tr>
            <tr className="hide-mobile border">
              <td className="text-center py-0">
                <div className="row align-items-center">
                  <div className="col">
                    {view === "portada" ? 1 : view === "resumen" ? 2 : 3}
                  </div>
                  <div className="col border py-2">DE</div>
                  <div className="col">3</div>
                </div>
              </td>
            </tr>
            <tr className="border bg-primary text-white">
              <td colSpan={5}>FECHA</td>
            </tr>
            <tr className="border">
              <td colSpan={5}>
                {moment(estimacionversion?.createdAt).format("DD MMM YYYY")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-12 col-md-6 col-xl-4 px-md-0">
        <table className="table mb-md-0 border">
          <tbody>{renderView()}</tbody>
        </table>
      </div>
      <div className="col-12 col-md-6 col-xl-4 px-md-0">
        <table className="table mb-md-0 border">
          <tbody>
            <tr className="border bg-primary text-white">
              <td colSpan={2}>PERIODO DE EJECUCION</td>
            </tr>
            <tr className="border">
              <td className="border" style={{ width: 25 }}>
                DE:
              </td>
              <td>{moment(estimacion?.fecha_inicio).format("DD MMM YYYY")}</td>
            </tr>
            <tr className="border">
              <td className="border" style={{ width: 25 }}>
                AL:
              </td>
              <td>{moment(estimacion?.fecha_fin).format("DD MMM YYYY")}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-12 col-md-6 col-xl-4 px-md-0">
        <table className="table mb-md-0 border">
          <tbody>
            <tr className="border">
              <td className="border" style={{ width: 125 }}>
                PAQUETE NO.:
              </td>
              <td>{estimacion?.numero}</td>
              <td className="border" style={{ widows: 50 }}>
                TIPO:
              </td>
              <td className="text-uppercase">{estimacion?.tipo}</td>
            </tr>
            <tr className="border">
              <td className="border" style={{ width: 125 }}>
                OBRA:
              </td>
              <td colSpan={3}>{proyecto.nombre}</td>
            </tr>
            <tr className="border">
              <td className="border" style={{ width: 125 }}>
                SUPERVISION:
              </td>
              <td colSpan={3}>{renderSupervision()}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EstimacionVersionHeader;
