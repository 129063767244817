import React, { createContext, useReducer } from "react";
import MontosReducer from "../reducers/MontosReducer";
import MontosService from "../services/MontosService";
import { MONTOS_RECIBIDOS } from "../types/montos";

const initialState = {
  montos: null,
};

export const MontosContext = createContext(initialState);

export const MontosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MontosReducer, initialState);

  const getMontosProyecto = (idProyecto) => {
    MontosService.getMontosProyecto(idProyecto).then((res) => {
      const montos = res.data;
      dispatch({ type: MONTOS_RECIBIDOS, payload: montos });
    });
  };

  const getMontosContrato = (idProyecto, idContrato) => {
    MontosService.getMontosContrato(idProyecto, idContrato).then((res) => {
      const montos = res.data;
      dispatch({ type: MONTOS_RECIBIDOS, payload: montos });
    });
  };

  return (
    <MontosContext.Provider
      value={{ ...state, getMontosContrato, getMontosProyecto }}
    >
      {children}
    </MontosContext.Provider>
  );
};
