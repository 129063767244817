import React from "react";
import { IonItem, IonLabel, IonButton } from "@ionic/react";

const PartidaMobile = ({ partida, collapsed, setPartida, toggleDestajos }) => {
  return (
    <IonItem key={partida.idPartida} className="px-0">
      {/* <CheckBox checked={isComplete(partida)} disabled /> */}

      <IonLabel className="ion-text-wrap">
        {/* <span style={{ color: "#db8c63" }} className="bold me-2">
          {formatPonderacion(partida.real)}
        </span>*/}
        <button
          slot="end"
          fill="light"
          className="btn btn-light p-0"
          style={{
            backgroundColor: "#db8c63",
            color: "white",
            width: "30px",
            height: "30px",
          }}
          onClick={() => toggleDestajos(partida.idPartida)}
        >
          <i className={`fa fa-chevron-${collapsed ? "down" : "up"}`}></i>
        </button>

        <span className="ps-2 bold">{partida.nombre}</span>
      </IonLabel>
      <IonButton
        slot="end"
        fill="light"
        style={{ color: "#b76d4f" }}
        onClick={() => setPartida(partida)}
        className="border mx-1 px-1 bg-white border-0"
      >
        <i className="fa fa-info"></i>
      </IonButton>
    </IonItem>
  );
};

export default PartidaMobile;
