import React, { useContext, useState } from 'react';
import { OrdenesContext } from '../../context/OrdenesContext';
import { AuthContext } from '../../context/AuthContext';

const ProductOrderUpdatesForm = ({ order, clearModal }) => {
    const [status, setStatus] = useState(order.status);
    const [selectedDate, setSelectedDate] = useState(order?.fecha_entrega);
    const { updateOrder } = useContext(OrdenesContext)
    const { user } = useContext(AuthContext);

    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    const handleSubmit = () => {
        updateOrder(order.idOrden, { ...order, status, fecha_entrega: selectedDate, user: user.idUsuario });
    };

    return (
        <div>
            <label>Status:</label>
            <select
                className="mb-3 form-control"
                value={status}
                onChange={handleStatusChange}
            >
                <option value="solicitado">Solicitado</option>
                <option value="aprobado">Aprobado</option>
                <option value="recibido">Recibido</option>
            </select>


            <label>Fecha de Entrega:</label>
            <input
                type="date"
                className="mb-4 form-control"
                value={selectedDate}
                onChange={handleDateChange}
            />

            <div className="w-100 d-flex mt-4">
                <button className="btn me-1 w-50" onClick={clearModal}> Cancelar </button>
                <button
                    className="btn btn-primary ms-1 w-50"
                    onClick={handleSubmit}
                >
                    Actualizar Estatus
                </button>
            </div>
        </div>
    );
};

export default ProductOrderUpdatesForm;
