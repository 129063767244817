import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";

const UsuarioFila = ({ usuario, editUsuario, deleteUsuario }) => {
  const { S3_ENDPOINT } = useContext(AppConfigContext);

  const userImgSrc = 
    usuario.idAdjunto !== undefined && usuario.tipo_adjunto !== undefined ?
    `${S3_ENDPOINT}/files/adjuntos/${usuario.idAdjunto}.${usuario.tipo_adjunto}` : '';

  return (
    <tr className="hover-light border-bottom">
      <td className="py-4 ps-5">{usuario.nombre}</td>
      <td className="py-4">
        <img
          src={userImgSrc}
          alt={usuario.nombre}
          className="card-img-top rounded-circle"
          style={{ width: "50px", height: '50px', objectFit: "cover" }}
        />
      </td>
      <td className="py-4">
        <i className="fa fa-envelope text-primary" />
        <span className="ms-2">{usuario.correo}</span>
      </td>
      <td className="py-4 text-capitalize">{usuario.permiso}</td>
      <td className="py-4">
        <button
          className="btn btn-sm btn-outline-primary me-3"
          onClick={() => editUsuario(usuario)}
        >
          <i className="fa fa-edit" />
        </button>
        <button
          className="btn btn-sm btn-outline-danger"
          onClick={() => deleteUsuario(usuario)}
        >
          <i className="fa fa-trash" />
        </button>
      </td>
    </tr>
  );
};

export default UsuarioFila;
