import {
  CLEAR_ALERTS,
  CHECK_ALERTA,
  CREATE_ALERTA,
  ALERTAS_RECEIVED,
  TOGGLE_ALERT_CARD,
  CLEAR_SINGLE_ALERT,
  SET_PROPERTY_ALERTA,
  SET_PROPERTY_TRIGGER,
  CREATE_ALERT_TRIGGER,
  DELETE_TRIGGER_ALERT,
  SHOW_SPINNER_ALERTAS,
  HIDE_SPINNER_ALERTAS,
  GLOBAL_ALERTAS_RECEIVED,
} from "../types/alertas";

const schema = {
  all_triggers: false,
  idSubDestajo: null,
  idAlerta: "nueva",
  fecha_hora: "",
  idProyecto: null,
  idDestajo: null,
  idPartida: null,
  contenido: null,
  all_users: true,
  interval: null,
  triggers: [],
  period: null,
  times: null,
};

const triggerSchema = (index) => ({
  idTrigger: `nuevo-${index}`,
  type: "progress",
  operator: ">=",
  treshold: "",
});

export default (state, { type, payload }) => {
  switch (type) {
    case ALERTAS_RECEIVED:
      return { ...state, alertas: payload };
    case GLOBAL_ALERTAS_RECEIVED:
      let showAlertasDot = payload.length > 0 || typeof payload === "object";
      return { ...state, alertas: payload, showAlertasDot };
    case TOGGLE_ALERT_CARD:
      return {
        ...state,
        showAlertasCard: !state.showAlertasCard,
        showAlertasDot: false,
      };
    case CREATE_ALERTA:
      return { ...state, alerta: schema };
    case CHECK_ALERTA:
      let alertas = { ...state.alertas };
      for (let tipo in alertas) {
        let alertasTipo = alertas[tipo];
        for (let i = 0; i < alertasTipo.length; i++) {
          if (parseInt(alertasTipo[i].idAlerta) === parseInt(payload)) {
            alertasTipo.splice(i, 1);
            break;
          }
        }
      }
      return { ...state, alertas };
    case SET_PROPERTY_ALERTA: {
      const alerta = { ...state.alerta };
      const { key, value } = payload;
      alerta[key] = value;
      return { ...state, alerta };
    }
    case SHOW_SPINNER_ALERTAS:
      return { ...state, showSpinner: true };
    case HIDE_SPINNER_ALERTAS:
      return { ...state, showSpinner: false };
    case CLEAR_ALERTS:
      return { ...state, alertas: [] };
    case CREATE_ALERT_TRIGGER: {
      const alerta = { ...state.alerta };
      if (!Array.isArray(alerta.triggers)) {
        alerta.triggers = [];
      }
      alerta.triggers = [
        ...alerta.triggers,
        triggerSchema(alerta.triggers.length),
      ];
      return { ...state, alerta };
    }
    case DELETE_TRIGGER_ALERT: {
      const alerta = { ...state.alerta };
      const idTrigger = payload;
      let { triggers } = alerta;
      if (Array.isArray(triggers)) {
        triggers = [...triggers];
        let index = triggers.findIndex(
          (trigger) => trigger.idTrigger === idTrigger
        );
        if (index !== -1) {
          triggers.splice(index, 1);
        }
        alerta.triggers = triggers;
      }
      return { ...state, alerta };
    }
    case SET_PROPERTY_TRIGGER: {
      const alerta = { ...state.alerta };
      const { idTrigger, key, value } = payload;
      let triggers = alerta.triggers;
      if (!Array.isArray(triggers)) {
        triggers = [];
      }
      triggers = [...triggers];
      let index = triggers.findIndex(
        (trigger) => trigger.idTrigger === idTrigger
      );
      if (index !== -1) {
        triggers[index][key] = value;
      }
      return { ...state, alerta: { ...alerta, triggers } };
    }
    case CLEAR_SINGLE_ALERT:
      return { ...state, alerta: null };
    default:
      return { ...state };
  }
};
