import { HIDE_SPINNER, SHOW_SPINNER } from "../actions/types";
import {
  RECORRIDOS_RECIBIDOS,
  RECORRIDO_RECIBIDO,
  AGREGAR_RECORRIDO,
  SET_PROPERTY_TOUR,
  UPDATE_TOUR,
  DELETE_TOUR,
} from "../types/recorridos";

export default (state, { type, payload }) => {
  switch (type) {
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    case RECORRIDOS_RECIBIDOS:
      return { ...state, recorridos: payload };
    case RECORRIDO_RECIBIDO:
      return { ...state, recorrido: payload };
    case AGREGAR_RECORRIDO:
      return {
        ...state,
        recorridos: [...state.recorridos, payload],
        recorrido: undefined,
      };
    case SET_PROPERTY_TOUR:
      let recorrido = { ...state.recorrido };
      const { key, value } = payload;
      recorrido[key] = value;
      return { ...state, recorrido };
    case UPDATE_TOUR:
      let recorridos = [...state.recorridos];
      let index = recorridos.findIndex(
        (recorrido) => recorrido.idTour === payload.idTour
      );
      if (index !== -1) recorridos[index].nombre = payload.nombre;
      return { ...state, recorridos };
    case DELETE_TOUR:
      let tours = [...state.recorridos];
      let idx = tours.findIndex((recorrido) => recorrido.idTour === payload);
      if (idx !== -1) tours.splice(index, 1);
      return { ...state, recorridos: tours };
    default:
      return { ...state };
  }
};
