import React from "react";
import UserItem from "./UserItem";
import { useNavigate } from "react-router-dom";

const UserCard = ({ user, children, handleEdit, handleDelete }) => {
  const navigate = useNavigate();
  return (
    <div
      className="card p-3 shadow-sm mb-2 cursor-pointer hover-border-primary"
      onClick={() => navigate(`/admin/users/${user.user_id}`)}
    >
      <UserItem user={user} handleEdit={handleEdit} handleDelete={handleDelete}>
        {children}
      </UserItem>
    </div>
  );
};

export default UserCard;
