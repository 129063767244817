import React, { useState, useEffect, useContext } from "react";
import { EntradasContext } from "../../context/EntradasContext";
import { ModalContext } from "../../context/ModalContext";

const ImageInput = ({
  value,
  base_url,
  modifier,
  multiple,
  hideImage,
  hideLabel,
}) => {
  const [src, setSrc] = useState("");
  const [files, setFiles] = useState([]);

  const { previewAdjunto, clearAdjuntos } = useContext(EntradasContext);

  const { alert } = useContext(ModalContext);

  useEffect(() => {
    if (value && files === null) {
      if (base_url && base_url !== null) {
        setSrc(`${base_url}/${value}`);
      } else {
        setSrc(value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    clearAdjuntos();
    if (files?.length > 0 && files !== undefined) {
      files.forEach((file) => {
        let reader = new FileReader();
        const name = file.name;

        reader.onload = (e) => {
          let src = e.target.result;
          setSrc(src);
          previewAdjunto(name, src);
        };

        reader.readAsDataURL(file);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const renderImages = () => {
    if (multiple && Array.isArray(value)) {
    }
  };

  const renderImagen = (currentFiles) => {
    if (multiple) {
      modifier([...currentFiles]);
      setFiles([...currentFiles]);
    } else {
      modifier(currentFiles[0]);
      setFiles([currentFiles[0]]);
    }
  };

  const selectFile = () => {
    document.getElementById("fileInput").click();
  };

  const imageInputHandler = (e) => {
    const imagesPassLimit = verifyImagesLimit(e.target.files);

    if (!imagesPassLimit && e.target.files !== undefined) {
      renderImagen(e.target.files);
    }
  };

  const verifyImagesLimit = (files) => {
    if (files.length > 10) {
      alert("Solo se pueden cargar hasta 10 imagenes a la vez");
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="container-fluid px-0 " style={{ position: "relative" }}>
      <div className="row align-items-center mx-0 ">
        {renderImages()}

        <div className={`col-12 ${!hideImage ? "col-md-6" : ""} px-0`}>
          <input
            className="d-none"
            style={{ cursor: "pointer" }}
            id="fileInput"
            type="file"
            accept="image/*"
            multiple={multiple}
            onChange={imageInputHandler}
          />
          <button
            type="button"
            onClick={selectFile}
            className="btn px-1 w-100 btn-outline-primary "
          >
            <i className="fa fa-image"></i>{" "}
            {!hideLabel && (
              <span className="small ms-2 d-inline-block">
                {src !== "" ? "Replace" : "Upload"}
              </span>
            )}
          </button>
        </div>

        {!hideImage && (
          <div className="col-12 col-md-6">
            {src !== "" && (
              <img
                src={src}
                alt="Upload"
                className="d-block m-auto w-100 my-3"
                style={{ maxHeight: 250, objectFit: "contain" }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageInput;
