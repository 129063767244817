import React, { useContext } from "react";
import { PartidasContratoContext } from "../../context/PartidasContratoContext";
import { PartidasContext } from "../../context/PartidasContext";
import ReactSwitch from "react-switch";
import moment from "moment";

const PreviewPartida = ({ partida }) => {
  const { partidas } = useContext(PartidasContext);
  const { deletePartida, setPropiedadPartida } = useContext(
    PartidasContratoContext
  );
  const { clave, nombre } = partida;

  const renderFecha = (key) => {
    if (key === "inicio") {
      if (partida.fecha_inicio && partida.fecha_inicio !== null) {
        return moment(partida.fecha_inicio).utc().format("YYYY-MM-DD");
      }
    } else if (partida.fecha_fin && partida.fecha_fin !== null) {
      return moment(partida.fecha_fin).utc().format("YYYY-MM-DD");
    }
  };

  const renderCronograma = () => {
    if (Array.isArray(partidas)) {
      if (partidas.length === 0) {
        return (
          <ReactSwitch
            checked={partida.cronograma}
            onChange={(checked) =>
              setPropiedadPartida(
                partida.idPartidaContrato,
                "cronograma",
                checked
              )
            }
          />
        );
      }
      return (<select
        className="form-control mb-3"
        onChange={(e) =>
          setPropiedadPartida(
            partida.idPartidaContrato,
            "cronograma",
            e.target.value
          )
        }
      >
        {[
          <option value={null}>Sin Asignar</option>,
          <option value="create">Crear una Nueva</option>,
          ...partidas.map((partida) => (
            <option value={partida.idPartida}>{partida.nombre}</option>
          )),
        ]}
      </select>
      );
    }
  };

  return (
    <tr>
      <td>{clave}</td>
      <td>{nombre}</td>
      <td>
        <button
          className="btn btn-sm btn-outline-danger"
          onClick={() => deletePartida(partida.idPartidaContrato)}
        >
          <i className="fa fa-trash"></i>
        </button>
      </td>
      <td>{renderCronograma()}</td>
      <td>{renderFecha("fin")}</td>
      <td>{renderFecha("inicio")}</td>
    </tr>
  );
};

export default PreviewPartida;
