import React, { useContext } from "react";
import { PagosContext } from "../../context/PagosContext";
import { getValue } from "../../utils";

const CancelPagoForm = ({
  idProyecto,
  idContrato,
  handleCancel,
  handleCallback,
}) => {
  const { pago, setPropiedadPago, postCancelPago } = useContext(PagosContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    postCancelPago(idProyecto, idContrato, pago, handleCallback);
  };

  return (
    <form onSubmit={handleSubmit}>
      <p>
        El pago no se borrará de la base de datos pero se mostrará como
        cancelado.
      </p>
      <label>Fecha Cancelación</label>
      <input
        type="date"
        className="form-control mb-3"
        value={getValue(pago, "fecha_cancelacion", "date")}
        onChange={(e) => setPropiedadPago("fecha_cancelacion", e.target.value)}
      />
      <label>Comentario</label>
      <input
        type="text"
        className="form-control"
        value={getValue(pago, "comentario")}
        onChange={(e) => setPropiedadPago("comentario", e.target.value)}
      />
      <div className="row mt-3">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn text-muted w-100"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn btn-primary w-100">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default CancelPagoForm;
