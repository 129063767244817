export const mainColor = "#b76d4f";

export const primaryButton = (color) => ({
  backgroundColor: color ? color : mainColor,
  borderColor: color ? color : mainColor,
});

export const outlineButton = (color) => ({
  borderColor: color ? color : mainColor,
  color: color ? color : mainColor,
});

export const accentText = (color) => ({
  color: color ? color : mainColor,
});

export const setPrimaryColor = (color) => {
  let buttons = document.getElementsByClassName("btn-primary");
  let secondaryButtons = document.getElementsByClassName("btn-outline-primary");
  let accentText = document.getElementsByClassName("text-primary");
  buttons.forEach((button) => {
    button.style.backgroundColor = color;
  });
  secondaryButtons.forEach((button) => {
    button.style.borderColor = color;
  });
  accentText.forEach((text) => {
    text.style.color = color;
  });
};

export const colors = [
  { title: "Programado", color: "#007991", className: "programado-gradiente" },
  { title: "Real", color: "#89d405", className: "real-gradiente" },
  {
    title: "Diferencial",
    color: "#FC6767",
    className: "diferencial-gradiente",
  },
];


export const hexToRgb = (hex) => {
  hex = hex.replace(/^#/, '');

  const red = parseInt(hex.slice(0, 2), 16);
  const green = parseInt(hex.slice(2, 4), 16);
  const blue = parseInt(hex.slice(4, 6), 16);

  return {
    r: red,
    g: green,
    b: blue
  };
}