import React from "react";

const PillTabs = ({ tabs, value, modifier }) => {
  const renderTabs = () => {
    return tabs.map((tab) => {
      const active = tab.value === value;
      return (
        <div key={tab.value} className="col px-0">
          <button
            onClick={() => modifier(tab.value)}
            className={`btn btn-sm w-100 ${active ? "btn-primary" : ""}`}
          >
            {tab.label}
          </button>
        </div>
      );
    });
  };

  return <div className="row align-items-center">{renderTabs()}</div>;
};

export default PillTabs;
