//Users
export const USERS_RECEIVED = "USERS_RECEIVED";
export const UPDATE_USER = "UPDATE_USER";
export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const SET_CORREO_USER = "SET_CORREO_USER";
export const SET_PERMISO_USER = "SET_PERMISO_USER";
export const SEARCH_USERS = "SEARCH_USERS";
export const RESET_SEARCH_USERS = "RESET_SEARCH_USERS";
export const EDIT_USUARIO = "EDIT_USUARIO";
export const SET_PROPERTY_USUARIO = "SET_PROPERTY_USUARIO";
export const CLEAR_USUARIO = "CLEAR_USUARIO";
