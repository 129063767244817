import React from "react";
import PagoCard from "./PagoCard";
import { IonList } from "@ionic/react";

const ListaPagos = ({ pagos, handleEdit }) => {
  const renderPagos = () => {
    if (Array.isArray(pagos)) {
      if (pagos.length === 0) {
        return (
          <p className="px-3">No hay pagos registrados para este contrato.</p>
        );
      }
      return pagos.map((pago) => <PagoCard key={pago.idPago} pago={pago} handleEdit={handleEdit} />);
    }
  };

  return (
    <div className="card py-3 shadow">
      <IonList
        id="pagos-list"
        style={{ overflowY: "auto", overflowX: "hidden" }}
      >
        {renderPagos()}
      </IonList>
    </div>
  );
};

export default ListaPagos;
