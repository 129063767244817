import {
  IonModal,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React from "react";

import "./guardarMovil.css";

const UltimoAvance = ({
  showModal,
  setShowModal,
  UltimoAvance,
  comentarios,
}) => {
  return (
    <IonModal
      isOpen={showModal}
      onDidDismiss={() => setShowModal(false)}
      initialBreakpoint={0.7}
      breakpoints={[0.7, 0.9]}
    >
      <IonContent className="py-4 ps-4">
        <IonGrid>
          <div className="py-4 ps-4" style={{ paddingRight: "30px" }}>
            <IonRow>
              <IonCol>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 className="titlePlantilla mt-3">Último Avance</h4>
                  <IonIcon
                    icon={closeOutline}
                    style={{ cursor: "pointer", fontSize: "20px" }}
                    onClick={() => setShowModal(false)}
                  />
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>{UltimoAvance}</IonCol>
            </IonRow>
            <IonRow>{comentarios}</IonRow>
          </div>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default UltimoAvance;
