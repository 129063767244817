import React from "react";
import Table from "../common/Table";
import PermisoFila from "./PermisoFila";
import InfoCard from "../common/InfoCard";

const PermissionsList = ({
  query,
  permissions,
  platform,
  isTableView,
  editPermission,
  deletePermission,
}) => {

  const renderPermissions = () => {
    if (Array.isArray(permissions)) {
      if (permissions.length === 0) {
        return <p>Aún no has agregado permisos.</p>;
      }

      return permissions.map((item) => (
        <InfoCard
          usuario={item}
          platform={platform}
          key={`${item.idUsuario}-${item.idProyecto}`}
          proyectName={item.nombre_proyecto}
          editUsuario={editPermission}
          deleteUsuario={deletePermission}
        />
      ));
    }
  };

  const renderTableColumns = () => {
    return (
      <>
        <th className="col-3 py-4 ps-5">Usuario</th>
        <th className="col-3 py-4 ps-1">Correo</th>
        <th className="col-2 py-4 ps-1">Proyecto</th>
        <th className="col-2 py-4 ps-1">Permiso</th>
        <th className="col-2 py-4 ps-1">Acciones</th>
      </>
    )
  }

  const renderPermissionsRows = () => {
    return (
      permissions?.map(item => {
        return (
          <PermisoFila
            key={`${item.idUsuario}-${item.idProyecto}`}
            usuario={item}
            editPermiso={editPermission}
            deletePermiso={deletePermission}
          />
        )
      })
    )
  }

  const renderContent = () => {
    if (isTableView) {
      return (
        <Table
          data={permissions}
          query={query}
          renderItems={renderPermissionsRows}
          renderColumns={renderTableColumns}
        />
      );
    }

    return (
      <div className={`px-0 row justify-content-center ${platform !== 'web' ? 'pb-3' : ''}`}>
        <div className="col-12">
          {renderPermissions()}
        </div>
      </div>
    )
  };

  return renderContent();
};

export default PermissionsList;
