import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppConfigContext } from "../../context/AppConfigContext";

const ClienteRow = ({ cliente, editCliente, deleteCliente }) => {
  const { S3_ENDPOINT } = useContext(AppConfigContext);

  const getSrc = () => {
    if (cliente.Adjunto !== null && cliente.Adjunto) {
      return `${S3_ENDPOINT}/files/adjuntos/${cliente.Adjunto.idAdjunto}.${cliente.Adjunto.tipo}`;
    }
  };
 
  return (
    <tr
      className="hover-light align-items-center border-bottom"
      key={cliente.idCliente}
    >
      <td className="py-4 ps-5">
        <Link 
          to={`/clientes/${cliente.idCliente}`} 
          className="text-primary"
          state={{ clientName: cliente.nombre }}
        >
          <i className="fa fa-users"></i> {cliente.nombre}
        </Link>
      </td>
      <td className="py-4">
        <img
          src={cliente.Adjunto ? getSrc() : "https://via.placeholder.com/150"}
          alt={cliente.nombre}
          className="card-img-top rounded-circle"
          style={{ width: "50px", height: "50px", objectFit: "cover" }}
        />
      </td>
      <td>
        <div className="color" style={{ backgroundColor: cliente.color }} />
      </td>
      <td>
        <button
          className="btn btn-outline-primary me-3"
          onClick={() => editCliente(cliente)}
        >
          <i className="fa fa-edit"></i>
        </button>
        <button
          className="btn btn-outline-danger"
          onClick={() => deleteCliente(cliente)}
        >
          <i className="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  );
};

export default ClienteRow;
