import React, { useContext, useState, useEffect } from "react";
import {
  IonContent,
  IonModal,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/react";
import { EntradasContext } from "../../context/EntradasContext";
import { AuthContext } from "../../context/AuthContext";
import BitacoraInput from "../bitacora/BitacoraInput";
import { closeOutline } from "ionicons/icons";
import Entradas from "../bitacora/Entradas";

const limit = 25;

const Comentarios = ({ idProyecto, idItem, setShowModal, showModal }) => {
  const [offset, setOffset] = useState(0);

  const { max, entradas, getEntradas, postEntrada, resetEntradas } =
    useContext(EntradasContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    resetEntradas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useEffect(() => {
    fetchEntradas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useEffect(() => {
    if (Array.isArray(entradas)) {
      setOffset(entradas.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (message, files) => {
    postEntrada(idProyecto, { message, files, idItem });
  };

  const fetchEntradas = () => {
    if (idItem && idItem !== null) {
      getEntradas(idProyecto, offset, limit, { idItem });
    }
  };

  const handleReset = () => {
    setShowModal(false);
    resetEntradas();
  };

  return (
    <>
      <IonModal
        initialBreakpoint={1}
        breakpoints={[1, 0.8]}
        isOpen={showModal}
        onDidDismiss={() => handleReset()}
      >
        <IonContent>
          <IonGrid
            className="overflow-hidden"
            style={{ height: "calc(100% - 80px)" }}
          >
            <IonRow>
              <IonCol>
                <h2>Comentarios</h2>
              </IonCol>
              <IonCol className="justify-content-end align-items-center d-flex">
                <IonIcon
                  icon={closeOutline}
                  style={{ cursor: "pointer", fontSize: "24px" }}
                  onClick={() => setShowModal(false)}
                />
              </IonCol>
            </IonRow>
            <IonRow
              className="overflow-y-auto"
              style={{ height: "calc(100% - 50px)" }}
            >
              <IonCol>
                <Entradas
                  scrollCallback={fetchEntradas}
                  idUsuario={user.idUsuario}
                  idProyecto={idProyecto}
                  entradas={entradas}
                  user={user}
                  max={max}
                />
              </IonCol>
            </IonRow>
          </IonGrid>

          <BitacoraInput modifier={handleSubmit} />
        </IonContent>
      </IonModal>
    </>
  );
};

export default Comentarios;
