import api from "./api";
import firebase from "firebase";
import "firebase/auth";

const FirebaseService = (firebaseConfig) => {
  firebaseConfig = JSON.parse(firebaseConfig);
  // Initialize Firebase
  if (firebase.apps.length < 1) {
    firebase.initializeApp(firebaseConfig);
  }

  const getToken = () => auth.currentUser.getIdToken(true);

  const auth = firebase.auth();

  return {
    signIn: (email, password) =>
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((user) => {
          return getToken().then((token) => {
            api.defaults.headers.common["Authorization"] = token;
            return user;
          });
        }),
    userLoggedIn: (success, error) =>
      auth.onAuthStateChanged((user) => {
        if (user) {
          getToken().then((token) => {
            api.defaults.headers.common["Authorization"] = token;
            if (success) success(user);
          });
        } else {
          error();
        }
      }),
    signOut: () => auth.signOut(),
    signUp: (correo, password) =>
      auth.createUserWithEmailAndPassword(correo, password),
    recoverPassword: (email) => auth.sendPasswordResetEmail(email),
    getToken: () => auth.currentUser.getIdToken(true),
    verifyEmail: () => auth.currentUser.sendEmailVerification(),
    updateEmail: (email) => auth.currentUser.updateEmail(email),
    setToken: (token) => (api.defaults.headers.common["Authorization"] = token),
  };
};

export default FirebaseService;
