import api from "./api";

const route = "/partidas";

export default {
  getPartidas: (idProyecto) => api.get(`${route}/${idProyecto}`),
  getPartidasContrato: (idProyecto) =>
    api.get(`${route}/${idProyecto}/contratos/all`),
  getPartidasAllContratos: (idProyecto, idContrato) =>
    api.get(`${route}/${idProyecto}/contrato/${idContrato}`),
  getSinglePartida: (idProyecto, idPartida) =>
    api.get(`${route}/${idProyecto}/partida/${idPartida}`),
  postPartidas: (idProyecto, partidas) =>
    api.post(`${route}/${idProyecto}`, { idProyecto, partidas }),
  postSinglePartida: (idProyecto, partida) =>
    api.post(`${route}/${idProyecto}`, { ...partida }),
  putSinglePartida: (idProyecto, partida) =>
    api.put(`${route}/${idProyecto}/partida/${idProyecto}`, { ...partida }),
};
