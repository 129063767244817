import React, { useContext, useEffect } from "react";
import { ProyectosContext } from "../../context/ProyectosContext";
import { SubdestajosContext } from "../../context/SubdestajosContext";
import ItemDetail from "./ItemDetail";

const SubdestajoDetail = ({ idProyecto, subdestajo, callback }) => {
  const { proyecto } = useContext(ProyectosContext);
  const {
    getSingleSubdestajo,
    postSingleSubdestajo,
    setPropertySingleSubdestajo,
  } = useContext(SubdestajosContext);

  useEffect(() => {
    fetchSubdestajo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSubdestajo = () => {
    getSingleSubdestajo(idProyecto, subdestajo.idSubDestajo);
  };

  return (
    <div className="container-fluid py-4">
      <ItemDetail
        id="idSubDestajo"
        item={subdestajo}
        proyecto={proyecto}
        callback={callback}
        itemLabel="Subdestajo"
        saveItem={postSingleSubdestajo}
        modifier={setPropertySingleSubdestajo}
      />
    </div>
  );
};

export default SubdestajoDetail;
