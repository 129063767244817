

import React, { useContext, useEffect, useState } from "react";
import { DestajosContext } from "../../context/DestajosContext";
import { ConceptosContext } from "../../context/ConceptosContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import { ContratosContext } from "../../context/ContratosContext";
import { ModalContext } from "../../context/ModalContext";


const SubConceptoForm = ({ idPartidaContrato, idConceptoPadre, editMode, currentData }) => {
  const { postSubconcepto, spinner, loading } = useContext(ConceptosContext);
  const { clearModal } = useContext(ModalContext);
  const { destajos } = useContext(DestajosContext);
  const { proyecto } = useContext(ProyectosContext);
  const { contrato } = useContext(ContratosContext);


const subconceptoSchema = {
  idPartidaContrato,
  idConceptoPadre,
  clave: "",
  concepto: "",
  unidad: "",
  cantidad: 0,
  precio: 0,
  precio_unitario: 0,
  costo: 0,
  subconcepto: true
}
  const currentSubconcepto = editMode ? currentData : subconceptoSchema;

  const [subconcepto, setSubconcepto] = useState(currentSubconcepto);

  const setProperty = (key, value) => {
    setSubconcepto({
      ...subconcepto,
      [key]: value
    })
  }

  useEffect(() => {
    if(editMode && currentData) {
      setSubconcepto(currentData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const precioUnitario = parseFloat(subconcepto.precio) / parseFloat(subconcepto.cantidad);
    setProperty("precio_unitario",  precioUnitario);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subconcepto.cantidad, subconcepto.precio]);

  const renderDestajos = () => {
    if (Array.isArray(destajos)) {
      return [
        <option key="null" value={null}>
          Sin Destajo
        </option>,
        ...destajos.map((destajo) => (
          <option key={destajo.idDestajo} value={destajo.idDestajo}>
            {destajo.nombre}
          </option>
        )),
      ];
    }
  };

  const handleSubmitConceptos = () => {
      postSubconcepto(
        proyecto.idProyecto,
        contrato.idContrato,
        subconcepto
      );
  }

  const handleCancel = () => {
    clearModal();
  }

  return (
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-6 mb-2 position-relative d-flex flex-column">
          <label className="">Clave</label>
          <input
            name="clave"
            className="form-control w-100"
            value={subconcepto.clave}
            maxLength={140}
            placeholder="Clave"
            onChange={(e) => setProperty("clave", e.target.value)}
          />
        </div>

        <div className="col-6 mb-2 position-relative d-flex flex-column">
          <label className="">Nombre</label>
          <input
            name="nombre"
            className="form-control w-100"
            value={subconcepto.concepto}
            maxLength={140}
            placeholder="Nombre"
            onChange={(e) => setProperty("concepto", e.target.value)}

          />
        </div>

        {/* Unidad */}
        <div className="col-6 mb-2 position-relative d-flex flex-column">
          <label className="">Unidad</label>
          <input
            name="unidad"
            className="form-control w-100"
            value={subconcepto.unidad}
            
            maxLength={140}
            placeholder="Unidad"
            onChange={(e) => setProperty("unidad", e.target.value)}
          />
        </div>

        {/* Cantidad */}
        <div className="col-6 mb-2 position-relative d-flex flex-column">
          <label className="">Cantidad</label>
          <input
            name="cantidad"
            className="form-control w-100"
            type="number"
            disabled={editMode}
            value={subconcepto.cantidad}
            placeholder="Cantidad"
            onChange={(e) => setProperty("cantidad", parseFloat(e.target.value))}
          />
        </div>

        {/* Precio */}
        <div className="col-6 mb-2 position-relative d-flex flex-column">
          <label className="">Precio</label>
          <input
            name="precio"
            className="form-control w-100"
            type="number"
            value={subconcepto.precio}
            disabled={editMode}
            placeholder="$ Precio"
            onChange={(e) => setProperty("precio", parseFloat(e.target.value))}
          />
        </div>

        {/* Costo */}
        <div className="col-6 mb-2 position-relative d-flex flex-column">
          <label className="">Costo</label>
          <input
            name="costo"
            className="form-control w-100"
            type="number"
            value={subconcepto.costo}
            disabled={editMode}
            placeholder="$ Costo"
            onChange={(e) => setProperty("costo", parseFloat(e.target.value))}
          />
        </div>

        <div className="col-6 mb-2 position-relative d-flex flex-column">
          <label className="">Destajo</label>
          <select
            value={subconcepto.idDestajo}
            className="form-control"
            onChange={(e) => setProperty("idDestajo", e.target.value)}
          >
            {renderDestajos()}
          </select>
        </div>


      </div> 

      <div className="row">
          <button
            type="button"
            className="btn btn-primary  col-6"
            onClick={handleSubmitConceptos}
            disabled={spinner || loading}
          >
            Guardar
          </button>
          <button
            type="button"
            variant="outline-danger"
            className="btn btn-outline col-6"
            onClick={handleCancel}
            disabled={spinner}
          >
            Cancelar
          </button>
        </div>
    </div>
  );
};

export default SubConceptoForm;
