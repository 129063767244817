import React, { useContext, useState } from "react";
import { PartidasContratoContext } from "../context/PartidasContratoContext";
import { EstimacionesContext } from "../context/EstimacionesContext";
import ConceptoDetail from "../components/conceptos/ConceptoDetail";
import { ConceptosContext } from "../context/ConceptosContext";
import { PreciarioContext } from "../context/PreciarioContext";
import { ModalContext } from "../context/ModalContext";
import useProyecto from "./useProyecto";
import { CapacitorContext } from "../context/CapacitorContext";
import { ContratosContext } from "../context/ContratosContext";
import { useParams } from "react-router-dom";

const usePreciario = () => {
  const [query, setQuery] = useState("");
  const [showConceptos, setShowConceptos] = useState([]);

  const { idEstimacionVersion } = useParams();

  const { concepto, conceptos, conceptosExtra, setConcepto } =
    useContext(ConceptosContext);
  const { view, setView, editMode } = useContext(PreciarioContext);
  const { partidas } = useContext(PartidasContratoContext);
  const { estimacion } = useContext(EstimacionesContext);
  const { modalComponent } = useContext(ModalContext);
  const { contrato } = useContext(ContratosContext);
  const { platform } = useContext(CapacitorContext);
  const { permiso } = useProyecto();

  const idContrato = contrato?.idContrato;
  const idEstimacion = estimacion?.idEstimacion;

  function showConceptoDetail(concepto) {
    setConcepto(concepto);
    if (platform !== "web") {
      modalComponent(`Concepto ${concepto.clave}`, <ConceptoDetail />);
    }
  }

  function toggleConceptosPartida(idPartidaContrato) {
    const current = [...showConceptos];
    const index = showConceptos.indexOf(idPartidaContrato);
    if (index === -1) current.push(idPartidaContrato);
    else current.splice(index, 1);
    setShowConceptos(current);
  }

  let partidasRender = Array.isArray(partidas) ? [...partidas] : [];
  let conceptosRender = Array.isArray(conceptos) ? [...conceptos] : [];

  if (editMode === "partidas") {
    partidasRender = partidas.filter(
      (partida) => String(partida.idPartidaContrato)[0] !== "d"
    );
  }

  if (editMode === "conceptos-extra") {
    conceptosRender = conceptosExtra.filter(
      (concepto) => String(concepto.idConceptoExtra)[0] !== "d"
    );
  }

  if (editMode === "conceptos-estimacion") {
    if (["finanzas", "obras"].includes(permiso)) {
      let conceptosEstimados = conceptos.filter(
        (concepto) => concepto.estimado
      );
      let partidasConceptosEstimados = conceptosEstimados.map(
        (concepto) => concepto.idPartidaContrato
      );
      partidasRender = partidasRender.filter((partida) =>
        partidasConceptosEstimados.includes(partida.idPartidaContrato)
      );
    }
  }

  if (estimacion !== null) {
    partidasRender = partidasRender.filter((partida) => {
      return (
        conceptos.find(
          (concepto) => concepto.idPartidaContrato === partida.idPartidaContrato
        ) !== undefined
      );
    });
  }

  function getConceptosPartida(idPartidaContrato) {
    return conceptosRender.filter(
      (concepto) => concepto.idPartidaContrato === idPartidaContrato
    );
  }

  return {
    view,
    query,
    setView,
    setQuery,
    concepto,
    editMode,
    idContrato,
    estimacion,
    setConcepto,
    idEstimacion,
    showConceptos,
    conceptosExtra,
    showConceptoDetail,
    getConceptosPartida,
    idEstimacionVersion,
    toggleConceptosPartida,
    partidas: partidasRender,
    conceptos: conceptosRender,
  };
};

export default usePreciario;
