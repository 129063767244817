import api from "./api";

const route = "/usuarios";

export default {
  //General
  getUsuarios: () => api.get(route),
  getUsuariosProyecto: (idProyecto) =>
    api.get(`${route}/proyecto/${idProyecto}`),
  postUsuario: (usuario) => api.post(route, { ...usuario }),
  putUsuario: (usuario) => api.put(route, { ...usuario }),
  deleteUsuario: (uidUser) => api.delete(`${route}/${uidUser}`),
};
