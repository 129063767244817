import React from "react";
import moment from "moment";

const RecorridoCard = ({
  permiso,
  onClick,
  recorrido,
  editRecorrido,
  deleteRecorrido,
}) => {
  function renderNombre() {
    if (recorrido.Usuario && recorrido.Usuario !== null) {
      return recorrido.Usuario.nombre;
    }
  }

  function renderActions() {
    if (["admin", "supervisor"].includes(permiso))
      return (
        <div className="col-12 col-md-3" xs={12} md={3}>
          <button
            className="btn btn-outline-secondary me-3"
            onClick={editRecorrido}
          >
            <i className="fa fa-edit"></i>
          </button>
          <button
            className="btn btn-outline-danger"
            variant="outline-danger"
            onClick={deleteRecorrido}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      );
  }

  return (
    <div
      className="card p-3 border-0 shadow-sm mb-3 tour-card"
      onClick={onClick}
    >
      <div className="row align-items-center">
        <div className="col-12 col-md-3" xs={12} md={3}>
          <p className="bold mb-0">{recorrido.nombre}</p>
        </div>
        <div className="col-6 col-md-3" xs={6} md={3}>
          <p className="mb-0">{renderNombre()}</p>
        </div>
        <div className="col-6 col-md-3" xs={6} md={3}>
          <p className="mb-0">
            {moment(recorrido.createdAt).format("DD MMM YYYY")}
          </p>
        </div>
        {renderActions()}
      </div>
    </div>
  );
};

export default RecorridoCard;
