import { v4 as uuidv4 } from "uuid";

import {
  CREATE_WAREHOUSE,
  WAREHOUSES_RECEIVED,
  DELETE_WAREHOUSE,
  UPDATE_WAREHOUSE,
} from "../types/warehouses";


const warehousesReducer = (state, { type, payload }) => {
  switch (type) {
    case WAREHOUSES_RECEIVED:
      return { ...state, warehouses: payload };
    case CREATE_WAREHOUSE:
      return { ...state, warehouses: [...state.warehouses, { ...payload, id: uuidv4() }] };
    case UPDATE_WAREHOUSE:
      const updateIndex = state.warehouses.findIndex(
        warehouse => warehouse.id === payload.warehouse_id
      );
      if (updateIndex !== -1) {
        const updatedWarehouses = [...state.warehouses];
        updatedWarehouses[updateIndex] = payload.warehouseData;
        return { ...state, warehouses: updatedWarehouses };
      }
      return state;
    case DELETE_WAREHOUSE:
      // Filter out the warehouse to delete
      const filteredWarehouses = state.warehouses.filter(
        warehouse => warehouse.id !== payload.warehouse_id
      );
      return { ...state, warehouses: filteredWarehouses };
    default:
      return state;
  }
};

export default warehousesReducer;
