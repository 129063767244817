import {
  TOGGLE_DESTAJO,
  TOGGLE_PARTIDA,
  SET_SHOW_DESTAJOS,
  SET_SHOW_SUBDESTAJOS,
} from "../types/cronograma";

const CronogramaReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_SHOW_DESTAJOS:
      return { ...state, showDestajos: payload };
    case SET_SHOW_SUBDESTAJOS:
      return { ...state, showSubDestajos: payload };
    case TOGGLE_PARTIDA: {
      const showDestajos = [...state.showDestajos];
      const index = showDestajos.indexOf(payload);
      if (index !== -1) {
        showDestajos.splice(index, 1);
      } else {
        showDestajos.push(payload);
      }
      return { ...state, showDestajos };
    }
    case TOGGLE_DESTAJO: {
      const showSubDestajos = [...state.showSubDestajos];
      const index = showSubDestajos.indexOf(payload);
      if (index !== -1) {
        showSubDestajos.splice(index, 1);
      } else {
        showSubDestajos.push(payload);
      }
      return { ...state, showSubDestajos };
    }
    default:
      return { ...state };
  }
};
export default CronogramaReducer;
