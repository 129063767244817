import api from "./api";

const route = "/plantillas";

export default {
  getPlantillas: (tipo) => api.get(`${route}?tipo=${tipo}`),
  postPlantilla: (plantilla) => api.post(route, { ...plantilla }),
  postPreciario: (idProyecto) => api.post(`${route}/${idProyecto}/preciario`),
  setPlantilla: (idProyecto, idPlantilla) =>
    api.post(`${route}/${idProyecto}/${idPlantilla}`),
  setPlantillaChecklist: (idProyecto, idChecklist, idPlantilla) =>
    api.post(`${route}/${idProyecto}/checklist/${idChecklist}/${idPlantilla}`),
  putPlantilla: (idPlantilla, nombre) =>
    api.put(`${route}/${idPlantilla}`, { nombre }),
  deletePlantilla: (idPlantilla) => api.delete(`${route}/${idPlantilla}`),
};
