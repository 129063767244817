import React, { useContext, useEffect } from "react";
import { ModalContext } from "../../context/ModalContext";
import { RecorridosContext } from "../../context/RecorridosContext";
import { getValue } from "../../utils";

const RecorridoForm = ({ idProyecto }) => {
  const {
    spinner,
    recorrido,
    postTour,
    updateTour,
    clearRecorrido,
    setPropertyTour,
  } = useContext(RecorridosContext);
  const { clearModal } = useContext(ModalContext);

  useEffect(() => {
    if (recorrido === null) {
      handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recorrido]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isNaN(recorrido.idTour)) {
      return postTour(
        idProyecto,
        recorrido.nombre,
        recorrido.fecha_recorrido,
        recorrido.file
      );
    }
    updateTour(recorrido);
  };

  const handleCancel = () => {
    clearModal();
    clearRecorrido();
  };

  const renderForm = () => {
    if (recorrido && recorrido !== null) {
      return (
        <div className="container-fluid px-1">
          <label>Nombre</label>
          <input
            type="text"
            value={getValue(recorrido, "nombre")}
            className="form-control mb-4"
            onChange={(e) => setPropertyTour("nombre", e.target.value)}
          />
          <label>Fecha del Recorrido</label>
          <input
            type="date"
            value={getValue(recorrido, "fecha_recorrido", "date")}
            className="form-control mb-4"
            onChange={(e) => setPropertyTour("fecha_recorrido", e.target.value)}
          />
          <div className="row mx-0 mt-5">
            {!recorrido.idTour ? (
              <input
                type="file"
                accept="zip"
                className="form-control border-0 mb-3"
                onChange={(e) => setPropertyTour("file", e.target.files[0])}
              />
            ) : (
              <p className="small ps-3 pr-3">
                *Los archivos del recorrido no pueden modificarse. Si necesitas
                cambiarlos, elimina el recorrido.
              </p>
            )}
          </div>
          <div className="row px-2 mt-3">
            <div className="col-6">
              <button
                type="button"
                className="btn w-100 text-muted"
                onClick={handleCancel}
              >
                Cancelar
              </button>
            </div>
            <div className="col-6">
              <button
                type="submit"
                disabled={spinner}
                className="btn btn-primary w-100"
              >
                {spinner ? <div className="spinner-border"></div> : "Guardar"}
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  return <form onSubmit={handleSubmit}>{renderForm()}</form>;
};

export default RecorridoForm;
