import React from "react";

const EncabezadoTabla = ({ editMode }) => {
  return (
    <tr className="bg-light border p-2 bold">
      {editMode && <td className="mw-50-px" />}
      <td className={editMode ? "picker" : "ponderacion"}>%</td>
      <td className="ps-3 rubro">Rubro</td>
      <td className="text-center">Avance</td>
      <td className="ps-3">Crítico</td>
      <td className="ps-3" style={{ minWidth: 150 }}>
        Fecha Inicio
      </td>
      <td className="ps-3" style={{ minWidth: 150 }}>
        Fecha Fin
      </td>
      <td>Acciones</td>
    </tr>
  );
};

export default EncabezadoTabla;
