import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";

const Modal = () => {
  const {
    title,
    content,
    children,
    onCancel,
    onSuccess,
    component,
    clearModal,
  } = useContext(ModalContext);

  const handleSuccess = () => {
    onSuccess();
    clearModal();
  };
  const handleCancel = () => {
    if (typeof onCancel === "function") {
      onCancel();
    }
    clearModal();
  };

  return (
    <div
      id="modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      aria-labelledby="modal"
      // data-bs-backdrop="static"
      className="modal modal-lg overflow-x-hidden fade"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header border-0 pt-4 pb-2 px-4">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-10 px-0">
                  <h5 className="mb-0 modal-title bold">{title}</h5>
                </div>
                <div className="col-2 px-0 text-end mobile-center">
                  <button
                    className="btn btn-light border"
                    onClick={handleCancel}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-body pb-4 px-4"
            style={{
              borderRadius:
                !onSuccess && !onCancel ? "0px 0px 10px 10px" : "0px",
            }}
          >
            {content}
            {component}
            {children}
          </div>
          {(onSuccess || onCancel) && (
            <div className="modal-footer border-0 bg-white">
              <div className="container-fluid px-0">
                <div className="row">
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn w-100 text-muted"
                      data-dismiss="modal"
                      onClick={handleCancel}
                    >
                      Cancelar
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn btn-primary w-100"
                      type="button"
                      onClick={handleSuccess}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
