import {
  SET_CAMBIOESTIMACIONVERSION,
  CREATE_CAMBIOESTIMACIONVERSION,
  CAMBIOESTIMACIONVERSIONS_RECEIVED,
  SET_PROPERTY_CAMBIOESTIMACIONVERSION,
} from "../types/cambioestimacionversiones";

const schema = {};

const CambioEstimacionVersionesReducer = (state, { type, payload }) => {
  switch (type) {
    case CAMBIOESTIMACIONVERSIONS_RECEIVED:
      return { ...state, cambioestimacionversiones: payload };
    case SET_CAMBIOESTIMACIONVERSION:
      return { ...state, cambioestimacionversion: payload };
    case CREATE_CAMBIOESTIMACIONVERSION:
      return { ...state, cambioestimacionversion: schema };
    case SET_PROPERTY_CAMBIOESTIMACIONVERSION: {
      const { key, value } = payload;
      const cambioestimacionversion = { ...state.cambioestimacionversion };
      cambioestimacionversion[key] = value;
      return { ...state, cambioestimacionversion };
    }
    default:
      return { ...state };
  }
};

export default CambioEstimacionVersionesReducer;
