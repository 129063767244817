import React, { useEffect, useContext } from "react";
import { ProveedoresContext } from "../../context/ProveedoresContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import { getValue } from "../../utils";

const ProveedorForm = ({ idProveedor, handleCancel }) => {
  const {
    proveedor,
    saveProveedor,
    createProveedor,
    getSingleProveedor,
    setPropiedadProveedor,
  } = useContext(ProveedoresContext);
  const { getAllProyectos } = useContext(ProyectosContext);

  useEffect(() => {
    // Edit proveedor
    if (!isNaN(parseInt(idProveedor))) {
      getSingleProveedor(idProveedor);
    } else {
      // Create new proveedor
      createProveedor();
    }
    getAllProyectos();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProveedor]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPropiedadProveedor(name, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveProveedor(proveedor);
  };

  return (
    <form onSubmit={handleSubmit} className="container-fluid">
      <label>Razon Social</label>
      <input
        type="text"
        name="razon_social"
        onChange={handleChange}
        value={getValue(proveedor, "razon_social")}
        className="form-control bg-light py-2 mb-4"
      />
      <label>RFC</label>
      <input
        name="rfc"
        type="text"
        onChange={handleChange}
        value={getValue(proveedor, "rfc")}
        className="form-control bg-light py-2 mb-4"
      />
      <div className="row">
        <div className="col-6">
          <button
            type="button"
            className="btn text-muted w-100"
            onClick={handleCancel}
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn btn-primary w-100">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default ProveedorForm;
