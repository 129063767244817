import React, { useContext, useEffect } from "react";
import { colors } from "../../utils/colors";
import ColorLegend from "../common/ColorLegend";
import { isCoordinador } from "../../utils/permisos";
import { AuthContext } from "../../context/AuthContext";
import { MenuContext } from "../../context/MenuContext";
import { CapacitorContext } from "../../context/CapacitorContext";

const ObrasFilters = ({ query, filter, setFilter, setQuery, handleCreate }) => {
  const { user } = useContext(AuthContext);
  const { setButtons } = useContext(MenuContext);
  const { platform } = useContext(CapacitorContext);

  useEffect(() => {
    if (isCoordinador(user) && platform !== "web") {
      setButtons(
        <button className="btn btn-primary" onClick={handleCreate}>
          <i className="fa fa-plus"></i>
        </button>
      );
    }
    return () => setButtons(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCreateBtn = () => {
    if (isCoordinador(user) && platform === "web") {
      return (
        <div className="col-12 col-md-4 text-end hide-mobile">
          <button className="btn btn-primary" onClick={handleCreate}>
            <i className="fa fa-plus"></i>
          </button>
        </div>
      );
    }
  };

  return (
    <div className="container-fluid px-0 py-2">
      <div
        className="row align-items-center"
      >
        <div className="col-12 col-md-8">
          <input
            type="text"
            value={query}
            className="form-control bg-white"
            placeholder="Buscar un proyecto..."
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        {renderCreateBtn()}
      </div>
      <div className="row align-items-center mb-2">
        <div className="col-12 col-lg-6 py-2">
          <div className="row align-items-center py-2 mx-0 obras-filters">
            <div className="col-4 px-1">
              <button
                className={`btn btn-tab w-100 ${
                  filter === null ? "btn-primary" : "btn-outline-primary"
                }`}
                onClick={() => setFilter(null)}
              >
                Todo
              </button>
            </div>
            <div className="col-4 px-1">
              <button
                className={`btn btn-tab w-100 ${
                  filter === "activas" ? "btn-primary" : "btn-outline-primary"
                }`}
                onClick={() => setFilter("activas")}
              >
                Activas
              </button>
            </div>
            <div className="col-4 px-1">
              <button
                className={`btn btn-tab w-100 ${
                  filter === "completadas"
                    ? "btn-primary"
                    : "btn-outline-primary"
                }`}
                onClick={() => setFilter("completadas")}
              >
                Completadas
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <ColorLegend colors={colors} />
        </div>
      </div>
    </div>
  );
};

export default ObrasFilters;
