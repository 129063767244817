import api from "./api";

const route = "/tours";

export default {
  getTours: idProyecto => api.get(`${route}/${idProyecto}`),
  getTour: (idProyecto, idTour) =>
    api.get(`${route}/${idProyecto}/${idTour}/tour`),
  postTour: (idProyecto, formData) =>
    api.post(`${route}/${idProyecto}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }),
  putTour: (idProyecto, idTour, nombre) => api.put(`${route}/${idProyecto}/${idTour}`, { nombre }),
  deleteTour: (idProyecto, idTour) => api.delete(`${route}/${idProyecto}/${idTour}`)
};
