import React, { createContext, useReducer, useContext } from "react";
import ConceptosUbicacionesService from "../services/ConceptosUbicacionesService";
import ConceptosUbicacionesReducer from "../reducers/ConceptosUbicacionesReducer";
import {
  CONCEPTOSUBICAICONS_RECEIVED,
  SET_CONCEPTOSUBICAICON,
  CREATE_CONCEPTOSUBICACION,
  SET_PROPERTY_CONCEPTOSUBICAICON,
  REMOVE_CONCEPTOUBICACION,
} from "../types/conceptosubicaciones";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  conceptosubicaciones: null,
  conceptosubicacion: null,
};

export const ConceptosUbicacionesContext = createContext(initialState);

export const ConceptosUbicacionesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    ConceptosUbicacionesReducer,
    initialState
  );

  const { alert, success, clearModal } = useContext(ModalContext);

  const getConceptosUbicaciones = (
    idProyecto,
    idContrato,
    idEstimacionVersion
  ) => {
    ConceptosUbicacionesService.getConceptosUbicaciones(
      idProyecto,
      idContrato,
      idEstimacionVersion
    )
      .then((response) => {
        const { conceptosubicaciones } = response.data;
        dispatch({
          type: CONCEPTOSUBICAICONS_RECEIVED,
          payload: conceptosubicaciones,
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getConceptosUbicacionConcepto = (
    idProyecto,
    idEstimacionVersion,
    idConcepto
  ) => {
    ConceptosUbicacionesService.getConceptosUbicacionesConcepto(
      idProyecto,
      idEstimacionVersion,
      idConcepto
    )
      .then((response) => {
        const { conceptosubicaciones } = response.data;
        dispatch({
          type: CONCEPTOSUBICAICONS_RECEIVED,
          payload: conceptosubicaciones,
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setConceptosUbicacion = (conceptosUbicacion) => {
    dispatch({ type: SET_CONCEPTOSUBICAICON, payload: conceptosUbicacion });
  };

  const createConceptoUbicacion = () => {
    dispatch({ type: CREATE_CONCEPTOSUBICACION });
  };

  const setPropertyConceptosUbicacion = (idConceptoUbicacion, key, value) => {
    dispatch({
      type: SET_PROPERTY_CONCEPTOSUBICAICON,
      payload: { idConceptoUbicacion, key, value },
    });
  };

  const saveConceptoUbicacion = (
    idProyecto,
    idContrato,
    conceptoubicacion,
    callback
  ) => {
    dispatch({ type: SHOW_SPINNER });
    ConceptosUbicacionesService.postConceptoUbicacion(
      idProyecto,
      idContrato,
      conceptoubicacion.idEstimacionVersion,
      conceptoubicacion
    )
      .then(() => {
        success("Concepto guardado.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const saveConceptosUbicacion = (conceptosUbicacion, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = ConceptosUbicacionesService.putConceptosUbicacion;
    if (isNaN(parseInt(conceptosUbicacion.idConceptoUbicacion))) {
      service = ConceptosUbicacionesService.postConceptosUbicacion;
    }
    service(conceptosUbicacion)
      .then(() => {
        success("ConceptosUbicacion saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteConceptosUbicacion = (idConceptoUbicacion, callback) => {
    dispatch({ type: SHOW_SPINNER });
    ConceptosUbicacionesService.deleteConceptosUbicacion(idConceptoUbicacion)
      .then(() => {
        success("ConceptosUbicacion deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const removeConceptoUbicacion = (idConceptoUbicacion) => {
    dispatch({ type: REMOVE_CONCEPTOUBICACION, payload: idConceptoUbicacion });
  };

  return (
    <ConceptosUbicacionesContext.Provider
      value={{
        ...state,
        saveConceptoUbicacion,
        setConceptosUbicacion,
        saveConceptosUbicacion,
        getConceptosUbicaciones,
        removeConceptoUbicacion,
        deleteConceptosUbicacion,
        createConceptoUbicacion,
        getConceptosUbicacionConcepto,
        setPropertyConceptosUbicacion,
      }}
    >
      {children}
    </ConceptosUbicacionesContext.Provider>
  );
};
