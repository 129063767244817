import React, { useContext, useEffect } from "react";
import { DestajosContext } from "../../context/DestajosContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import SearchableSelect from "../common/SearchableSelect";

const SelectDestajo = ({ selected, modifier }) => {
  const { destajos, getDestajos } = useContext(DestajosContext);
  const { proyecto } = useContext(ProyectosContext);
  const { idProyecto } = proyecto;

  useEffect(() => {
    if (!Array.isArray(destajos)) {
      getDestajos(idProyecto);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto]);

  return (
    <div className="mb-3">
      <label>Selecciona un Destajo</label>
      <SearchableSelect
        items={destajos}
        labelKey="nombre"
        selected={selected}
        selectItem={modifier}
      />
    </div>
  );
};

export default SelectDestajo;
