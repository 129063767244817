import arrayMove from "array-move";
import {
  SET_DESTAJO,
  DELETE_DESTAJO,
  DESTAJOS_RECEIVED,
  REORDENAR_DESTAJOS,
  SET_PROPERTY_DESTAJO,
  SET_PROPERTY_SINGLE_DESTAJO,
  CREATE_NUEVO_DESTAJO,
} from "../types/destajos";

const DestajosReducer = (state, { type, payload }) => {
  switch (type) {
    case DESTAJOS_RECEIVED:
      return { ...state, destajos: payload };
    case SET_DESTAJO: {
      let { destajo } = state;
      if (destajo !== null && typeof destajo === "object" && payload !== null) {
        destajo = { ...destajo, ...payload };
      } else {
        destajo = payload;
      }
      return { ...state, destajo };
    }
    case CREATE_NUEVO_DESTAJO: {
      return { ...state, destajos: [...state.destajos, payload] };
    }
    case SET_PROPERTY_SINGLE_DESTAJO: {
      const destajo = { ...state.destajo };
      const { key, value } = payload;
      destajo[key] = value;
      return { ...state, destajo };
    }
    case SET_PROPERTY_DESTAJO: {
      const { idDestajo, key, value } = payload;
      let destajos = [...state.destajos];
      let index = destajos.findIndex(
        (destajo) => String(destajo.idDestajo) === String(idDestajo)
      );
      if (index !== -1) {
        destajos[index][key] = value;
      }
      return { ...state, destajos };
    }
    case DELETE_DESTAJO: {
      let destajos = [...state.destajos];
      for (let i = 0; i < destajos.length; i++)
        if (destajos[i].idDestajo === payload) {
          if (String(payload).includes("nuev")) destajos.splice(i, 1);
          else destajos[i].idDestajo = "delete" + payload;
        }
      return { ...state, destajos };
    }
    case REORDENAR_DESTAJOS: {
      const { idPartida, oldIndex, newIndex } = payload;
      const destajos = [...state.destajos];
      let destajosPartida = destajos.filter(
        (destajo) => String(destajo.idPartida) === String(idPartida)
      );
      destajosPartida = destajosPartida.sort((a, b) =>
        a.orden > b.orden ? 1 : -1
      );
      destajosPartida = [...arrayMove(destajosPartida, oldIndex, newIndex)];
      destajosPartida.forEach((destajo, index) => {
        destajo.orden = index + 1;
      });
      return { ...state, destajos };
    }
    default:
      return { ...state };
  }
};
export default DestajosReducer;
