import React, { createContext, useReducer, useContext } from "react";
import ProveedoresService from "../services/ProveedoresService";
import ProveedoresReducer from "../reducers/ProveedoresReducer";
import {
  SET_PROVEEDOR,
  CREATE_PROVEEDOR,
  DELETE_PROVEEDOR,
  PROVEEDORES_RECEIVED,
  SET_PROPERTY_PROVEEDOR,
} from "../types/proveedores";
import { ModalContext } from "./ModalContext";

const initialState = {
  proveedores: null,
  proveedor: null,
};

export const ProveedoresContext = createContext(initialState);

export const ProveedoresProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProveedoresReducer, initialState);
  const { clearModal, success } = useContext(ModalContext);

  const getAllProveedores = () => {
    ProveedoresService.getAllProveedores().then((res) => {
      const { proveedores } = res.data;
      dispatch({ type: PROVEEDORES_RECEIVED, payload: proveedores });
    });
  };

  const getProveedoresProyecto = (idProyecto) => {
    ProveedoresService.getProveedoresProyecto(idProyecto)
      .then((res) => {
        const { proveedores } = res.data;
        console.log(proveedores);
        dispatch({ type: PROVEEDORES_RECEIVED, payload: proveedores });
      })
      .catch(alert);
  };

  const getSingleProveedor = (idProveedor) => {
    ProveedoresService.getSingleProveedor(idProveedor).then((res) => {
      const { proveedor } = res.data;
      dispatch({ type: SET_PROVEEDOR, payload: proveedor });
    });
  };

  const createProveedor = () => {
    dispatch({ type: CREATE_PROVEEDOR });
  };

  const saveProveedor = (proveedor) => {
    let service = ProveedoresService.putProveedor;
    if (isNaN(parseInt(proveedor.idProveedor))) {
      service = ProveedoresService.postProveedor;
    }
    service(proveedor, proveedor.idProyecto)
      .then(() => {
        success("Proveedor guardado.");
      })
      .catch(alert)
      .finally(clearModal);
  };

  const deleteProveedor = (idProveedor) => {
    ProveedoresService.deleteProveedor(idProveedor).then(() => {
      success("Producto Borrado");
      dispatch({ type: DELETE_PROVEEDOR, payload: { idProveedor } });
    });
    clearModal();
  };

  const setPropiedadProveedor = (key, value) => {
    dispatch({ type: SET_PROPERTY_PROVEEDOR, payload: { key, value } });
  };

  return (
    <ProveedoresContext.Provider
      value={{
        ...state,
        saveProveedor,
        createProveedor,
        deleteProveedor,
        getAllProveedores,
        getSingleProveedor,
        setPropiedadProveedor,
        getProveedoresProyecto,
      }}
    >
      {children}
    </ProveedoresContext.Provider>
  );
};
