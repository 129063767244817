import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ConceptosContext } from "../../context/ConceptosContext";

const ConceptoForm = () => {
  const { concepto, saveConcepto, setPropiedadConcepto } =
    useContext(ConceptosContext);

  const handleInputChange = (prop, value) => {
    setPropiedadConcepto(prop, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveConcepto(concepto);
  };

  return (
    <form onSubmit={handleSubmit}>
      {" "}
      <div className="form-group">
        <label htmlFor="nombre">Nombre</label>
        <input
          type="text"
          className="form-control"
          id="nombre"
          value={concepto.nombre}
          onChange={(e) => handleInputChange("nombre", e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="unidad">Unidad</label>
        <input
          type="text"
          className="form-control"
          id="unidad"
          value={concepto.unidad}
          onChange={(e) => handleInputChange("unidad", e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="cantidad">Cantidad</label>
        <input
          type="number"
          className="form-control"
          id="cantidad"
          value={concepto.cantidad}
          onChange={(e) => handleInputChange("cantidad", e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="precioUnitario">Precio Unitario</label>
        <input
          type="number"
          className="form-control"
          id="precioUnitario"
          value={concepto.precioUnitario}
          onChange={(e) => handleInputChange("precioUnitario", e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="importe">Importe</label>
        <input
          type="number"
          className="form-control"
          id="importe"
          value={concepto.importe}
          onChange={(e) => handleInputChange("importe", e.target.value)}
        />
      </div>
    </form>
  );
};

ConceptoForm.propTypes = {
  concepto: PropTypes.shape({
    nombre: PropTypes.string.isRequired,
    unidad: PropTypes.string.isRequired,
    cantidad: PropTypes.number.isRequired,
    precioUnitario: PropTypes.number.isRequired,
    importe: PropTypes.number.isRequired,
  }).isRequired,
};

export default ConceptoForm;
