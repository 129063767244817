import React, { createContext, useContext, useReducer } from "react";
import PermisosReducer from "../reducers/PermisosReducer";
import PermisosService from "../services/PermisosService";
import {
  SET_PERMISO,
  PERMISOS_RECEIVED,
  SET_PROPERTY_PERMISO,
  PERMISOS_PROYECTO_RECEIVED,
} from "../types/permisos";
import { ModalContext } from "./ModalContext";

const initialState = {
  permisos_proyecto: null,
  permisos: null,
  permiso: null,
  tipos: null,
};

const permisoSchema = {
  id: "nuevo",
  idUsuario: null,
  idPermiso: null,
  idProyecto: null,
};

export const PermisosContext = createContext(initialState);

export const PermisosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PermisosReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getPermisos = () => {
    PermisosService.getPermisos()
      .then((res) => {
        const { permisos } = res.data;
        dispatch({ type: PERMISOS_RECEIVED, payload: permisos });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getPermisosProyectos = () => {
    PermisosService.getPermisosProyectos().then((res) => {
      const { permisos } = res.data;
      dispatch({ type: PERMISOS_PROYECTO_RECEIVED, payload: permisos });
    });
  };

  const deletePermiso = (idUsuario, idProyecto, callback) => {
    PermisosService.deletePermisoProyecto(idUsuario, idProyecto)
      .then(() => {
        success("Permiso eliminado con éxito.");
        if (callback) callback();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const postPermiso = (permiso) => {
    const { idUsuario, idProyecto, idPermiso } = permiso;
    let service = PermisosService.putPermisoProyecto;
    if (permiso.id === "nuevo") {
      service = PermisosService.postPermisoProyecto;
    }
    service(idUsuario, idProyecto, idPermiso)
      .then(() => {
        success("Permiso guardado con éxito.");
        getPermisosProyectos();
        clearModal();
      })
      .catch((error) => {
        let message = error;
        if (error.response) {
          if (error.response.status === 409) {
            message =
              "Este usuario ya tiene permiso para esta obra, edita el permiso actual.";
          }
        }
        alert(message);
      });
  };
  const setPropiedadPermiso = (key, value) => {
    dispatch({ type: SET_PROPERTY_PERMISO, payload: { key, value } });
  };

  const createPermiso = () => {
    dispatch({
      type: SET_PERMISO,
      payload: permisoSchema,
    });
  };

  const editPermiso = (permiso) => {
    dispatch({ type: SET_PERMISO, payload: permiso });
  };

  return (
    <PermisosContext.Provider
      value={{
        ...state,
        getPermisos,
        postPermiso,
        editPermiso,
        deletePermiso,
        createPermiso,
        setPropiedadPermiso,
        getPermisosProyectos,
      }}
    >
      {children}
    </PermisosContext.Provider>
  );
};
