import React from "react";

const EstadoObra = ({ estado }) => {
  return (
    <div
      className={`badge badge-pill bg-${
        estado === 1 ? "success" : estado === 2 ? "warning" : "danger"
      }-light w-100 p-2 large`}
    >
      <i
        className={`fa fa-${
          estado === 1 ? "check" : "exclamation-triangle"
        } me-1`}
      />
      {estado === 1 ? "A tiempo" : estado === 2 ? "Retraso" : "Retraso Severo"}
    </div>
  );
};

export default EstadoObra;
