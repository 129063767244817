import React, { createContext, useContext, useReducer } from "react";
import {
  HIDE_SPINNER,
  SHOW_SPINNER,
  SET_EDIT_MODE,
  CONCEPTOS_EXTRA_RECIBIDOS,
  DELETE_CONCEPTO,
} from "../actions/types";
import {
  SET_LOADING,
  CREATE_CONCEPTO,
  SET_SUBCONCEPTO,
  CONCEPTOS_RECIBIDOS,
  SET_PROPERTY_CONCEPTO,
  SET_SINGLE_CONCEPTO,
  CREATE_CONCEPTO_GENERADOR,
  SET_PROPIEDAD_CONCEPTO_GENERADOR,
  CONCEPTOS_GENERADOR_RECIBIDOS,
  APPEND_CONCEPTO,
} from "../types/conceptos";
import { validarConceptos } from "../utils";
import ConceptosService from "../services/ConceptosService";
import ConceptosReducer from "../reducers/ConceptosReducer";
import { ModalContext } from "./ModalContext";
import { SELECT_ESTIMACION, SET_CONCEPTO_PRECIOS } from "../types/estimaciones";

const initialState = {
  conceptosGenerador: null,
  conceptosExtra: null,
  conceptos: null,
  concepto: null,
  subconcepto: null,
  concepto_precios: null,
  spinner: false,
  loading: false,
};

function getColValue(concepto, col) {
  const anterior = `${col}Anterior`;
  const value = parseFloat(concepto[col]) - parseFloat(concepto[anterior]);
  if (value > 0) return value;
  return 0;
}

export const ConceptosContext = createContext(initialState);

export const ConceptosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ConceptosReducer, initialState);

  const { success, alert } = useContext(ModalContext);

  const createConceptoGenerador = () => {
    dispatch({ type: CREATE_CONCEPTO_GENERADOR });
  };

  const getConceptosContrato = (idProyecto, idContrato) => {
    ConceptosService.getConceptosContrato(idProyecto, idContrato).then(
      (res) => {
        const { conceptos, conceptosExtra } = res.data;
        dispatch({ type: CONCEPTOS_RECIBIDOS, payload: conceptos });
        dispatch({ type: CONCEPTOS_EXTRA_RECIBIDOS, payload: conceptosExtra });
        setLoading(false);
      }
    );
  };

  const getConceptosEstimacion = (idProyecto, idEstimacion) => {
    ConceptosService.getConceptosEstimacion(idProyecto, idEstimacion).then(
      (res) => {
        const { conceptos } = res.data;
        dispatch({
          type: SELECT_ESTIMACION,
          payload: conceptos,
        });
      }
    );
  };

  const getConceptosGenerador = (idProyecto, idConcepto) => {
    ConceptosService.getConceptosGenerador(idProyecto, idConcepto).then(
      (res) => {
        const { conceptos } = res.data;
        dispatch({
          type: CONCEPTOS_GENERADOR_RECIBIDOS,
          payload: conceptos,
        });
      }
    );
  };

  const formatConceptoPrecios = (concepto) => {
    if (concepto.all_precios?.length > 0) {
      const pricesData = concepto.all_precios.map((obj, index) => {
        const data = obj.Precio;
        let diferencial = 0;
        let costo_unitario = 0;
        let importe = 0;

        if (index !== 0) {
          const precioAnterior =
            concepto.all_precios[index - 1].Precio.precio_unitario;
          diferencial = data.precio_unitario - precioAnterior;
        }

        costo_unitario = data.costo / concepto.cantidad;
        importe = data.precio_unitario * concepto.cantidad;

        return {
          ...data,
          diferencial,
          costo_unitario,
          importe,
        };
      });

      concepto.all_precios = pricesData;
    }

    return concepto;
  };

  const getSingleConcepto = (idProyecto, idConcepto) => {
    ConceptosService.getSingleConcepto(idProyecto, idConcepto).then((res) => {
      const { concepto } = res.data;
      let conceptoFormatted;

      if (concepto) {
        if (concepto.precio_actual) {
          concepto.precio_actual.costo_unitario =
            concepto.precio_actual?.costo / concepto.cantidad;
        }
        conceptoFormatted = formatConceptoPrecios(concepto);
      }

      dispatch({ type: SET_SINGLE_CONCEPTO, payload: conceptoFormatted });
    });
  };

  const getConceptoPrecios = (idConcepto, idProyecto) => {
    ConceptosService.getConceptoPrecios(idConcepto, idProyecto).then((res) => {
      const { precios } = res.data;

      dispatch({
        type: SET_CONCEPTO_PRECIOS,
        payload: precios,
      });
    });
  };

  const getConceptosEstimacionVersion = (idProyecto, idEstimacionVersion) => {
    ConceptosService.getConceptosEstimacionVersion(
      idProyecto,
      idEstimacionVersion
    ).then((res) => {
      const { conceptos } = res.data;
      dispatch({ type: CONCEPTOS_RECIBIDOS, payload: conceptos });
    });
  };

  const appendConcepto = (concepto) => {
    dispatch({ type: APPEND_CONCEPTO, payload: concepto });
  };

  const setConcepto = (concepto) => {
    dispatch({ type: SET_SINGLE_CONCEPTO, payload: concepto });
  };

  const setConceptos = (conceptos) => {
    dispatch({ type: CONCEPTOS_RECIBIDOS, payload: conceptos });
  };

  const setSubconcepto = (subconcepto) => {
    dispatch({ type: SET_SUBCONCEPTO, payload: subconcepto });
  };

  const createConcepto = (idPartida) => {
    dispatch({ type: CREATE_CONCEPTO, payload: idPartida });
  };

  const setPropiedadConcepto = (idConcepto, key, value) => {
    dispatch({
      type: SET_PROPERTY_CONCEPTO,
      payload: { idConcepto, key, value },
    });
  };

  const setPropiedadConceptoGenerador = (idConceptoUbicacion, key, value) => {
    dispatch({
      type: SET_PROPIEDAD_CONCEPTO_GENERADOR,
      payload: { idConceptoUbicacion, key, value },
    });
  };

  const deleteConcepto = (idConcepto) => {
    dispatch({ type: DELETE_CONCEPTO, payload: idConcepto });
  };

  const setLoading = (payload) => {
    dispatch({ type: SET_LOADING, payload });
  };

  const postSubconcepto = async (idProyecto, idContrato, subconcepto) => {
    if (!validarConceptos([subconcepto])) {
      return alert(
        "Todos los conceptos deben tener al menos una clave, nombre y unidad."
      );
    }

    if (isNaN(subconcepto.idConcepto)) {
      setLoading(true);
      ConceptosService.postSubconcepto(idProyecto, idContrato, subconcepto)
        .then((res) => {
          getConceptosContrato(idProyecto, idContrato);
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      setLoading(true);
      ConceptosService.updateSubconcepto(idProyecto, idContrato, subconcepto)
        .then((res) => {
          getConceptosContrato(idProyecto, idContrato);
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const postConceptos = async (idProyecto, idContrato, conceptos, callback) => {
    if (!validarConceptos(conceptos)) {
      return alert(
        "Todos los conceptos deben tener al menos una clave, nombre y unidad."
      );
    }
    dispatch({ type: SHOW_SPINNER });
    if (conceptos.length > 100) {
      let iterations = Math.ceil(conceptos.length / 100);
      for (let i = 0; i < iterations; i++) {
        let currentConceptos = [...conceptos].slice(i * 100, (i + 1) * 100);
        await ConceptosService.postConceptos(
          idProyecto,
          idContrato,
          currentConceptos
        );
      }
      dispatch({ type: SET_EDIT_MODE, payload: null });
      success("Conceptos guardados con éxito.");
      dispatch({ type: HIDE_SPINNER });
      if (typeof callback === "function") {
        callback();
      }
    } else {
      ConceptosService.postConceptos(idProyecto, idContrato, conceptos)
        .then(() => {
          dispatch({ type: HIDE_SPINNER });
          if (typeof callback === "function") {
            callback();
          }
          dispatch({ type: SET_EDIT_MODE, payload: null });
          success("Conceptos guardados con éxito.");
        })
        .catch((error) => {
          dispatch({ type: HIDE_SPINNER });
          alert(error);
        });
    }
  };

  const postConceptosEstimacion = (
    idProyecto,
    idContrato,
    idEstimacion,
    conceptos,
    callback
  ) => {
    dispatch({ type: SHOW_SPINNER });
    if (!conceptos || conceptos === null) {
      return alert("No hay conceptos para esta estimación");
    }
    conceptos = conceptos.map((concepto) => {
      if (
        concepto.estimadoAnterior > 0 &&
        parseFloat(concepto.estimado) !== parseFloat(concepto.estimadoAnterior)
      ) {
        concepto.estimado = getColValue(concepto, "estimado");
        concepto.supervisado = getColValue(concepto, "supervisado");
      }
      return concepto;
    });
    const promises = [];
    const iterations = conceptos.length / 25;
    for (let i = 0; i < iterations; i++) {
      const current = conceptos.slice(i * 25, i * 25 + 25);
      promises.push(
        ConceptosService.postConceptosEstimacion(
          idProyecto,
          idContrato,
          idEstimacion,
          current
        )
      );
    }
    Promise.all(promises)
      .then((res) => {
        success("Conceptos actualizados con éxito.");
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        //alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const postConceptosEstimacionUpload = (
    idProyecto,
    idContrato,
    idEstimacion,
    conceptos,
    callback
  ) => {
    dispatch({ type: SHOW_SPINNER });
    if (!conceptos || conceptos === null) {
      return alert("No hay conceptos para esta estimación");
    }
    conceptos = conceptos.map((concepto) => {
      if (
        concepto.estimadoAnterior > 0 &&
        parseFloat(concepto.estimado) !== parseFloat(concepto.estimadoAnterior)
      ) {
        concepto.estimado = getColValue(concepto, "estimado");
        concepto.supervisado = getColValue(concepto, "supervisado");
      }
      return concepto;
    });
    const promises = [];
    const iterations = Math.ceil(conceptos.length / 25);
    for (let i = 0; i < iterations; i++) {
      const current = [...conceptos].slice(i * 25, i * 25 + 25);
      promises.push(
        ConceptosService.postConceptosEstimacionUpload(
          idProyecto,
          idContrato,
          idEstimacion,
          current
        )
      );
    }
    Promise.all(promises)
      .then((res) => {
        success("Conceptos actualizados con éxito.");
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const postConceptosAutorizados = (
    idProyecto,
    idContrato,
    idEstimacion,
    conceptos,
    conceptosExtra
  ) => {
    dispatch({ type: SHOW_SPINNER });
    if (!Array.isArray(conceptos)) {
      return alert("No hay conceptos para esta estimación");
    }
    if (Array.isArray(conceptosExtra)) {
      if (conceptosExtra.length > 0) {
        ConceptosService.postConceptosAutorizadosExtra(
          idProyecto,
          idContrato,
          idEstimacion,
          conceptosExtra
        )
          .then((res) => {
            success("Conceptos autorizados con éxito.");
          })
          .catch((error) => {
            dispatch({ type: HIDE_SPINNER });
            // alert(error);
          });
      }
    }
    conceptos = conceptos.map((concepto) => {
      if (
        concepto.autorizadoAnterior > 0 &&
        parseFloat(concepto.autorizadoAnterior) !==
          parseFloat(concepto.autorizado)
      ) {
        concepto.autorizado = getColValue(concepto, "autorizado");
      }
      return concepto;
    });
    const promises = [];
    const iterations = Math.ceil(conceptos.length / 25);
    for (let i = 0; i < iterations; i++) {
      const current = conceptos.slice(i * 25, i * 25 + 25);
      promises.push(
        ConceptosService.postConceptosAutorizados(
          idProyecto,
          idContrato,
          idEstimacion,
          current
        )
      );
    }
    Promise.all(promises)
      .then((res) => {
        success("Conceptos actualizados con éxito.");
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const postConceptosAutorizadosUpload = (
    idProyecto,
    idContrato,
    idEstimacion,
    conceptos
  ) => {
    dispatch({ type: SHOW_SPINNER });
    if (!Array.isArray(conceptos)) {
      return alert("No hay conceptos para esta estimación");
    }
    conceptos = conceptos.map((concepto) => {
      if (
        concepto.autorizadoAnterior > 0 &&
        parseFloat(concepto.autorizadoAnterior) !==
          parseFloat(concepto.autorizado)
      ) {
        concepto.autorizado = getColValue(concepto, "autorizado");
      }
      return concepto;
    });
    const promises = [];
    const iterations = Math.ceil(conceptos.length / 25);
    for (let i = 0; i < iterations; i++) {
      const current = conceptos.slice(i * 25, i * 25 + 25);
      promises.push(
        ConceptosService.postConceptosAutorizadosUpload(
          idProyecto,
          idContrato,
          idEstimacion,
          current
        )
      );
    }
    Promise.all(promises)
      .then((res) => {
        success("Conceptos actualizados con éxito.");
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const saveConceptoGenerador = (
    idProyecto,
    idContrato,
    concepto,
    callback
  ) => {
    ConceptosService.postConceptoGenerador(
      idProyecto,
      idContrato,
      concepto
    ).then(() => {
      if (typeof callback === "function") {
        callback();
      }
    });
  };

  const updatePrecioAutorizadoConcepto = (idProyecto, data, callback) => {
    ConceptosService.putPrecioAutorizadoConcepto(idProyecto, data)
      .then(() => {
        success("Concepto actualizado");
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <ConceptosContext.Provider
      value={{
        ...state,
        setConcepto,
        setConceptos,
        postConceptos,
        appendConcepto,
        setSubconcepto,
        createConcepto,
        deleteConcepto,
        postSubconcepto,
        getSingleConcepto,
        getConceptoPrecios,
        getConceptosContrato,
        setPropiedadConcepto,
        saveConceptoGenerador,
        getConceptosGenerador,
        getConceptosEstimacion,
        createConceptoGenerador,
        postConceptosEstimacion,
        postConceptosAutorizados,
        updatePrecioAutorizadoConcepto,
        getConceptosEstimacionVersion,
        setPropiedadConceptoGenerador,
        postConceptosEstimacionUpload,
        postConceptosAutorizadosUpload,
      }}
    >
      {children}
    </ConceptosContext.Provider>
  );
};
