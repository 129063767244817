import React from "react";
import ProductsOrderHandler from "../../utils/productOrders/ProductsOrderHandler";
import SearchBar from "../global/SearchBar";
import "../product/product.css";


const ProductList = ({ productList, order, orderCompleted }) => {
    const { handleEditProductOrder, handleCreateProductOrder, handleDeleteProductOrder } = ProductsOrderHandler()
    const renderActionButtons = (productoOrden) => {
        return (
            <div style={{ minWidth: "125px" }}>
                <button className="btn">
                    <i className="fa fa-bell text-primary"></i>
                </button>
                <button className="btn" onClick={() => handleEditProductOrder(order.idOrden, productoOrden)}>
                    <i className="fa fa-edit text-primary"></i>
                </button>

                <button className="btn" onClick={() => handleDeleteProductOrder(order.idOrden, productoOrden.idProductoOrden)}>
                    <i className="fa fa-trash text-primary"></i>
                </button>
            </div>
        );

    };

    return (

        <div className="mb-3">

            <div className="w-100 d-flex flex-row align-items-center mb-3">
                <div className="w-25">
                    <p className="bold">Artículos</p>
                </div>
                <div className="w-75 d-flex align-items-center justify-content-between">
                    <div className="w-75" style={{ paddingRight: "10px" }}>
                        <SearchBar />
                    </div>
                    <div className="w-25">
                        <button
                            onClick={() => { handleCreateProductOrder(order.idOrden) }}
                            className="btn btn-primary w-100"
                            style={{ minWidth: "118 px" }}
                        >
                            + Producto
                        </button>
                    </div>
                </div>
            </div>

            <div className="card shadow p-3">
                <table className="text-center">
                    <thead>
                        <tr>
                            <th style={{ textAlign: "left" }}>ARTICULO</th>
                            <th style={{ textAlign: "center" }}>UNIDAD</th>
                            <th style={{ textAlign: "center" }}>CANTIDAD</th>
                            <th style={{ textAlign: "center" }}>$PU</th>
                            <th style={{ textAlign: "center" }}>DESCUENTO</th>
                            <th style={{ textAlign: "center" }}>IMPORTE</th>
                            {!orderCompleted && <th style={{ textAlign: "center" }}>ACCIONES</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {productList && productList.length > 0 && productList.map((productoOrden) => (
                            <tr key={productoOrden.idProductoOrden}>
                                <td width="20%" style={{ textAlign: "left" }}>{productoOrden.articulo}</td>
                                <td width="10%" align="center">{productoOrden.unidad}</td>
                                <td width="10%" align="center">{productoOrden.cantidad}</td>
                                <td width="10%" align="center">{productoOrden.precioUnitario} - {productoOrden.moneda}</td>
                                <td width="10%" align="center">{productoOrden.descuento}</td>
                                <td width="10%" align="center">{productoOrden.importe}</td>
                                {!orderCompleted && <td width="10%" align="center">
                                    {renderActionButtons(productoOrden)}
                                </td>}
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ProductList;
