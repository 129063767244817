import React, { createContext, useContext, useReducer } from "react";
import AdjuntosService from "../services/AdjuntosService";
import UsuarioService from "../services/UsuarioService";
import UserReducer from "../reducers/UserReducer";
import {
  LOGIN,
  LOGOUT,
  EDITAR_USUARIO,
  GUARDAR_USUARIO,
  SET_PROPERTY_USER,
} from "../types/usuario";

import { SHOW_SPINNER, HIDE_SPINNER } from "../actions/types";
import FirebaseService from "../services/FirebaseService";
import { AppConfigContext } from "./AppConfigContext";
import { ModalContext } from "./ModalContext";
import moment from "moment";

const initialState = {
  user: null,
  correo: null,
  password: null,
  telefono: null,
  cuenta: null,
  direccion: null,
  spinner: false,
};

export const AuthContext = createContext(initialState);

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  const { app_name, firebaseConfig } = useContext(AppConfigContext);
  const { success, alert } = useContext(ModalContext);

  const AuthService = FirebaseService(firebaseConfig);

  const handleError = (error) => {
    dispatch({ type: HIDE_SPINNER });
    if (error.response) {
      if (error.response.status === 403) {
        return alert(`Lo sentimos. No tienes un acceso activo a ${app_name}.`);
      }
      if (error.response.status === 404) {
        return alert(
          "Lo sentimos. Ese usuario no existe en nuestra base de datos."
        );
      }
    }
    alert(error);
  };
  function signInWithGoogle() {
    dispatch({ type: SHOW_SPINNER });
    AuthService.signInWithGoogle()
      .then((user) => {
        if (user && user !== null) {
          AuthService.getToken().then((token) => {
            UsuarioService.setToken(token);
            UsuarioService.getUsuario()
              .then((res) => {
                let { usuario } = res.data;
                dispatch({
                  type: LOGIN,
                  payload: usuario,
                });
                dispatch({ type: HIDE_SPINNER });
              })
              .catch(handleError);
          });
        } else {
          alert("Contraseña incorrecta. Por favor, intenta de nuevo");
          dispatch({ type: HIDE_SPINNER });
          // AuthService.signOut();
        }
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          alert(
            "Lo sentimos. No encontramos una cuenta con ese correo. ¡Regístrate!"
          );
        }
        if (error.code === "auth/wrong-password") {
          alert("Contraseña incorrecta. Por favor, intenta de nuevo");
        } else {
          alert(error);
        }
        dispatch({ type: HIDE_SPINNER });
      });
  }

  function signIn(email, password) {
    dispatch({ type: SHOW_SPINNER });
    AuthService.signIn(email, password)
      .then((user) => {
        if (user && user !== null) {
          AuthService.getToken().then((token) => {
            UsuarioService.setToken(token);
            UsuarioService.getUsuario()
              .then((res) => {
                let { usuario } = res.data;
                dispatch({
                  type: LOGIN,
                  payload: usuario,
                });
                dispatch({ type: HIDE_SPINNER });
              })
              .catch(handleError);
          });
        } else {
          alert("Contraseña incorrecta. Por favor, intenta de nuevo");
          dispatch({ type: HIDE_SPINNER });
          // AuthService.signOut();
        }
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          alert(
            "Lo sentimos. No encontramos una cuenta con ese correo. ¡Regístrate!"
          );
        }
        if (error.code === "auth/wrong-password") {
          alert("Contraseña incorrecta. Por favor, intenta de nuevo");
        } else {
          alert(error);
        }
        dispatch({ type: HIDE_SPINNER });
      });
  }

  function userLoggedIn() {
    dispatch({ type: SHOW_SPINNER });
    AuthService.userLoggedIn(
      (user) => {
        if (user && user !== null) {
          AuthService.getToken().then((token) => {
            UsuarioService.setToken(token);
            UsuarioService.getUsuario()
              .then((res) => {
                let { usuario } = res.data;
                dispatch({
                  type: LOGIN,
                  payload: usuario,
                });
                dispatch({ type: HIDE_SPINNER });
              })
              .catch(handleError);
          });
        }
        dispatch({ type: HIDE_SPINNER });
      },
      (error) => {
        if (error) {
          alert(error);
          // AuthService.signOut();
        }
        dispatch({ type: HIDE_SPINNER });
      }
    );
  }

  function signOut() {
    AuthService.signOut()
      .then(() => {
        dispatch({ type: LOGOUT });
      })
      .catch((error) => {
        alert(error);
      });
  }

  async function signUp(nombre, correo, password, telefono) {
    dispatch({ type: SHOW_SPINNER });
    if (!nombre || nombre === "") return alert("Ingresa tu nombre");
    if (!correo || correo === "") return alert("Ingresa tu correo");
    if (!telefono || telefono === "") return alert("Ingresa tu telefono");
    AuthService.signUp(correo, password)
      .then((user) => {
        const uid = user.user.uid;
        UsuarioService.signUp({
          name: nombre,
          email: correo,
          phone: telefono,
          uid,
        }).then(() => {
          signIn(correo, password);
        });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        if (error.response) {
          if (error.response.status === 412) {
            return alert("Contraseña incorrecta. Por favor, intenta de nuevo");
          }
        }
        if (error.message === "EMAIL_EXISTS") {
          return alert(
            "Ya existe una cuenta con ese correo electrónico, inicia sesión."
          );
        }
        alert(error.toString());
      });
  }

  function getUsuario() {
    UsuarioService.getUsuario().then((res) => {
      const { customer } = res.data;
      dispatch({ type: LOGIN, payload: customer });
    });
  }

  function editarUsuario() {
    dispatch({ type: EDITAR_USUARIO });
  }

  function cancelEdit() {
    dispatch({ type: GUARDAR_USUARIO });
  }

  function setPropiedadUser(key, value) {
    if (key === "idAdjunto") {
      dispatch({ type: SET_PROPERTY_USER, payload: { key: "file", value } });
      if (!value)
        dispatch({ type: SET_PROPERTY_USER, payload: { key, value } });
    } else {
      if (key === "telefono") {
        value = String(value).replace(/\D/g, "");
        value = String(value).substring(0, 10);
      }
      dispatch({ type: SET_PROPERTY_USER, payload: { key, value } });
    }
  }

  function recoverPassword(email) {
    AuthService.recoverPassword(email)
      .then(() => {
        success("Te hemos enviado un correo para reestablecer tu contraseña.");
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          AuthService.sendPasswordReset(email).then(() => {
            success(
              "¡Te hemos enviado un correo para reestablecer tu contraseña!"
            );
          });
        } else {
          alert("Hubo un error al enviar el correo. Inténtalo más tarde.");
        }
      });
  }

  function updateUsuario(usuario) {
    const promises = [];
    if (usuario.picture && usuario.picture !== null) {
      if (usuario.picture.name) {
        const promiseAdjunto = new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("file", usuario.picture);
          AdjuntosService.postAdjunto(formData).then((res) => {
            const { file_id } = res.data;
            usuario.file_id = file_id;
            resolve();
          });
        });
        promises.push(promiseAdjunto);
      }
    }
    Promise.all(promises).then(() => {
      const data = { ...usuario };
      delete data.file;
      delete data.uid;
      delete data.activo;
      data.birthday = moment(data.birthday).format("YYYY-MM-DD");
      UsuarioService.putUsuario(data)
        .then(() => {
          getUsuario();
          dispatch({ type: GUARDAR_USUARIO });
          success("Perfil actualizado con éxito.");
        })
        .catch((error) => {
          alert(error);
        });
    });
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signInWithGoogle,
        signUp,
        signOut,
        getUsuario,
        cancelEdit,
        userLoggedIn,
        updateUsuario,
        editarUsuario,
        recoverPassword,
        setPropiedadUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
