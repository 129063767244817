import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MenuContext } from "../context/MenuContext";

const menuOptions = [
  {
    label: "Cronograma",
    url: "/progreso",
  },
  {
    label: "Estimaciones",
    url: "/finanzas",
  },
  {
    label: "Bitacora",
    url: "/bitacora",
  },
  {
    label: "Supervisión",
    url: "/supervision",
  },
];

const MenuContratista = () => {
  const navigate = useNavigate();
  const { setSelected } = useContext(MenuContext);

  useEffect(() => {
    setSelected(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderMenuOptions = () => {
    return menuOptions.map((option) => (
      <div key={option.url} className="col-6 px-1 mb-2">
        <div
          className="card menu-card border"
          onClick={() => navigate(`.${option.url}`)}
        >
          <div className="card-body w-100 d-flex align-items-center">
            <h3 className="h5 text-center d-block w-100">{option.label}</h3>
          </div>
        </div>
      </div>
    ));
  };
  return (
    <div className="container-fluid">
      <div className="row">{renderMenuOptions()}</div>
    </div>
  );
};

export default MenuContratista;
