//Folder
export const SET_FOLDER = "SET_FOLDER";
export const CLEAR_FOLDER = "CLEAR_FOLDER";
export const POP_BREADCRUMB = "POP_BREADCRUMB";
export const FOLDER_RECEIVED = "FOLDER_RECEIVED";
export const SET_FOLDER_ROOT = "SET_FOLDER_ROOT";
export const PUSH_BREADCRUMB = "PUSH_BREADCRUMB";
export const CREATE_SUBFOLDER = "CREATE_SUBFOLDER";
export const CLEAR_BREADCRUMB = "CLEAR_BREADCRUMB";
export const SET_FILES_FOLDER = "SET_FILES_FOLDER";
export const FOLDERS_RECEIVED = "FOLDERS_RECEIVED";
export const SET_SUBFOLDER_NAME = "SET_SUBFOLDER_NAME";
export const SET_NAVBAR = "SET_NAVBAR";
export const SET_BREADCRUMB = "SET_BREADCRUMB";
