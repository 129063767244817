import api from "./api";

const route = "/ordenes";
const productOrderRoute = "/productoOrdenes"

export default {
  getOrder: (_, idOrder) => api.get(`${route}/orden/${idOrder}`),
  getProjectOrder: (idProyecto, idOrder) => api.get(`${route}/${idProyecto}/${idOrder}`),
  getOrdenes: (idProyecto) => api.get(`${route}/${idProyecto}`),
  putOrder: (idOrder, orderData) => api.put(`${route}/orden/${idOrder}`, { orderData }),
  postOrder: (idProyecto, idProducto, orden) => api.post(`${route}/`, { idProyecto, idProducto, orden }),
  deleteOrder: (idOrder) => api.delete(`${route}/orden/${idOrder}`),
  approveOrder: (idProyecto, idOrder) => api.post(`${route}/${idProyecto}/${idOrder}/approve`),

  //orden - producto
  createProductsOrder: (orderId, newProductOrder) => api.post(`${productOrderRoute}/${orderId}/`, { newProductOrder }),
  getProductsOrder: (orderId) => api.get(`${productOrderRoute}/${orderId}/`),
  getOrdenesDeProducto: (idProducto) => api.get(`${productOrderRoute}/producto/${idProducto}/`),
  deleteProductsOrder: (productOrderId) => api.delete(`${productOrderRoute}/${productOrderId}/`),
  editProductsOrder: (productOrderId, productOrderData) => api.put(`${productOrderRoute}/${productOrderId}/`, { productOrderData }),

};
