/**
 * Contenedor de Gráfica
 * Due: 3 de Junio
 */
import React from "react";

const ChartCard = ({ titulo, children }) => {
  return (
    <div className="card p-3 shadow mt-2 mb-2" style={{ borderRadius: 10 }}>
      <div className="container-fluid pt-2 px-0">
        {titulo && titulo !== null && <h4 className="px-3">{titulo}</h4>}
        {children}
      </div>
    </div>
  );
};

export default ChartCard;
