import React, { createContext, useContext, useReducer } from "react";
import RecorridosReducer from "../reducers/RecorridosReducer";
import RecorridosService from "../services/RecorridosService";
import {
  RECORRIDOS_RECIBIDOS,
  RECORRIDO_RECIBIDO,
  SET_PROPERTY_TOUR,
  DELETE_TOUR,
} from "../types/recorridos";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../actions/types";

const initialState = {
  recorridos: null,
  recorrido: null,
  spinner: false,
};

export const RecorridosContext = createContext(initialState);

export const RecorridosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(RecorridosReducer, initialState);
  const { alert, success } = useContext(ModalContext);
  const getRecorridos = (idProyecto) => {
    RecorridosService.getTours(idProyecto)
      .then((res) => {
        const { tours } = res.data;
        dispatch({ type: RECORRIDOS_RECIBIDOS, payload: tours });
      })
      .catch(alert);
  };

  const getRecorrido = (idProyecto, idTour) => {
    RecorridosService.getTour(idProyecto, idTour)
      .then((res) => {
        const { tiles } = res.data;
        dispatch({ type: RECORRIDO_RECIBIDO, payload: tiles });
      })
      .catch(alert);
  };

//  ['0-call-center', '1-oficinas', '2-area-recreativa', '3-cocina'];

  const postTour = (idProyecto, nombre, fecha_recorrido, file) => {
    dispatch({ type: SHOW_SPINNER });
    let formData = new FormData();
    formData.append("file", file);
    formData.append("nombre", nombre);
    formData.append("fecha_recorrido", fecha_recorrido);
    RecorridosService.postTour(idProyecto, formData)
      .then((res) => {
        getRecorridos(idProyecto);
        success("Recorrido guardado");
        dispatch({ type: HIDE_SPINNER });
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const createRecorrido = () => {
    dispatch({ type: RECORRIDO_RECIBIDO, payload: { nombre: "" } });
  };

  const editTour = (tour) => {
    dispatch({ type: RECORRIDO_RECIBIDO, payload: tour });
  };

  const setPropertyTour = (key, value) => {
    dispatch({ type: SET_PROPERTY_TOUR, payload: { key, value } });
  };

  const updateTour = (idProyecto, idTour, nombre) => {
    RecorridosService.putTour(idProyecto, idTour, nombre)
      .then(() => {
        success("Recorrido guardado");
      })
      .catch(alert);
  };

  const deleteTour = (idProyecto, idTour) => {
    RecorridosService.deleteTour(idProyecto, idTour)
      .then(() => {
        dispatch({ type: DELETE_TOUR, payload: idTour });
        success("Recorrido eliminado.");
      })
      .catch(alert);
  };

  const clearRecorrido = () => {
    dispatch({ type: RECORRIDO_RECIBIDO, payload: null });
  };
  return (
    <RecorridosContext.Provider
      value={{
        ...state,
        postTour,
        editTour,
        updateTour,
        deleteTour,
        getRecorrido,
        getRecorridos,
        clearRecorrido,
        createRecorrido,
        setPropertyTour,
      }}
    >
      {children}
    </RecorridosContext.Provider>
  );
};
