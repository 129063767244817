import api from "./api";

const route = "/revisiones";

export default {
  //General
  getRevisiones: (idChecklist, idProyecto) =>
    api.get(`${route}/${idChecklist}/${idProyecto}`),
  postRevision: (revision) => api.post(`${route}`, { ...revision }),
  addItemOnRevision: (idProyecto, item, revision) =>
    api.post(`${route}/${idProyecto}/add-item`, { item, revision }),
  updateItemOnRevision: (idProyecto, idRevisionItem, data) =>
    api.put(`${route}/${idProyecto}/revision-item/${idRevisionItem}`, { data }),
};
