import React, { useContext, useEffect } from "react";
import { PlantillasContext } from "../../context/PlantillasContext";
import { ModalContext } from "../../context/ModalContext";
import PlantillaRow from "./PlantillaRow";

const Plantillas = ({ tipo, disabled, idProyecto, idChecklist }) => {
  const { clearModal } = useContext(ModalContext);
  const {
    plantilla,
    plantillas,
    setPlantilla,
    getPlantillas,
    deletePlantilla,
    selectPlantilla,
    setPlantillaChecklist,
  } = useContext(PlantillasContext);

  useEffect(() => {
    fetchPlantillas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPlantillas = () => {
    getPlantillas(tipo);
  };

  const handlePlantilla = () => {
    if (tipo === "cronograma") {
      setPlantilla(idProyecto, plantilla);
    }
    if (tipo === "checklists") {
      setPlantillaChecklist(idProyecto, idChecklist, plantilla);
    }
  };

  const renderButtons = () => {
    if (plantilla && plantilla !== null && !disabled) {
      return (
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              onClick={clearModal}
              className="btn text-muted w-100"
            >
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button
              type="submit"
              onClick={handlePlantilla}
              className="btn btn-primary w-100"
            >
              Aplicar
            </button>
          </div>
        </div>
      );
    }
  };

  const renderPlantillas = () => {
    if (Array.isArray(plantillas)) {
      if (plantillas.length === 0) {
        return (
          <div className="row">
            <p className="px-0">Aún no tienes plantillas guardadas.</p>
          </div>
        );
      }
      return plantillas.map((current) => (
        <PlantillaRow
          disabled={disabled}
          plantilla={current}
          selected={plantilla}
          key={current.idPlantilla}
          handleCallback={fetchPlantillas}
          deletePlantilla={deletePlantilla}
          selectPlantilla={selectPlantilla}
        />
      ));
    }
  };

  return (
    <div className="container-fluid">
      {renderPlantillas()}
      {renderButtons()}
    </div>
  );
};

export default Plantillas;
