import {
  EDIT_CONTRATO,
  CREATE_CONTRATO,
  SELECT_CONTRATO,
  SELECTING_CONTRATO,
  CONTRATOS_RECIBIDOS,
  CANCEL_EDIT_CONTRATO,
  SET_PROPERTY_CONTRATO,
} from "../types/contratos";
import { HIDE_SPINNER, SHOW_SPINNER } from "../actions/types";

const contratoSchema = (num) => ({
  idContrato: "nuevo",
  idProveedor: null,
  proveedor: "",
  numero: num,
});

const ContratosReducer = (state, { type, payload }) => {
  switch (type) {
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    case CONTRATOS_RECIBIDOS:
      return { ...state, contratos: payload, loading: false };
    case SELECT_CONTRATO:
      return {
        ...state,
        contrato: payload,
        selecting: false,
      };
    case SET_PROPERTY_CONTRATO: {
      const contrato = { ...state.contrato };
      const { key, value } = payload;
      contrato[key] = value;
      return { ...state, contrato };
    }
    case CREATE_CONTRATO: {
      return {
        ...state,
        contrato: contratoSchema(payload),
        editMode: true,
      };
    }
    case EDIT_CONTRATO: {
      return { ...state, editMode: true };
    }
    case CANCEL_EDIT_CONTRATO: {
      return { ...state, editMode: false };
    }
    case SELECTING_CONTRATO: {
      return { ...state, selecting: true };
    }
    default:
      return { ...state };
  }
};
export default ContratosReducer;
