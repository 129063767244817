import React, { useContext, useEffect } from "react";
import ProductTable from "../components/product/ProductTable/ProductTable";
import ProductFilters from "../components/product/ProductFilters";
import ProductsHandler from "../utils/products/ProductsHandler";
import "../css/almacen.css";
import { MenuContext } from "../context/MenuContext";

const Almacen = ({ idProyecto }) => {
  const { selectTabs, setSelected } = useContext(MenuContext);

  useEffect(() => {
    selectTabs();
    setSelected({ title: "almacen" });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { handleCreateProduct } = ProductsHandler({ idProyecto: idProyecto });

  return (
    <div className="container-fluid px-0">
      <div className="row">
        <div className="col-8 col-md-9 col-lg-10 col-xl-11">
          <ProductFilters />
        </div>
        <div className="col-4 col-md-3 col-lg-2 col-xl-1">
          <button
            onClick={handleCreateProduct}
            className="btn btn-primary w-100"
          >
            <i className="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <ProductTable projectId={idProyecto} />
    </div>
  );
};

export default Almacen;
