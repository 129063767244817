import api from "./api";
import { getArgs } from "../utils";

const route = "/obras";

export default {
  getProyectos: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getAllProyectos: () => api.get(`${route}/admin/all`),
  getProyecto: (idProyecto) => api.get(`${route}/${idProyecto}`),
  postProyecto: (proyecto) => api.post(route, { ...proyecto }),
  putProyecto: (proyecto) =>
    api.put(`${route}/${proyecto.idProyecto}`, { ...proyecto }),
  deleteProyecto: (idProyecto) => api.delete(`${route}/${idProyecto}`),
};
