import { HIDE_SPINNER, SHOW_SPINNER } from "../actions/types";
import {
  SET_ADJUNTOS,
  SET_FOCUSED,
  SET_PROPERTY_ADJUNTO,
  SET_SINGLE_ADJUNTO,
} from "../types/adjuntos";

const AdjuntoReducer = (state, { type, payload }) => {
  switch (type) {
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    case SET_ADJUNTOS:
      return { ...state, adjuntos: payload };
    case SET_SINGLE_ADJUNTO:
      return { ...state, adjunto: payload };
    case SET_PROPERTY_ADJUNTO:
      console.log(JSON.parse(JSON.stringify(state)));
      console.log(JSON.parse(JSON.stringify(payload)));
      const adjunto = { ...state.adjunto };
      console.log(JSON.parse(JSON.stringify(adjunto)));
      const { key, value } = payload;
      adjunto[key] = value;
      console.log(JSON.parse(JSON.stringify(adjunto)));
      return { ...state, adjunto };
    case SET_FOCUSED:
      return { ...state, focused: payload };
    default:
      return { ...state };
  }
};
export default AdjuntoReducer;
