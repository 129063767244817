import React, { useContext, useEffect, useState } from "react";
import { RetrasosContext } from "../../context/RetrasosContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import { PagosContext } from "../../context/PagosContext";
import ChartCard from "../analiticas/ChartCard";
import ColorLegend from "../common/ColorLegend";
import Entradas from "../bitacora/Entradas";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
import moment from "moment";
import TabGroup from "../common/TabGroup";

const GraficaFinanzas = ({ idProyecto }) => {
  const { proyecto, progresoObra, getProgresoObra } =
    useContext(ProyectosContext);
  const { retrasos, getRetrasos } = useContext(RetrasosContext);
  const { pagos, getPagosProyecto } = useContext(PagosContext);
  const [selected, setSelected] = useState(0);
  const [pagosDots, setPagosDots] = useState(null);
  const [totalPagos, setTotalPagos] = useState(null);

  const tabs = [
    { title: "Mes", selecteds: true, value: 0 },
    { title: "Semana", selected: false, value: 1 },
    { title: "Día", selected: false, value: 2 },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto]);

  useEffect(() => {
    if (Array.isArray(pagos)) {
      handleTotalPagos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagos]);

  useEffect(() => {
    if (totalPagos !== null) {
      handlePagosDots();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPagos]);

  function fetchData() {
    getPagosProyecto(idProyecto);
    getProgresoObra(idProyecto);
    getRetrasos(idProyecto);
  }

  function handleTotalPagos() {
    let total = 0;
    pagos.forEach((pago) => {
      total += parseFloat(pago.monto);
    });
    setTotalPagos(total);
  }

  function handlePagosDots() {
    const pagosMes = getPagosDots(pagos, 30);
    const pagosSemana = getPagosDots(pagos, 7);
    const pagosDia = getPagosDots(pagos, 1);
    setPagosDots({ pagosMes, pagosSemana, pagosDia });
  }

  function getTabs() {
    if (progresoObra && progresoObra !== null) {
      if (progresoObra.programadoMes.length === 0) {
        if (selected === 0) setSelected(1);
        return tabs.slice(1);
      }
    }
    return tabs;
  }

  function renderComentarios() {
    if (Array.isArray(retrasos)) {
      if (retrasos.length === 0) {
        return <p>No hay retrasos registrados en la bitácora.</p>;
      }
      return <Entradas entradas={retrasos} />;
    }
  }

  const colors = [
    {
      title: "Avance Programado",
      color: "#007991",
      className: "programado-gradiente",
    },
    {
      title: "Real Pagos",
      color: "#007991",
      className: "pagos-gradiente",
    },
    {
      title: "Avance Real",
      color: "#007991",
      className: "real-finanzas-gradiente",
    },
  ];

  function getPagosDots(pagos, interval) {
    const { fecha_inicio, fecha_fin } = proyecto;
    let fin = moment(fecha_fin).add(interval, "days");
    let inicio = moment(fecha_inicio);
    let dots = [];
    let acum = 0.0;

    let pagosSet = new Set();
    while (inicio.isBefore(fin)) {
      const current = moment(inicio).startOf("day");

      if (pagos.length === 0) {
        acum = 0;
      } else {
        let currentPagos = pagos.filter((pago) =>
          moment(pago.fecha_hora_autorizacion).isBefore(current)
        );
        // eslint-disable-next-line no-loop-func
        currentPagos.forEach((pago) => {
          if (!pagosSet.has(pago.idPago)) acum += parseFloat(pago.monto);
          pagosSet.add(pago.idPago);
        });
      }

      dots.push(
        (parseFloat(acum) / parseFloat(proyecto.autorizado)).toFixed(2)
      );
      inicio.add(interval, "days");
    }
    return dots;
  }

  function getProgramadoDots(realDia, programadoDia) {
    if (programadoDia.length >= realDia.length) {
      return programadoDia;
    }
    let dots = [];
    let i = 0;
    while (i < realDia.length) {
      if (!programadoDia || i >= programadoDia.length) {
        dots.push(
          programadoDia
            ? parseFloat(programadoDia[programadoDia.length - 1]).toFixed(2)
            : 0
        );
      } else {
        dots.push(parseFloat(programadoDia[i]).toFixed(2));
      }
      i++;
    }
    return dots;
  }

  /*function getCostosDots(realDia, programadoDia, real) {
    if (programadoDia.length >= realDia.length) {
      return programadoDia.map((programado) =>
        (parseFloat(programado) * (real ? 0.72 : 0.64)).toFixed(2)
      );
    }
    let dots = [];
    let i = 0;
    while (i < realDia.length) {
      if (!programadoDia || i >= programadoDia.length) {
        dots.push(
          programadoDia
            ? parseFloat(programadoDia[programadoDia.length - 1]).toFixed(2)
            : 0
        );
      } else {
        dots.push(parseFloat(programadoDia[i]).toFixed(2));
      }
      i++;
    }
    dots = dots.map((dot) =>
      (parseFloat(dot) * (real ? 0.72 : 0.64)).toFixed(2)
    );
    return dots;
  }*/

  function getRealDots(realDia, programadoDia) {
    if (programadoDia.length < realDia.length) {
      return realDia;
    }
    let dots = [];
    let i = 0;
    while (i < programadoDia.length) {
      let current = realDia[i];
      if (current && i < realDia.length) {
        dots.push(parseFloat(current).toFixed(2));
      } else {
        dots.push(0);
      }
      i++;
    }
    return dots;
  }

  function formatPagosDots(pagosData, programadoData) {
    if (programadoData.length > pagosData.length) {
      let currentLastItem = pagosData[pagosData.length - 1];
      let diff = programadoData.length - pagosData.length;
      for (let i = 0; i < diff; i++) {
        pagosData.push(
          isNaN(currentLastItem) ? 0 : parseFloat(currentLastItem).toFixed(2)
        );
      }
    }
    return pagosData;
  }

  function renderGrafica() {
    if (
      progresoObra &&
      progresoObra !== null &&
      proyecto !== null &&
      proyecto !== null &&
      pagosDots !== null
    ) {
      const {
        programadoDia,
        realDia,
        programadoMes,
        realMes,
        realSemana,
        programadoSemana,
      } = progresoObra;
      if (Array.isArray(realDia) && Array.isArray(programadoMes)) {
        const programadoData =
          selected === 0
            ? programadoMes
            : selected === 1
            ? programadoSemana
            : programadoDia;
        const realData =
          selected === 0 ? realMes : selected === 1 ? realSemana : realDia;
        const pagosData =
          selected === 0
            ? pagosDots.pagosMes
            : selected === 1
            ? pagosDots.pagosSemana
            : pagosDots.pagosDia;
        return (
          <>
            <Chart
              type="area"
              options={{
                colors: ["#007991", "#CC1B7F", "#FF8008", "#00FF00", "#FF0000"],
                dataLabels: {
                  enabled: false,
                  enabledOnSeries: 1,
                },
                legend: {
                  show: true,
                },
                xaxis: {
                  title: {
                    text: "Tiempo en días",
                  },
                  labels: {
                    hideOverlappingLabels: true,
                    style: {
                      fontSize: "8px",
                    },
                  },
                },
                yaxis: {
                  title: {
                    text: "Porcentaje completado",
                  },
                },
              }}
              series={[
                {
                  name: "Programado",
                  data: getProgramadoDots(realData, programadoData),
                },
                {
                  name: "Real",
                  data: getRealDots(realData, programadoData),
                },
                {
                  name: "Pagos",
                  data: formatPagosDots(pagosData, programadoData),
                },
              ]}
            />
            <TabGroup
              tabs={getTabs()}
              selected={selected}
              modifier={setSelected}
            />
          </>
        );
      }
    }
  }

  return (
    <ChartCard>
      <div className="row">
        <h4 className="mb-3">Progreso de Obra</h4>

        <div className="col-md-12 col-xl-8 px-0">{renderGrafica()}</div>
        <div className="col-md-12 col-xl-4 border-left-1 bg-white">
          <h5 className="font-weight-bold mb-0">Leyenda</h5>
          <div className="py-3">
            <ColorLegend colors={colors} />
          </div>
          <h5 className="bold">Comentarios</h5>
          <div
            className="card bg-light p-3"
            style={{ height: 250, overflow: "hidden" }}
          >
            {renderComentarios()}
          </div>
          <Link
            className="btn btn-outline-primary my-3"
            to={`/obra/${idProyecto}/bitacora`}
          >
            Ir a Bitacora
          </Link>
        </div>
      </div>
    </ChartCard>
  );
};

export default GraficaFinanzas;
