import React, { useContext, useEffect } from "react";
import SearchableSelect from "../global/SearchableSelect";
import { PermisosContext } from "../../context/PermisosContext";
import { UsuariosContext } from "../../context/UsuariosContext";
import { ProyectosContext } from "../../context/ProyectosContext";

const PermisoForm = ({ handleCancel }) => {
  const { users, getUsers } = useContext(UsuariosContext);
  const { permisos, getPermisos } = useContext(PermisosContext);
  const { proyectos, getAllProyectos } = useContext(ProyectosContext);
  const { permiso, postPermiso, setPropiedadPermiso } =
    useContext(PermisosContext);

  useEffect(() => {
    getUsers();
    getPermisos();
    getAllProyectos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      Array.isArray(proyectos) &&
      Array.isArray(permisos) &&
      Array.isArray(users)
    ) {
      setupInitial();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permisos, proyectos, users]);

  const handleSubmit = (e) => {
    e.preventDefault();
    postPermiso(permiso);
  };

  const setupInitial = () => {
    if (!permiso.idUsuario && users) {
      if (users.length > 0)
        setPropiedadPermiso("idUsuario", users[0].idUsuario);
    }
    if (!permiso.idProyecto && proyectos) {
      if (proyectos.length > 0)
        setPropiedadPermiso("idProyecto", proyectos[0].idProyecto);
    }
    if (!permiso.idPermiso) {
      setPropiedadPermiso("idPermiso", 1);
    }
  };


  const renderObras = () => {
    if (Array.isArray(proyectos)) {
      return <div className="mb-3">
        <SearchableSelect
          items={proyectos}
          itemText={"nombre"}
          itemValue={"idProyecto"}
          modifier={(proyecto) => setPropiedadPermiso("idProyecto", proyecto.value)}
          value={permiso.idProyecto}
        />
      </div>

    }
  };

  const renderUsers = () => {
    if (users) {
      const usersOptions = users.map((user) => {
        const nombre = user.nombre && user.correo
          ? `${user.nombre} : ${user.correo}`
          : user.nombre || user.correo || 'No Name';

        return {
          nombre,
          idUsuario: user.idUsuario
        };
      });

      return <div className="mb-3">
        <SearchableSelect
          items={usersOptions}
          itemText={"nombre"}
          itemValue={"idUsuario"}
          modifier={(usuario) => setPropiedadPermiso("idUsuario", usuario.value)}
          value={permiso.idUsuario}
        />
      </div>

    }

  }
  const renderPermisos = () => {
    if (Array.isArray(permisos)) {
      return <div className="mb-3">
        <SearchableSelect
          items={permisos}
          itemText={"nombre"}
          itemValue={"idPermiso"}
          modifier={(permiso) => setPropiedadPermiso("idPermiso", permiso.value)}
          value={permiso.idPermiso}
        />
      </div>

    }
  }

  const renderUserForm = () => {
    if (permiso?.id === "nuevo") {
      return renderUsers()
    }
    return <p>{permiso?.nombre_usuario}</p>;
  };

  const renderProyectoForm = () => {
    if (permiso?.id === "nuevo") {
      return (renderObras());
    }
    return <p>{permiso?.nombre_proyecto}</p>;
  };

  return (
    <form onSubmit={handleSubmit} className="container-fluid px-0">
      <label className="d-block">Usuario</label>
      {renderUserForm()}
      <label className="d-block">Obra</label>
      {renderProyectoForm()}
      <label className="d-block">Permiso</label>
      {renderPermisos()}
      {permiso?.idPermiso !== "" && permiso?.idPermiso !== null && (
        <div className="row mt-3 mb-3">
          <div className="container">
            <p className="small">
              *El permiso debe borrarse si se requiere cambiar proyecto o
              usuario
            </p>
          </div>
        </div>
      )}
      <div className="row">
       
        <div className="col-6 ">
          <button
            type="button"
            onClick={handleCancel}
            className="btn text-muted w-100"
          >
            Cancelar
          </button>
        </div>

        <div className="col-6 ">
          <button type="submit" className="btn btn-primary w-100">
            Guardar
          </button>
        </div>

      </div>
    </form>
  );
};

export default PermisoForm;
