import React, { useState } from "react";
import "../../css/searchBar.css"

const SearchBar = ({ onSearch }) => {
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        onSearch(query);
    };

    return (
        <input
            className="form-control  w-100 bg-white py-2 px-4"
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
        />
    );
};

export default SearchBar;