import React, { useState, useContext, useEffect } from "react";
import { EstimacionesContext } from "../../context/EstimacionesContext";
import { ConceptosContext } from "../../context/ConceptosContext";
import SearchableSelect from "../global/SearchableSelect";
import moment from "moment";

const SelectEstimacion = ({ idProyecto, idContrato }) => {
  const [selected, setSelected] = useState("");
  const { getConceptosEstimacion } = useContext(ConceptosContext);
  const { estimaciones, clearEstimacion, selectEstimacion } =
    useContext(EstimacionesContext);

  useEffect(() => {
    if (!isNaN(parseInt(selected))) {
      selectEstimacion(idProyecto, idContrato, selected);
      getConceptosEstimacion(idProyecto, selected);
    } else {
      clearEstimacion();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selected,
    idProyecto,
    idContrato,
  ]);

  return (
    <SearchableSelect
      items={estimaciones}
      itemText={estimacion => `#${estimacion.numero} - ${moment(estimacion.fecha_fin).format("DD MMM YYYY")}`}
      itemValue={"idEstimacion"}
      modifier={(e) => setSelected(e.value)}
      value={selected}
    />
  );
};

export default SelectEstimacion;
