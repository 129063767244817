import React, { useContext } from "react";
import { MenuContext } from "../../context/MenuContext";

const MobileSubHeader = () => {
  const { selected, buttons, onClickTitle } = useContext(MenuContext);

  const selectedValid =
    selected && selected !== null && typeof selected === "object";


  return (
    <div className="">
      <div className={"row bg-secondary p-2 align-items-center"}>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-8">
              <h3 onClick={onClickTitle} className="mb-0 bold text-capitalize">
                {selectedValid ? selected.title : typeof selected === "string" ? selected : ""}
              </h3>
              {selectedValid && <p className="mb-0">{selected.subtitle}</p>}
            </div>
            <div className="col-4 text-end">{buttons}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileSubHeader;
