import arrayMove from "array-move";
import {
  SET_SUBDESTAJO,
  SUBDESTAJOS_RECEIVED,
  SET_PROPERTY_SINGLE_SUBDESTAJO,
  CREATE_NUEVO_SUBDESTAJO,
  REORDENAR_SUBDESTAJOS,
  DELETE_SUBDESTAJO,
  SET_PROPERTY_SUBDESTAJO,
} from "../types/subdestajos";

const SubdestajosReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_SUBDESTAJO: {
      let { subdestajo } = state;
      if (
        subdestajo !== null &&
        typeof subdestajo === "object" &&
        payload !== null
      ) {
        subdestajo = { ...subdestajo, ...payload };
      } else {
        subdestajo = payload;
      }
      return { ...state, subdestajo };
    }
    case SUBDESTAJOS_RECEIVED: {
      return { ...state, subdestajos: payload };
    }
    case SET_PROPERTY_SINGLE_SUBDESTAJO: {
      const subdestajo = { ...state.subdestajo };
      const { key, value } = payload;
      subdestajo[key] = value;
      return { ...state, subdestajo };
    }
    case SET_PROPERTY_SUBDESTAJO: {
      const { idSubDestajo, key, value } = payload;
      let subdestajos = [...state.subdestajos];
      let subdestajo = subdestajos.find(
        (subdestajo) => String(subdestajo.idSubDestajo) === String(idSubDestajo)
      );
      if (subdestajo) subdestajo[key] = value;
      return { ...state, subdestajos };
    }
    case CREATE_NUEVO_SUBDESTAJO:
      return { ...state, subdestajos: [...state.subdestajos, payload] };
    case DELETE_SUBDESTAJO: {
      let subdestajos = [...state.subdestajos];
      for (let i = 0; i < subdestajos.length; i++) {
        let subdestajo = subdestajos[i];
        if (subdestajo.idSubDestajo === payload) {
          if (String(subdestajo.idSubDestajo).includes("nuev"))
            subdestajos.splice(i, 1);
          else subdestajo.idSubDestajo = "delete" + payload;
        }
      }
      return { ...state, subdestajos };
    }
    case REORDENAR_SUBDESTAJOS: {
      const { oldIndex, newIndex } = payload;
      const subdestajos = [...state.subdestajos];
      if (typeof state.editMode === "string") {
        const idDestajo = parseInt(state.editMode.substring(4));
        let subdestajosDestajo = subdestajos.filter(
          (subdestajo) => String(subdestajo.idDestajo) === String(idDestajo)
        );
        subdestajosDestajo = subdestajosDestajo.sort((a, b) =>
          a.orden > b.orden ? 1 : -1
        );
        subdestajosDestajo = [
          ...arrayMove(subdestajosDestajo, oldIndex, newIndex),
        ];
        subdestajosDestajo.forEach((subdestajo, index) => {
          subdestajo.orden = index + 1;
        });
      }
      return { ...state, subdestajos };
    }
    default:
      return { ...state };
  }
};
export default SubdestajosReducer;
