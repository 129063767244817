import React, { useReducer, createContext, useContext } from "react";
import { RETRASOS_RECEIVED } from "../actions/types";
import RetrasosReducer from "../reducers/RetrasosReducer";
import RetrasosService from "../services/RetrasosService";
import { ModalContext } from "./ModalContext";

const initialState = {
  retrasos: null,
};

export const RetrasosContext = createContext(initialState);

export const RetrasosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(RetrasosReducer, initialState);

  const { alert, success } = useContext(ModalContext);

  const getRetrasos = (idProyecto) => {
    RetrasosService.getRetrasos(idProyecto)
      .then((res) => {
        const { retrasos } = res.data;
        dispatch({ type: RETRASOS_RECEIVED, payload: retrasos });
      })
      .catch(alert);
  };

  const postRetraso = (idProyecto, retraso, callback) => {
    if (retraso.contenido !== "") {
      RetrasosService.postRetraso(idProyecto, retraso)
        .then(() => {
          success("Retraso agregado.");
          if (callback) callback();
        })
        .catch(alert);
    }
  };

  return (
    <RetrasosContext.Provider value={{ ...state, getRetrasos, postRetraso }}>
      {children}
    </RetrasosContext.Provider>
  );
};
