import React, { useState, useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { DestajosContext } from "../../context/DestajosContext";
import { PartidasContext } from "../../context/PartidasContext";
import { SubdestajosContext } from "../../context/SubdestajosContext";
import { PlantillasContext } from "../../context/PlantillasContext";
import { ChecklistContext } from "../../context/ChecklistContext";
import { ItemsContext } from "../../context/ItemsContext";

const PlantillaForm = ({ tipo }) => {
  const [nombre, setNombre] = useState("");
  const { items } = useContext(ItemsContext);
  const { clearModal } = useContext(ModalContext);
  const { partidas } = useContext(PartidasContext);
  const { destajos } = useContext(DestajosContext);
  const { subdestajos } = useContext(SubdestajosContext);
  const { crearPlantillaCronograma } = useContext(PlantillasContext);
  const { checklist, postPlantillaChecklist } = useContext(ChecklistContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (tipo === "cronograma") {
      crearPlantillaCronograma(partidas, destajos, subdestajos, nombre);
    }
    if (tipo === "checklists") {
      postPlantillaChecklist(nombre, checklist.sections, items);
    }
  };

  const renderPreview = () => {
    if (tipo === "cronograma") {
      return (
        <div className="row">
          <div className="col-4">
            <h5 className="titleCuadradoPlantilla">PARTIDAS</h5>
            <div className="p-3 mb-3 fondoIndividualesPlantilla">
              <ul className="list-unstyled">
                <li className="mb-1">Nombre</li>
                <li className="mb-1">Ponderación</li>
                <li className="mb-1">Orden</li>
                <li className="mb-1">Punto Crítico</li>
                <li className="mb-1">Destajos</li>
              </ul>
            </div>
          </div>
          <div className="col-4">
            <h5 className="titleCuadradoPlantilla">DESTAJOS</h5>
            <div className="p-3 mb-3 fondoIndividualesPlantilla">
              <ul className="list-unstyled">
                <li className="mb-1">Nombre</li>
                <li className="mb-1">Ponderación</li>
                <li className="mb-1">Orden</li>
                <li className="mb-1">Subdestajos</li>
              </ul>
            </div>
          </div>
          <div className="col-4">
            <h5 className="titleCuadradoPlantilla">SUBDESTAJOS</h5>
            <div className="p-3 mb-3 fondoIndividualesPlantilla">
              <ul className="list-unstyled">
                <li className="mb-1">Nombre</li>
                <li className="mb-1">Ponderación</li>
                <li className="mb-1">Orden</li>
              </ul>
            </div>
          </div>
        </div>
      );
    }
    if (tipo === "checklists") {
      return (
        <div className="row">
          <div className="col-6">
            <h5 className="titleCuadradoPlantilla">SECCIONES</h5>
            <div className="p-3 mb-3 fondoIndividualesPlantilla">
              <ul className="list-unstyled">
                <li className="mb-1">Nombre</li>
                <li className="mb-1">Orden</li>
              </ul>
            </div>
          </div>
          <div className="col-6">
            <h5 className="titleCuadradoPlantilla">ITEMS</h5>
            <div className="p-3 mb-3 fondoIndividualesPlantilla">
              <ul className="list-unstyled">
                <li className="mb-1">Nombre</li>
                <li className="mb-1">Duracion</li>
                <li className="mb-1">Responsable</li>
              </ul>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label className="mb-2 text-uppercase">Nombre de la Plantilla</label>
      <input
        type="text"
        value={nombre}
        className="form-control mb-3"
        placeholder="Ejemplo: Cafeterías"
        onChange={(e) => setNombre(e.target.value)}
      />
      <p>La plantilla guardará la siguiente información</p>
      {renderPreview()}
      <div className="row">
        <div className="col-6">
          <button
            className="btn text-muted w-100"
            onClick={clearModal}
            type="button"
          >
            CANCELAR
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn w-100 btn-primary">
            <i className="fa fa-save"></i> GUARDAR
          </button>
        </div>
      </div>
    </form>
  );
};

export default PlantillaForm;
