import React, { useContext, useEffect } from "react";
import { SubdestajosContext } from "../../context/SubdestajosContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import SearchableSelect from "../common/SearchableSelect";

const SelectSubDestajo = ({ selected, modifier }) => {
  const { subdestajos, getSubDestajosProyecto } =
    useContext(SubdestajosContext);
  const { proyecto } = useContext(ProyectosContext);
  const { idProyecto } = proyecto;

  useEffect(() => {
    if (!Array.isArray(subdestajos)) {
      getSubDestajosProyecto(idProyecto);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto]);

  return (
    <div className="mb-3">
      <label>Selecciona una SubDestajo</label>
      <SearchableSelect
        items={subdestajos}
        labelKey="nombre"
        selected={selected}
        selectItem={modifier}
      />
    </div>
  );
};

export default SelectSubDestajo;
