import React from 'react'
import '../product.css'


const icons = {
    'aprobado': <i className="fa fa-check-circle me-2"></i>,
    'solicitado': <i className="fa fa-paper-plane me-2"></i>,
    'recibido': <i className="fa fa-inbox me-2"></i>
}


const ProductOrderStatusBadge = ({ status }) => {
    return <div className={`badge badge-pill bg-${status === 'aprobado' ? "success" : status === 'solicitado' ? "warning" : "success"}-light w-100 p-2 large`}>
        {icons[status]}
        <span className="mb-0 ps-1">{status}</span>
    </div>;
};

export default ProductOrderStatusBadge;