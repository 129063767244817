import React, { createContext, useReducer } from "react";
import CronogramaReducer from "../reducers/CronogramaReducer";
import {
  TOGGLE_DESTAJO,
  TOGGLE_PARTIDA,
  SET_SHOW_DESTAJOS,
  SET_SHOW_SUBDESTAJOS,
} from "../types/cronograma";

const initialState = {
  showDestajos: [],
  showSubDestajos: [],
};

export const CronogramaContext = createContext(initialState);

export const CronogramaProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CronogramaReducer, initialState);

  const setOpenPartidas = (partidas) => {
    dispatch({ type: SET_SHOW_DESTAJOS, payload: partidas });
  };

  const setOpenDestajos = (destajos) => {
    dispatch({ type: SET_SHOW_SUBDESTAJOS, payload: destajos });
  };

  const toggleOpenPartida = (idPartida) => {
    dispatch({ type: TOGGLE_PARTIDA, payload: idPartida });
  };

  const toggleOpenDestajo = (idDestajo) => {
    dispatch({ type: TOGGLE_DESTAJO, payload: idDestajo });
  };

  return (
    <CronogramaContext.Provider
      value={{
        ...state,
        toggleOpenPartida,
        toggleOpenDestajo,
        setOpenDestajos,
        setOpenPartidas,
      }}
    >
      {children}
    </CronogramaContext.Provider>
  );
};
