import React, { useContext } from "react";
import { ProductsContext } from "../../context/ProductsContext";
import { ModalContext } from "../../context/ModalContext";
import ProductForm from "../../components/product/ProductForm";
import ProductCloneForm from "../../components/product/ProductCloneForm";
import { useNavigate } from "react-router-dom";
import InsumoForm from "../../components/product/InsumoForm/InsumoForm";

const ProductsHandler = (props) => {
  const { modalComponent, clearModal, confirm } = useContext(ModalContext);
  const { deleteProduct, deleteProductInsumo, cloneProduct } =
    useContext(ProductsContext);
  const navigate = useNavigate();

  const handleCancel = () => {
    clearModal();
  };

  const handleCreateProduct = () => {
    modalComponent(
      "Añadir Producto:",
      <ProductForm
        handleCancel={handleCancel}
        title={"Agregar producto"}
        projectId={props?.projectId ? props.projectId : undefined}
      />
    );
  };

  const handleEditProduct = (product) => {
    modalComponent(
      `Editar Producto:`,
      <ProductForm
        handleCancel={handleCancel}
        title={"Editar producto"}
        productToEdit={product}
      />
    );
  };

  const handleDeleteProduct = (product_id, goBack) => {
    const onDelete = () => {
      deleteProduct(product_id);
      if (goBack) {
        navigate(-1);
      }
    };

    modalComponent(
      "¿Eliminar Producto?",
      <div className="w-100 d-flex mt-3">
        <button className="btn me-1 w-50" onClick={clearModal}>
          Cancelar
        </button>
        <button className="btn btn-danger ms-1 w-50" onClick={onDelete}>
          Eliminar Producto
        </button>
      </div>
    );
  };

  const handleCloneProduct = (product_id) => {
    modalComponent(
      "Agregar Insumo:",
      <ProductCloneForm cloneProduct={cloneProduct} product_id={product_id} />
    );
  };

  const handleCreateChildProduct = (parentId) => {
    modalComponent(
      "Agregar Insumo:",
      <InsumoForm parentId={parentId} handleCancel={handleCancel} />
    );
  };

  const handleEditChildProduct = (idProductoPadre, productoHijo, cantidad) => {
    modalComponent(
      `Editar Insumo`,
      <InsumoForm
        parentId={idProductoPadre}
        childId={productoHijo.idProducto}
        insumoToEdit={{ productoHijo, cantidad }}
        handleCancel={handleCancel}
      />
    );
  };

  const handleDeleteChildProduct = (idProductoPadre, idProductoHijo) => {
    confirm("¿Eliminar Insumo?", () =>
      deleteProductInsumo(idProductoPadre, idProductoHijo)
    );
  };

  return {
    handleCreateProduct,
    handleCreateChildProduct,
    handleEditProduct,
    handleEditChildProduct,
    handleDeleteChildProduct,
    handleDeleteProduct,
    handleCloneProduct,
  };
};

export default ProductsHandler;
