import {
  PERMISOS_RECEIVED,
  CREATE_NUEVO_PERMISO,
  SEARCH_PERMISOS,
  RESET_SEARCH,
  SET_PROPERTY_PERMISO,
  TIPOS_PERMISOS_RECIBIDOS,
  PERMISOS_PROYECTO_RECEIVED,
  SET_PERMISO,
} from "../types/permisos";

export default (state, { type, payload }) => {
  let permiso = {};

  switch (type) {
    case PERMISOS_PROYECTO_RECEIVED:
      return { ...state, permisos_proyecto: payload };
    case TIPOS_PERMISOS_RECIBIDOS:
      return { ...state, tipos: payload };
    case PERMISOS_RECEIVED:
      return { ...state, permisos: payload };
    case CREATE_NUEVO_PERMISO:
      return { ...state, permiso: payload };
    case SET_PERMISO:
      return { ...state, permiso: payload };
    case SET_PROPERTY_PERMISO:
      const { key, value } = payload;
      permiso = { ...state.permiso };
      permiso[key] = value;
      return { ...state, permiso };
    case SEARCH_PERMISOS:
      let permisos = [...state.permisos];
      let searchResult = permisos.filter((permiso) => {
        if (permiso.correo) {
          if (permiso.correo.toLowerCase().startsWith(payload)) return permiso;
        }
        if (permiso.nombre_usuario) {
          if (permiso.nombre_usuario.toLowerCase().startsWith(payload))
            return permiso;
        }
        if (permiso.nombre_proyecto) {
          if (permiso.nombre_proyecto.toLowerCase().startsWith(payload))
            return permiso;
        }
        if (permiso.direccion) {
          if (permiso.direccion.toLowerCase().startsWith(payload))
            return permiso;
        }
        return null;
      });
      return { ...state, query: payload, searchResult };
    case RESET_SEARCH:
      return { ...state, query: "", searchResult: undefined };
    default:
      return { ...state };
  }
};
