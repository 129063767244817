import React, { useReducer, createContext, useContext } from "react";
import SubdestajosReducer from "../reducers/SubdestajosReducer";
import SubdestajosService from "../services/SubdestajosService";
import AvancesService from "../services/AvancesService";
import {
  SET_PROPERTY_SINGLE_SUBDESTAJO,
  SET_PROPERTY_SUBDESTAJO,
  CREATE_NUEVO_SUBDESTAJO,
  REORDENAR_SUBDESTAJOS,
  SUBDESTAJOS_RECEIVED,
  DELETE_SUBDESTAJO,
  SET_SUBDESTAJO,
} from "../types/subdestajos";
import { validarPorcentajeTotal } from "../utils";
import { ModalContext } from "./ModalContext";

const initialState = {
  subdestajos: null,
  subdestajo: null,
};

export const SubdestajosContext = createContext(initialState);

export const SubdestajosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SubdestajosReducer, initialState);
  const { alert, success } = useContext(ModalContext);

  const getSingleSubdestajo = (idProyecto, idSubDestajo) => {
    SubdestajosService.getSingleSubdestajo(idProyecto, idSubDestajo).then(
      (res) => {
        const { subdestajo } = res.data;
        dispatch({ type: SET_SUBDESTAJO, payload: subdestajo });
      }
    );
  };

  const getSubDestajos = (idProyecto) => {
    SubdestajosService.getSubdestajos(idProyecto)
      .then((res) => {
        const { subdestajos } = res.data;
        dispatch({ type: SUBDESTAJOS_RECEIVED, payload: subdestajos });
      })
      .catch(alert);
  };

  const setPropiedadSubDestajo = (idSubDestajo, key, value) => {
    dispatch({
      type: SET_PROPERTY_SUBDESTAJO,
      payload: { idSubDestajo, key, value },
    });
  };

  const setPropertySingleSubdestajo = (key, value) => {
    dispatch({ type: SET_PROPERTY_SINGLE_SUBDESTAJO, payload: { key, value } });
  };

  const createNuevoSubDestajo = (idDestajo, numSubDestajos) => {
    dispatch({
      type: CREATE_NUEVO_SUBDESTAJO,
      payload: {
        idSubDestajo: "nuevo-" + numSubDestajos,
        idDestajo: idDestajo,
        nombre: "Nuevo Subdestajo",
        ponderacion: 0,
        orden: numSubDestajos + 1,
        progreso: 0,
      },
    });
  };

  const deleteSubDestajo = (idSubDestajo) => {
    dispatch({ type: DELETE_SUBDESTAJO, payload: idSubDestajo });
  };

  const postAvanceSubDestajo = (
    idSubDestajo,
    positivo,
    idProyecto,
    callback
  ) => {
    const cont = positivo ? 10 : -10;
    AvancesService.postAvance(idProyecto, { idSubDestajo, cont })
      .then(() => {
        if (callback) callback();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const reorderSubDestajos = (subdestajos) => {
    for (let i = 0; i < subdestajos.length; i++) subdestajos[i].orden = i;
    dispatch({ type: SUBDESTAJOS_RECEIVED, payload: subdestajos });
  };

  const postSubDestajos = (idProyecto, subdestajos, callback) => {
    if (!validarPorcentajeTotal(subdestajos, "idSubDestajo")) {
      return alert(
        "La suma de las ponderaciones debe ser 100. Verifica las ponderaciones de los subdestajos."
      );
    }
    SubdestajosService.postSubdestajos(idProyecto, subdestajos)
      .then(() => {
        success("Cambios a Subdestajos guardados exitosamente.");
        if (callback) callback();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const reordenarSubdestajos = ({ oldIndex, newIndex }) => {
    dispatch({
      type: REORDENAR_SUBDESTAJOS,
      payload: { oldIndex, newIndex },
    });
  };

  const setSubdestajo = (subdestajo) => {
    dispatch({ type: SET_SUBDESTAJO, payload: subdestajo });
  };

  const clearSingleSubdestajo = () => {
    dispatch({ type: SET_SUBDESTAJO, payload: null });
  };

  const postSingleSubdestajo = (idProyecto, subdestajo, callback) => {
    let service = SubdestajosService.putSingleSubdestajo;
    let { idSubDestajo } = subdestajo;
    if (isNaN(idSubDestajo)) {
      service = SubdestajosService.postSingleSubdestajo;
    }
    let data = { ...subdestajo };
    if (typeof data.responsable === "object" && data.responsable !== null) {
      data.responsable = data.responsable.idUsuario;
    }
    service(idProyecto, data).then(() => {
      success("Subdestajo guardado.");
      if (typeof callback === "function") {
        callback();
      }
    });
  };

  return (
    <SubdestajosContext.Provider
      value={{
        ...state,
        setSubdestajo,
        getSubDestajos,
        postSubDestajos,
        deleteSubDestajo,
        reorderSubDestajos,
        getSingleSubdestajo,
        postSingleSubdestajo,
        postAvanceSubDestajo,
        reordenarSubdestajos,
        clearSingleSubdestajo,
        createNuevoSubDestajo,
        setPropiedadSubDestajo,
        setPropertySingleSubdestajo,
      }}
    >
      {children}
    </SubdestajosContext.Provider>
  );
};
