import React, { useContext, useEffect, useState } from "react";
import ChecklistDashboard from "../components/checklists/ChecklistDashboard";
import ChecklistHeader from "../components/checklists/ChecklistHeader";
import ChecklistItems from "../components/checklists/ChecklistItems";
import ChecklistModes from "../components/checklists/ChecklistModes";
import { CapacitorContext } from "../context/CapacitorContext";
import Comentarios from "../components/checklists/Comentarios";
import GuardarMovil from "../components/movil/GuardarMovil";
import { MenuContext } from "../context/MenuContext";
import useChecklists from "../hooks/useChecklists";
import useProyecto from "../hooks/useProyecto";
import { useParams } from "react-router-dom";

const SingleChecklist = ({ idProyecto }) => {
  const { idChecklist } = useParams();

  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showModalComments, setShowModalComments] = useState(false);

  const { platform } = useContext(CapacitorContext);
  const { setSelected } = useContext(MenuContext);
  const { color } = useProyecto();

  const { progreso, fetchChecklist } =
    useChecklists();

  useEffect(() => {
    setSelected({ title: "checklist" });
    fetchChecklist(idChecklist);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`
        container-fluid h-100 
        ${platform === "web" ? "px-0" : "px-2"}
      `}
      style={{ overflowY: "auto" }}
    >
      <div className="row mt-3">
        <ChecklistDashboard color={color} progreso={progreso} />
      </div>
      <ChecklistModes />
      <div className="row px-2">
        <div className="card shadow-sm py-3 px-0 my-3 mb-4 border-0">
          <ChecklistHeader />
          <ChecklistItems />
        </div>
      </div>
      <Comentarios
        showModal={showModalComments}
        setShowModal={setShowModalComments}
        idProyecto={idProyecto}
      />
      <GuardarMovil
        idProyecto={idProyecto}
        showModal={showSaveModal}
        setShowModal={setShowSaveModal}
      />
    </div>
  );
};

export default SingleChecklist;
