import React, { useContext, useEffect } from "react";
import { EstimacionesContext } from "../../context/EstimacionesContext";
import SelectEstimacion from "../finanzas/SelectEstimacion";
import { ModalContext } from "../../context/ModalContext";
import EstimacionForm from "../finanzas/EstimacionForm";
import { useNavigate } from "react-router-dom";
import useProyecto from "../../hooks/useProyecto";
import { CapacitorContext } from "../../context/CapacitorContext";

const EstimacionesContrato = ({ idProyecto, idContrato }) => {
  const navigate = useNavigate();
  const { permiso } = useProyecto();

  const { platform } = useContext(CapacitorContext);
  const { modalComponent } = useContext(ModalContext);
  const {
    estimacion,
    estimaciones,
    editEstimacion,
    createEstimacion,
    selectEstimacion,
    getEstimacionesContrato,
  } = useContext(EstimacionesContext);

  useEffect(() => {
    fetchEstimaciones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto, idContrato]);

  function fetchEstimaciones() {
    getEstimacionesContrato(idProyecto, idContrato);
  }

  function handleSelect(idEstimacion) {
    selectEstimacion(idProyecto, idContrato, idEstimacion);
  }

  function handleEdit() {
    editEstimacion(estimacion);
    modalComponent(
      "Editar Estimacion",
      <EstimacionForm
        idProyecto={idProyecto}
        idContrato={idContrato}
        handleCallback={fetchEstimaciones}
      />
    );
  }

  function handleCreateEstimacion() {
    createEstimacion();
    modalComponent(
      "Agregar Estimacion",
      <EstimacionForm
        idProyecto={idProyecto}
        idContrato={idContrato}
        handleCallback={fetchEstimaciones}
      />
    );
  }

  function renderEstimaciones() {
    if (Array.isArray(estimaciones)) {
      return (
        <SelectEstimacion
          permiso={permiso}
          idProyecto={idProyecto}
          idContrato={idContrato}
          estimaciones={estimaciones}
          selectEstimacion={handleSelect}
          createEstimacion={createEstimacion}
        />
      );
    }
  }

  function renderButtons() {
    const components = [];
    if (estimacion !== null) {
      if (["admin", "contratista", "supervisor"].includes(permiso)) {
        components.push(
          <button
            key="edit"
            onClick={handleEdit}
            className="btn btn-outline-primary me-2"
          >
            <i className="fa fa-edit me-1" />
            Editar
          </button>
        );
      }
      components.push(
        <button
          onClick={() =>
            navigate(
              `/obra/${idProyecto}/finanzas/${idContrato}/estimacion/${estimacion.idEstimacion}/versiones`
            )
          }
          className="btn btn-outline-dark border me-2"
          key="versiones"
        >
          <i className="fa fa-tasks me-1" />
          Versiones
        </button>
      );
      // components.push(
      //   <div key="acciones" className="position-relative d-inline-block">
      //     <button
      //       onClick={() => setShowActions(!showActions)}
      //       className="btn btn-light me-3"
      //     >
      //       <i className="fa fa-ellipsis-v mx-1" />
      //     </button>
      //     {renderActions()}
      //   </div>
      // );
    }
    //if (permiso === "contratista" && platform !== "web") {
      components.push(
        <button
          key="agregar"
          onClick={handleCreateEstimacion}
          className="btn btn-outline-primary"
        >
          <i className="fa fa-plus" />
        </button>
      );
    //}

    return components;
  }

  // function renderActions() {
  //   if (showActions) {
  //     return <EstimacionesActions handleClose={() => setShowActions(false)} />;
  //   }
  // }

  const renderView = () => {
    if (permiso === "contratista" && platform !== "web" && Array.isArray(estimaciones)) {
      return (
        <div className="mt-2">
          {estimaciones.map((estimacion) => (
            <div onClick={() => navigate(`./estimacion/${estimacion.idEstimacion}/versiones`)} className="card border bg-light p-3 mb-3">
              <div className="row">
                <div className="col-9">Estimacion #{estimacion.numero}</div>
                <div className="col-3 text-end">
                  <i className="fa fa-chevron-right text-primary" />
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-12 col-md-6 py-2">{renderEstimaciones()}</div>
        <div className="col-12 col-md-6 py-2 text-md-end">
          {renderButtons()}
        </div>
      </div>
    );
  };

  return (
    <div>
      <p className="mb-0 bold">Estimaciones</p>
      {renderView()}
    </div>
  );
};

export default EstimacionesContrato;
