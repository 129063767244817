import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { MenuContext } from "../context/MenuContext";
import { ModalContext } from "../context/ModalContext";
import ObraCard from "../components/proyectos/ObraCard";
import ObraForm from "../components/proyectos/ObraForm";
import { ProyectosContext } from "../context/ProyectosContext";
import ObrasFilters from "../components/proyectos/ObrasFilters";

const Obras = () => {
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState(null);

  const {
    options,
    postObra,
    proyectos,
    hideOptions,
    viewOptions,
    getProyectos,
    editProyecto,
    deleteProyecto,
    createProyecto,
    clearProyectos,
    clearSingleProyecto,
  } = useContext(ProyectosContext);

  const { user } = useContext(AuthContext);

  const { selectTabs, setSelected } = useContext(MenuContext);

  const { confirm, clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    selectTabs();
    getProyectos();
    setSelected({ title: "obras" });
    return clearProyectos;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (proyecto) => {
    confirm(
      <p>
        ¿Estás seguro que deseas eliminar el proyecto {proyecto.nombre}? Esta
        acción NO puede deshacerse.
      </p>,
      () => deleteProyecto(proyecto.idProyecto)
    );
  };

  const handleCancel = () => {
    clearModal();
    clearSingleProyecto();
  };

  const handleEdit = (proyecto) => {
    editProyecto(proyecto);

    modalComponent("Editar Proyecto", <ObraForm handleCancel={handleCancel} />);
  };

  const handleCreate = () => {
    createProyecto();

    modalComponent(
      "Agregar Proyecto",
      <ObraForm handleCancel={handleCancel} />
    );
  };

  const renderProyectos = () => {
    if (Array.isArray(proyectos)) {
      if (proyectos.length === 0) {
        return <p>Aún no tienes proyectos completadas</p>;
      }
      let proyectosRender = proyectos;
      if (filter !== null) {
        proyectosRender = proyectosRender.filter((proyecto) =>
          filter === "activas"
            ? parseInt(proyecto.real) < 1
            : parseInt(proyecto.real) === 1
        );
      }
      if (query && query !== "") {
        proyectosRender = proyectosRender.filter(({ nombre, calle }) => {
          nombre = String(nombre).toLowerCase();
          calle = String(calle).toLowerCase();
          const currentQuery = String(query).toLowerCase();
          return (
            nombre.includes(currentQuery) ||
            nombre.startsWith(currentQuery) ||
            calle.includes(currentQuery) ||
            calle.startsWith(currentQuery)
          );
        });
      }
      if (proyectosRender.length === 0) {
        return (
          <p className="mx-3 px-1">
            No se encontraron proyectos que coincidan con tu búsqueda.
          </p>
        );
      }
      return proyectosRender.map((proyecto) => (
        <div key={proyecto.idProyecto} className="col-sm-12 col-lg-6 col-xl-4">
          <ObraCard
            key={`proyecto-${proyecto.idProyecto}`}
            user={user}
            options={options}
            postObra={postObra}
            proyecto={proyecto}
            handleEdit={handleEdit}
            viewOptions={viewOptions}
            hideOptions={hideOptions}
            handleDelete={handleDelete}
          />
        </div>
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div
      className="container-fluid view-container h-100"
      style={{
        overflowX: "hidden",
      }}
    >
      <div className="row mx-0">
        <ObrasFilters
          query={query}
          filter={filter}
          setQuery={setQuery}
          setFilter={setFilter}
          handleCreate={handleCreate}
        />
      </div>
      <div className="container-fluid px-0 mb-3 position-relative h-100">
        <div className="row">{renderProyectos()}</div>
      </div>
    </div>
  );
};

export default Obras;
