import { HIDE_SPINNER, SHOW_SPINNER } from "../actions/types";
import {
  PARTIDAS_PRECIARIO_RECIBIDO,
  CREATE_PARTIDA_PRECIARIO,
  DELETE_PARTIDA_PRECIARIO,
  EDIT_PARTIDAS_PRECIARIO,
  SEARCH_PARTIDAS_PRECIARIO,
  SET_PROPERTY_PARTIDA_PRECIARIO,
  SET_PRECIARIO,
} from "../types/partidasContrato";
import { search } from "../utils";

const partidaContratoSchema = (num) => ({
  idPartidaContrato: `nueva-${num}`,
  orden: num,
  nombre: "",
  clave: "",
});

const getPrevious = (partidas, conceptos, conceptosExtra) => {
  let prevConceptos = conceptos;
  let prevPartidas = partidas;
  let prevExtras = conceptosExtra;
  if (prevConceptos === null || !prevConceptos) prevConceptos = [];
  if (prevPartidas === null || !prevPartidas) prevPartidas = [];
  if (prevExtras === null || !prevExtras) prevExtras = [];
  prevConceptos = JSON.parse(JSON.stringify([...prevConceptos]));
  prevPartidas = JSON.parse(JSON.stringify([...prevPartidas]));
  prevExtras = JSON.parse(JSON.stringify([...prevExtras]));
  return { prevConceptos, prevPartidas, prevExtras };
};

const PartidasContratoReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_PRECIARIO:
      return { ...state, preciario: payload };
    case PARTIDAS_PRECIARIO_RECIBIDO:
      return { ...state, partidas: payload, editMode: null, loading: false };
    case SEARCH_PARTIDAS_PRECIARIO:
      if (payload !== "" && payload !== null) {
        let partidas2 = search(state.partidas, payload);
        let conceptos2 = search(state.conceptos, payload);
        let partidasSet = new Set();
        conceptos2.forEach((concepto) => {
          partidasSet.add(concepto.idPartidaContrato);
        });
        partidas2.forEach((partida) => {
          partidasSet.add(partida.idPartidaContrato);
        });
        let partidas3 = [];
        partidasSet.forEach((idPartidaContrato) => {
          let partida = state.partidas.find(
            (partida) => partida.idPartidaContrato === idPartidaContrato
          );
          if (partida) partida = { ...partida };
          partidas3.push(partida);
        });
        partidas2 = partidas3;
        let conceptosExtras2 = search(state.conceptosExtra, payload);
        if (state.query === "" || state.query === null) {
          let prevElements = getPrevious(
            state.partidas,
            state.conceptos,
            state.conceptosExtra
          );
          return {
            ...state,
            partidas: partidas2,
            conceptos: conceptos2,
            conceptosExtra: conceptosExtras2,
            ...prevElements,
            query: payload,
          };
        }
        return {
          ...state,
          partidas: partidas2,
          conceptos: conceptos2,
          conceptosExtra: conceptosExtras2,
          query: payload,
        };
      }
      return {
        ...state,
        partidas: state.prevPartidas,
        conceptos: state.prevConceptos,
        conceptosExtra: state.prevExtras,
        prevPartidas: null,
        prevConceptos: null,
        prevExtras: null,
        query: payload,
      };
    case DELETE_PARTIDA_PRECIARIO: {
      let partidas = [...state.partidas];
      let partida = partidas.find(
        (partida) => String(partida.idPartidaContrato) === String(payload)
      );
      if (partida) {
        partida.idPartidaContrato = `delete-${partida.idPartidaContrato}`;
      }
      return { ...state, partidas };
    }
    case EDIT_PARTIDAS_PRECIARIO:
      let prev = getPrevious(
        state.partidas,
        state.conceptos,
        state.conceptosExtra
      );
      return {
        ...state,
        editMode: "partidas",
        prevPartidas: prev.prevPartidas,
        prevConceptos: prev.prevConceptos,
        prevExtras: prev.prevExtras,
      };
    case SET_PROPERTY_PARTIDA_PRECIARIO:
      const { idPartidaContrato } = payload;
      const partidas = [...state.partidas];
      const partida = partidas.find(
        (partida) =>
          String(partida.idPartidaContrato) === String(idPartidaContrato)
      );
      if (partida) partida[payload.key] = payload.value;
      return { ...state, partidas };
    case CREATE_PARTIDA_PRECIARIO:
      let currPartidas = state.partidas;
      if (currPartidas === null) currPartidas = [];
      return {
        ...state,
        partidas: [
          ...currPartidas,
          partidaContratoSchema(currPartidas.length + 1),
        ],
      };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};
export default PartidasContratoReducer;
