import moment from "moment";
import {
  PREPEND_ENTRADAS,
  SET_MAX_ENTRADAS,
  APPEND_ENTRADA,
  APPEND_ADJUNTO,
  CANCEL_ADJUNTO,
  CLEAR_ADJUNTOS,
  RESET_ENTRADAS,
  CLEAR_BITACORA,
} from "../types/entradas";

export default (state, { type, payload }) => {
  switch (type) {
    case SET_MAX_ENTRADAS:
      return { ...state, max: state.max + payload };
    case APPEND_ENTRADA: {
      let entradas = state.entradas;
      if (!entradas || entradas === null) {
        entradas = [];
      }
      entradas = [...entradas];
      entradas.push(payload);
      return { ...state, entradas };
    }

    case PREPEND_ENTRADAS:
      let entradas = state.entradas;
      if (!entradas || entradas === null) {
        entradas = [];
      }
      entradas = [...entradas];
      let idEntradas = entradas.map((entrada) => entrada.idEntrada);
      if (Array.isArray(payload)) {
        let entradasToPush = payload.filter(
          (entrada) => !idEntradas.includes(entrada.idEntrada)
        );
        entradas = entradas.concat(entradasToPush);
      }
      entradas = entradas.sort((a, b) =>
        moment(a.createdAt).isAfter(moment(b.createdAt)) ? 1 : -1
      );
      return { ...state, entradas };
    case RESET_ENTRADAS:
      return { ...state, entradas: [] };
    case APPEND_ADJUNTO: {
      let adjuntos = state.adjuntos;
      if (Array.isArray(adjuntos)) adjuntos = [...adjuntos];
      else adjuntos = [];
      return { ...state, adjuntos: [...adjuntos, payload] };
    }
    case CANCEL_ADJUNTO:
      let adjuntos = [...state.adjuntos];
      adjuntos.splice(payload, 1);
      return { ...state, adjuntos };
    case CLEAR_ADJUNTOS:
      return { ...state, adjuntos: [] };
    case CLEAR_BITACORA:
      return { ...state, entradas: [] };
    default:
      return { ...state };
  }
};
