import React, { useContext } from "react";
import { Link } from "react-router-dom";
import useProyecto from "../../hooks/useProyecto";
import usePreciario from "../../hooks/usePreciario";
import { formatMonto, getValue } from "../../utils";
import { AppConfigContext } from "../../context/AppConfigContext";

const ConceptoEstimacion = ({ concepto }) => {
  let {
    estimado,
    cantidad,
    autorizado,
    supervisado,
    importe_autorizado,
    autorizadoAcumulado,
  } = concepto;
  const appconfig = useContext(AppConfigContext);
  const { idContrato, estimacion } = usePreciario();
  const { idProyecto } = useProyecto();

  const getUrl = () => {
    if(estimacion && estimacion !== null) {
      return `/obra/${idProyecto}/finanzas/${idContrato}/generador/${concepto.idConcepto}/estimacion/${estimacion.idEstimacion}`;
    }
    return `/obra/${idProyecto}/finanzas/${idContrato}/concepto/${concepto.idConcepto}`;
  }
  
  const renderPorcentaje = () => {
    if (parseFloat(cantidad) > 0) {
      return `${formatMonto(
        (parseFloat(autorizado) / parseFloat(cantidad)) * 100
      )}%`;
    }
  };

  return (
    <>
      <td className="bg-secondary ps-3">
        {getValue(appconfig, "conceptos_generadores", "boolean")  && estimacion !== null ? (
          <Link
            to={getUrl()}
            className="text-primary text-underline small"
          >
          {formatMonto(!isNaN(parseFloat(estimado)) ? estimado : 0.0)}
          </Link>
        ) : (
          <span>
            {formatMonto(!isNaN(parseFloat(estimado)) ? estimado : 0.0)}
          </span>
        )}
      </td>
      {getValue(appconfig, "supervise_estimates", "boolean") && (
        <td className="bg-secondary">
          {formatMonto(!isNaN(parseFloat(supervisado)) ? supervisado : 0.0)}
        </td>
      )}
      {getValue(appconfig, "supervise_estimates", "boolean") && (
        <td className="bg-secondary">
          {formatMonto(!isNaN(parseFloat(autorizado)) ? autorizado : 0.0)}
        </td>
      )}
      <td className="bg-secondary">
        $
        {formatMonto(
          !isNaN(parseFloat(importe_autorizado)) ? importe_autorizado : 0.0
        )}
      </td>
      <td className="bg-secondary">
        {formatMonto(autorizadoAcumulado !== null ? autorizadoAcumulado : 0.0)}
      </td>
      <td className="bg-secondary">
        {formatMonto(parseFloat(cantidad) - parseFloat(autorizado))}
      </td>
      <td className="bg-secondary">{renderPorcentaje()}</td>
    </>
  );
};

export default ConceptoEstimacion;
