import {
  EDIT_MODE_ON,
  EDIT_MODE_OFF,
  SET_EDIT_MODE,
  SET_LEVEL,
} from "../types/editmode";

export default (state, { type, payload }) => {
  switch (type) {
    case SET_EDIT_MODE:
      return {
        ...state,
        editMode: !["coordinador", "admin"].includes(payload),
        permiso: payload,
      };
    case EDIT_MODE_ON:
      return { ...state, editMode: payload };
    case EDIT_MODE_OFF:
      return { ...state, editMode: false };
    case SET_LEVEL:
      if (state.level === payload) return { ...state, open: ++state.open };
      let open = state.open;
      if (open - 1 <= 0) {
        return { ...state, level: payload, open: 1 };
      }
      return { ...state, open: --open };
    default:
      return { ...state };
  }
};
